import React, { useEffect, useState, useCallback } from "react";
import PropTypes from "prop-types";
import { observer, inject, PropTypes as MobXPropTypes } from "mobx-react";
import { DragDropContext, Droppable } from "react-beautiful-dnd";

import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";

import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import Collapse from "@mui/material/Collapse";
import CardContent from "@mui/material/CardContent";
import Grid from "@mui/material/Grid";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import IconButton from "@mui/material/IconButton";
import MenuItem from "@mui/material/MenuItem";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import { CircularProgress, Select } from "@mui/material";

import { styled } from "@mui/system";

import DraggableProductChip from "./DraggableChip";
import DataTable from "../../../../components/Datatable/Datatable";

import Bucket from "./BucketProductChooser/Bucket";
import PositiveAction from "../../../../components/Button/PositiveAction";
import NegativeAction from "../../../../components/Button/NegativeAction";
import Switch from "../../../../components/Switch";
import { checkForNoEligibilityRules } from "../AddEditValidation";

// This is to apply custom styling to the search button
const StyledGridItem = styled(
  Grid,
  {},
)({ button: { height: "100%", width: "100%" } });

const customStyling = {
  dragAndDrop: {
    bucket: {
      topBarStyling: { minHeight: "40px" },
      boxStyling: { marginTop: "10px", minHeight: "250px" },
      gridSizes: { bucket: 4, qtyTextField: 3 },
    },
    defaultBucket: {
      paddingBottom: "5px",
      paddingTop: "5px",
    },
  },
  generic: { defaultGridSpacing: 3, defaultTopPadding: 3, fullWidthGrid: 12 },
  productRulesForm: {
    allowAnyProduct: {
      switchComponent: { display: "flex", justifyContent: "flex-end" },
      qtyInput: { display: "flex", justifyContent: "flex-end", mb: 2, mt: 1 },
    },
    buttons: { height: "100%" },
    tooltipLeftPadding: 1,
    titleTextMargin: "auto",
    tableSubtextColour: "grey",
    tableSubtextSize: 14,
    tableColumnPadding: { paddingTop: "5px", paddingBottom: "5px" },
    gridSizes: {
      addBucket: 3,
      dropdown: 4,
      search: 5,
      searchButton: 3,
      titleComponents: 6,
    },
  },
};

const rowsPerPageOptions = [10, 15, 20, 25];

const handleDragEnd =
  handleBucketDrop =>
  ({ destination, source, draggableId }) => {
    if (!destination) {
      return;
    }
    if (destination.droppableId === source.droppableId) {
      return;
    }

    handleBucketDrop(draggableId, source.droppableId, destination.droppableId);
  };

const itemFilter = filter => item => {
  return !filter || item.name.toLowerCase().includes(filter.toLowerCase());
};

const BucketProductChooser = ({
  appStore,
  // eslint-disable-next-line react/require-default-props
  buckets,
  allowAnyProduct = false,
  handleAdd,
  handleRemove,
  handleBucketDrop,
  handleRemoveBucket,
  handleBucketAdd,
  handleBucketChange,
  handleSetAllowAnyProduct,
  bucketErrors,
  submitError,
  prefix,
  action,
  showCollapsibleContent,
  toggleCollapsed,
  readOnly = false,
}) => {
  const [loading, setLoading] = useState(false);

  const [productBucketFilter, setProductBucketFilter] = useState("");

  const [tableOptions, setTableOptions] = useState({
    rowsPerPage: rowsPerPageOptions[0],
  });
  const [currentPage, setCurrentPage] = useState(0);
  const [rowCount, setRowCount] = useState(0);
  const [results, setResults] = useState([]);

  const [searchOptions, setSearchOptions] = useState({
    textSearch: "",
    searchType: "PRODUCT",
  });
  const [paginationUpdate, setPaginationUpdate] = useState(false);
  const [emptyQueryMessage, setEmptyQueryMessage] = useState(false);

  const searchTypes = [
    { text: "Product", value: "PRODUCT" },
    { text: "Major Group", value: "MAJORGROUP" },
    { text: "Product Group", value: "PRODUCTGROUP" },
    { text: "Sub Group", value: "SUBGROUP" },
    { text: "Tag", value: "GENERIC" },
  ];

  const handleBucketFilterChange = ({ target: { value } }) => {
    setProductBucketFilter(value);
  };

  const handleSearchOptionsChange = ({ target: { value, name } }) => {
    setSearchOptions({ ...searchOptions, [name]: value });
  };

  const getItemStyle = (isDraggingOver, id) => {
    const error = bucketErrors.some(e =>
      [e.itemBucket, e.conflictBucket].includes(id),
    );

    const highlightNonDefaultBuckets =
      id &&
      submitError?.emptyNonDefaultBucket &&
      !["default", "staging", "exclusions"].includes(id);

    const defaultStyle =
      error || highlightNonDefaultBuckets ? "1px solid red" : "";

    return {
      backgroundColor: isDraggingOver ? "aliceblue" : "inherit",
      border: isDraggingOver ? "1px dashed gray" : defaultStyle,
    };
  };

  const shouldShowFilter = () => {
    const productCount = Object.keys(buckets).reduce((acc, bucket) => {
      return acc + Object.keys(buckets[bucket].items).length;
    }, 0);
    return productCount > 10;
  };

  // queryProducts could be improved by moving the itemsToExclude to be filtered via the state on the frontend.
  // In "applyOptions" it should be possible to add a not filter.
  const queryProducts = useCallback(
    ({ textSearch, page, rowsPerPage, itemsToExclude }) =>
      appStore.productStore
        .findAll(
          2000,
          { tillProductName_contains: textSearch, ID_notIn: itemsToExclude },
          "promotionProductSearch",
        )
        .then(() => {
          const paginatedResult = appStore.productStore.applyOptions({
            order: "ASC",
            orderBy: "tillProductName",
            rowsPerPage,
            offset: rowsPerPage * page,
            modelType: "promotionProductSearch",
          });

          setResults(paginatedResult.items.map(item => item.asPromoRow()));
          setRowCount(paginatedResult.count);

          if (!paginatedResult.count) {
            setEmptyQueryMessage(true);
          }

          setLoading(false);
        }),
    [appStore.productStore],
  );

  const queryProductTags = useCallback(
    ({ textSearch, page, rowsPerPage, searchType, itemsToExclude }) =>
      appStore.productTagStore
        .findAll()
        .then(() => {
          const searchFilter = {
            type: "AND",
            filter: {
              type_is: searchType,
              name_contains: textSearch,
              id_not: itemsToExclude,
            },
          };

          const resultOrdering = { sortCol: "name", direction: "asc" };

          const paginatedResult = appStore.productTagStore
            .paginate(
              rowsPerPage,
              rowsPerPage * page,
              resultOrdering,
              searchFilter,
            )
            .map(item => item.asPromoRow());

          setResults(paginatedResult);
        })
        .finally(() => {
          setRowCount(appStore.productTagStore.count);

          if (!appStore.productTagStore.count) {
            setEmptyQueryMessage(true);
          }

          setLoading(false);
        }),
    [appStore.productTagStore],
  );

  const sendQuery = useCallback(() => {
    const { textSearch, searchType } = searchOptions;

    let tableOptionsToUse = {};

    // If this is a pagination update use the details in state
    if (paginationUpdate) {
      setPaginationUpdate(false);

      tableOptionsToUse = tableOptions;
    } else {
      // If the user has resubmitted the search then page and rows should be reset to zero
      tableOptionsToUse = {
        rowsPerPage: rowsPerPageOptions[0],
      };
      setLoading(true);
      setCurrentPage(0);
      setTableOptions(tableOptionsToUse);
    }

    setEmptyQueryMessage(false);

    const { rowsPerPage } = tableOptionsToUse;

    const itemsToExclude = Object.values(buckets).reduce((acc, curr) => {
      const bucketIds = curr?.items ? Object.keys(curr.items) : [];

      return [...acc, ...bucketIds];
    }, []);

    switch (searchType) {
      case "PRODUCT":
        queryProducts({
          textSearch,
          page: currentPage,
          rowsPerPage,
          itemsToExclude,
        });
        break;
      case "MAJORGROUP":
      case "PRODUCTGROUP":
      case "SUBGROUP":
      case "GENERIC":
        queryProductTags({
          textSearch,
          page: currentPage,
          rowsPerPage,
          searchType,
          itemsToExclude,
        });
        break;
      default:
        throw new Error("Invalid search arguement");
    }
  }, [
    currentPage,
    paginationUpdate,
    queryProductTags,
    queryProducts,
    searchOptions,
    buckets,
    tableOptions,
  ]);

  useEffect(() => {
    if (paginationUpdate) {
      sendQuery();
    }
  }, [paginationUpdate, sendQuery]);

  const handlePageChange = newPage => {
    setCurrentPage(newPage);
    setPaginationUpdate(true);
  };

  const handleChangeRowsPerPage = numberOfRows => {
    setTableOptions({
      ...tableOptions,
      page: 0,
      rowsPerPage: parseInt(numberOfRows, 10),
    });
    setPaginationUpdate(true);
  };

  const handleRowClick = (rowMeta, { dataIndex }) => {
    const rowData = results[dataIndex];
    setPaginationUpdate(true);
    handleAdd(rowData);
  };

  const dataTableColumns = [
    {
      label: "Name",
      name: "name",
      options: {
        setCellProps: () => ({
          style: customStyling.productRulesForm.tableColumnPadding,
        }),
        customBodyRenderLite: tableMeta => {
          const rowData = results[tableMeta];
          const isProduct = rowData.tillProductName;

          return isProduct ? (
            <Box>
              <Typography>{rowData.tillProductName}</Typography>
              <Typography
                color={customStyling.productRulesForm.tableSubtextColour}
                fontSize={customStyling.productRulesForm.tableSubtextSize}
              >
                {rowData.name}
              </Typography>
            </Box>
          ) : (
            <Typography>{rowData.name}</Typography>
          );
        },
        sort: false,
      },
    },
    // if the current view has results that are products show the groups column
    ...(results?.[0]?.type === "PRODUCT"
      ? [
          {
            label: "Product Group",
            name: "productGroup",
            options: {
              setCellProps: () => ({
                style: customStyling.productRulesForm.tableColumnPadding,
              }),
              customBodyRenderLite: tableMeta => {
                const rowData = results[tableMeta];

                return (
                  <Box>
                    <Typography>{rowData.productGroup}</Typography>
                  </Box>
                );
              },
              sort: false,
            },
          },
        ]
      : []),
    {
      label: "POS Code",
      name: "POSId",
      options: {
        setCellProps: () => ({
          style: customStyling.productRulesForm.tableColumnPadding,
        }),
        customBodyRenderLite: tableMeta => {
          const rowData = results[tableMeta];

          return (
            <Box>
              <Typography>{rowData.POSId}</Typography>
            </Box>
          );
        },
        sort: false,
      },
    },
  ];

  const exclusionBucketTitleBar = (
    <>
      <Grid item alignItems="center" display="flex">
        <Typography>Exclusions</Typography>
      </Grid>
      <Grid item alignItems="center" display="flex" sx={{ pl: 1 }}>
        <Tooltip
          arrow
          title="Items in here that are matched with items in a basket will make this promotion ineligible."
        >
          <HelpOutlineIcon fontSize="small" />
        </Tooltip>
      </Grid>
    </>
  );

  const getBucketTitleBar = (bucket, bucketId, bucketIndex) => (
    <Grid
      container
      display="flex"
      justifyContent="space-between"
      alignItems="center"
    >
      <Grid item>
        <Typography>{bucket.name || `Bucket ${bucketIndex + 1}`}</Typography>
      </Grid>
      {action?.id !== "multibuy" && (
        <Grid item xs={customStyling.dragAndDrop.bucket.gridSizes.qtyTextField}>
          <TextField
            label="Qty"
            size="small"
            type="number"
            value={bucket.quantity}
            inputProps={{ min: 1, "aria-label": "bucket-quantity" }}
            variant="outlined"
            onChange={handleBucketChange(bucketId, "quantity")}
          />
        </Grid>
      )}
      {bucketId !== "default" && (
        <Grid item>
          <NegativeAction
            buttonText="Delete"
            onClick={() => {
              if (searchOptions.textSearch) {
                setPaginationUpdate(true);
              }

              handleRemoveBucket(bucketId);
            }}
          />
        </Grid>
      )}
    </Grid>
  );

  const handleAllowAnyToggle = () => handleSetAllowAnyProduct(!allowAnyProduct);

  return (
    <Card>
      <CardContent>
        <Grid container spacing={customStyling.generic.defaultGridSpacing}>
          <Grid
            container
            item
            xs={customStyling.productRulesForm.gridSizes.titleComponents}
          >
            <Grid item>
              <Typography>Eligibility Rules</Typography>
            </Grid>
            <Grid item pl={customStyling.productRulesForm.tooltipLeftPadding}>
              <Tooltip
                arrow
                title="Search for items that should make this promotion eligible."
              >
                <HelpOutlineIcon fontSize="small" />
              </Tooltip>
            </Grid>
            {allowAnyProduct === false &&
            checkForNoEligibilityRules(buckets) ? (
              <Grid container item>
                <Typography
                  data-testid="no-eligibility-rules-header-warning"
                  color="red"
                >
                  You have no eligibility rules defined
                </Typography>
              </Grid>
            ) : (
              <></>
            )}
          </Grid>
          <Grid
            item
            container
            xs={customStyling.productRulesForm.gridSizes.titleComponents}
          >
            <Grid
              item
              sx={
                customStyling.productRulesForm.allowAnyProduct.switchComponent
              }
              xs={customStyling.generic.fullWidthGrid}
            >
              <Switch
                active={allowAnyProduct}
                onChange={handleAllowAnyToggle}
                colour="success"
                testId="toggle-any-product"
                label="Any Item"
              />
              <IconButton
                onClick={toggleCollapsed}
                data-testid="eligibility-rules-collapse-toggle"
              >
                {showCollapsibleContent ? <ExpandLess /> : <ExpandMore />}
              </IconButton>
            </Grid>
            {allowAnyProduct && action?.id !== "multibuy" ? (
              <Grid
                item
                sx={customStyling.productRulesForm.allowAnyProduct.qtyInput}
                xs={customStyling.generic.fullWidthGrid}
              >
                <TextField
                  label="Trigger Quantity"
                  size="small"
                  type="number"
                  value={buckets.default.quantity}
                  inputProps={{ min: 1, "aria-label": "bucket-quantity" }}
                  variant="outlined"
                  onChange={handleBucketChange("default", "quantity")}
                  data-testid="any-product-trigger-quantity"
                />
              </Grid>
            ) : (
              <></>
            )}
          </Grid>
        </Grid>
        <Collapse in={showCollapsibleContent}>
          {!allowAnyProduct ? (
            <>
              {!readOnly && (
                <>
                  <Grid
                    container
                    spacing={customStyling.generic.defaultGridSpacing}
                    pt={customStyling.generic.defaultTopPadding}
                  >
                    <Grid
                      item
                      lg={customStyling.productRulesForm.gridSizes.search}
                    >
                      <TextField
                        fullWidth
                        name="textSearch"
                        variant="outlined"
                        label="Search"
                        value={searchOptions.textSearch}
                        onChange={handleSearchOptionsChange}
                        onKeyDown={({ keyCode }) => {
                          // 13 is enter
                          if (keyCode === 13 && searchOptions.textSearch) {
                            sendQuery();
                          }
                        }}
                        data-testid="product-rules-search-input"
                      />
                    </Grid>
                    <Grid
                      item
                      lg={customStyling.productRulesForm.gridSizes.dropdown}
                    >
                      <Select
                        fullWidth
                        name="searchType"
                        variant="outlined"
                        value={searchOptions.searchType}
                        onChange={handleSearchOptionsChange}
                        data-testid="product-rules-search-type-select"
                      >
                        {searchTypes.map(({ text, value }) => (
                          <MenuItem value={value} key={text}>
                            {text}
                          </MenuItem>
                        ))}
                      </Select>
                    </Grid>
                    <StyledGridItem
                      item
                      lg={customStyling.productRulesForm.gridSizes.searchButton}
                    >
                      <PositiveAction
                        buttonText="Search"
                        onClick={sendQuery}
                        disabled={loading || !searchOptions.textSearch}
                        testId="product-rules-submit-button"
                      />
                    </StyledGridItem>
                  </Grid>
                  <Grid item pt={customStyling.generic.defaultTopPadding}>
                    {loading && (
                      <Box textAlign="center">
                        <CircularProgress />
                      </Box>
                    )}
                    {!loading ? (
                      <>
                        {emptyQueryMessage && (
                          <Alert severity="info">
                            <AlertTitle>
                              <strong>No Results Found.</strong>
                            </AlertTitle>
                            <Typography>
                              Check you input the correct text and have the
                              correct query type selected in the dropdown.
                            </Typography>
                          </Alert>
                        )}
                        {results.length ? (
                          <Box mb="20px">
                            <DataTable
                              columns={dataTableColumns}
                              elevation={1}
                              tableData={results}
                              handleChangePage={handlePageChange}
                              handleChangeRowsPerPage={handleChangeRowsPerPage}
                              handleRowClick={handleRowClick}
                              page={currentPage}
                              rowsPerPageOptions={rowsPerPageOptions}
                              totalRowCount={rowCount}
                              tableOptions={tableOptions}
                              viewColumns={false}
                            />
                          </Box>
                        ) : (
                          <></>
                        )}
                      </>
                    ) : (
                      <></>
                    )}
                  </Grid>
                  {shouldShowFilter() && (
                    <Grid item pt={customStyling.generic.defaultTopPadding}>
                      <Typography>Bucket Filtering</Typography>
                      <TextField
                        variant="outlined"
                        label="Filter products within buckets"
                        placeholder="Filter"
                        margin="normal"
                        fullWidth
                        onChange={handleBucketFilterChange}
                        value={productBucketFilter}
                      />
                    </Grid>
                  )}
                </>
              )}
              <DragDropContext onDragEnd={handleDragEnd(handleBucketDrop)}>
                <Grid container columnSpacing={2} rowSpacing={1} mt={1}>
                  {!readOnly && (
                    <>
                      <Grid container width="auto" pl={2}>
                        <Grid item>
                          <Typography>Shortlisted Items</Typography>
                        </Grid>
                        <Grid
                          item
                          pl={customStyling.productRulesForm.tooltipLeftPadding}
                        >
                          <Tooltip
                            arrow
                            title="This is your shortlist. Items here are waiting for you to add them to a bucket and are not conditions of the promotion yet."
                          >
                            <HelpOutlineIcon fontSize="small" />
                          </Tooltip>
                        </Grid>
                      </Grid>
                      {/*
        This is currently disabled because I'm considering other solutions
        <Grid item>
          <Button
            type="button"
            color="secondary"
            endIcon={<AddIcon />}
            onClick={() => {}}
          >
            Move all to default bucket
          </Button>
        </Grid> */}
                      <Grid item xs={customStyling.generic.fullWidthGrid}>
                        <Paper variant="outlined">
                          <Droppable
                            droppableId="staging"
                            direction="horizontal"
                            sx={{ flexShrink: 0 }}
                          >
                            {(provided, snapshot) => (
                              <Box
                                ref={provided.innerRef}
                                {...provided.droppableProps}
                                style={getItemStyle(snapshot.isDraggingOver)}
                                sx={customStyling.dragAndDrop.defaultBucket}
                              >
                                {!Object.keys(buckets.staging.items).length && (
                                  <Typography
                                    color="secondary"
                                    width="100%"
                                    textAlign="center"
                                  >
                                    Items selected from your search results will
                                    appear here
                                  </Typography>
                                )}
                                {Object.values(buckets.staging.items)
                                  .filter(itemFilter(productBucketFilter))
                                  .map((item, index) => (
                                    <DraggableProductChip
                                      item={item}
                                      index={index}
                                      handleRemove={itemToRemove => {
                                        if (searchOptions.textSearch) {
                                          setPaginationUpdate(true);
                                        }

                                        return handleRemove("staging")(
                                          itemToRemove,
                                        )();
                                      }}
                                      prefix={prefix}
                                      highlight={false}
                                      key={item.id}
                                    />
                                  ))}
                                {provided.placeholder}
                              </Box>
                            )}
                          </Droppable>
                        </Paper>
                      </Grid>
                    </>
                  )}
                  <Grid container width="auto" mt="24px" pl={2}>
                    <Grid item>
                      <Typography>Bucket Configuration</Typography>
                    </Grid>
                    <Grid
                      item
                      pl={customStyling.productRulesForm.tooltipLeftPadding}
                    >
                      <Tooltip
                        arrow
                        title="Bring items from your shortlist into buckets to apply them as conditions for the promotion."
                      >
                        <HelpOutlineIcon fontSize="small" />
                      </Tooltip>
                    </Grid>
                  </Grid>

                  <Grid
                    container
                    justifyContent="space-between"
                    pt="10px"
                    pl={2}
                    mb="10px"
                  >
                    <Grid item>
                      <Alert severity="info">
                        <AlertTitle>
                          <strong>
                            {Object.keys(buckets).filter(
                              key => !["staging", "exclusions"].includes(key),
                            ).length === 1
                              ? "Tip: Single Bucket Promotions"
                              : "Tip: Multi-bucket Promotions"}
                          </strong>
                        </AlertTitle>
                        <Typography>
                          {Object.keys(buckets).filter(
                            key => !["staging", "exclusions"].includes(key),
                          ).length === 1
                            ? "With a single bucket promotion any of the bucket's contents can trigger the promotion."
                            : "With a multi-bucket promotion, items from each bucket must be present in the basket to trigger the promotion."}
                        </Typography>
                        {bucketErrors.map(be => (
                          <Typography key={be.message}>
                            &bull; {be.message}
                          </Typography>
                        ))}
                      </Alert>
                    </Grid>

                    {!readOnly && (
                      <Grid item display="flex" alignItems="center">
                        <PositiveAction
                          buttonText="Add bucket"
                          onClick={handleBucketAdd}
                          disabled={action?.id === "multibuy"}
                          testId="add-bucket-button"
                        />
                      </Grid>
                    )}
                  </Grid>

                  {Object.entries(buckets || {})
                    .filter(([id]) =>
                      action?.id === "multibuy"
                        ? id === "default"
                        : !["discountable", "staging", "exclusions"].includes(
                            id,
                          ),
                    )
                    .map(([bucketId, bucket], bucketIndex) => (
                      <Bucket
                        bucket={bucket}
                        bucketKey={bucketId}
                        bucketStyle="DefaultBucket"
                        bucketErrors={bucketErrors}
                        getItemStyle={getItemStyle}
                        handleRemove={itemToRemove => {
                          if (searchOptions.textSearch) {
                            setPaginationUpdate(true);
                          }

                          return handleRemove(bucketId)(itemToRemove)();
                        }}
                        highlight={item => {
                          return bucketErrors.some(e =>
                            [e.item.meta?.id, e.conflict.meta.id].includes(
                              item.meta.id,
                            ),
                          );
                        }}
                        key={bucketId}
                        itemFilter={itemFilter}
                        prefix={prefix}
                        productBucketFilter={productBucketFilter}
                        searchOptions={searchOptions}
                        setPaginationUpdate={setPaginationUpdate}
                        titleBar={getBucketTitleBar(
                          bucket,
                          bucketId,
                          bucketIndex,
                        )}
                      />
                    ))}

                  <Bucket
                    bucket={buckets.exclusions}
                    bucketErrors={bucketErrors}
                    bucketStyle="ExclusionBucket"
                    bucketKey="exclusions"
                    getItemStyle={getItemStyle}
                    handleRemove={itemToRemove => {
                      if (searchOptions.textSearch) {
                        setPaginationUpdate(true);
                      }

                      return handleRemove("exclusions")(itemToRemove)();
                    }}
                    highlight={() => false}
                    itemFilter={itemFilter}
                    key="exclusions"
                    prefix={prefix}
                    productBucketFilter={productBucketFilter}
                    searchOptions={searchOptions}
                    setPaginationUpdate={setPaginationUpdate}
                    titleBar={exclusionBucketTitleBar}
                  />

                  {bucketErrors.length > 0 && (
                    <Grid item xs={customStyling.generic.fullWidthGrid}>
                      <Alert severity="error">
                        <AlertTitle>Overlapping Items</AlertTitle>
                        <Typography>
                          Individual items may only exist in a single bucket,
                          please address the issues below before continuing.
                        </Typography>
                        {bucketErrors.map(be => (
                          <Typography key={be.message}>
                            &bull; {be.message}
                          </Typography>
                        ))}
                      </Alert>
                    </Grid>
                  )}
                  {action?.id === "multibuy" &&
                  Object.keys(buckets || {}).filter(
                    bucket =>
                      ![
                        "default",
                        "discountable",
                        "staging",
                        "exclusions",
                      ].includes(bucket),
                  ).length ? (
                    <Grid item xs={customStyling.generic.fullWidthGrid} mt={1}>
                      <Alert
                        severity="warning"
                        data-testid="multibuy-warning-alert"
                      >
                        <AlertTitle>
                          <strong>Multibuy Warning</strong>
                        </AlertTitle>
                        <Typography>
                          Changing to the multibuy promotion type will, once
                          saved, discard other buckets and change the default
                          bucket quantity to 1.
                        </Typography>
                      </Alert>
                    </Grid>
                  ) : (
                    <></>
                  )}
                </Grid>
              </DragDropContext>
            </>
          ) : (
            <Alert severity="info" data-testid="any-product-alert">
              <AlertTitle>
                <strong>Allow Any Products Enabled</strong>
              </AlertTitle>
              <Typography>
                This means that any current or future products will be
                applicable to this promotion.
              </Typography>
            </Alert>
          )}
        </Collapse>
      </CardContent>
    </Card>
  );
};

BucketProductChooser.propTypes = {
  appStore: MobXPropTypes.objectOrObservableObject.isRequired,
  buckets: (props, propName, componentName) => {
    if (!props[propName].default) {
      return new Error(
        `Invalid prop \`${propName}\` supplied to \`${componentName}\`. A default bucket is required.`,
      );
    }
    // Each bucket requires an items property
    const errors = Object.entries(props[propName]).filter(
      ([, value]) => !value.items,
    );
    if (errors.length) {
      return new Error(
        `Invalid prop \`${propName}\` supplied to \`${componentName}\`. All buckets require an items property.`,
      );
    }
    return null;
  },
  allowAnyProduct: PropTypes.bool,
  handleAdd: PropTypes.func.isRequired,
  handleRemove: PropTypes.func.isRequired,
  handleBucketDrop: PropTypes.func.isRequired,
  handleBucketAdd: PropTypes.func.isRequired,
  handleBucketChange: PropTypes.func.isRequired,
  handleRemoveBucket: PropTypes.func.isRequired,
  handleSetAllowAnyProduct: PropTypes.func.isRequired,
  prefix: PropTypes.string.isRequired,
  bucketErrors: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  submitError: PropTypes.shape({
    emptyNonDefaultBucket: PropTypes.bool,
  }).isRequired,
  action: PropTypes.shape({
    id: PropTypes.string,
  }).isRequired,
  showCollapsibleContent: PropTypes.bool.isRequired,
  toggleCollapsed: PropTypes.func.isRequired,
  readOnly: PropTypes.bool,
};

const Component = inject("appStore")(observer(BucketProductChooser));

export default Component;
