import { types, getEnv, getParent, flow } from "mobx-state-tree";
import gql from "graphql-tag";

import { removeTypename } from "../helpers";
import Session, { fragments as sessionFragments } from "./models/Session";

// @todo treat versioning changes on preProcess
export default types
  .model("SessionStore", {
    sessions: types.optional(types.map(Session), {}),
  })
  .views(self => ({
    get appStore() {
      return getParent(self);
    },
  }))
  .actions(self => {
    return {
      find: flow(function* find(id) {
        const { apolloClient } = getEnv(self);

        try {
          const response = yield apolloClient.query({
            query: gql`
            {
              session(id: "${id}") {
                ...SessionFullDetails
              }
            }
            ${sessionFragments.fullDetails}
          `,
          });

          const session = self.track(response.data.session);

          return Promise.resolve(session);
        } catch (error) {
          return Promise.reject(error);
        }
      }),

      track(entity) {
        if (!self.sessions.has(entity.id)) {
          const input = removeTypename(entity);

          input.user = self.appStore.userStore.track(input.user);

          return self.sessions.put(input);
        }

        return self.sessions.get(entity.id);
      },
    };
  });
