import { types, getParent, getEnv, flow } from "mobx-state-tree";
import gql from "graphql-tag";

import Customer, { fragments as CustomerFragments } from "./Customer";
import Site, { fragments as SiteFragments } from "./Site";
import { fragments as NoteFragments } from "./Note";
import User, { fragments as UserFragments } from "./User";
import { fragments as DepositFragments } from "./Deposit";

import { transformer } from "../../helpers";
import DateTime from "./DateTime";

export const fragments = {
  fullDetails: gql`
    fragment BookingFullDetails on Booking {
      id
      status
      errorReason
      customer {
        ...CustomerFullDetails
      }
      site {
        ...SiteFullDetails
      }
      expectedGuests
      expectedAt
      deposits {
        ...DepositFullDetails
      }
      notes {
        ...NoteFullDetails
      }
      createdAt
      createdBy {
        ...UserFullDetails
      }
      updatedAt
      updatedBy {
        ...UserFullDetails
      }
    }
    ${UserFragments.fullDetails}
    ${CustomerFragments.fullDetails}
    ${SiteFragments.fullDetails}
    ${DepositFragments.fullDetails}
    ${NoteFragments.fullDetails}
  `,
};

export default types
  .model("Booking", {
    id: types.identifier,
    status: types.string,
    errorReason: types.maybeNull(types.string),
    site: types.reference(Site),
    customer: types.reference(Customer),
    expectedGuests: types.string,
    expectedAt: DateTime,
    createdAt: DateTime,
    createdBy: types.reference(User),
    updatedAt: types.maybeNull(DateTime),
    updatedBy: types.maybeNull(types.reference(User)),
  })
  .views(self => ({
    get bookingStore() {
      return getParent(getParent(self));
    },
    get appStore() {
      return getParent(self.bookingStore);
    },
    get deposits() {
      return [...self.appStore.depositStore.items.values()].filter(
        item => item.for.id === self.id,
      );
    },
  }))
  .actions(self => {
    // const { apolloClient } = getEnv(self);

    return {
      accept() {
        self.status = "ACCEPTED";
      },

      cancel: flow(function* cancel() {
        self.status = "CANCELLED";
        // @todo this gets called on a clone so outside scope apolloClient is not available
        const { apolloClient } = getEnv(self);

        yield apolloClient.mutate({
          mutation: gql`
          mutation updateBooking {
              cancelBooking(id: "${self.id}") {
                status 
              }
            }
        `,
        });
      }),

      setSite(value) {
        self.site = value;
      },

      setCustomer(value) {
        self.customer = value;
      },

      setExpectedGuests(value) {
        self.expectedGuests = value;
      },

      setExpectedAt(value) {
        self.expectedAt = value;
      },

      save: flow(function* save() {
        // @todo this gets called on a clone so outside scope apolloClient is not available
        const { apolloClient } = getEnv(self);
        const params = transformer({
          id: self.id,
          expectedGuests: self.expectedGuests,
          expectedAt: self.expectedAt.toISOString(),
        });

        yield apolloClient.mutate({
          mutation: gql`
          mutation updateBooking {
              updateBooking(input: {
                status: ${self.status}
                errorReason: null
                ${params}
              }) {
                 id
              }
          }
      `,
        });
      }),
    };
  });
