import React, { useState, useEffect } from "react";
import moment from "moment";
import PropTypes from "prop-types";
import { useParams } from "react-router-dom";
import gql from "graphql-tag";
import { useMutation, useQuery } from "@apollo/client";
import { useSnackbar } from "notistack";

import Alert from "@mui/material/Alert";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

import { DateRangeFilter } from "../../../components/Basetable/Filters/DateRangeFilter";
import Switch from "../../../components/Switch";
import PositiveAction from "../../../components/Button/PositiveAction";
import ProductOptionGroupsTable from "../../ProductOptionGroups/Components/ProductOptionGroupsTable";

import {
  GET_PRODUCT_OPTION_GROUPS_BY_PRODUCT_ID,
  GET_PRODUCT_OPTIONS_BY_PRODUCT_ID,
  UPDATE_PRODUCT,
} from "../../../helpers/apollo/utils";

const defaultColumns = [
  { label: "Name", name: "name", options: { filter: false, sort: true } },
  {
    label: "Created At",
    name: "createdAt",
    options: { filter: false, sort: true },
  },
  {
    label: "Created between",
    name: "createdBetween",
    options: {
      display: false,
      sort: true,
      filter: true,
      filterType: "custom",
      filterBy: "createdAt_between",
      customFilterListOptions: {
        render: v =>
          `Created from: ${v[0]?.from ? moment(v[0].from).format("DD/MM/YYYY") : "Past"} ${v[0]?.to ? `- ${moment(v[0].to).format("DD/MM/YYYY")}` : ""}`,
      },
      filterOptions: {
        display: (filterList, onChange, index, column) => {
          return (
            <DateRangeFilter
              column={column}
              index={index}
              label="Created at"
              onChangeHandler={onChange}
              values={filterList[index]}
            />
          );
        },
      },
      viewColumns: false,
    },
  },
];

const ProductOptionGroupManager = ({ columns = defaultColumns }) => {
  const { productId, siteId } = useParams();
  const { enqueueSnackbar } = useSnackbar();

  const [currentPage, setCurrentPage] = useState(0);
  const [savedGroups, setSavedGroups] = useState([]);
  const [selectedGroups, setSelectedGroups] = useState([]);
  const [showSelected, setShowSelected] = useState(false);

  const { error: queryError, loading: queryLoading } = useQuery(
    gql(GET_PRODUCT_OPTION_GROUPS_BY_PRODUCT_ID()),
    {
      fetchPolicy: "cache-and-network",
      onCompleted: ({ product }) => {
        if (product?.productOptionGroups) {
          setSelectedGroups(product.productOptionGroups);
          setSavedGroups(product.productOptionGroups);
        }
      },
      skip: !productId,
      variables: {
        productId,
        siteId,
      },
    },
  );

  const [
    updateProductOptionGroups,
    { error: mutationError, loading: mutationLoading },
  ] = useMutation(gql(UPDATE_PRODUCT()), {
    onCompleted: () => {
      enqueueSnackbar("Your changes have been saved", {
        SnackbarProps: {
          "data-testid": "product-option-groups-saved-snackbar",
        },
        variant: "success",
      });
    },
    refetchQueries: [
      gql(GET_PRODUCT_OPTION_GROUPS_BY_PRODUCT_ID()), // DocumentNode object
      "ProductOptionGroupsByProductId", // Query name
      {
        query: gql(GET_PRODUCT_OPTIONS_BY_PRODUCT_ID()),
        skip: !productId,
        variables: { productId },
      },
    ],
  });

  const toggleSelectedFilter = (items, value) => {
    setSelectedGroups(value ? items : []);
    setShowSelected(value);
    setCurrentPage(0);
  };

  const parseSelectedItems = items => {
    return items.map(([id, { name, createdAt }]) => {
      const createdAtUTC = moment(createdAt, "Do MMM YYYY, HH:mm");

      return {
        id,
        name,
        createdAt: createdAtUTC.isValid()
          ? createdAtUTC.format("Do MMM YYYY, HH:mm")
          : moment(createdAt).format("Do MMM YYYY, HH:mm"),
      };
    });
  };

  useEffect(() => {
    if (mutationError || queryError) {
      const fallbackErrorMessage = "Please try again later.";

      const errorMsg =
        mutationError?.message || queryError?.message || fallbackErrorMessage;

      enqueueSnackbar(`An unexpected error has occured: ${errorMsg}`, {
        variant: "error",
        SnackbarProps: {
          "data-testid": "product-option-groups-error-snackbar",
        },
      });
    }
  }, [mutationError, queryError, enqueueSnackbar]);

  return (
    <Card>
      <CardContent>
        <Grid container spacing={2}>
          <Grid item container alignItems="center" xs={12}>
            <Grid item>
              <Typography>Product Option Groups</Typography>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            {!productId ? (
              <Alert severity="info" data-testid="no-product-id-message">
                In order to assign product option groups you must first create
                this product.
              </Alert>
            ) : (
              <ProductOptionGroupsTable
                columns={columns}
                overrideData={showSelected ? selectedGroups : null}
                multiSelectOptions={selectionState => ({
                  customToolbar: () => {
                    let itemsToAdd = false;

                    selectionState.forEach((value, key) => {
                      if (!savedGroups.some(({ id }) => id === key)) {
                        itemsToAdd = true;
                      }
                    });

                    const itemsToRemove = savedGroups.find(
                      ({ id }) => !selectionState.has(id),
                    );

                    return (
                      <Box sx={{ width: "100%" }}>
                        <Box sx={{ mt: "12px" }}>
                          <PositiveAction
                            disabled={
                              !itemsToAdd &&
                              !itemsToRemove &&
                              !mutationLoading &&
                              !queryLoading
                            }
                            buttonText="Save Changes"
                            onClick={() => {
                              const groups = Array.from(
                                selectionState.entries(),
                              ).map(([id]) => id);

                              const dataToSubmit = {
                                id: productId,
                                productOptionGroups: groups,
                              };

                              if (siteId !== undefined) {
                                dataToSubmit.siteId = siteId;
                              }

                              setSavedGroups(
                                parseSelectedItems(
                                  Array.from(selectionState.entries()),
                                ),
                              );

                              updateProductOptionGroups({
                                variables: {
                                  input: dataToSubmit,
                                },
                              });
                            }}
                            testId="save-groups-btn"
                          />
                        </Box>
                      </Box>
                    );
                  },
                  customToolbarSelect: () => {
                    const items = parseSelectedItems(
                      Array.from(selectionState.entries()),
                    );

                    return (
                      <Grid
                        container
                        item
                        justifyContent="flex-start"
                        xl={10}
                        lg={9}
                        xs={6}
                        sx={{ mr: 8 }}
                      >
                        <Switch
                          active={showSelected}
                          disabled={!items.length}
                          label="Show selected only"
                          onChange={() =>
                            toggleSelectedFilter(items, !showSelected)
                          }
                          testId="show-selected-switch"
                        />
                      </Grid>
                    );
                  },
                  onRowSelectionChange: (cur, allRowsSelected) => {
                    if (!allRowsSelected.length) {
                      toggleSelectedFilter(null, false);
                    }
                  },
                  selectableRows: "multiple",
                  selectToolbarPlacement: "above",
                })}
                onPageChange={curPage => setCurrentPage(curPage)}
                page={currentPage}
                defaultSelectedItems={savedGroups}
                defaultSort={[{ field: "name", direction: "ASC" }]}
              />
            )}
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

ProductOptionGroupManager.propTypes = {
  columns: PropTypes.arrayOf(
    PropTypes.shape({ label: PropTypes.string, name: PropTypes.string }),
  ),
};

export default ProductOptionGroupManager;
