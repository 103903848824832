import { types } from "mobx-state-tree";
import moment from "moment";

const DateTime = types.custom({
  name: "DateTime",
  fromSnapshot(value) {
    return moment(value);
  },
  toSnapshot(value) {
    return value.toISOString();
  },
  isTargetType(value) {
    return value instanceof moment;
  },
  getValidationMessage(value) {
    if (moment(value).isValid()) {
      return "";
    }

    return `'${value}' doesn't look like a valid date`;
  },
});

export default DateTime;
