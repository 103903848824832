import { types, getEnv, getParent, flow } from "mobx-state-tree";
import gql from "graphql-tag";

import { transformer, removeTypename } from "../helpers";
import User, { fragments as UserFragments } from "./models/User";
import { fragments as SessionFragments } from "./models/Session";

// @todo treat versioning changes on preProcess
export default types
  .model("UserStore", {
    items: types.optional(types.map(User), {}),
  })
  .views(self => ({
    get appStore() {
      return getParent(self);
    },
  }))
  .actions(self => {
    const { apolloClient } = getEnv(self);
    const findAllLoaded = false;

    return {
      find: flow(function* find(id) {
        if (self.items.has(id)) {
          return self.items.get(id);
        }

        try {
          const response = yield apolloClient.query({
            query: gql`
            {
              user(id: "${id}") {
                ...UserFullDetails
              }
            }
            ${UserFragments.fullDetails}
          `,
          });

          const item = self.track(response.data.user);

          return Promise.resolve(item);
        } catch (error) {
          return Promise.reject(error);
        }
      }),

      findAll: flow(function* findAll(limit = 0, offset = 0, filter = {}) {
        if (findAllLoaded) {
          return Promise.resolve(self.items);
        }

        try {
          const response = yield apolloClient.query({
            query: gql`
              {
                users {
                  ...UserFullDetails
                }
              }
              ${UserFragments.fullDetails}
            `,
          });

          response.data.users.forEach(item => self.track(item));

          return Promise.resolve(self.items);
        } catch (error) {
          return Promise.reject(error);
        }
      }),

      changePassword: flow(function* changePassword({
        oldPassword,
        newPassword,
      }) {
        try {
          const response = yield apolloClient.mutate({
            mutation: gql`
            mutation changeUserPassword {
              changeUserPassword(
                ${transformer({
                  oldPassword,
                  newPassword,
                })}
              ) {
                ...SessionFullDetails
              }
            }
            ${SessionFragments.fullDetails}
          `,
          });

          const session = self.appStore.sessionStore.track(
            response.data.changeUserPassword,
          );

          self.appStore.setSession(session);

          return Promise.resolve(true);
        } catch (error) {
          return Promise.reject(error);
        }
      }),

      lockUser: flow(function* lockUser(id, reason) {
        try {
          yield apolloClient.mutate({
            mutation: gql`
              mutation changeUserStatus {
                changeUserStatus(id: "${id}", reason: "${reason}") {
                  status
                }
              }
            `,
          });

          return Promise.resolve(true);
        } catch (error) {
          return Promise.reject(error);
        }
      }),

      add: flow(function* add(entity) {
        try {
          const input = transformer({
            organisationId: entity.organisationId,
            firstName: entity.firstName,
            lastName: entity.lastName,
            email: entity.email,
            password: entity.password,
            roleId: "be198434-3853-4be2-a5ae-15ce6e172daa",
            phone: entity.phone,
            isPasswordChangeRequired: entity.isPasswordChangeRequired,
            // for the trasformer to work with an enum it needs to be passed as a function
            scopes: (entity.scopes ?? []).map(item => () => item),
          });

          const response = yield apolloClient.mutate({
            mutation: gql`
            mutation addUser {
              addUser(input: {
                ${input}
              }) {
                ...UserFullDetails
              }
            }
            ${UserFragments.fullDetails}
          `,
          });

          return Promise.resolve(self.track(response.data.addUser));
        } catch (error) {
          return Promise.reject(error);
        }
      }),

      delete: flow(function* remove(id) {
        try {
          const response = yield apolloClient.mutate({
            mutation: gql`
            mutation deleteUser {
              deleteUser(id: "${id}")
            }
          `,
          });

          if (response.data.deleteUser === true) {
            self.items.delete(id);
          }
          return Promise.resolve(true);
        } catch (error) {
          return Promise.reject(error);
        }
      }),

      track(entity) {
        if (!self.items.has(entity.id)) {
          const input = removeTypename(entity);

          return self.items.put(input);
        }

        return self.items.get(entity.id);
      },
    };
  });
