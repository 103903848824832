import React, { useState } from "react";
import PropTypes from "prop-types";

import moment from "moment";

import Input from "@mui/material/Input";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";

import TimePicker from "rc-time-picker";
import "rc-time-picker/assets/index.css";

import AlarmIcon from "@mui/icons-material/Alarm";

const TimePickerComponent = ({ value, onChange }) => {
  const [pickerOpen, setPickerOpen] = useState(false);

  return (
    <>
      <Input
        style={{ width: 100 }}
        type="text"
        value={value ? value.format("HH:mm") : ""}
        endAdornment={
          <InputAdornment position="end">
            <IconButton onClick={() => setPickerOpen(true)} size="medium">
              <AlarmIcon />
            </IconButton>
          </InputAdornment>
        }
      />
      <TimePicker
        allowEmpty={false}
        style={{ width: 0 }}
        open={pickerOpen}
        showSecond={false}
        defaultValue={value}
        onChange={onChange}
        inputFormat="HH:mm"
        onClose={() => setPickerOpen(false)}
        minuteStep={5}
        inputReadOnly
      />
    </>
  );
};

TimePickerComponent.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.instanceOf(moment).isRequired,
};

export default TimePickerComponent;
