import React from "react";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";

import { observer, inject, PropTypes as MobXPropTypes } from "mobx-react";

import { withStyles } from "@mui/styles";

import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";

import Badge from "@mui/material/Badge";
import Avatar from "@mui/material/Avatar";

import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ExitIcon from "@mui/icons-material/ExitToApp";
import IconNotification from "@mui/icons-material/Notifications";
import IconUser from "@mui/icons-material/Face";
import Link from "@mui/material/Link";

import { drawerWidth } from "./Sidebar";

const styles = theme => ({
  appBar: {
    position: "fixed",
    zIndex: theme.zIndex.drawer + 1,
  },
  navIconHide: {
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  logout: {
    marginLeft: 20,
  },
  avatar: {
    margin: "0 10px 0 10px",
  },
  userDetails: {
    height: 64,
    backgroundColor: "#fff",
    color: "#000",
    flex: `0 0 ${drawerWidth}px`,
  },
});

const TopBarComponent = ({ appStore, classes }) => {
  const navigate = useNavigate();

  const handleSidebarToggle = () => {
    appStore.setSidebarOpen(!appStore.isSidebarOpen);
  };

  const handleClickLogout = () => {
    appStore.setLoading(true);
    appStore.logout();
  };

  return (
    <AppBar className={classes.appBar} enableColorOnDark>
      <Toolbar disableGutters>
        <div style={{ display: "flex", alignItems: "center", width: "100%" }}>
          <div className={classes.userDetails}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                height: "100%",
              }}
            >
              <IconButton
                color="inherit"
                aria-label="open drawer"
                onClick={handleSidebarToggle}
                className={classes.navIconHide}
                size="medium"
              >
                <MenuIcon />
              </IconButton>

              <Avatar className={classes.avatar}>
                <IconUser />
              </Avatar>
              <Link
                to="/app/users/change-password"
                onClick={event => {
                  event.preventDefault();
                  navigate("/app/users/change-password");
                }}
                underline="hover"
              >
                <Typography style={{ flex: "0 0 auto" }}>
                  {appStore.viewer.firstName} {appStore.viewer.lastName}
                </Typography>
              </Link>
              <IconButton
                className={classes.logout}
                onClick={handleClickLogout}
                size="medium"
              >
                <ExitIcon />
              </IconButton>
            </div>
          </div>
          <Typography
            type="h6"
            color="inherit"
            style={{ flex: 1, marginLeft: 10 }}
          >
            Polaris Elements
          </Typography>
          {appStore.inChangeset && (
            <div style={{ flex: "1 1 auto" }}>
              {appStore.inChangeset.name} to be applied at{" "}
              {appStore.inChangeset.applyAt.toISOString()}
            </div>
          )}
          <div style={{ flex: "0 0 60px" }}>
            <IconButton color="inherit" size="medium">
              <Badge
                className={classes.badge}
                badgeContent=""
                overlap="rectangular"
              >
                <IconNotification />
              </Badge>
            </IconButton>
          </div>
        </div>
      </Toolbar>
    </AppBar>
  );
};

TopBarComponent.propTypes = {
  appStore: MobXPropTypes.objectOrObservableObject.isRequired,
  classes: PropTypes.shape({
    appBar: PropTypes.string,
    userDetails: PropTypes.string,
    navIconHide: PropTypes.string,
    avatar: PropTypes.string,
    logout: PropTypes.string,
    badge: PropTypes.string,
    drawerHeader: PropTypes.string,
  }).isRequired,
};

const TopBar = withStyles(styles)(
  inject("appStore")(observer(TopBarComponent)),
);

export default TopBar;
