import React, { useEffect, useState } from "react";

import { observer, inject, PropTypes as MobXPropTypes } from "mobx-react";
import validator from "validator";
import { useParams, useNavigate } from "react-router-dom";

import { makeStyles } from "@mui/styles";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Container from "@mui/material/Container";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import Grid from "@mui/material/Grid";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import TextField from "@mui/material/TextField";

import ErrorBoundary from "../../components/ErrorBoundary";
import Page from "../../components/Page";
import PositiveAction from "../../components/Button/PositiveAction";
import NegativeAction from "../../components/Button/NegativeAction";

export const ERROR_MESSAGES = {
  required: {
    name: "Please specify a name",
    POSPaymentTypeId: "Please specify a numeric Media ID",
  },
};

const useStyles = makeStyles(theme => ({
  input: {
    margin: theme.spacing(1),
  },
  form: {
    padding: 12,
  },
  formControl: {
    maxWidth: 200,
  },
}));

const AddEditComponent = ({ appStore }) => {
  const navigate = useNavigate();
  const { paymentTypeId } = useParams();
  const classes = useStyles();

  const [formData, setFormData] = useState({
    name: "",
    POSPaymentTypeId: "",
    fluxPaymentType: "",
  });
  const [paymentType, setPaymentType] = useState(null);

  const [formDataErrors, setFormDataErrors] = useState({});
  const [queryError, setQueryError] = useState("");

  const handleCancel = () => {
    appStore.setLoading();
    navigate("/app/payment-types");
  };

  const handleInputChange = ({ target: { value, name } }) => {
    setFormData({ ...formData, [name]: value });
  };

  const handlePOSPaymentTypeIdChange = event => {
    const newValue = event.target.value;
    const parsedValue = parseInt(newValue, 10);

    if (
      newValue !== "" &&
      (!validator.isNumeric(newValue) || Number.isNaN(parsedValue))
    ) {
      setFormDataErrors({
        ...formDataErrors,
        POSPaymentTypeId: ERROR_MESSAGES.required.POSPaymentTypeId,
      });
    } else {
      setFormData({ ...formData, POSPaymentTypeId: newValue });
    }
  };

  const validateFormData = data => {
    const validationErrors = {};

    if (validator.isEmpty(data.name.toString())) {
      validationErrors.name = ERROR_MESSAGES.required.name;
    }

    if (!validator.isNumeric(data.POSPaymentTypeId.toString())) {
      validationErrors.POSPaymentTypeId =
        ERROR_MESSAGES.required.POSPaymentTypeId;
    }

    return validationErrors;
  };

  const handleSubmit = e => {
    e.preventDefault();

    const validationErrors = validateFormData(formData);

    if (!Object.keys(validationErrors).length) {
      appStore.setLoading();
      const { name, POSPaymentTypeId, fluxPaymentType } = formData;

      if (paymentType === null) {
        appStore.paymentTypeStore
          .add({
            name,
            POSPaymentTypeId,
            fluxPaymentType: fluxPaymentType === "" ? null : fluxPaymentType,
          })
          .then(() => navigate("/app/payment-types"))
          .catch(error => {
            appStore.log.error(error);
            setQueryError(error.message);
            appStore.setLoading(false);
          });
      } else {
        paymentType.setName(name);
        paymentType.setPOSPaymentTypeId(POSPaymentTypeId);
        paymentType.setFluxPaymentType(
          fluxPaymentType === "" ? null : fluxPaymentType,
        );

        paymentType
          .save()
          .then(() => navigate("/app/payment-types"))
          .catch(error => {
            appStore.log.error(error);
            setQueryError(error.message);
            appStore.setLoading(false);
          });
      }
    } else {
      setFormDataErrors(validationErrors);
    }
  };

  useEffect(() => {
    if (paymentTypeId !== undefined && paymentTypeId !== "add") {
      appStore.paymentTypeStore
        .find(paymentTypeId)
        .then(record => {
          const { name, POSPaymentTypeId, fluxPaymentType } = record;
          setPaymentType(record);
          setFormData({ name, POSPaymentTypeId, fluxPaymentType });
          appStore.setLoading(false);
        })

        .catch(error => {
          appStore.log.error(error);
          navigate("/app/404");
        });
    } else {
      appStore.setLoading(false);
    }
  }, [paymentTypeId]);

  const { name, POSPaymentTypeId, fluxPaymentType } = formData;
  return (
    <ErrorBoundary>
      <Page title={`${paymentType !== null ? "Edit" : "Add"} payment type`}>
        <Container maxWidth={false}>
          <form autoComplete="off" noValidate className={classes.form}>
            <Card>
              <CardContent>
                <Grid container spacing={3}>
                  {queryError && (
                    <Grid item xs={12}>
                      <FormHelperText error>{queryError}</FormHelperText>
                    </Grid>
                  )}

                  <Grid item lg={3} md={6} xs={12}>
                    <TextField
                      required
                      inputProps={{ "data-testid": "name" }}
                      variant="standard"
                      fullWidth
                      name="name"
                      label="Name"
                      value={name}
                      error={!!formDataErrors.name}
                      helperText={formDataErrors.name}
                      onChange={handleInputChange}
                    />
                  </Grid>
                  <Grid item lg={3} md={6} xs={12}>
                    <TextField
                      required
                      inputProps={{ "data-testid": "POSPaymentTypeId" }}
                      variant="standard"
                      fullWidth
                      label="POS Media ID"
                      value={POSPaymentTypeId}
                      error={!!formDataErrors.POSPaymentTypeId}
                      helperText={formDataErrors.POSPaymentTypeId}
                      onChange={handlePOSPaymentTypeIdChange}
                    />
                  </Grid>
                  <Grid item lg={12} />

                  <Grid item xs={12} sm={6} md={3}>
                    <FormControl
                      variant="standard"
                      fullWidth
                      error={!!formDataErrors.fluxPaymentType}
                    >
                      <InputLabel>Flux Payment Type</InputLabel>
                      <Select
                        variant="standard"
                        name="fluxPaymentType"
                        value={fluxPaymentType}
                        onChange={handleInputChange}
                      >
                        <MenuItem value="">
                          <em>Select Flux payment type...</em>
                        </MenuItem>
                        <MenuItem value="CASH">CASH</MenuItem>
                        <MenuItem value="CARD">CARD</MenuItem>
                        <MenuItem value="CHECQUE">CHECQUE</MenuItem>
                        <MenuItem value="GIFTCARD">GIFTCARD</MenuItem>
                      </Select>
                      <FormHelperText>
                        {formDataErrors.fluxPaymentType}
                      </FormHelperText>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <Box pr={1} display="inline">
                      <NegativeAction
                        buttonText="Cancel"
                        onClick={handleCancel}
                      />
                    </Box>
                    <PositiveAction buttonText="Save" onClick={handleSubmit} />
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </form>
        </Container>
      </Page>
    </ErrorBoundary>
  );
};

AddEditComponent.propTypes = {
  appStore: MobXPropTypes.objectOrObservableObject.isRequired,
};

export default inject("appStore")(observer(AddEditComponent));
