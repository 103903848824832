import React, { useState } from "react";
import PropTypes from "prop-types";

import { styled } from "@mui/system";
import {
  FormHelperText,
  TextField,
  Typography,
  Grid,
  Box,
} from "@mui/material";

import PositiveAction from "../Button/PositiveAction";
import NegativeAction from "../Button/NegativeAction";
import LoginStyle from "./styles/Login";

const StyledBox = styled(Box, {})(LoginStyle);

const LoginForm = ({ allowCancel = false, displayName, formError, submit }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const handleEmailChange = ({ target: { value } }) => {
    setEmail(value);
  };

  const handlePasswordChange = ({ target: { value } }) => {
    setPassword(value);
  };

  const handleSubmit = () => {
    submit(email, password);
  };

  const handleSubmitOnInput = ({ keyCode }) => {
    // 13 is enter
    if (keyCode === 13) {
      handleSubmit();
    }
  };

  return (
    <StyledBox>
      <form>
        <h1>Sign in</h1>
        <h3 data-testid="displayName">Polaris Cloud for {displayName}</h3>
        {!!formError && (
          <FormHelperText data-testid="formError" error>
            {formError}
          </FormHelperText>
        )}
        <TextField
          className="email"
          fullWidth
          id="email"
          label="Email"
          onChange={handleEmailChange}
          onKeyDown={handleSubmitOnInput}
          value={email}
          variant="standard"
        />
        <TextField
          className="password"
          fullWidth
          id="password"
          label="Password"
          onChange={handlePasswordChange}
          onKeyDown={handleSubmitOnInput}
          type="password"
          value={password}
          variant="standard"
        />
        <Grid container gap={4}>
          {allowCancel ? (
            <Grid item lg>
              <NegativeAction
                buttonText="Cancel"
                testId="formCancel"
                onClick={() => {
                  window.location.reload();
                }}
              />
            </Grid>
          ) : (
            <></>
          )}
          <Grid item lg>
            <PositiveAction
              buttonText="Login"
              testId="formSubmit"
              onClick={handleSubmit}
            />
          </Grid>
        </Grid>
        <Typography className="copyrightText" color="textSecondary">
          &copy; Polaris Elements Ltd {new Date().getUTCFullYear()}
        </Typography>
      </form>
    </StyledBox>
  );
};

LoginForm.propTypes = {
  allowCancel: PropTypes.bool,
  displayName: PropTypes.string.isRequired,
  formError: PropTypes.string.isRequired,
  submit: PropTypes.func.isRequired,
};

export default LoginForm;
