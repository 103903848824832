import React from "react";

import IconButton from "@mui/material/IconButton";
import CancelIcon from "@mui/icons-material/Cancel";

const Delete = props => {
  return (
    <IconButton
      disableRipple
      {...props}
      style={{ background: "transparent", color: "red" }}
      size="medium"
    >
      <CancelIcon titleAccess="removeButton" />
    </IconButton>
  );
};

export default Delete;
