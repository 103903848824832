import React from "react";
import { observer, inject, PropTypes as MobXPropTypes } from "mobx-react";

import Hidden from "@mui/material/Hidden";
import Drawer from "@mui/material/Drawer";
import Divider from "@mui/material/Divider";

import SidebarLinks, { drawerWidth } from "./ClientLevelLinks";

export { drawerWidth };

const SidebarComponent = ({ appStore }) => {
  const handleSidebarToggle = () => {
    appStore.setSidebarOpen(!appStore.isSidebarOpen);
  };

  const drawerStyling = {
    width: drawerWidth,
    "& .MuiDrawer-paper": {
      marginTop: "64px",
      width: drawerWidth,
      boxSizing: "border-box",
      height: `calc(100% - 64px)`,
    },
  };

  return (
    <>
      <Hidden mdUp>
        <Drawer
          variant="temporary"
          anchor="left"
          open={appStore.isSidebarOpen}
          onClose={handleSidebarToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={drawerStyling}
        >
          <SidebarLinks />
        </Drawer>
      </Hidden>
      <Hidden mdDown implementation="css">
        <Drawer variant="permanent" anchor="left" sx={drawerStyling}>
          <Divider />
          <SidebarLinks />
        </Drawer>
      </Hidden>
    </>
  );
};

SidebarComponent.propTypes = {
  appStore: MobXPropTypes.objectOrObservableObject.isRequired,
};

const Sidebar = inject("appStore")(observer(SidebarComponent));

export default Sidebar;
