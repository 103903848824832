import React, { useEffect } from "react";
import PropTypes from "prop-types";

import { styled } from "@mui/material/styles";
import { DataGrid, useGridApiRef } from "@mui/x-data-grid";

import CustomGridRow from "../../../components/Datagrid/CustomGridRow";

const StyledBox = styled("div")(({ theme }) => ({
  width: "100%",
  "& .Mui-error": {
    backgroundColor: `rgb(126,10,15, ${theme.palette.mode === "dark" ? 0 : 0.1})`,
    color: theme.palette.error.main,
  },
}));

const PrintTextDataTable = ({
  tableData,
  columns,
  loading = false,
  processRowUpdate,
  errorSnackbar,
}) => {
  const apiRef = useGridApiRef();

  useEffect(() => {
    // To avoid a setTimeout we have tested subscribing to the 'stateChange' event,
    // however, this causes cell jumping and breaks responsively and therefore
    // have resorted to a timeout, which is also used in the MUI docs for this feature.
    setTimeout(() => {
      apiRef?.current?.autosizeColumns({
        includeHeaders: true,
        includeOutliers: true,
      });
    }, 50);
  }, [tableData]);

  return (
    <StyledBox>
      <DataGrid
        apiRef={apiRef}
        columns={columns}
        editMode="row"
        loading={loading}
        rows={tableData}
        slots={{
          row: CustomGridRow,
        }}
        sortModel={[{ field: "lineNumber", sort: "asc" }]}
        columnVisibilityModel={{
          lineNumber: false,
        }}
        processRowUpdate={processRowUpdate}
        onProcessRowUpdateError={errorSnackbar}
        rowHeight={30}
        columnHeaderHeight={35}
        hideFooter
        disableColumnMenu
        autoHeight
      />
    </StyledBox>
  );
};

PrintTextDataTable.propTypes = {
  tableData: PropTypes.arrayOf(PropTypes.object).isRequired,
  columns: PropTypes.arrayOf(PropTypes.object).isRequired,
  loading: PropTypes.bool,
  processRowUpdate: PropTypes.func.isRequired,
  errorSnackbar: PropTypes.func.isRequired,
};

export default PrintTextDataTable;
