import React from "react";
import moment from "moment";

import ListUpcoming from "./List";

const List = () => {
  return (
    <ListUpcoming
      defaultFilter={{ scheduledAt_between: { to: moment.utc() } }}
    />
  );
};

export default List;
