import React, { useState, useEffect } from "react";

import gql from "graphql-tag";
import { useQuery, useMutation } from "@apollo/client";
import { observer, inject, PropTypes as MobXPropTypes } from "mobx-react";

import { styled } from "@mui/material/styles";
import { useSnackbar } from "notistack";
import { v4 } from "uuid";
import { DataGrid, useGridApiRef } from "@mui/x-data-grid";

import CustomGridRow from "../../../components/Datagrid/CustomGridRow";
import CustomToolbar from "../../../components/Datagrid/CustomToolbar";

import { hasCharacterLengthValidationError } from "../../../components/Datagrid/utils/validation";

import {
  ADD_SALES_AREA,
  GET_SALES_AREAS,
  UPDATE_SALES_AREA,
} from "../../../helpers/apollo/utils";

const StyledBox = styled("div")(({ theme }) => ({
  height: "70vh",
  width: "100%",
  "& .Mui-error": {
    backgroundColor: `rgb(126,10,15, ${theme.palette.mode === "dark" ? 0 : 0.1})`,
    color: theme.palette.error.main,
  },
}));

const getColumns = () => [
  {
    field: "description",
    headerName: "Description",
    type: "text",
    editable: true,
    minWidth: 150,
    preProcessEditCellProps: params => {
      const {
        props,
        props: { value },
      } = params;

      return {
        ...props,
        error: hasCharacterLengthValidationError({ value, minLength: 2 }), // Min length of 2 as per the API
      };
    },
  },
  {
    field: "serviceContributionPercentage",
    headerName: "Service Contribution Percentage",
    type: "boolean",
    editable: true,
    flex: 1,
  },
];

export const generateNewRow = () => ({
  id: v4(),
  description: "",
  serviceContributionPercentage: false,
  // We use this when updating a row to tell us this department needs to be created
  createdRow: true,
});

const SalesAreasBulkEditor = ({ appStore }) => {
  const apiRef = useGridApiRef();
  const { enqueueSnackbar } = useSnackbar();

  const [columns, setColumns] = useState([]);
  const [createdRow, setCreatedRow] = useState(false);
  const [tableData, setTableData] = useState([]);

  const errorSnackbar = errorMsg => {
    enqueueSnackbar(`Error: ${errorMsg}`, {
      variant: "error",
      SnackbarProps: {
        "data-testid": "bulk-sales-areas-error-snackbar",
      },
    });
  };

  useEffect(() => {
    // To avoid a setTimeout we have tested subscribing to the 'stateChange' event,
    // however, this causes cell jumping and breaks responsively and therefore
    // have resorted to a timeout, which is also used in the MUI docs for this feature.
    setTimeout(() => {
      apiRef?.current?.autosizeColumns({
        includeHeaders: true,
        includeOutliers: true,
      });
    }, 10);
  }, [tableData]);

  const { loading: loadingSalesAreas } = useQuery(gql(GET_SALES_AREAS()), {
    fetchPolicy: "cache-and-network",
    onCompleted: data => {
      const salesAreas = data?.salesAreasWithCount?.salesAreas;

      if (salesAreas) {
        setTableData(salesAreas);
      }

      setColumns(getColumns());

      appStore.setLoading(false);
    },
    onError: error => {
      appStore.setLoading(false);
      errorSnackbar(error.message);
    },
  });

  const [addSalesArea] = useMutation(gql(ADD_SALES_AREA()), {
    onCompleted: () => {
      enqueueSnackbar("Your new entry has been created", {
        SnackbarProps: {
          "data-testid": "bulk-sales-areas-added-snackbar",
        },
        variant: "success",
      });
    },
    refetchQueries: [gql(GET_SALES_AREAS()), "SalesAreas"],
  });

  const [updateSalesArea] = useMutation(gql(UPDATE_SALES_AREA()), {
    onCompleted: () => {
      enqueueSnackbar("Your changes have been saved", {
        SnackbarProps: {
          "data-testid": "bulk-sales-areas-saved-snackbar",
        },
        variant: "success",
      });
    },
    refetchQueries: [gql(GET_SALES_AREAS()), "SalesAreas"],
  });

  const addRow = () => {
    const newRow = generateNewRow();

    const newTableData = [...tableData];
    newTableData.unshift(newRow);

    setTableData(newTableData);
    setCreatedRow(true);
  };

  const deleteRow = () => {
    const newTableData = tableData.filter(row => row.createdRow === undefined);

    setTableData(newTableData);
    setCreatedRow(false);
  };

  const handleSubmitRow = async (updatedRow, originalRow) => {
    const managableFields = ["description", "serviceContributionPercentage"];

    const rowChanges = managableFields.reduce((changes, field) => {
      if (updatedRow.createdRow || originalRow[field] !== updatedRow[field]) {
        changes[field] = updatedRow[field];
      }

      return changes;
    }, {});

    const submitData = {
      variables: {
        input: rowChanges,
      },
    };

    if (Object.keys(rowChanges).length) {
      if (updatedRow.createdRow !== undefined) {
        return addSalesArea(submitData).then(() => {
          setCreatedRow(false);
          return updatedRow;
        });
      } else {
        submitData.variables.input.id = updatedRow.id;
        return updateSalesArea(submitData).then(() => {
          return updatedRow;
        });
      }
    } else {
      return updatedRow;
    }
  };

  return (
    <StyledBox>
      <DataGrid
        apiRef={apiRef}
        columns={columns}
        editMode="row"
        loading={loadingSalesAreas}
        rows={tableData}
        slots={{
          row: CustomGridRow,
          toolbar: () => (
            <CustomToolbar
              addAction={addRow}
              deleteAction={deleteRow}
              isRowCreated={createdRow}
            />
          ),
        }}
        processRowUpdate={handleSubmitRow}
        onProcessRowUpdateError={errorSnackbar}
      />
    </StyledBox>
  );
};

SalesAreasBulkEditor.propTypes = {
  appStore: MobXPropTypes.objectOrObservableObject.isRequired,
};

export default inject("appStore")(observer(SalesAreasBulkEditor));
