import React from "react";
import moment from "moment";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import {
  getGridStringOperators,
  getGridSingleSelectOperators,
} from "@mui/x-data-grid-pro";

import DateTimeBetweenFilter from "../../../components/Datagrid/Filters/DateTimeBetweenFilter.js";

export const orderColumns = [
  {
    field: "id",
    headerName: "ID",
    type: "text",
    filterOperators: getGridStringOperators()?.filter(
      operator => operator.value === "equals",
    ),
  },
  {
    field: "displayReference",
    headerName: "Display Reference",
    type: "text",
    filterOperators: getGridStringOperators()?.filter(
      operator => operator.value === "equals",
    ),
  },
  {
    field: "externalReference",
    headerName: "External Reference",
    type: "text",
    filterOperators: getGridStringOperators()?.filter(
      operator => operator.value === "equals",
    ),
  },
  {
    field: "siteName",
    headerName: "Site",
    type: "text",
    filterOperators: getGridStringOperators()?.filter(
      operator => operator.value === "contains",
    ),
    sortable: false,
  },
  {
    field: "type",
    headerName: "Type",
    type: "singleSelect",
    valueOptions: ["COLLECTION", "SERVICE_DELIVERY", "SELF_DELIVERY", "EAT_IN"],
    getOptionLabel: value => value,
    getOptionValue: value => value,
    filterOperators: getGridSingleSelectOperators()?.filter(
      operator => operator.value === "is",
    ),
  },
  {
    field: "status",
    headerName: "Status",
    type: "singleSelect",
    valueOptions: [
      "NEW",
      "ACCEPTED",
      "REJECTED",
      "ERROR",
      "SCHEDULED",
      "PROCESSED",
      "COMPLETED",
      "CANCELLED",
    ],
    getOptionLabel: value => value,
    getOptionValue: value => value,
    cellClassName: params => {
      const val = params?.value;

      return val === "ERROR" || val === "REJECTED"
        ? "status-cell-negative"
        : val === "NEW" || val === "SCHEDULED" || val === "CANCELLED"
          ? "status-cell-neutral"
          : "status-cell-positive";
    },
    filterOperators: getGridSingleSelectOperators()?.filter(
      operator => operator.value === "is",
    ),
  },
  {
    field: "errorReason",
    headerName: "Error Reason",
    type: "text",
    filterable: false,
  },
  {
    field: "createdAt",
    headerName: "Created At",
    type: "dateTime",
    valueFormatter: value => moment(value).format("DD/MM/YYYY hh:mm A"),
    filterOperators: [
      {
        value: "is",
        InputComponent: props => <DateTimeBetweenFilter props={props} />,
      },
    ],
  },
  {
    field: "expectedAt",
    headerName: "Expected At",
    type: "dateTime",
    valueFormatter: value => moment(value).format("DD/MM/YYYY hh:mm A"),
    filterOperators: [
      {
        value: "is",
        InputComponent: props => <DateTimeBetweenFilter props={props} />,
      },
    ],
  },
  {
    field: "createdByEmail",
    headerName: "Created By",
    type: "text",
    filterOperators: getGridStringOperators()?.filter(
      operator => operator.value === "equals",
    ),
  },
  {
    field: "itemCode",
    headerName: "Item PLU",
    type: "text",
    filterOperators: getGridStringOperators()?.filter(
      operator => operator.value === "equals",
    ),
  },
  {
    field: "itemModifierCode",
    headerName: "Item Modifier PLU",
    type: "text",
    filterOperators: getGridStringOperators()?.filter(
      operator => operator.value === "equals",
    ),
  },
];

export const orderDetailColumns = [
  {
    field: "code",
    headerName: "PLU",
    valueFormatter: (value, params) => {
      if (params.modifier !== undefined) {
        return `${value} (Modifier)`;
      }
      return value;
    },
    cellClassName: params => {
      return params?.row?.modifier !== undefined ? "modifier-plu-cell" : null;
    },
    minWidth: 200,
  },
  {
    field: "quantity",
    headerName: "Quantity",
    type: "number",
    minWidth: 100,
  },
  {
    field: "price",
    headerName: "Price",
    type: "number",
    minWidth: 100,
    renderCell: params => (
      <Box
        sx={{
          display: "flex",
          height: "100%",
          justifyContent: "right",
          ...(params.row.discounted !== null && {
            textDecoration: "line-through",
          }),
        }}
      >
        <Typography
          component="div"
          sx={{
            my: "auto",
            fontSize: "15px",
          }}
        >
          {`£${(params.value / 100).toFixed(2)}`}
        </Typography>
      </Box>
    ),
  },
  {
    field: "discounted",
    headerName: "Discounted Price",
    type: "number",
    minWidth: 185,
    valueFormatter: value => {
      return value !== null ? `£${(value / 100).toFixed(2)}` : "";
    },
  },
];
