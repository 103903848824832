import React, { useState } from "react";
import PropTypes from "prop-types";
import moment from "moment";

import { styled } from "@mui/system";
import Alert from "@mui/material/Alert";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Checkbox from "@mui/material/Checkbox";
import Chip from "@mui/material/Chip";
import Collapse from "@mui/material/Collapse";
import FormControlLabel from "@mui/material/FormControlLabel";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import IconButton from "@mui/material/IconButton";

import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";

import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";

import { TimePicker } from "@mui/x-date-pickers/TimePicker";

import PositiveAction from "../../../../components/Button/PositiveAction";
import Switch from "../../../../components/Switch";
import { checkForNoDays } from "../AddEditValidation";

const weekdays = [1, 2, 3, 4, 5, 6, 7];

const minutesToMoment = minutesOfDay => {
  if (minutesOfDay !== null) {
    return moment()
      .set("hour", Math.floor(minutesOfDay / 60))
      .set("minute", minutesOfDay % 60);
  }

  return null;
};

// This is to apply specific styling to the add button for the time interval form
const StyledGridItem = styled(
  Grid,
  {},
)({ button: { width: "100%" }, marginBottom: "8px" });

const DayTimeChooser = ({
  dayRules,
  handleRemoveTimeInterval,
  handleAddTimeInterval,
  handleDayChange,
  handleApplyToAllDays,
  showCollapsibleContent,
  toggleCollapsed,
  readOnly = false,
}) => {
  const [startTime, setStartTime] = useState(weekdays.map(() => null));
  const [endTime, setEndTime] = useState(weekdays.map(() => null));
  const [isDialogOpen, setDialogOpen] = useState(weekdays.map(() => false));
  const [dialogErrors, setDialogErrors] = useState(weekdays.map(() => false));

  const clearTimes = day => {
    const newStartTimes = [...startTime];
    newStartTimes[day] = null;
    setStartTime(newStartTimes);
    const newEndTimes = [...endTime];
    newEndTimes[day] = null;
    setEndTime(newEndTimes);
  };

  const toggleDialog = (day, state) => {
    const dialogs = [...isDialogOpen];
    dialogs[day] = (state == null && !dialogs[day]) || state;
    setDialogOpen(dialogs);
  };

  const handleSetStartTime = day => date => {
    const newStartTimes = [...startTime];
    newStartTimes[day] = date.hours() * 60 + date.minutes();
    setStartTime(newStartTimes);
  };

  const handleSetEndTime = day => date => {
    const newEndTimes = [...endTime];
    newEndTimes[day] = date.hours() * 60 + date.minutes();
    setEndTime(newEndTimes);
  };

  const validateAddTimeInterval = day => {
    const startTimeIsAfterEnd = startTime[day] > endTime[day];

    const proposedDayRulesSorted = [
      ...dayRules[day].intervals,
      { start: startTime[day], end: endTime[day] },
    ].sort((a, b) => a.start - b.start);

    // Look over the sorted array of intervals and if the current end date
    // is greater than or equal to the next intervals start date mark as invalid
    const overlapOnTime = proposedDayRulesSorted.find(
      (interval, index, array) =>
        array[index + 1] !== undefined
          ? interval.end >= array[index + 1].start
          : false,
    );

    const newDialogErrors = [...dialogErrors];

    if (!startTimeIsAfterEnd && !overlapOnTime) {
      newDialogErrors[day] = false;
      setDialogErrors(newDialogErrors);

      handleAddTimeInterval(day, startTime[day], endTime[day]);

      clearTimes(day);
      toggleDialog(day);
    } else {
      let errorString = "";

      if (startTimeIsAfterEnd) {
        errorString += "The start time must occur before the end time.";
      }

      if (overlapOnTime) {
        errorString +=
          "You cannot have a time interval that overlaps with an existing one.";
      }

      newDialogErrors[day] = errorString;
      setDialogErrors(newDialogErrors);
    }
  };

  return (
    <Card id="day-chooser">
      <CardContent>
        <Grid container justifyContent="space-between">
          <Grid container alignItems="center" item xs={9}>
            <Typography>Day and time rules&nbsp;</Typography>
            <Tooltip
              arrow
              title={`The days of the week this promotion runs. Specific times can also be set.
              If the end time is before the start time, then it applies to the following day.
              e.g. 9:00PM -> 2:00AM`}
            >
              <HelpOutlineIcon fontSize="small" />
            </Tooltip>
            {checkForNoDays(dayRules) ? (
              <Grid container item>
                <Typography data-testid="no-days-header-warning" color="red">
                  You have no days selected
                </Typography>
              </Grid>
            ) : (
              <></>
            )}
          </Grid>
          <Grid container item xs={3} justifyContent="flex-end">
            <Switch
              active={
                dayRules &&
                Object.values(dayRules).filter(day => !!day).length === 7
              }
              onChange={handleApplyToAllDays}
              colour="success"
              testId="enable-all-days"
              label="Apply to all"
            />
            <IconButton
              onClick={toggleCollapsed}
              data-testid="day-time-collapse-toggle"
            >
              {showCollapsibleContent ? <ExpandLess /> : <ExpandMore />}
            </IconButton>
          </Grid>
        </Grid>
        <Collapse in={showCollapsibleContent}>
          <Grid container spacing={4}>
            {weekdays.map(day => {
              return (
                <Grid container item sm={6} lg={4} xl={3} key={day}>
                  <Grid container alignItems="start">
                    <Grid
                      container
                      item
                      xs={12}
                      alignItems="center"
                      justifyContent="space-between"
                    >
                      <Grid item>
                        <FormControlLabel
                          control={
                            <Checkbox
                              color="primary"
                              checked={!!dayRules?.[day]}
                              onChange={handleDayChange(day)}
                            />
                          }
                          label={moment().isoWeekday(day).format("dddd")}
                        />
                      </Grid>
                      {!readOnly && (
                        <Grid item>
                          <PositiveAction
                            disabled={!dayRules?.[day]}
                            onClick={() => {
                              toggleDialog(day);
                              clearTimes(day);
                            }}
                            testId={`${day}-add-time-button`}
                            buttonText={
                              !isDialogOpen[day] ? "Add hours" : "Cancel"
                            }
                          />
                        </Grid>
                      )}
                    </Grid>
                  </Grid>
                  {dayRules?.[day] && (
                    <>
                      {isDialogOpen[day] && (
                        <Grid container spacing={1}>
                          <Grid item xs={6}>
                            <TimePicker
                              slotProps={{
                                openPickerIcon: {
                                  "data-testid":
                                    "day-time-picker-start-time-icon",
                                },
                                textField: {
                                  "data-testid":
                                    "day-time-picker-start-time-input",
                                  fullWidth: true,
                                },
                              }}
                              label="Start"
                              ampm={false}
                              timeSteps={{ minutes: 1 }}
                              maxTime={minutesToMoment(endTime[day])}
                              value={minutesToMoment(startTime[day])}
                              onChange={handleSetStartTime(day)}
                            />
                          </Grid>
                          <Grid item xs={6}>
                            <TimePicker
                              slotProps={{
                                openPickerIcon: {
                                  "data-testid":
                                    "day-time-picker-end-time-icon",
                                },
                                textField: {
                                  "data-testid":
                                    "day-time-picker-end-time-input",
                                  fullWidth: true,
                                },
                              }}
                              label="End"
                              ampm={false}
                              timeSteps={{ minutes: 1 }}
                              minTime={minutesToMoment(startTime[day])}
                              value={minutesToMoment(endTime[day])}
                              onChange={handleSetEndTime(day)}
                            />
                          </Grid>
                          <StyledGridItem item xs={12}>
                            <PositiveAction
                              disabled={
                                startTime[day] === null || endTime[day] === null
                              }
                              onClick={() => validateAddTimeInterval(day)}
                              testId={`${day}-submit-time-button`}
                              buttonText="Add"
                            />
                          </StyledGridItem>
                          {dialogErrors[day] && (
                            <Grid item xs={12}>
                              <Alert
                                severity="error"
                                data-testid={`${day}-error-alert`}
                                sx={{ mb: 2 }}
                              >
                                {dialogErrors[day]}
                              </Alert>
                            </Grid>
                          )}
                        </Grid>
                      )}
                      <Grid item xs={12}>
                        {dayRules[day].intervals.map((interval, i) => {
                          const start = minutesToMoment(interval.start);
                          const end = minutesToMoment(interval.end);
                          return (
                            <Chip
                              key={`d-${day}-interval-${interval.start}-${interval.end}`}
                              onDelete={() => handleRemoveTimeInterval(day, i)}
                              label={`${start.format("HH:mm")} - ${end.format(
                                "HH:mm",
                              )}`}
                            />
                          );
                        })}
                      </Grid>
                    </>
                  )}
                </Grid>
              );
            })}
          </Grid>
        </Collapse>
      </CardContent>
    </Card>
  );
};

DayTimeChooser.propTypes = {
  handleRemoveTimeInterval: PropTypes.func.isRequired,
  handleAddTimeInterval: PropTypes.func.isRequired,
  handleDayChange: PropTypes.func.isRequired,
  handleApplyToAllDays: PropTypes.func.isRequired,
  dayRules: PropTypes.shape({ days: PropTypes.shape({}) }).isRequired,
  showCollapsibleContent: PropTypes.bool.isRequired,
  toggleCollapsed: PropTypes.func.isRequired,
  readOnly: PropTypes.bool,
};

export default DayTimeChooser;
