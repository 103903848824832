import React from "react";
import moment from "moment";

import CustomTimePicker from "../../../components/Datagrid/CustomTimePicker";
import CustomMultiSelect from "../../../components/Datagrid/CustomMultiSelect";
import MultiSelectFilter from "../../../components/Datagrid/Filters/MultiSelectFilter";

const getColumns = salesAreas => [
  {
    field: "enabled",
    headerName: "Enabled",
    type: "boolean",
    editable: true,
  },
  {
    field: "salesAreas",
    headerName: "Sales Areas",
    type: "custom",
    editable: true,
    valueOptions: salesAreas,
    valueFormatter: value => {
      if (value === null || value.length === 0) {
        return "";
      }

      return value
        .map(
          id => salesAreas.find(({ id: areaId }) => areaId === id).description,
        )
        .sort((a, b) => a.localeCompare(b))
        .join(", ");
    },
    filterOperators: [
      {
        value: "isAnyOf",
        getApplyFilterFn: filterItem => {
          if (filterItem.value === undefined || filterItem.value.length === 0) {
            return null;
          }

          return value => {
            return value.some(id => filterItem.value.includes(id));
          };
        },
        InputComponent: props => (
          <MultiSelectFilter props={props} options={salesAreas} />
        ),
      },
    ],
    renderEditCell: params => (
      <CustomMultiSelect params={params} options={salesAreas} />
    ),
  },
  {
    field: "description",
    headerName: "Description",
    type: "text",
    editable: true,
    preProcessEditCellProps: params => {
      const {
        props,
        props: { value },
      } = params;

      return {
        ...props,
        error: !value,
      };
    },
  },
  {
    field: "day",
    headerName: "Day",
    type: "singleSelect",
    editable: true,
    valueOptions: [
      { text: "Monday", value: "1" },
      { text: "Tuesday", value: "2" },
      { text: "Wednesday", value: "3" },
      { text: "Thursday", value: "4" },
      { text: "Friday", value: "5" },
      { text: "Saturday", value: "6" },
      { text: "Sunday", value: "7" },
    ],
    minWidth: 135,
    getOptionLabel: ({ text }) => text,
    getOptionValue: ({ value }) => value,
  },
  {
    field: "startTime",
    headerName: "Start Time",
    type: "custom",
    editable: true,
    minWidth: 175,
    valueFormatter: value => {
      return value === null ? "" : moment.utc(value * 1000).format("hh:mm A");
    },
    renderEditCell: params => <CustomTimePicker params={params} />,
  },
  {
    field: "endTime",
    headerName: "End Time",
    type: "custom",
    editable: true,
    minWidth: 175,
    valueFormatter: value => {
      return value === null ? "" : moment.utc(value * 1000).format("hh:mm A");
    },
    renderEditCell: params => <CustomTimePicker params={params} />,
  },
  {
    field: "priceLevel",
    headerName: "Price Level",
    type: "singleSelect",
    editable: true,
    minWidth: 175,
    valueOptions: [
      { text: "Price Level 1", value: "1" },
      { text: "Price Level 2", value: "2" },
      { text: "Price Level 3", value: "3" },
      { text: "Price Level 4", value: "4" },
      { text: "Price Level 5", value: "5" },
      { text: "Price Level 6", value: "6" },
    ],
    getOptionLabel: ({ text }) => text,
    getOptionValue: ({ value }) => value,
  },
];

export default getColumns;
