import React from "react";
import { useParams } from "react-router-dom";
import PropTypes from "prop-types";

const ClassComponentParamWrapper = ({ Component }) => {
  const params = useParams();

  return <Component match={{ params }} />;
};

ClassComponentParamWrapper.propTypes = {
  Component: PropTypes.oneOfType([PropTypes.element, PropTypes.object])
    .isRequired,
};

export default ClassComponentParamWrapper;
