import React from "react";

import SyncPOS from "./Components/CustomRender/SyncPOS";
import LoyaltyConnection from "./Components/CustomRender/LoyaltyConnection";
import JustEatSettings from "./Components/CustomRender/JustEatSettings";
import PropertyManagementSettings from "./Components/CustomRender/PropertyManagementSettings";

const generalSettings = ({ appStore }) => {
  return [
    {
      label: "Account Name",
      storeName: "accountName",
      storeSetter: appStore.settingsStore.setAccountName,
      type: "TEXT",
    },
    {
      label: "Polaris Data Subdomain",
      storeName: "polarisDataSubdomain",
      storeSetter: appStore.settingsStore.setPolarisDataSubdomain,
      type: "TEXT",
      // This regex allows either an empty string or a valid subdomain
      // e.g. 3 characters or more, starting with a letter or number,
      // ending with a letter or number, and containing only letters, numbers, dots, or hyphens.
      validationRegex: /(^$)|(^[a-zA-Z0-9][a-zA-Z0-9.-]+[a-zA-Z0-9]$)/,
      errorMessage: "Please enter a valid subdomain",
    },
    {
      label: "Booking Cancellation Policy",
      storeName: "bookingCancellationPolicy",
      storeSetter: appStore.settingsStore.setBookingCancellationPolicy,
      storeOptions: Array.from(
        appStore.settingsStore.bookingCancellationPolicies.values(),
      ),
      type: "DROPDOWN",
    },
    {
      label: "Minimum Order Surcharge PLU",
      storeName: "minimumOrderSurchargePlu",
      storeSetter: appStore.settingsStore.setMinimumOrderSurchargePlu,
      type: "NUMBER",
      errorMessage: "Please specify a numeric PLU",
    },
    {
      label: "Delivery Fee PLU",
      storeName: "deliveryFeePlu",
      storeSetter: appStore.settingsStore.setDeliveryFeePlu,
      type: "NUMBER",
      errorMessage: "Please specify a numeric PLU",
    },
    {
      label: "Trading Day End Time",
      storeName: "tradingDayEndTime",
      storeSetter: appStore.settingsStore.setTradingDayEndTime,
      type: "TIME",
      validationRegex: /^\d+$/,
      errorMessage: "Please specify a valid time",
      tooltip: "The point at which trading has normally ceased for the day.",
      required: true,
    },
    {
      label: "Table Number Enabled",
      storeName: "isTableNumberEnabled",
      storeSetter: appStore.settingsStore.setIsTableNumberEnabled,
      type: "SWITCH",
    },
    {
      label: "EAT IN Enabled",
      storeName: "isEatInEnabled",
      storeSetter: appStore.settingsStore.setIsEatInEnabled,
      type: "SWITCH",
    },
    {
      label: "Transaction Count Report Enabled",
      storeName: "isReportTransactionCountEnabled",
      storeSetter: appStore.settingsStore.setIsReportTransactionCountEnabled,
      type: "SWITCH",
    },
    {
      label: "Show Changesets",
      storeName: "showChangesets",
      storeSetter: appStore.settingsStore.setShowChangesets,
      type: "SWITCH",
    },
  ];
};

const posSettings = ({ appStore }) => {
  return [
    {
      label: "POS Service Charge ID",
      storeName: "POSServiceChargeId",
      storeSetter: appStore.settingsStore.setPOSServiceChargeId,
      type: "TEXT",
      required: true,
      errorMessage: "Please specify a numeric ID",
    },
    {
      label: "Default POS Version",
      storeName: "POSVersion",
      storeSetter: appStore.settingsStore.setPOSVersion,
      type: "TEXT",
      required: true,
      validationRegex: /^\d{1,2}\.\d{1,3}\.\d{1,3}$/,
      errorMessage: "Please specify a valid POS Version ID (i.e. 6.3.500)",
    },
    {
      label: "POS Sync Enabled",
      storeName: "isSyncEnabled",
      storeSetter: appStore.settingsStore.setIsSyncEnabled,
      type: "SWITCH",
    },
    {
      customRender: <SyncPOS key="setting-syncPos" />,
      type: "CUSTOM",
    },
    {
      label: "Uncompile order items when sending to POS",
      storeName: "uncompileOrderItemsForPOS",
      storeSetter: appStore.settingsStore.setUncompileOrderItemsForPOS,
      type: "SWITCH",
    },
  ];
};

const loyaltySettings = () => {
  return [
    {
      customRender: <LoyaltyConnection key="setting-loyaltyConnection" />,
      type: "CUSTOM",
    },
  ];
};

const collinsSettings = ({ appStore }) => {
  return [
    {
      label: "Collins Preorder Enabled",
      storeName: "isCollinsPreorderEnabled",
      storeSetter: appStore.settingsStore.setIsCollinsPreorderEnabled,
      type: "SWITCH",
    },
  ];
};

const deliverooSettings = ({ appStore }) => {
  return [
    {
      label: "Deliveroo Discount ID",
      storeName: "deliverooDiscountId",
      storeSetter: appStore.settingsStore.setDeliverooDiscountId,
      type: "NUMBER",
      errorMessage: "Please specify a numeric ID",
    },
    {
      label: "Deliveroo Brand IDs",
      storeName: "deliverooBrandIds",
      storeSetter: appStore.settingsStore.setDeliverooBrandIds,
      type: "MULTI-TEXT",
      validationRegex: /^([a-zA-Z0-9]+)(,[a-zA-Z0-9]+)*$/,
      errorMessage: "Please specify brand IDs as alphanumeric values",
    },
  ];
};

const justEatSettings = () => {
  return [
    {
      customRender: <JustEatSettings key="setting-justeat" />,
      type: "CUSTOM",
    },
  ];
};

const vitaMojoSettings = ({ appStore }) => {
  return [
    {
      label: "Vita Mojo Discount ID",
      storeName: "vitaMojoDiscountId",
      storeSetter: appStore.settingsStore.setVitaMojoDiscountId,
      type: "NUMBER",
      errorMessage: "Please specify a numeric ID",
    },
  ];
};

const preOrdersSettings = ({ appStore }) => {
  return [
    {
      label: "Pre-order Lookahead Time",
      storeName: "preorderLookaheadTime",
      storeSetter: appStore.settingsStore.setPreorderLookaheadTime,
      type: "TIME",
      ampm: false,
      tooltip:
        "This defines the earliest time that we will send a preorder to POS. For example, if this is set to 3 hours, we would not send a preorder for 6PM before 3PM. We will never send preorders unless we are on the same trading day.",
      validationRegex: /^\d+$/,
      errorMessage: "Please specify in hours and minutes",
      required: true,
    },
  ];
};

const propertyManagementSettings = () => {
  return [
    {
      customRender: (
        <PropertyManagementSettings key="setting-property-Management" />
      ),
      type: "CUSTOM",
    },
  ];
};

const settings = ({ appStore }) => {
  return [
    {
      category: "General",
      items: generalSettings({ appStore }),
    },
    {
      category: "Pos",
      items: posSettings({ appStore }),
    },
    {
      category: "Loyalty",
      items: loyaltySettings(),
    },
    {
      category: "Collins",
      items: collinsSettings({ appStore }),
    },
    {
      category: "Deliveroo",
      items: deliverooSettings({ appStore }),
    },
    {
      category: "Just Eat",
      items: justEatSettings({ appStore }),
      disableDefaultSave: true,
    },
    {
      category: "Vita Mojo",
      items: vitaMojoSettings({ appStore }),
    },
    {
      category: "Pre-orders",
      items: preOrdersSettings({ appStore }),
    },
    {
      category: "Property Management",
      items: propertyManagementSettings(),
      disableDefaultSave: true,
    },
  ];
};

export default settings;
