export const createColumns = (serverRoles = [], departments = []) => [
  {
    field: "name",
    description: "Server Name",
    headerName: "Name",
    type: "text",
    editable: true,
    flex: 2,
    preProcessEditCellProps: params => {
      const { props } = params;
      const { value } = props;

      return {
        ...props,
        error: !value,
      };
    },
  },
  {
    field: "logonCode",
    description: "Server's till login code",
    headerName: "Logon Code",
    type: "text",
    editable: true,
    flex: 1,
    preProcessEditCellProps: params => {
      const { props } = params;
      const { value } = props;

      return {
        ...props,
        error: !value,
      };
    },
  },
  {
    field: "userLevel",
    description: "User's primary auth Level",
    headerName: "User Level",
    type: "singleSelect",
    editable: true,
    flex: 1,
    getOptionValue: ({ id }) => id,
    getOptionLabel: ({ description }) => description,
    valueOptions: serverRoles,
    preProcessEditCellProps: params => {
      const { props } = params;
      const { value } = props;
      return {
        ...props,
        error: !value,
      };
    },
  },
  {
    field: "authCode",
    description: "Server's secondary till login code",
    headerName: "Auth Code",
    type: "text",
    editable: true,
    flex: 1,
  },
  {
    field: "authLevel",
    description: "User's secondary auth Level",
    headerName: "Auth Level",
    type: "singleSelect",
    editable: true,
    flex: 1,
    getOptionValue: ({ id }) => id,
    getOptionLabel: ({ description }) => description,
    valueOptions: [{ id: "", description: "(None)" }, ...serverRoles],
  },
  {
    field: "isTraining",
    description: "Server is training",
    headerName: "Training",
    type: "boolean",
    editable: true,
    flex: 1,
  },
  {
    field: "hourlyCost",
    description: "Server's hourly cost",
    headerName: "Hourly Cost",
    editable: true,
    flex: 1,
    type: "text",
    valueFormatter: value => `£${(value / 100).toFixed(2)}`,
  },
  {
    field: "department",
    description: "Server's department",
    headerName: "Department",
    type: "singleSelect",
    editable: true,
    flex: 2,
    getOptionValue: ({ id }) => id,
    getOptionLabel: ({ description }) => description,
    valueOptions: [{ id: "", description: "(None)" }, ...departments],
  },
  {
    field: "isAutomated",
    description: "Server is automated",
    headerName: "Automated",
    type: "boolean",
    editable: true,
    flex: 1,
  },
];
