import differenceBy from "lodash/differenceBy";

const isTag = item => {
  return ["GENERIC", "MAJORGROUP", "PRODUCTGROUP", "SUBGROUP"].includes(
    item.type,
  );
};

/**
 *
 * @param {object} appStore
 * @param {object} productRules Buckets in the form of bucketId => bucket
 * @param {function} dispatchError update the state of the caller
 * @param {function} setBucketErrors  updates the state of the caller
 */
const checkBucketRules = async (
  appStore,
  productRules,
  dispatchError,
  setBucketErrors,
) => {
  // To check for conflicts we need to know about all products, even in tags.
  // If there are tags in this bucket, get the productIds for the tag and any children that the tag has.
  // This allows for checking against MAJORGROUPS, the code fetches the product ids of ALL children of that tag.
  const getAllProductsInBucket = async (bucketId, bucket) => {
    const mapProductsToSource = [];
    await Promise.allSettled(
      Object.values(bucket.items).map(async bucketItem => {
        if (isTag(bucketItem)) {
          // If this is in MST it will be returned from state, else it will fetch from API
          const tagModel = await appStore.productTagStore.find(bucketItem.id);
          // If we've already fetched products, this will return those, else it will get them from the API.
          const tagModelWithProducts =
            await tagModel.getAllAssociatedProducts();
          // Iterate the products on this tag, creating an array entry for it with the information required
          // to product validation errors later.
          tagModelWithProducts.products.forEach(productId =>
            mapProductsToSource.push({
              id: productId,
              name: bucketItem.name,
              type: "TAG",
              metaId: bucketItem.meta.id,
              bucketId,
            }),
          );
        } else {
          // This is a product
          mapProductsToSource.push({
            id: bucketItem.id,
            name: bucketItem.name,
            type: "PRODUCT",
            metaId: bucketItem.meta.id,
            bucketId,
          });
        }
      }),
    );
    return mapProductsToSource;
  };

  /**
   * Find all of the product ids that appear in seen and the next of the remaining buckets (conflicts)
   * @param {array} seen Array of buckets' products that have already been checked for conflicts
   * @param {array} remainingBuckets Buckets left to check
   * @param {array} remainingBucketIds Ids of buckets left to check
   */
  const checkSeenAgainstNext = async (
    seen,
    remainingBuckets,
    remainingBucketIds,
  ) => {
    let allConflicts = [];
    const nextBucket = remainingBuckets.shift();
    const nextBucketId = remainingBucketIds.shift();
    const productsInNextBucket = await getAllProductsInBucket(
      nextBucketId,
      nextBucket,
    );

    // I'm using differenceBy to get the product items found in both seen buckets and the next bucket
    const conflictedInSeen = differenceBy(
      seen,
      differenceBy(seen, productsInNextBucket, "id"),
      "id",
    );

    const conflictingInNext = differenceBy(
      productsInNextBucket,
      differenceBy(productsInNextBucket, seen, "id"),
      "id",
    );

    // Iterate over the conflicts in the next bucket, fetching the conflicted items from the seen buckets.
    // This allows us to inform the user of what/how many have conflicted
    const conflictingItems = conflictingInNext
      .flatMap(conflictingItem => {
        const conflictedWith = conflictedInSeen.filter(
          ({ id }) => id === conflictingItem.id,
        );
        return conflictedWith.map(uniqueConflict => {
          return {
            conflict: {
              name: conflictingItem.name,
              meta: { id: conflictingItem.metaId },
              type: conflictingItem.type,
            },
            item: {
              name: uniqueConflict.name,
              meta: { id: uniqueConflict.metaId },
              type: uniqueConflict.type,
            },
            itemBucket: uniqueConflict.bucketId,
            conflictBucket: conflictingItem.bucketId,
            qty: 1,
          };
        });
      })
      // We're checking products against products, not tags against products.
      // We will almost certainly have duplicates here, because a tag can contain
      // many products.  This reduce will address that, rolling all dupes up into a single
      // conflict and set a qty of conflicting items
      .reduce((acc, conflict) => {
        const conflictingTag = acc.find(
          item =>
            item.item.meta.id === conflict.item.meta.id &&
            item.conflict.meta.id === conflict.conflict.meta.id,
        );
        if (conflictingTag) {
          conflictingTag.qty += 1;
          return acc;
        }
        return [...acc, conflict];
      }, []);

    allConflicts = allConflicts.concat(conflictingItems);

    const allSeen = [...productsInNextBucket, ...seen];
    // Continuing iterating remaining buckets recursively.
    if (remainingBuckets.length) {
      const nextBucketResults = await checkSeenAgainstNext(
        allSeen,
        remainingBuckets,
        remainingBucketIds,
      );
      allConflicts.push(...nextBucketResults);
    }
    return allConflicts;
  };

  /**
   * Iterate conflicts and create helpful error messages
   * @param {array} conflictesByBucket Array of arrays, conflicts by bucket
   */
  const getErrorMessages = (conflicts, buckets) => {
    return conflicts.map(bucketConflict => {
      const { item, conflict, itemBucket, conflictBucket, qty } =
        bucketConflict;

      const itemBucketIndex = Object.keys(buckets).findIndex(
        bucketId => bucketId === itemBucket,
      );
      const conflictBucketIndex = Object.keys(buckets).findIndex(
        bucketId => bucketId === conflictBucket,
      );

      const itemBucketName =
        itemBucketIndex === 0
          ? "the default bucket"
          : `bucket ${itemBucketIndex}`;
      const conflictBucketName =
        conflictBucketIndex === 0
          ? "the default bucket"
          : `bucket ${conflictBucketIndex}`;

      let message;
      if (item.type === "PRODUCT" && conflict.type === "PRODUCT") {
        message = `${item.name} is in ${itemBucketName} and ${conflictBucketName}`;
      } else if (item.type === "PRODUCT" && conflict.type === "TAG") {
        message = `${conflict.name} in ${conflictBucketName} contains ${item.name} from ${itemBucketName}`;
      } else if (item.type === "TAG" && conflict.type === "PRODUCT") {
        message = `${conflict.name} in ${conflictBucketName} is a member of ${item.name} in ${itemBucketName}`;
      } else {
        message = `${
          conflict.name
        } in ${conflictBucketName} shares ${qty} item${
          qty > 1 ? "s" : ""
        } with ${item.name} in ${itemBucketName}`;
      }

      return { ...bucketConflict, message };
    });
  };

  const buckets = Object.values(productRules);
  const bucketIds = Object.keys(productRules);

  // Nothing to check
  if (buckets.length < 2) {
    return;
  }

  const firstBucket = buckets.shift();
  const bucketId = bucketIds.shift();

  const productsInFirstBucket = await getAllProductsInBucket(
    bucketId,
    firstBucket,
  );
  const conflicts = await checkSeenAgainstNext(
    productsInFirstBucket,
    buckets,
    bucketIds,
  );

  const errorMessages = getErrorMessages(conflicts, productRules);
  setBucketErrors(errorMessages);

  if (errorMessages.length) {
    dispatchError({
      type: "set",
      key: "bucketError",
      message: errorMessages.map(({ message }) => message),
    });
  } else {
    dispatchError({ type: "unset", key: "bucketError" });
  }
};

export default checkBucketRules;
