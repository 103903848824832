import React from "react";

import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";

import { closeSnackbar } from "notistack";

export const closeAction = snackbarId => (
  <IconButton
    size="small"
    aria-label="close"
    color="inherit"
    onClick={() => closeSnackbar(snackbarId)}
  >
    <CloseIcon fontSize="small" />
  </IconButton>
);

/**
 * Helper function to test if a snackbar is triggered or not.
 * The main use of this is to test if a snackbar is enqueued with a particular data-testId,
 * however, you can include specific text or variant to also test by.
 */
export const testSnackbar = ({
  exists = true,
  mockFunc,
  testId,
  text,
  variant,
}) => {
  const testText = text ?? expect.any(String);
  const testOptions = {
    ...(testId && {
      SnackbarProps: {
        "data-testid": testId,
      },
    }),
    variant: variant ?? expect.any(String),
  };

  if (exists) {
    expect(mockFunc).toHaveBeenCalledWith(
      testText,
      expect.objectContaining(testOptions),
    );
  } else {
    expect(mockFunc).not.toHaveBeenCalledWith(
      testText,
      expect.objectContaining(testOptions),
    );
  }
};
