import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import gql from "graphql-tag";
import { useQuery, useMutation } from "@apollo/client";
import { styled } from "@mui/material/styles";
import { observer, inject, PropTypes as MobXPropTypes } from "mobx-react";
import { useSnackbar } from "notistack";
import { v4 } from "uuid";

import { DataGrid, useGridApiRef } from "@mui/x-data-grid";

import CustomGridRow from "../../../components/Datagrid/CustomGridRow";
import CustomToolbar from "../../../components/Datagrid/CustomToolbar";

import { columns, formatColumns } from "./utils/columns";

import {
  GET_TILLS,
  UPDATE_TILL,
  ADD_TILL,
} from "../../../helpers/apollo/utils";

const StyledBox = styled("div")(({ theme }) => ({
  height: "70vh",
  width: "100%",
  "& .Mui-error": {
    backgroundColor: `rgb(126,10,15, ${theme.palette.mode === "dark" ? 0 : 0.1})`,
    color: theme.palette.error.main,
  },
}));

export const getNewRow = () => {
  const id = v4();

  const newRow = {
    id,
    tillId: null,
    tillName: "",
    canBeMaster: false,
    apiServer: false,
    apiTill: false,
    takeOut: false,
    pdqTerminal: false,
    rabbitMq: false,
    logonLength: null,
    machineId: "",
    licence: "",
    days: null,
    oposPort: "",
    scannerName: "",
    // We use this when updating a row to tell us this till needs to be created
    createdRow: true,
  };

  return newRow;
};

const TillsBulkEditor = ({ appStore, site = "" }) => {
  const apiRef = useGridApiRef();

  const { enqueueSnackbar } = useSnackbar();

  const [tableData, setTableData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [siteId, setSiteId] = useState(site);
  const [createdRow, setCreatedRow] = useState(false);

  const resizeColumns = () => {
    apiRef?.current?.autosizeColumns({
      columns: ["tillName", "machineId", "licence", "oposPort", "scannerName"],
      includeHeaders: true,
      includeOutliers: true,
      expand: true,
    });
  };

  useEffect(() => {
    // To avoid a setTimeout we have tested subscribing to the 'stateChange' event,
    // however, this causes cell jumping and breaks responsively and therefore
    // have resorted to a timeout, which is also used in the MUI docs for this feature.
    setTimeout(() => {
      resizeColumns();
    }, 10);
  }, [tableData]);

  useEffect(() => {
    // We want to ensure the siteId isn't null before setting it in state,
    // triggerring an API call with the filter.
    if (site) {
      setLoading(true);
      setSiteId(site);
      setCreatedRow(false);
    }
  }, [site]);

  const { error: getTillsError } = useQuery(gql(GET_TILLS()), {
    fetchPolicy: "cache-and-network",
    onCompleted: data => {
      setTableData(data?.tills || []);

      appStore.setLoading(false);
      setLoading(false);
    },
    skip: !siteId,
    variables: {
      filter: {
        siteId_is: siteId,
      },
    },
  });

  const [updateTill, { error: updateTillError }] = useMutation(
    gql(UPDATE_TILL()),
    {
      onCompleted: () => {
        enqueueSnackbar("Your changes have been saved", {
          SnackbarProps: { "data-testid": "bulk-tills-saved-snackbar" },
          variant: "success",
        });
      },
      refetchQueries: [gql(GET_TILLS()), "Tills"],
    },
  );

  const [addTill, { error: addTillError }] = useMutation(gql(ADD_TILL()), {
    onCompleted: () => {
      enqueueSnackbar("Your new till has been created", {
        SnackbarProps: { "data-testid": "bulk-tills-added-snackbar" },
        variant: "success",
      });
    },
    refetchQueries: [gql(GET_TILLS()), "Tills"],
  });

  // When an API error occurs, display it in a snackbar
  useEffect(() => {
    if (getTillsError || updateTillError || addTillError) {
      const fallbackErrorMessage = "Please try again later.";

      const errorMsg =
        getTillsError?.message ||
        updateTillError?.message ||
        addTillError?.message ||
        fallbackErrorMessage;

      enqueueSnackbar(`Error: ${errorMsg}`, {
        variant: "error",
        SnackbarProps: {
          "data-testid": "bulk-tills-error-snackbar",
        },
      });
    }
  }, [getTillsError, updateTillError, addTillError, enqueueSnackbar]);

  const addRow = () => {
    const newRow = getNewRow();

    const newTableData = [...tableData];
    newTableData.unshift(newRow);

    setTableData(newTableData);
    setCreatedRow(true);
  };

  const deleteRow = () => {
    const newTableData = tableData.filter(row => row.createdRow === undefined);

    setTableData(newTableData);
    setCreatedRow(false);
  };

  const updateRow = (newRow, oldRow) => {
    // Get any changed fields
    const rowUpdates = Object.fromEntries(
      Object.entries(newRow).filter(
        ([key, val]) => key in oldRow && oldRow[key] !== val,
      ),
    );

    if (Object.keys(rowUpdates).length) {
      if (newRow.createdRow !== undefined) {
        addTill({
          variables: {
            input: {
              siteId,
              ...rowUpdates,
            },
          },
        });

        setCreatedRow(false);
      } else {
        updateTill({
          variables: {
            input: {
              id: newRow.id,
              ...rowUpdates,
            },
          },
        });
      }
    }

    return newRow;
  };

  return (
    <StyledBox>
      <DataGrid
        apiRef={apiRef}
        columns={formatColumns(columns)}
        editMode="row"
        loading={loading}
        rows={tableData}
        slots={{
          row: CustomGridRow,
          toolbar: () => (
            <CustomToolbar
              addAction={addRow}
              addLabel="Add Till"
              deleteAction={deleteRow}
              deleteLabel="Delete Till"
              isRowCreated={createdRow}
            />
          ),
        }}
        processRowUpdate={updateRow}
        // This below error handler is required when using processRowUpdate
        onProcessRowUpdateError={error => {
          enqueueSnackbar(`Error: ${error.message}`, {
            variant: "error",
            SnackbarProps: {
              "data-testid": "bulk-tills-error-snackbar",
            },
          });
        }}
      />
    </StyledBox>
  );
};

TillsBulkEditor.propTypes = {
  appStore: MobXPropTypes.objectOrObservableObject.isRequired,
  site: PropTypes.string,
};

export default inject("appStore")(observer(TillsBulkEditor));
