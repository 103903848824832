import React, { useEffect, useState } from "react";
import { observer, inject, PropTypes as MobXPropTypes } from "mobx-react";

import validator from "validator";

import owasp from "owasp-password-strength-test";

import { useNavigate } from "react-router-dom";

import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import InputLabel from "@mui/material/InputLabel";
import Input from "@mui/material/Input";
import InputAdornment from "@mui/material/InputAdornment";
import TextField from "@mui/material/TextField";
import { Box } from "@mui/material";
import IconVisibility from "@mui/icons-material/Visibility";
import IconVisibilityOff from "@mui/icons-material/VisibilityOff";

import PositiveAction from "./Button/PositiveAction";
import NegativeAction from "./Button/NegativeAction";

const PasswordChangeComponent = ({ appStore }) => {
  const navigate = useNavigate();
  const [formError, setFormError] = useState("");

  const [currentPassword, setCurrentPassword] = useState("");
  const [errorCurrentPassword, setErrorCurrentPassword] = useState("");
  const [showCurrentPassword, setShowCurrentPassword] = useState(false);

  const [newPassword, setNewPassword] = useState("");
  const [errorNewPassword, setErrorNewPassword] = useState("");
  const [showNewPassword, setShowNewPassword] = useState(false);

  const [confirmNewPassword, setConfirmNewPassword] = useState("");
  const [errorConfirmNewPassword, setErrorConfirmNewPassword] = useState("");

  // on initial load
  useEffect(() => {
    appStore.setLoading(false);
  }, [appStore]);

  const handleCurrentPasswordChange = event => {
    setCurrentPassword(event.target.value);
    setErrorCurrentPassword("");
    setFormError("");
  };

  const handleNewPasswordChange = event => {
    setNewPassword(event.target.value);
    setErrorNewPassword("");
    setFormError("");
  };

  const handleConfirmNewPasswordChange = event => {
    setConfirmNewPassword(event.target.value);
    setErrorConfirmNewPassword("");
    setFormError("");
  };

  const handleMouseDownPassword = event => {
    event.preventDefault();
  };

  const handleCancel = () => {
    appStore.setLoading();
    navigate(-1);
  };

  const handleSubmit = event => {
    let hasError = false;
    event.preventDefault();

    if (validator.isEmpty(currentPassword.toString())) {
      hasError = true;
      setErrorCurrentPassword("Please specify Current Password");
    }

    const result = owasp.test(newPassword.toString());
    if (result.strong === false) {
      hasError = true;
      setErrorNewPassword(result.errors.join(" "));
    }

    if (newPassword !== confirmNewPassword) {
      hasError = true;
      setErrorConfirmNewPassword(
        "Password confirmation does not match New Password",
      );
    }

    if (!hasError) {
      appStore.setLoading();

      appStore.userStore
        .changePassword({
          oldPassword: currentPassword,
          newPassword,
        })
        .then(() => {
          appStore.viewer.setIsPasswordChangeRequired(false);
          navigate("/app");
        })
        .catch(error => {
          appStore.log.error(error);
          setFormError(error.message);
          appStore.setLoading(false);
        });
    }
  };

  if (appStore.isLoading) {
    return null;
  }

  return (
    <form>
      <Grid
        container
        spacing={3}
        sx={{ padding: "12px", justifyContent: "flex-start" }}
      >
        {formError && (
          <Grid item xs={12}>
            <FormHelperText error>{formError}</FormHelperText>
          </Grid>
        )}
        <Grid item xs={12}>
          <Grid item xs={6}>
            <FormControl
              variant="standard"
              fullWidth
              error={!!errorCurrentPassword}
            >
              <InputLabel>Current Password</InputLabel>
              <Input
                id="oldPassword"
                type={showCurrentPassword ? "text" : "password"}
                value={currentPassword}
                onChange={handleCurrentPasswordChange}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() =>
                        setShowCurrentPassword(!showCurrentPassword)
                      }
                      onMouseDown={handleMouseDownPassword}
                      size="medium"
                    >
                      {showCurrentPassword ? (
                        <IconVisibility />
                      ) : (
                        <IconVisibilityOff />
                      )}
                    </IconButton>
                  </InputAdornment>
                }
              />
              {!!errorCurrentPassword && (
                <FormHelperText>{errorCurrentPassword}</FormHelperText>
              )}
            </FormControl>
          </Grid>
        </Grid>
        <Grid item xs={6}>
          <FormControl variant="standard" fullWidth error={!!errorNewPassword}>
            <InputLabel>New Password</InputLabel>
            <Input
              id="newPassword"
              type={showNewPassword ? "text" : "password"}
              value={newPassword}
              onChange={handleNewPasswordChange}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => setShowNewPassword(!showNewPassword)}
                    onMouseDown={handleMouseDownPassword}
                    size="medium"
                  >
                    {showNewPassword ? (
                      <IconVisibility />
                    ) : (
                      <IconVisibilityOff />
                    )}
                  </IconButton>
                </InputAdornment>
              }
            />
            <FormHelperText>
              The password must be at least 10 characters long and contain at
              least one uppercase letter, one number and one special character
            </FormHelperText>
          </FormControl>
        </Grid>
        <Grid item xs={6}>
          <TextField
            variant="standard"
            fullWidth
            type="password"
            autoComplete="off"
            id="confirmPassword"
            label="New Password (confirm)"
            value={confirmNewPassword}
            error={!!errorConfirmNewPassword}
            helperText={errorConfirmNewPassword}
            onChange={handleConfirmNewPasswordChange}
          />
        </Grid>
        <Grid item xs={12}>
          <Box mr={1} display="inline">
            <NegativeAction buttonText="Cancel" onClick={handleCancel} />
          </Box>
          <PositiveAction
            buttonText="Save"
            onClick={handleSubmit}
            testId="submitButton"
          />
        </Grid>
      </Grid>
    </form>
  );
};

PasswordChangeComponent.propTypes = {
  appStore: MobXPropTypes.objectOrObservableObject.isRequired,
};

export default inject("appStore")(observer(PasswordChangeComponent));
