import React, { Fragment, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { observer, inject, PropTypes as MobXPropTypes } from "mobx-react";
import { toJS } from "mobx";
import { Link } from "react-router-dom";

import clsx from "clsx";
import { withStyles } from "@mui/styles";
import red from "@mui/material/colors/red";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import FormHelperText from "@mui/material/FormHelperText";
import IconButton from "@mui/material/IconButton";
import CancelIcon from "@mui/icons-material/Cancel";
import EditIcon from "@mui/icons-material/Edit";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";

import MUIDataTable from "mui-datatables";

import AddAction from "../../components/Button/AddAction";
import ErrorBoundary from "../../components/ErrorBoundary";
import NegativeAction from "../../components/Button/NegativeAction";
import Page from "../../components/Page";
import PositiveAction from "../../components/Button/PositiveAction";
import Switch from "../../components/Switch";

const styles = theme => ({
  margin: {
    margin: theme.spacing(1),
  },
  button: {
    margin: 0,
    marginLeft: theme.spacing(1),
  },
  danger: {
    color: red[500],
    "&:hover": {
      backgroundColor: red[50],
    },
  },
});

const ListComponent = ({ appStore, classes }) => {
  const [items, setItems] = useState([]);
  const [selected, setSelected] = useState(null);
  const [formError, setFormError] = useState("");

  useEffect(() => {
    appStore.siteStore
      .findAll()
      .then(results => {
        setItems([...results.values()]);

        appStore.setLoading(false);
      })
      .catch(error => {
        appStore.log.error(error);
        setFormError(error.message);
        appStore.setLoading(false);
      });
  }, [appStore, appStore.siteStore.items.size]);

  const handleDeleteClick = item => {
    setSelected(toJS(item));
  };

  const toggleActiveStatus = (idToSearch, currentStatus, updateTableValue) => {
    const updatedStatus = !currentStatus;

    // update mobx cached data
    const foundStateItem = items.find(({ id }) => id === idToSearch);
    foundStateItem.setActive(updatedStatus).save();
    // trigger a re-render so that items are filtered as expected
    updateTableValue(updatedStatus);
  };

  const columns = [
    // we use this in the "active" field column to find the correct record in the
    // "items" state.
    {
      name: "id",
      options: { filter: false, sort: false, display: false },
    },
    {
      name: "name",
      label: "Name",
      options: {
        filter: true,
        sort: true,
        sortOrder: "asc",
      },
    },
    {
      name: "POSHouseNumber",
      label: "POS House Number",
      options: { filter: false, sort: true },
    },
    {
      name: "uberEatsStoresJoined",
      label: "Uber Eats Store ID",
      options: { filter: false, sort: true },
    },
    {
      name: "active",
      label: "Status",
      options: {
        filter: true,
        sort: true,
        filterType: "custom",
        filterList: ["Active"],
        filterOptions: {
          names: ["Active", "Inactive"],
          logic: (fieldValue, filters) => {
            const activeFilterStatus = filters[0];
            return (
              (activeFilterStatus === "Inactive" && fieldValue) ||
              (activeFilterStatus === "Active" && !fieldValue)
            );
          },
          display: (filterList, onChange, index, column) => (
            <FormControl variant="standard">
              <InputLabel>Status</InputLabel>
              <Select
                variant="standard"
                value={filterList[index]}
                onChange={({ target: { value } }) =>
                  // ternary operator to allow "All" filter to function correctly
                  onChange(value ? [value] : [], index, column)
                }
                data-testid="status-filter-select"
              >
                <MenuItem value="">All</MenuItem>
                <MenuItem value="Active">Active</MenuItem>
                <MenuItem value="Inactive">Inactive</MenuItem>
              </Select>
            </FormControl>
          ),
        },
        customBodyRender: (value, { rowData }, updateValue) => {
          const currentRowId = rowData[0];
          return (
            <Switch
              active={value}
              colour="success"
              onChange={() =>
                toggleActiveStatus(currentRowId, value, updateValue)
              }
            />
          );
        },
        customFilterListOptions: { render: value => `Status: ${value}` },
      },
    },
    {
      name: "",
      label: "Actions",
      options: {
        filter: false,
        sort: false,
        customBodyRenderLite: dataIndex => {
          const rowData = items[dataIndex];
          return (
            <Fragment key={rowData.id}>
              <Link to={`/app/sites/${rowData.id}`}>
                <IconButton
                  className={classes.button}
                  size="medium"
                  aria-label="Edit Site"
                >
                  <EditIcon />
                </IconButton>
              </Link>
              <IconButton
                className={clsx(classes.button, classes.danger)}
                onClick={() => handleDeleteClick(rowData)}
                size="medium"
              >
                <CancelIcon />
              </IconButton>
            </Fragment>
          );
        },
      },
    },
  ];

  const options = {
    filterType: "dropdown",
    fixedHeader: true,
    rowsPerPage: 100,
    selectableRows: "none",
    search: false,
    tableBodyHeight: "60vh",
    sortOrder: {
      name: "name",
      direction: "asc",
    },
    setTableProps: () => ({ "data-testid": "MUI-Datatable" }),
  };

  const handleDeleteYes = () => {
    appStore.setLoading();

    appStore.siteStore
      .remove(selected.id)
      .then(() => {
        handleDeleteClick(null);
        appStore.setLoading(false);
      })
      .catch(error => {
        appStore.log.error(error);
        setFormError(error.message);
        appStore.setLoading(false);
      });
  };

  const handleDeleteNo = () => {
    setSelected(null);
  };

  return (
    <ErrorBoundary>
      <Page title="Sites">
        <Container maxWidth={false}>
          <Grid container rowSpacing={3}>
            <Grid container item xs={12} justifyContent="end" mt={3}>
              <Link to="/app/sites/add">
                <AddAction buttonText="Add site" />
              </Link>
            </Grid>
            <Grid item xs={12}>
              <section>
                {selected && (
                  <Dialog open>
                    <DialogTitle>Delete site</DialogTitle>
                    <DialogContent>
                      <DialogContentText>
                        Are you sure you want to delete this site ?
                        <br />
                        <b>{selected.name}</b>
                      </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                      <NegativeAction
                        buttonText="No"
                        onClick={handleDeleteNo}
                      />
                      <PositiveAction
                        buttonText="Yes"
                        onClick={handleDeleteYes}
                      />
                    </DialogActions>
                  </Dialog>
                )}
                {formError && (
                  <FormHelperText error>{formError}</FormHelperText>
                )}
                {items.length > 0 && (
                  <MUIDataTable
                    data={items}
                    columns={columns}
                    options={options}
                  />
                )}
              </section>
            </Grid>
          </Grid>
        </Container>
      </Page>
    </ErrorBoundary>
  );
};

ListComponent.propTypes = {
  appStore: MobXPropTypes.objectOrObservableObject.isRequired,
  classes: PropTypes.shape({
    button: PropTypes.string,
    danger: PropTypes.string,
    addButton: PropTypes.string,
    showInactive: PropTypes.string,
  }).isRequired,
};

const List = withStyles(styles)(inject("appStore")(observer(ListComponent)));

export default List;
