import React from "react";

import Checkbox from "@mui/material/Checkbox";

// This is really only to add a test-id to each select checkbox
export const CustomCheckbox = props => {
  const testId =
    // eslint-disable-next-line react/prop-types
    props["data-description"] === "row-select-header"
      ? "select-all-toggle"
      : // eslint-disable-next-line react/prop-types
        `row-${props["data-index"] ?? 0}-selection`;
  return (
    <Checkbox
      {...{
        ...props,
        inputProps: {
          // eslint-disable-next-line react/prop-types
          ...(props.inputProps ?? {}),

          "data-testid": testId,
        },
      }}
    />
  );
};

/**
 * This reducer handles the actions involved in storing models
 * when selections/deselections are made in the table
 */
export function selectedItemsReducer(state, action) {
  const { item, items = [], type } = action;
  const newState = new Map(state);

  switch (type) {
    case "set":
      // Set the selected items to the items passed in
      return new Map(items.map(_item => [_item.id, _item]));
    case "update":
      // Add or remove an item from our state of selected items
      if (state.has(item.id)) {
        newState.delete(item.id);
        return newState;
      }
      newState.set(item.id, item);
      return newState;
    case "toggle":
      // This is select all/deselect all
      // eslint-disable-next-line no-case-declarations
      let somethingWasDeselected;
      // Iterate over the data in the current page of the table
      // If any of those items are currently in our selectedItems state
      // We know that deselect all has been pressed and we should remove it.
      items.forEach(_item => {
        if (state.has(_item.id)) {
          newState.delete(_item.id);
          somethingWasDeselected = true;
        }
      });
      // If we didn't delete anything above we know that it was
      // selectall that was pressed, so add all items in the current
      // table view to the state
      if (!somethingWasDeselected) {
        items.forEach(_item => {
          newState.set(_item.id, _item);
        });
      }
      return newState;
    default:
      throw new Error("Unknown action to selectedItemsReducer");
  }
}

// Handle selection changes from the table
export const selectChangeManagement = (
  action,
  tableState,
  { tableData, changeSelectedItems },
) => {
  if (action === "rowSelectionChange") {
    // If there's no dataIndex it means the de/select all toggle was used
    if (tableState?.previousSelectedRow?.dataIndex === undefined) {
      changeSelectedItems({ type: "toggle", items: tableData });
      return;
    }
    // If dataIndex exists we know a row was de/selected so update it.
    const item = tableData[tableState.previousSelectedRow.dataIndex];
    changeSelectedItems({ type: "update", item });
  }
};

export const handleRenderConfigForSelectedRows = (
  selectedItems,
  setRowsSelected,
  tableData,
  rowsPerPage,
) => {
  // Find which of our tracked items are selected
  const itemsInViewToSelect = tableData
    .map(({ id }, index) => {
      if (selectedItems.has(id)) {
        // And return their row index
        return index;
      }
      return null;
    })
    .filter(item => item !== null);

  // To keep the 'x Rows Selected' number accurate we tell
  // DataTable that some rows are selected but on a row
  // that we're not rendering on this page.
  const totalAmountOfSelectItems = selectedItems.size;
  const paddingRequired = totalAmountOfSelectItems - itemsInViewToSelect.length;
  const padding = Array(paddingRequired).fill(null);

  setRowsSelected([
    ...itemsInViewToSelect,
    ...padding.map((item, key) => rowsPerPage + key),
  ]);
};
