export default ({ highlight, theme }) => ({
  marginTop: 4,
  marginLeft: 4,
  backgroundColor: highlight ? theme.palette.error.main : "",
  color: highlight
    ? theme.palette.error.contrastText
    : theme.palette.contrastText,
  "&& .MuiChip-deleteIcon": {
    color: highlight
      ? theme.palette.error.contrastText
      : "rgba(8, 33, 48, 0.26)",
  },
  "&& .MuiChip-avatar": {
    color: highlight
      ? `${theme.palette.error.contrastText} !important`
      : `${theme.palette.grey[700]} !important`,
    backgroundColor: highlight
      ? theme.palette.error.dark
      : theme.palette.grey[400],
  },
});
