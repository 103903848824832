import { types, getEnv, getParent, flow } from "mobx-state-tree";
import gql from "graphql-tag";

import { transformer, removeTypename } from "../helpers";
import Deposit, { fragments as depositFragments } from "./models/Deposit";

// @todo treat versioning changes on preProcess
export default types
  .model("DepositStore", {
    items: types.optional(types.map(Deposit), {}),
  })
  .views(self => ({
    get appStore() {
      return getParent(self);
    },
  }))
  .actions(self => {
    const { apolloClient } = getEnv(self);
    const findAllLoaded = false;

    return {
      find: flow(function* find(id) {
        if (self.items.has(id)) {
          return self.items.get(id);
        }

        try {
          const response = yield apolloClient.query({
            query: gql`
            {
              deposit(id: "${id}") {
                ...DepositFullDetails
              }
            }
            ${depositFragments.fullDetails}
          `,
          });

          const item = self.track(response.data.deposit);

          return Promise.resolve(item);
        } catch (error) {
          return Promise.reject(error);
        }
      }),

      findAll: flow(function* findAll(filter = {}) {
        if (findAllLoaded) {
          return Promise.resolve(self.items);
        }

        try {
          const response = yield apolloClient.query({
            query: gql`
              {
                deposits {
                  ...DepositFullDetails
                }
              }
              ${depositFragments.fullDetails}
            `,
          });

          response.data.deposits.forEach(item => self.track(item));

          return Promise.resolve(self.items);
        } catch (error) {
          return Promise.reject(error);
        }
      }),

      add: flow(function* add(entity) {
        try {
          const input = transformer({
            forId: entity.forId,
            paymentTypeId: entity.paymentTypeId,
            amount: parseInt(entity.amount, 10),
            notes: entity.notes,
            takenAt: self.appStore.currentDateTime.toISOString(),
            currency: "GBP",
          });

          const response = yield apolloClient.mutate({
            mutation: gql`
            mutation addBooking {
              addDeposit(input: {
                status: TAKEN
                ${input}
              }) { 
                ...DepositFullDetails
                }
              }
              ${depositFragments.fullDetails}
          `,
          });

          return Promise.resolve(self.track(response.data.addDeposit));
        } catch (error) {
          return Promise.reject(error);
        }
      }),

      track(entity) {
        if (!self.items.has(entity.id)) {
          const input = removeTypename(entity);

          input.paymentType = self.appStore.paymentTypeStore.track(
            input.paymentType,
          );

          input.createdBy = self.appStore.userStore.track(input.createdBy);

          if (input.updatedBy !== null) {
            input.updatedBy = self.appStore.userStore.track(input.updatedBy);
          }

          return self.items.put(input);
        }

        return self.items.get(entity.id);
      },
    };
  });
