import React from "react";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { Link } from "react-router-dom";

import AddAction from "../../components/Button/AddAction";
import ErrorBoundary from "../../components/ErrorBoundary";
import Page from "../../components/Page";
import ProductsTable from "./Components/ProductsTable";

const List = () => {
  return (
    <ErrorBoundary>
      <Page title="Products">
        <Container maxWidth={false}>
          <Grid container rowSpacing={3}>
            <Grid
              container
              item
              xs={12}
              justifyContent="space-between"
              alignItems="center"
              mt={3}
            >
              <Grid item>
                <Typography variant="h2">Products</Typography>
              </Grid>
              <Grid item>
                <Link to="/app/products/add">
                  <AddAction />
                </Link>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Paper>
                <Box minWidth={1050}>
                  <ProductsTable handleRowClick={() => {}} />
                </Box>
              </Paper>
            </Grid>
          </Grid>
        </Container>
      </Page>
    </ErrorBoundary>
  );
};

export default List;
