import { types, getEnv, getParent, flow } from "mobx-state-tree";
import gql from "graphql-tag";

import ModifierGroup, {
  fragments as modifierGroupFragments,
} from "./models/ModifierGroup";
import { removeTypename, transformer } from "../helpers";

// @todo treat versioning changes on preProcess
export default types
  .model("modifierGroupStore", {
    items: types.optional(types.map(ModifierGroup), {}),
  })
  .views(self => ({
    get appStore() {
      return getParent(self);
    },
    getOrFetch(id) {
      if (self.items.has(id)) {
        return self.items.get(id);
      }
      setImmediate(() => {
        self.find(id);
      });
      return null;
    },
  }))
  .actions(self => {
    const { apolloClient } = getEnv(self);
    let findAllLoaded = false;

    return {
      find: flow(function* find(id) {
        if (self.items.has(id)) {
          return self.items.get(id);
        }

        try {
          const response = yield apolloClient.query({
            query: gql`
            {
              modifierGroup(id: "${id}") {
                ...ModifierGroupFullDetails
              }
            }
            ${modifierGroupFragments.fullDetails}
          `,
          });

          const item = self.track(response.data.modifierGroup);

          return Promise.resolve(item);
        } catch (error) {
          return Promise.reject(error);
        }
      }),

      findAll: flow(function* findAll(limit = 0, offset = 0, filter = {}) {
        if (findAllLoaded) {
          return Promise.resolve(self.items);
        }

        try {
          const response = yield apolloClient.query({
            query: gql`
              {
                modifierGroups {
                  ...ModifierGroupFullDetails
                }
              }
              ${modifierGroupFragments.fullDetails}
            `,
          });

          response.data.modifierGroups.forEach(item => self.track(item));

          findAllLoaded = true;

          return Promise.resolve(self.items);
        } catch (error) {
          return Promise.reject(error);
        }
      }),

      add: flow(function* add(entity) {
        try {
          const input = transformer({
            name: entity.name,
            POSClassId: entity.POSClassId,
            selectionLimit: entity.selectionLimit,
          });

          const response = yield apolloClient.mutate({
            mutation: gql`
            mutation addModifierGroup {
              addModifierGroup(input: {
                ${input}
              }) {
                ...ModifierGroupFullDetails
              }
            }
            ${modifierGroupFragments.fullDetails}
          `,
          });

          return Promise.resolve(self.track(response.data.addModifierGroup));
        } catch (error) {
          return Promise.reject(error);
        }
      }),

      track(entity) {
        if (!self.items.has(entity.id)) {
          const input = removeTypename(entity);

          input.createdBy = self.appStore.userStore.track(input.createdBy);

          if (input.updatedBy !== null) {
            input.updatedBy = self.appStore.userStore.track(input.updatedBy);
          }
          return self.items.put(input);
        }

        return self.items.get(entity.id);
      },
    };
  });
