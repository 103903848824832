import React from "react";
import PropTypes from "prop-types";
import { Droppable } from "react-beautiful-dnd";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/system";
import DraggableProductChip from "../DraggableChip";
import BucketStyle from "../../../styles/Form/BucketProductChooser/Bucket";

const StyledGrid = styled(Grid, {})(BucketStyle);

export default function Bucket({
  bucket,
  bucketKey,
  bucketStyle = "DefaultBucket",
  getItemStyle,
  handleRemove,
  highlight,
  itemFilter,
  prefix,
  productBucketFilter,
  testId = "bucket",
  titleBar,
}) {
  return (
    <StyledGrid
      item
      key={bucketKey}
      className="droppableOuter"
      xs={4}
      bucketstyle={bucketStyle}
    >
      <Grid
        container
        alignItems="center"
        className="droppableTitleBar"
        data-testid={`${testId}-${bucketKey}-droppable`}
      >
        {titleBar}
      </Grid>
      <div aria-label="droppable">
        <Droppable droppableId={bucketKey}>
          {(provided, snapshot) => (
            <Paper
              variant="outlined"
              ref={provided.innerRef}
              {...provided.droppableProps}
              style={getItemStyle(snapshot.isDraggingOver, bucketKey)}
              className="droppableInner"
            >
              {Object.values(bucket.items)
                .filter(itemFilter(productBucketFilter))
                .map((item, index) => (
                  <DraggableProductChip
                    key={item.meta.id}
                    item={item}
                    bucketId={bucketKey}
                    index={index}
                    handleRemove={handleRemove}
                    prefix={prefix}
                    highlight={highlight(item)}
                    testId={`${testId}-${bucketKey}-draggable-chip-${item.meta.id}`}
                  />
                ))}
              {provided.placeholder}
            </Paper>
          )}
        </Droppable>
      </div>
    </StyledGrid>
  );
}

Bucket.propTypes = {
  bucket: PropTypes.shape({
    items: PropTypes.shape({}),
  }).isRequired,
  bucketErrors: PropTypes.arrayOf(
    PropTypes.shape({
      item: PropTypes.shape({
        meta: PropTypes.shape({
          id: PropTypes.string,
        }),
      }),
      conflict: PropTypes.shape({
        meta: PropTypes.shape({
          id: PropTypes.string,
        }),
      }),
    }),
  ).isRequired,
  bucketKey: PropTypes.string.isRequired,
  bucketStyle: PropTypes.string,
  getItemStyle: PropTypes.func.isRequired,
  handleRemove: PropTypes.func.isRequired,
  highlight: PropTypes.func.isRequired,
  itemFilter: PropTypes.func.isRequired,
  prefix: PropTypes.string.isRequired,
  productBucketFilter: PropTypes.string.isRequired,
  testId: PropTypes.string,
  titleBar: PropTypes.node.isRequired,
};
