import React, { useState, useEffect } from "react";
import { observer, inject, PropTypes as MobXPropTypes } from "mobx-react";
import { applySnapshot, getSnapshot } from "mobx-state-tree";
import validator from "validator";
import { useParams, useNavigate } from "react-router-dom";

import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import Container from "@mui/material/Container";
import FormHelperText from "@mui/material/FormHelperText";
import Grid from "@mui/material/Grid";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TextField from "@mui/material/TextField";
import TableRow from "@mui/material/TableRow";

import IconDelete from "../../components/IconButton/Delete";
import ErrorBoundary from "../../components/ErrorBoundary";
import Page from "../../components/Page";
import Autocomplete from "../../components/Autocomplete";
import PositiveAction from "../../components/Button/PositiveAction";
import NegativeAction from "../../components/Button/NegativeAction";

export const ERROR_MESSAGES = {
  required: {
    name: "Please specify modifier group's name",
  },
  other: {
    POSClassId: "Please specify a POS Class ID",
    POSClassIdNumberRange: "POS Class ID must be between 1 and 999",
    selectionLimit: "Selection limit must be a number",
  },
};

const AddEditComponent = ({ appStore }) => {
  const navigate = useNavigate();
  const { modifierGroupId } = useParams();

  const [formData, setFormData] = useState({
    name: "",
    POSClassId: "",
    selectionLimit: "",
  });
  const [snapshot, setSnapshot] = useState(null);
  const [modifierGroup, setModifierGroup] = useState(null);
  /** products with modifier */
  const [modifierProducts, setModifierProducts] = useState(
    modifierGroup?.modifierProducts || [],
  );

  const [formDataErrors, setFormDataErrors] = useState({});
  const [queryError, setQueryError] = useState("");
  const [modifierProductsError, setModifierProductsError] = useState("");

  useEffect(() => {
    if (modifierGroupId !== undefined) {
      appStore.modifierGroupStore
        .find(modifierGroupId)
        .then(result => {
          result
            .getModifierProducts()
            .then(modifierGroupWithModifierProducts => {
              const { name, POSClassId, selectionLimit } =
                modifierGroupWithModifierProducts;

              setSnapshot(getSnapshot(modifierGroupWithModifierProducts));
              setModifierGroup(modifierGroupWithModifierProducts);
              setFormData({
                name,
                POSClassId,
                selectionLimit: selectionLimit ?? "",
              });
              setModifierProducts(
                modifierGroupWithModifierProducts.modifierProducts,
              );
              appStore.setLoading(false);
            });
        })
        .catch(error => {
          appStore.log.error(error);
          navigate("/app/404");
        });
    } else {
      appStore.modifierGroupStore
        .findAll()
        .then(results => {
          let counter = 1;
          let suggestedId = null;

          if (results.length === 0) {
            suggestedId = counter;
          } else {
            const existingIds = [...results.values()]
              .map(option => option.POSClassId)
              .sort((a, b) => a - b);

            for (const id of existingIds) {
              if (id !== counter) {
                // we have a gap in the existing ids, fill
                suggestedId = counter;
                break;
              }
              counter += 1;
            }

            // existing ids are sequential, assign next
            if (suggestedId === null) {
              suggestedId = counter;
            }
          }
          setFormData({ ...formData, POSClassId: suggestedId });
          appStore.setLoading(false);
        })
        .catch(error => {
          appStore.log.error(error);
          navigate("/app/404");
        });
    }
  }, [modifierGroupId]);

  const onAddProduct = value => {
    if (!modifierProducts.find(item => item.id === value.id)) {
      if (
        value.modifierGroup !== null &&
        value.modifierGroup.id !== modifierGroupId
      ) {
        setModifierProductsError(
          `Product is already modifier for "${value.modifierGroup.name}"`,
        );
        return null;
      }
      setModifierProducts([...modifierProducts, value]);
      return null;
    }
    setModifierProductsError("Product already associated");
    return null;
  };

  const handleInputChange = ({ target: { value, name } }) => {
    setFormData({ ...formData, [name]: value });
  };

  const handlePOSClassIDChange = ({ target: { value } }) => {
    const newValue = parseInt(value, 10);

    if (
      Number.isNaN(newValue) ||
      !validator.isInt(value.toString(), {
        min: 1,
        max: 999,
      })
    ) {
      setFormDataErrors({
        ...formDataErrors,
        POSClassId: ERROR_MESSAGES.other.POSClassIdNumberRange,
      });
    } else {
      setFormData({ ...formData, POSClassId: newValue });
    }
  };

  const handleSelectionLimitChange = ({ target: { value, name } }) => {
    const fieldAsString = value.toString();

    if (
      fieldAsString.includes(".") ||
      (!validator.isNumeric(fieldAsString) && fieldAsString !== "")
    ) {
      setFormDataErrors({
        ...formDataErrors,
        [name]: ERROR_MESSAGES.other.selectionLimit,
      });
    } else {
      setFormData({
        ...formData,
        [name]: value !== "" ? parseInt(value, 10) : "",
      });
    }
  };

  const handleDeleteProductClick = element => {
    setModifierProducts(
      modifierProducts.filter(item => item.id !== element.id),
    );
  };

  const handleCancel = () => {
    if (modifierGroup !== null) {
      applySnapshot(modifierGroup, snapshot);
    }

    appStore.setLoading();
    navigate("/app/modifier-groups");
  };

  const validateFormData = data => {
    const validationErrors = {};

    if (validator.isEmpty(data.name.toString())) {
      validationErrors.name = ERROR_MESSAGES.required.name;
    }

    if (validator.isEmpty(data.POSClassId.toString())) {
      validationErrors.POSClassId = ERROR_MESSAGES.other.POSClassId;
    }

    return validationErrors;
  };

  const handleSubmit = event => {
    event.preventDefault();

    const validationErrors = validateFormData(formData);

    const { name, POSClassId, selectionLimit } = formData;

    if (!Object.keys(validationErrors).length) {
      appStore.setLoading();

      if (modifierGroup === null) {
        appStore.modifierGroupStore
          .add({
            ...formData,
            selectionLimit: selectionLimit === "" ? null : selectionLimit,
          })
          .then(result => {
            navigate(`/app/modifier-groups/${result.id}`);
            setModifierGroup(result);
            setFormDataErrors({});
          })
          .catch(error => {
            appStore.log.error(error);
            setQueryError(error.message.replace("GraphQL error: ", ""));
            appStore.setLoading(false);
          });
      } else {
        modifierGroup
          .setName(name)
          .setPOSClassId(POSClassId)
          .setModifierProducts(modifierProducts)
          .setSelectionLimit(selectionLimit === "" ? null : selectionLimit)
          .save()
          .then(() => navigate("/app/modifier-groups"))
          .catch(error => {
            applySnapshot(modifierGroup, snapshot);
            appStore.log.error(error);
            setQueryError(error.message.replace("GraphQL error: ", ""));
            appStore.setLoading(false);
          });
      }
    } else {
      setFormDataErrors(validationErrors);
    }
  };

  if (appStore.isLoading) {
    return null;
  }

  const { name, POSClassId, selectionLimit } = formData;

  return (
    <ErrorBoundary>
      <Page title={`${modifierGroup !== null ? "Edit" : "Add"} modifier group`}>
        <Container maxWidth={false}>
          <form autoComplete="off" noValidate>
            <Card>
              <CardContent>
                <Grid container spacing={3}>
                  {queryError && (
                    <Grid item xs={12}>
                      <FormHelperText error>{queryError}</FormHelperText>
                    </Grid>
                  )}
                  <Grid item lg={3} md={6} xs={12}>
                    <TextField
                      name="name"
                      inputProps={{ "data-testid": "name" }}
                      required
                      variant="standard"
                      fullWidth
                      label="Name"
                      value={name}
                      error={!!formDataErrors.name}
                      helperText={formDataErrors.name}
                      onChange={handleInputChange}
                    />
                  </Grid>
                  <Grid item lg={3} md={6} xs={12}>
                    <TextField
                      inputProps={{ "data-testid": "POSClassId" }}
                      required
                      variant="standard"
                      fullWidth
                      label="POS CLASS"
                      value={POSClassId}
                      error={!!formDataErrors.POSClassId}
                      helperText={formDataErrors.POSClassId}
                      onChange={handlePOSClassIDChange}
                    />
                  </Grid>
                  <Grid item lg={3} md={6} xs={12}>
                    <TextField
                      inputProps={{ "data-testid": "selectionLimit" }}
                      variant="standard"
                      fullWidth
                      label="Selection Limit"
                      name="selectionLimit"
                      value={selectionLimit}
                      error={!!formDataErrors.selectionLimit}
                      helperText={formDataErrors.selectionLimit}
                      onChange={handleSelectionLimitChange}
                    />
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
            {modifierGroup !== null && (
              <Box mt={3}>
                <Card>
                  <CardHeader title="Products in group" />
                  <CardContent>
                    <Grid container>
                      {modifierProductsError && (
                        <Grid item xs={12}>
                          <FormHelperText error>
                            {modifierProductsError}
                          </FormHelperText>
                        </Grid>
                      )}
                      <Grid item xs={12}>
                        <Autocomplete fullWidth onSelect={onAddProduct} />
                      </Grid>
                      <Grid item xs={12}>
                        <Table size="small">
                          <TableBody>
                            {modifierProducts.map(item => (
                              <TableRow key={item.id}>
                                <TableCell>{item.name}</TableCell>
                                <TableCell>{item.POSId}</TableCell>
                                <TableCell>
                                  <IconDelete
                                    disableRipple
                                    onClick={() =>
                                      handleDeleteProductClick(item)
                                    }
                                  />
                                </TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Box>
            )}
            <Box my={2} display="flex" justifyContent="flex-end">
              <Box pr={1} display="inline">
                <NegativeAction buttonText="Cancel" onClick={handleCancel} />
              </Box>
              <PositiveAction
                buttonText="Save"
                testId="submit-button"
                onClick={handleSubmit}
              />
            </Box>
          </form>
        </Container>
      </Page>
    </ErrorBoundary>
  );
};

AddEditComponent.propTypes = {
  appStore: MobXPropTypes.objectOrObservableObject.isRequired,
};

export default inject("appStore")(observer(AddEditComponent));
