import { types, getEnv, getParent, flow } from "mobx-state-tree";
import { values } from "mobx";

import gql from "graphql-tag";

import Site, { fragments as siteFragments } from "./models/Site";
import { removeTypename, transformer } from "../helpers";

// @todo treat versioning changes on preProcess
export default types
  .model("SiteStore", {
    items: types.optional(types.map(Site), {}),
  })
  .views(self => ({
    get appStore() {
      return getParent(self);
    },
    get basicSiteInformation() {
      return values(self.items).map(site => ({
        id: site.id,
        name: site.name,
        active: site.active,
      }));
    },
  }))
  .actions(self => {
    const { apolloClient } = getEnv(self);
    let findAllLoaded = false;

    return {
      find: flow(function* find(id) {
        if (self.items.has(id)) {
          return self.items.get(id);
        }

        try {
          const response = yield apolloClient.query({
            query: gql`
            {
              site(id: "${id}") {
                ...SiteFullDetails
              }
            }
            ${siteFragments.fullDetails}
          `,
          });

          const item = self.track(response.data.site);

          return Promise.resolve(item);
        } catch (error) {
          return Promise.reject(error);
        }
      }),
      findAll: flow(function* findAll(limit = 0, offset = 0, filter = {}) {
        if (findAllLoaded) {
          return Promise.resolve(self.items);
        }

        try {
          const response = yield apolloClient.query({
            query: gql`
              {
                sites (
                  limit: ${limit},
                  offset: ${offset},
                  filter: {${transformer(filter)}}
                ) {
                  ...SiteFullDetails
                }
              }
              ${siteFragments.fullDetails}
            `,
          });

          response.data.sites.forEach(site => self.track(site));
          findAllLoaded = true;

          return Promise.resolve(response.data.sites);
        } catch (error) {
          return Promise.reject(error);
        }
      }),
      add: flow(function* add(entity) {
        try {
          const input = transformer({
            name: entity.name,
            POSHouseNumber: parseInt(entity.POSHouseNumber, 10),
            justEatDiscountId: entity.justEatDiscountId,
            justEatSiteId: entity.justEatSiteId,
            hlsSftpUsername: entity.hlsSftpUsername,
            hlsSftpPassword: entity.hlsSftpPassword,
            hlsApiUsername: entity.hlsApiUsername,
            hlsApiPassword: entity.hlsApiPassword,
            hlsSiteSlug: entity.hlsSiteSlug,
            resDiaryRestaurantId: entity.resDiaryRestaurantId,
            resDiaryRestaurantSecret: entity.resDiaryRestaurantSecret,
            uberEatsStores: entity.uberEatsStores,
            collinsVenueId: entity.collinsVenueId,
            minimumOrderSurchargePlu: entity.minimumOrderSurchargePlu,
            deliveryFeePlu: entity.deliveryFeePlu,
            deliverooDiscountId: entity.deliverooDiscountId,
            deliverooStoreId: entity.deliveroStoreId,
            vitaMojoDiscountId: entity.vitaMojoDiscountId,
            preorderSendTime: entity.preorderSendTime,
            fluxStoreName: entity.fluxStoreName,
            POSServiceChargeId: entity.POSServiceChargeId,
            POSVersion: entity.POSVersion,
            loyaltyUrl: entity.loyaltyUrl,
            loyaltyUsername: entity.loyaltyUsername,
            loyaltyPassword: entity.loyaltyPassword,
            networkId: entity.networkId,
            active: entity.active,
          });

          const response = yield apolloClient.mutate({
            mutation: gql`
            mutation addSite {
              addSite(input: {
                ${input}
              }) {
                ...SiteFullDetails
              }
            }
            ${siteFragments.fullDetails}
          `,
          });

          return Promise.resolve(self.track(response.data.addSite));
        } catch (error) {
          return Promise.reject(error);
        }
      }),
      remove: flow(function* remove(id) {
        try {
          const response = yield apolloClient.mutate({
            mutation: gql`
            mutation deleteSite {
              deleteSite(id: "${id}")
            }
          `,
          });

          if (response.data.deleteSite === true) {
            self.items.delete(id);
          }
          return Promise.resolve(true);
        } catch (error) {
          return Promise.reject(error);
        }
      }),
      track(entity) {
        if (!self.items.has(entity.id)) {
          const input = removeTypename(entity);

          input.createdBy = self.appStore.userStore.track(input.createdBy);

          if (input.updatedBy !== null) {
            input.updatedBy = self.appStore.userStore.track(input.updatedBy);
          }
          return self.items.put(input);
        }

        return self.items.get(entity.id);
      },
    };
  });
