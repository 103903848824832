import React from "react";
import PropTypes from "prop-types";

import { GridEditSingleSelectCell } from "@mui/x-data-grid";

const MajorGroupEditSelect = ({ api = {}, props = {} }) => {
  const handleValueChange = async () => {
    // We clear the priorityPrintGroup when the Major Group changes,
    // as each priorityPrintGroup is tied to only one major group.
    await api?.current?.setEditCellValue({
      id: props.id,
      field: "priorityPrintGroup",
      value: "",
    });
  };

  return (
    <GridEditSingleSelectCell onValueChange={handleValueChange} {...props} />
  );
};

MajorGroupEditSelect.propTypes = {
  api: PropTypes.shape({
    current: PropTypes.shape({
      setEditCellValue: PropTypes.func,
    }),
  }),
  props: PropTypes.object,
  id: PropTypes.string,
};

export default MajorGroupEditSelect;
