import React from "react";
import PropTypes from "prop-types";

import Box from "@mui/material/Box";
import Chip from "@mui/material/Chip";

import Autocomplete from "../../../../components/Autocomplete";

const ProductChooser = ({
  errorMessage = "",
  handleAdd,
  handleRemove,
  includeGroups = false,
  prefix,
  required = false,
  store,
}) => {
  return (
    <Box>
      <Autocomplete
        errorMessage={errorMessage}
        fullWidth
        includeGroups={includeGroups}
        onSelect={handleAdd}
        required={required}
        useFrozenProducts
      />
      <Box mt={3}>
        {store &&
          Object.entries(store).map(([id, item]) => (
            <Chip
              data-id={id}
              key={`${prefix}-${id}`}
              label={item.name}
              onDelete={() => handleRemove(id)}
            />
          ))}
      </Box>
    </Box>
  );
};

ProductChooser.propTypes = {
  errorMessage: PropTypes.string,
  handleAdd: PropTypes.func.isRequired,
  handleRemove: PropTypes.func.isRequired,
  includeGroups: PropTypes.bool,
  prefix: PropTypes.string.isRequired,
  required: PropTypes.bool,
  store: PropTypes.shape({}).isRequired,
};

export default ProductChooser;
