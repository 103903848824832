import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { observer, inject, PropTypes as MobXPropTypes } from "mobx-react";
import { observable, action, toJS, reaction, makeObservable } from "mobx";
import { Link } from "react-router-dom";

import clsx from "clsx";
import { withStyles } from "@mui/styles";
import red from "@mui/material/colors/red";
import IconButton from "@mui/material/IconButton";
import Chip from "@mui/material/Chip";
import FormHelperText from "@mui/material/FormHelperText";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@mui/material/TextField";
import CancelIcon from "@mui/icons-material/Cancel";
import EditIcon from "@mui/icons-material/Edit";
import LockIcon from "@mui/icons-material/LockRounded";
import UnlockIcon from "@mui/icons-material/LockOpenRounded";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";

import MUIDataTable from "mui-datatables";

import ErrorBoundary from "../../components/ErrorBoundary";
import Page from "../../components/Page";
import MuiDatatablesFilters from "../../components/Filter/MuiDatatablesFilters";
import PositiveAction from "../../components/Button/PositiveAction";
import NegativeAction from "../../components/Button/NegativeAction";
import AddAction from "../../components/Button/AddAction";

const styles = theme => ({
  margin: {
    margin: theme.spacing(1),
  },
  button: {
    margin: 0,
    marginLeft: theme.spacing(1),
  },
  danger: {
    color: red[500],
    "&:hover": {
      backgroundColor: red[50],
    },
  },
});

class ListComponent extends React.Component {
  items = [];
  selected = null;
  statusSelected = null;
  error = "";

  lockedReason = "";
  errorLockedReason = "";

  handleLockReasonChange = event => {
    this.lockedReason = event.target.value;
    this.errorLockedReason = "";
    this.error = "";
    this.hasChanged = true;
  };

  listReaction = () => {};

  columns = [
    {
      name: "First name",
      options: {
        filter: false,
        sort: true,
        sortOrder: "asc",
      },
    },
    { name: "Last name", options: { filter: false, sort: true } },
    { name: "Email", options: { filter: false, sort: true } },
    {
      name: "Status",
      options: {
        filter: false,
        sort: true,
        customBodyRender: record => {
          return (
            <>
              <Chip
                label={record.status}
                color={record.status === "LOCKED" ? "secondary" : "primary"}
                variant="outlined"
              />
              {record.lockedReason !== "" ? (
                <>
                  <br />
                  {record.lockedReason}
                </>
              ) : (
                ""
              )}
            </>
          );
        },
      },
    },

    {
      name: "Actions",
      options: {
        filter: false,
        sort: false,
        customBodyRender: record => {
          const { classes } = this.props;
          return (
            <Fragment key={record.id}>
              <IconButton
                className={classes.button}
                onClick={() => this.handleStatusClick(record)}
                size="medium"
              >
                {record.status === "LOCKED" ? <UnlockIcon /> : <LockIcon />}
              </IconButton>
              <Link to={`/app/users/${record.id}`}>
                <IconButton className={classes.button} size="medium">
                  <EditIcon />
                </IconButton>
              </Link>
              <IconButton
                className={clsx(classes.button, classes.danger)}
                onClick={() => this.handleDeleteClick(record)}
                size="medium"
              >
                <CancelIcon />
              </IconButton>
            </Fragment>
          );
        },
      },
    },
  ];

  options = {
    filterType: "dropdown",
    rowsPerPage: 100,
    selectableRows: "none",
    search: false,
  };

  constructor(props) {
    super(props);

    makeObservable(this, {
      items: observable,
      selected: observable.ref,
      statusSelected: observable.ref,
      handleStatusClick: action,
      handleStatusYes: action,
      handleDeleteClick: action,
      handleModalNo: action,
      handleDeleteYes: action,
      setItems: action,
      lockedReason: observable,
      errorLockedReason: observable,
      handleLockReasonChange: action,
    });
  }

  componentDidMount() {
    const { appStore: app } = this.props;

    app.userStore
      .findAll()
      .then(results => {
        this.listReaction = reaction(
          () => [...results.values()].length,
          () => this.setItems([...results.values()]),
          { fireImmediately: true },
        );

        app.setLoading(false);
      })
      .catch(error => {
        app.log.error(error);
        this.setFormError(error.message);
        app.setLoading(false);
      });
  }

  componentWillUnmount() {
    this.listReaction();
  }

  setFormError = value => {
    this.error = value;
  };

  setItems = items => {
    this.items = items.map(item => [
      item.firstName,
      item.lastName,
      item.email,
      toJS(item),
      toJS(item),
    ]);
  };

  handleDeleteClick = item => {
    this.selected = toJS(item);
  };

  handleDeleteYes = () => {
    const { appStore: app } = this.props;
    const that = this;

    app.setLoading();

    app.userStore
      .delete(this.selected.id)
      .then(() => {
        that.handleDeleteClick(null);
        app.setLoading(false);
      })
      .catch(error => {
        app.log.error(error);
        this.setFormError(error.message.replace("GraphQL error: ", ""));
        app.setLoading(false);
      });
  };

  handleStatusClick = item => {
    this.statusSelected = toJS(item);
  };

  handleStatusYes = () => {
    const { appStore: app } = this.props;

    app.setLoading();

    app.userStore
      .lockUser(this.statusSelected.id, this.lockedReason)
      .then(() => {
        // TODO: rework component and use useNavigate, this never has worked.
        // app.routerStore.go("/app/users");
      })
      .catch(error => {
        app.log.error(error);
        // @todo find a nicer way to handle errors
        this.setFormError(error.message.replace("GraphQL error: ", ""));
        app.setLoading(false);
      });
  };

  handleModalNo = () => {
    this.error = "";
    this.selected = null;
    this.statusSelected = null;
  };

  render() {
    return (
      <ErrorBoundary>
        <Page title="Users">
          <Container maxWidth={false}>
            <Grid container rowSpacing={3}>
              <Grid container item xs={12} justifyContent="end" mt={3}>
                <Link to="/app/users/add">
                  <AddAction buttonText="Add user" />
                </Link>
              </Grid>
              <Grid item xs={12}>
                <section>
                  {this.selected && (
                    <Dialog open onClose={this.handleClose}>
                      <DialogTitle>Delete user</DialogTitle>
                      <DialogContent>
                        {!!this.error && (
                          <>
                            <FormHelperText error>{this.error}</FormHelperText>
                            <br />
                          </>
                        )}
                        <DialogContentText>
                          Are you sure you want to delete this user?
                          <br />
                          <b>
                            {this.selected.firstName} {this.selected.lastName}
                          </b>
                        </DialogContentText>
                      </DialogContent>
                      <DialogActions>
                        <NegativeAction
                          buttonText="No"
                          onClick={this.handleModalNo}
                        />
                        <PositiveAction
                          buttonText="Yes"
                          onClick={this.handleDeleteYes}
                        />
                      </DialogActions>
                    </Dialog>
                  )}
                  {this.statusSelected && (
                    <Dialog open onClose={this.handleClose}>
                      <DialogTitle>
                        {this.statusSelected.status === "LOCKED"
                          ? "Unlock "
                          : "Lock "}
                        user
                      </DialogTitle>
                      <DialogContent>
                        {!!this.error && (
                          <>
                            <FormHelperText error>{this.error}</FormHelperText>
                            <br />
                          </>
                        )}
                        <DialogContentText>
                          Are you sure you want to{" "}
                          {this.statusSelected.status === "LOCKED" ? (
                            <b>unlock</b>
                          ) : (
                            <b>lock</b>
                          )}{" "}
                          this user?
                          <br />
                          <b>
                            {this.statusSelected.firstName}{" "}
                            {this.statusSelected.lastName}
                          </b>
                          {this.statusSelected.status !== "LOCKED" && (
                            <TextField
                              variant="standard"
                              fullWidth
                              autoComplete="off"
                              label="Locking reason "
                              value={this.lockedReason}
                              error={!!this.errorLockedReason}
                              helperText={this.errorLockedReason}
                              onChange={this.handleLockReasonChange}
                            />
                          )}
                        </DialogContentText>
                      </DialogContent>
                      <DialogActions>
                        <NegativeAction
                          buttonText="No"
                          onClick={this.handleModalNo}
                        />
                        <PositiveAction
                          buttonText="Yes"
                          onClick={this.handleStatusYes}
                        />
                      </DialogActions>
                    </Dialog>
                  )}
                  {this.items.length > 0 && (
                    <MUIDataTable
                      data={toJS(this.items)}
                      columns={this.columns}
                      options={this.options}
                      components={{
                        TableFilterList: MuiDatatablesFilters,
                      }}
                    />
                  )}
                </section>
              </Grid>
            </Grid>
          </Container>
        </Page>
      </ErrorBoundary>
    );
  }
}
ListComponent.propTypes = {
  appStore: MobXPropTypes.objectOrObservableObject.isRequired,
  classes: PropTypes.shape({
    button: PropTypes.string,
    danger: PropTypes.string,
  }).isRequired,
};

const List = withStyles(styles)(inject("appStore")(observer(ListComponent)));

export default List;
