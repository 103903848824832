export const checkForErrors = ({
  dispatchError,
  legacyErrors,
  validator,
  data: {
    action,
    name,
    startAt,
    showEndDate,
    endAt,
    percentDiscount,
    rulesState,
    fixedDiscount,
    fixedPrice,
    freeProducts,
  },
}) => {
  let hasErrors = Object.keys(legacyErrors).length > 0;

  if (!action || !action.id) {
    hasErrors = true;
    dispatchError({
      type: "set",
      key: "action",
    });
  }

  if (validator.isEmpty(name.toString())) {
    dispatchError({ type: "set", key: "name" });
    hasErrors = true;
  }

  if (startAt === null) {
    dispatchError({ type: "set", key: "startDate" });
    hasErrors = true;
  }

  if (showEndDate && endAt === null) {
    dispatchError({ type: "set", key: "endDate" });
    hasErrors = true;
  }

  if (startAt && endAt && endAt.isBefore(startAt)) {
    dispatchError({ type: "set", key: "invalidStartEndDate" });
    hasErrors = true;
  }

  if (action.id === "percent_discount" && !percentDiscount) {
    dispatchError({ type: "set", key: "percentDiscount" });
    hasErrors = true;
  }

  if (action.id === "qualifying_discount_bucket") {
    if (!percentDiscount) {
      dispatchError({ type: "set", key: "percentDiscount" });
      hasErrors = true;
    }
    if (
      rulesState.buckets.discountable &&
      Object.values(rulesState.buckets.discountable.items).length < 1
    ) {
      dispatchError({ type: "set", key: "discountable" });
      hasErrors = true;
    }
  }

  if (action.id === "fixed_discount") {
    if (
      (!rulesState.minimumOrderAmount && rulesState.minimumOrderAmount !== 0) ||
      (rulesState.minimumOrderAmount &&
        rulesState.minimumOrderAmount.slice(-1) === ".")
    ) {
      dispatchError({ type: "set", key: "minimumOrderAmount" });
      hasErrors = true;
    }

    if (!fixedDiscount || fixedDiscount < 1) {
      dispatchError({ type: "set", key: "fixedDiscount" });
      hasErrors = true;
    }
  }

  if (action.id === "free_products" && Object.keys(freeProducts).length < 1) {
    dispatchError({ type: "set", key: "freeProducts" });
    hasErrors = true;
  }

  if (action.id === "fixed_price" && (!fixedPrice || fixedPrice < 1)) {
    dispatchError({ type: "set", key: "fixedPrice" });
    hasErrors = true;
  }

  return hasErrors;
};

export const checkForItemsInStagingBucket = stagingItems => {
  return !!Object.values(stagingItems).length;
};

export const checkForNoSites = sites => {
  return !Object.keys(sites).length;
};

export const checkForNoDays = days => {
  return Object.values(days).filter(day => day === false).length === 7;
};

export const checkForInactiveSite = (allSites, siteRules) => {
  return !!allSites.find(
    ({ active, id }) => siteRules[id] === true && active === false,
  );
};

export const checkEmptyNonDefaultBucket = (promotionType, buckets) => {
  // Multibuy handles removing all other buckets so dont fail validation.

  if (promotionType === "multibuy") {
    return false;
  }
  const bucketsToIgnore = ["default", "exclusions", "staging", "discountable"];

  return !!Object.keys(buckets).find(
    bucketName =>
      !bucketsToIgnore.includes(bucketName) &&
      !Object.keys(buckets[bucketName].items).length,
  );
};

export const checkForNoEligibilityRules = buckets => {
  const bucketsToIgnore = ["staging", "discountable"];

  return !Object.keys(buckets).find(
    bucketName =>
      !bucketsToIgnore.includes(bucketName) &&
      Object.keys(buckets[bucketName].items).length,
  );
};

export const checkStringForInvalidCharacters = stringToCheck => {
  // eslint-disable-next-line no-control-regex
  return /[\u0000-\u001F\u007F-\u009F]/g.test(stringToCheck);
};
