import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useParams } from "react-router-dom";
import gql from "graphql-tag";
import { useMutation, useQuery } from "@apollo/client";
import { useSnackbar } from "notistack";

import Alert from "@mui/material/Alert";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

import {
  GET_MODIFIER_GROUPS_WITH_COUNT,
  GET_MODIFIER_GROUP_BY_PRODUCT_ID,
  UPDATE_PRODUCT,
} from "../../../helpers/apollo/utils";

import BaseTable from "../../../components/Basetable/Basetable";
import Switch from "../../../components/Switch";
import PositiveAction from "../../../components/Button/PositiveAction";

const ProductModifierManager = ({ disabled, setHasGroups }) => {
  const { productId, siteId } = useParams();
  const { enqueueSnackbar } = useSnackbar();

  const [currentPage, setCurrentPage] = useState(0);
  const [savedModifierGroups, setSavedModifierGroups] = useState([]);
  const [selectedModifierGroups, setSelectedModifierGroups] = useState([]);
  const [showSelected, setShowSelected] = useState(false);

  const { error: queryError, loading: queryLoading } = useQuery(
    gql(GET_MODIFIER_GROUP_BY_PRODUCT_ID()),

    {
      onCompleted: ({ product }) => {
        const modifierGroup = product?.modifierGroup;
        setSavedModifierGroups(modifierGroup ? [product.modifierGroup] : []);
        setHasGroups(!!modifierGroup);
      },
      fetchPolicy: "cache-and-network",
      skip: !productId,
      variables: {
        productId,
        siteId,
      },
    },
  );

  const [
    updateProductModifierGroup,
    { error: mutationError, loading: mutationLoading },
  ] = useMutation(gql(UPDATE_PRODUCT()), {
    onCompleted: () => {
      enqueueSnackbar("Your changes have been saved", {
        SnackbarProps: { "data-testid": "product-modifier-saved-snackbar" },
        variant: "success",
      });
    },
    refetchQueries: [
      gql(GET_MODIFIER_GROUP_BY_PRODUCT_ID()), // DocumentNode object
      "ModifierGroupByProductId", // Query name
    ],
  });

  const columns = [
    { label: "Name", name: "name", options: { filter: false, sort: true } },
    {
      label: "POS Class Id",
      name: "POSClassId",
      options: { filter: false, sort: true },
    },
  ];

  const toggleSelectedFilter = (items, value) => {
    setSelectedModifierGroups(value ? items : []);
    setShowSelected(value);
    setCurrentPage(0);
  };

  const parseSelectedItems = items => {
    return items.map(([id, { name, POSClassId }]) => {
      return {
        id,
        name,
        POSClassId,
      };
    });
  };

  useEffect(() => {
    if (mutationError || queryError) {
      const fallbackErrorMessage = "Please try again later.";

      const errorMsg =
        mutationError?.message || queryError?.message || fallbackErrorMessage;

      enqueueSnackbar(`An unexpected error has occured: ${errorMsg}`, {
        variant: "error",
        SnackbarProps: {
          "data-testid": "product-modifier-error-snackbar",
        },
      });
    }
  }, [mutationError, queryError, enqueueSnackbar]);

  return (
    <Card>
      <CardContent>
        <Grid container spacing={2}>
          <Grid item container alignItems="center" xs={12}>
            <Grid item>
              <Typography>Product of Modifier Group</Typography>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            {!productId || disabled ? (
              <Alert
                severity="info"
                data-testid={
                  disabled
                    ? "product-modifier-manager-disabled-message"
                    : "no-product-id-message"
                }
              >
                {disabled
                  ? "This product cannot be a part of a modifier group when it has modifier groups assigned to it."
                  : "In order to assign this product to a modifier group you must first create the product."}
              </Alert>
            ) : (
              <BaseTable
                columns={columns}
                defaultSort={[{ field: "name", direction: "ASC" }]}
                overrideData={showSelected ? selectedModifierGroups : null}
                getTableData={d => {
                  return d.modifierGroupsWithCount.modifierGroups;
                }}
                getRowCount={d => d.modifierGroupsWithCount.totalRows}
                multiSelectOptions={selectionState => ({
                  customToolbar: () => {
                    let itemsToAdd = false;

                    selectionState.forEach((value, key) => {
                      if (!savedModifierGroups.some(({ id }) => id === key)) {
                        itemsToAdd = true;
                      }
                    });

                    const itemsToRemove = savedModifierGroups.find(
                      ({ id }) => !selectionState.has(id),
                    );

                    return (
                      <Box sx={{ width: "100%" }}>
                        <Box sx={{ mt: "12px" }}>
                          <PositiveAction
                            disabled={
                              !itemsToAdd &&
                              !itemsToRemove &&
                              !mutationLoading &&
                              !queryLoading
                            }
                            buttonText="Save Changes"
                            onClick={() => {
                              setSavedModifierGroups(
                                parseSelectedItems(
                                  Array.from(selectionState.entries()),
                                ),
                              );

                              const groups = Array.from(
                                selectionState.entries(),
                              ).map(([id]) => id);

                              updateProductModifierGroup({
                                variables: {
                                  input: {
                                    id: productId,
                                    ...(siteId && { siteId }),
                                    modifierGroup: groups[0] ?? null,
                                    // For now, this will only send the selected item if available,
                                    // otherwise will send null if no group is selected,
                                    // meaning the API should remove the current modifierGroup.
                                  },
                                },
                              });
                            }}
                            testId="save-product-modifier-btn"
                          />
                        </Box>
                      </Box>
                    );
                  },
                  customToolbarSelect: () => {
                    const items = parseSelectedItems(
                      Array.from(selectionState.entries()),
                    );

                    return (
                      <Grid
                        container
                        item
                        justifyContent="flex-start"
                        xl={10}
                        lg={9}
                        xs={6}
                        sx={{ mr: 8 }}
                      >
                        <Switch
                          active={showSelected}
                          disabled={!items.length}
                          label="Show selected only"
                          onChange={() =>
                            toggleSelectedFilter(items, !showSelected)
                          }
                          testId="show-selected-switch"
                        />
                      </Grid>
                    );
                  },
                  onRowSelectionChange: (cur, allRowsSelected) => {
                    if (!allRowsSelected.length) {
                      toggleSelectedFilter(null, false);
                    }
                  },
                  // Remove the function below to allow multiple rows to be selected
                  isRowSelectable: (dataIndex, selectedRows) => {
                    if (selectedRows.data.length) {
                      if (dataIndex !== selectedRows.data[0].dataIndex) {
                        return false;
                      }
                      return true;
                    }
                    return true;
                  },
                  selectableRowsHeader: false, // Remove this when multiple rows are aloud to be selected
                  selectableRows: "multiple",
                  selectToolbarPlacement: "above",
                })}
                onPageChange={curPage => setCurrentPage(curPage)}
                page={currentPage}
                searchBy="name_contains"
                query={GET_MODIFIER_GROUPS_WITH_COUNT()}
                defaultSelectedItems={savedModifierGroups}
              />
            )}
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

ProductModifierManager.propTypes = {
  disabled: PropTypes.bool.isRequired,
  setHasGroups: PropTypes.func.isRequired,
};

export default ProductModifierManager;
