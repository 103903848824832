import React, { useState, useEffect } from "react";
import gql from "graphql-tag";
import { useQuery, useMutation } from "@apollo/client";
import { styled } from "@mui/material/styles";
import { observer, inject, PropTypes as MobXPropTypes } from "mobx-react";
import { useSnackbar } from "notistack";
import { v4 } from "uuid";

import { DataGrid, useGridApiRef } from "@mui/x-data-grid";

import CustomGridRow from "../../../components/Datagrid/CustomGridRow";
import CustomToolbar from "../../../components/Datagrid/CustomToolbar";

import {
  ADD_TRANSACTION_REASON,
  GET_TRANSACTION_REASONS,
  UPDATE_TRANSACTION_REASON,
} from "../../../helpers/apollo/utils";

const StyledBox = styled("div")(({ theme }) => ({
  height: "70vh",
  width: "100%",
  "& .Mui-error": {
    backgroundColor: `rgb(126,10,15, ${theme.palette.mode === "dark" ? 0 : 0.1})`,
    color: theme.palette.error.main,
  },
}));

const columns = [
  {
    field: "description",
    headerName: "Description",
    type: "text",
    editable: true,
    flex: 1,
    preProcessEditCellProps: params => {
      const { props } = params;
      const { value } = props;

      return {
        ...props,
        error: !value,
      };
    },
  },
];

export const getNewRow = () => ({
  id: v4(),
  description: "",
  // We use this when updating a row to tell us this transaction reason needs to be created
  createdRow: true,
});

const VoidRefundReasonsBulkEditor = ({ appStore }) => {
  const { enqueueSnackbar } = useSnackbar();

  const [createdRow, setCreatedRow] = useState(false);
  const [tableData, setTableData] = useState([]);

  const errorSnackbar = errorMsg => {
    enqueueSnackbar(`Error: ${errorMsg}`, {
      variant: "error",
      SnackbarProps: {
        "data-testid": "bulk-transaction-reasons-error-snackbar",
      },
    });
  };

  const { loading } = useQuery(gql(GET_TRANSACTION_REASONS()), {
    fetchPolicy: "cache-and-network",
    onCompleted: data => {
      if (data?.transactionReasonsWithCount?.transactionReasons) {
        setTableData(data.transactionReasonsWithCount.transactionReasons);
      }

      appStore.setLoading(false);
    },
    onError: error => {
      appStore.setLoading(false);
      errorSnackbar(error.message);
    },
  });

  const [updateTransactionReason] = useMutation(
    gql(UPDATE_TRANSACTION_REASON()),
    {
      onCompleted: () => {
        enqueueSnackbar("Your changes have been saved", {
          SnackbarProps: {
            "data-testid": "bulk-transaction-reasons-saved-snackbar",
          },
          variant: "success",
        });
      },
      onError: error => errorSnackbar(error.message),
      refetchQueries: [gql(GET_TRANSACTION_REASONS()), "TransactionReasons"],
    },
  );

  const [addTransactionReason, { error: addTillError }] = useMutation(
    gql(ADD_TRANSACTION_REASON()),
    {
      onCompleted: () => {
        enqueueSnackbar("Your new void/refund reason has been created", {
          SnackbarProps: {
            "data-testid": "bulk-transaction-reasons-added-snackbar",
          },
          variant: "success",
        });
      },
      onError: error => errorSnackbar(error.message),
      refetchQueries: [gql(GET_TRANSACTION_REASONS()), "TransactionReasons"],
    },
  );

  const addRow = () => {
    const newRow = getNewRow();

    const newTableData = [...tableData];
    newTableData.unshift(newRow);

    setTableData(newTableData);
    setCreatedRow(true);
  };

  const deleteRow = () => {
    const newTableData = tableData.filter(row => row.createdRow === undefined);

    setTableData(newTableData);
    setCreatedRow(false);
  };

  const updateRow = (newRow, oldRow) => {
    // Only mutate if there are changes made
    if (newRow.description !== oldRow.description) {
      if (newRow.createdRow !== undefined) {
        addTransactionReason({
          variables: {
            description: newRow.description,
          },
        });

        setCreatedRow(false);
      } else {
        updateTransactionReason({
          variables: {
            input: {
              id: newRow.id,
              description: newRow.description,
            },
          },
        });
      }
    }

    return newRow;
  };

  return (
    <StyledBox>
      <DataGrid
        columns={columns}
        editMode="row"
        initialState={{
          sorting: {
            sortModel: [{ field: "description", sort: "asc" }],
          },
        }}
        loading={loading}
        rows={tableData}
        slots={{
          row: CustomGridRow,
          toolbar: () => (
            <CustomToolbar
              addAction={addRow}
              deleteAction={deleteRow}
              isRowCreated={createdRow}
            />
          ),
        }}
        processRowUpdate={updateRow}
        onProcessRowUpdateError={errorSnackbar}
      />
    </StyledBox>
  );
};

VoidRefundReasonsBulkEditor.propTypes = {
  appStore: MobXPropTypes.objectOrObservableObject.isRequired,
};

export default inject("appStore")(observer(VoidRefundReasonsBulkEditor));
