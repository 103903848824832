import React, { useState, useEffect } from "react";
import { observer, inject, PropTypes as MobXPropTypes } from "mobx-react";
import { useNavigate } from "react-router-dom";

import { makeStyles } from "@mui/styles";

import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Container from "@mui/material/Container";
import FormHelperText from "@mui/material/FormHelperText";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";

import validator from "validator";

import ErrorBoundary from "../../components/ErrorBoundary";
import Page from "../../components/Page";
import NegativeAction from "../../components/Button/NegativeAction";
import PositiveAction from "../../components/Button/PositiveAction";

const useStyles = makeStyles(theme => ({
  form: {
    padding: 12,
  },
  button: {
    marginRight: theme.spacing(1),
  },
}));

const AddSubscriber = ({ appStore }) => {
  const navigate = useNavigate();
  const classes = useStyles();
  const [formError, setFormError] = useState("");

  const [existingSubscribers, setExistingSubscribers] = useState([]);

  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");

  const handleEmailChange = event => {
    setEmail(event.target.value);
    setEmailError("");
    setFormError("");
  };

  useEffect(() => {
    appStore.recipientStore.subscriberStore
      .findAll()
      .then(results => {
        if (results) {
          setExistingSubscribers(
            [...results.values()].map(subscriber => subscriber.email),
          );
        }
        appStore.setLoading(false);
      })
      .catch(error => {
        appStore.log.error(error);
        navigate("/app/404");
      });
  }, []);

  const handleCancel = event => {
    event.preventDefault();
    appStore.setLoading();
    navigate("/app/reports/manage-subscribers");
  };

  const emailIsValid = (setErrors = true) => {
    let hasError = false;
    if (validator.isEmpty(email.toString())) {
      hasError = true;
      if (setErrors) {
        setEmailError("Please insert an email address");
      }
    }
    if (!validator.isEmail(email.toString())) {
      hasError = true;
      if (setErrors) {
        setEmailError("The email wasn't valid, please check and try again");
      }
    }
    if (existingSubscribers.includes(email.toString())) {
      hasError = true;
      if (setErrors) {
        setEmailError("That email is already linked to a subscriber");
      }
    }
    return hasError;
  };

  const handleSubmit = event => {
    event.preventDefault();
    const hasError = emailIsValid();

    if (!hasError) {
      appStore.setLoading();
      appStore.recipientStore.subscriberStore
        .add({
          email,
        })
        .then(() => {
          navigate(`/app/reports/manage-subscribers`);
        })
        .catch(error => {
          appStore.log.error(error);
          setFormError(error.message.replace("GraphQL error: ", ""));
          appStore.setLoading(false);
        });
    }
  };

  if (appStore.isLoading) {
    return null;
  }

  return (
    <ErrorBoundary>
      <Page title="Add Subscriber">
        <Container maxWidth={false}>
          <form autoComplete="off" noValidate className={classes.form}>
            <Card>
              <CardContent>
                <Grid container spacing={3}>
                  {formError && (
                    <Grid item xs={12}>
                      <FormHelperText error>{formError}</FormHelperText>
                    </Grid>
                  )}

                  <Grid item lg={3} md={6} xs={12}>
                    <TextField
                      inputProps={{ "data-testid": "email-input" }}
                      required
                      variant="standard"
                      fullWidth
                      label="Email"
                      value={email}
                      error={!!emailError}
                      helperText={emailError}
                      onChange={handleEmailChange}
                      placeholder="Email Address"
                    />
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
            <Box py={2}>
              <Box mr={1} display="inline">
                <NegativeAction buttonText="Cancel" onClick={handleCancel} />
              </Box>
              <PositiveAction
                buttonText="Save Subscriber"
                onClick={handleSubmit}
                disabled={emailIsValid(false)}
              />
            </Box>
          </form>
        </Container>
      </Page>
    </ErrorBoundary>
  );
};

AddSubscriber.propTypes = {
  appStore: MobXPropTypes.objectOrObservableObject.isRequired,
};

export default inject("appStore")(observer(AddSubscriber));
