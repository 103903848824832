import React, { useState } from "react";

import ErrorBoundary from "../../components/ErrorBoundary";
import Page from "../../components/Page";

import SplitView from "../../components/SplitView";
import SalesAreasDatatable from "./Components/SalesAreasDatatable.js";
import PrintTextsManager from "./Components/PrintTextsManager.js";

const PrintTextsList = () => {
  const [salesArea, setSalesArea] = useState(null);

  const handleSalesAreaChange = item =>
    // if the item is the default, in the query it should be null
    setSalesArea(item.id === "default" ? null : item.id);

  return (
    <ErrorBoundary>
      <Page title={"Print Texts"} style={{ height: "100%" }}>
        <SplitView
          startingWidthPercentage={35}
          minWidthPercentage={35}
          maxWidthPercentage={35}
          leftContent={
            <SalesAreasDatatable handleRowClick={handleSalesAreaChange} />
          }
          rightContent={<PrintTextsManager salesArea={salesArea} />}
        />
      </Page>
    </ErrorBoundary>
  );
};

export default PrintTextsList;
