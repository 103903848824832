import React, { useState, useEffect } from "react";
import propTypes from "prop-types";

import {
  InputLabel,
  MenuItem,
  Select,
  FormControl,
  Stack,
  Chip,
} from "@mui/material";

import CancelIcon from "@mui/icons-material/Cancel";

const MultiSelectFilter = ({ props, options }) => {
  const { item, applyValue } = props;

  // This function returns an array of return key values in the options object,
  // based on the search key provided.
  const getAssociateOptionValues = (array, searchKey, returnKey) => {
    if (!array) return [];

    return array
      .map(val => options.find(option => option[searchKey] === val))
      .filter(Boolean) // Removes any undefined or null values
      .map(match => match[returnKey]);
  };

  // When clicking the clear filter button, this removes the selected value chips
  useEffect(() => {
    if (item.value === undefined) {
      setSelectedNames(
        getAssociateOptionValues(item.value, "id", "description"),
      );
    }
  }, [item.value]);

  // When the filter is re-rendered, this sets the selectedNames back as per the provided id values
  const [selectedNames, setSelectedNames] = useState(
    getAssociateOptionValues(item.value, "id", "description"),
  );

  return (
    <FormControl variant="standard" fullWidth>
      <InputLabel>Value</InputLabel>
      <Select
        multiple
        value={selectedNames}
        data-testid="custom-datagrid-multi-select-filter"
        onChange={e => {
          setSelectedNames(e.target.value);
          applyValue({
            ...item,
            // Find the descriptions in the options array, return the ids
            value: getAssociateOptionValues(
              e.target.value,
              "description",
              "id",
            ),
          });
        }}
        renderValue={selected => (
          <Stack gap={1} direction="row" flexWrap="wrap">
            {selected.map(value => (
              <Chip
                key={value}
                label={value}
                variant="outlined"
                size="small"
                onDelete={() => {
                  const newValues = selectedNames.filter(
                    item => item !== value,
                  );

                  setSelectedNames(newValues);

                  applyValue({
                    ...item,
                    value: getAssociateOptionValues(
                      newValues,
                      "description",
                      "id",
                    ),
                  });
                }}
                deleteIcon={
                  <CancelIcon
                    onMouseDown={event => event.stopPropagation()}
                    data-testid={`custom-datagrid-multi-select-filter-delete-${value}`}
                  />
                }
              />
            ))}
          </Stack>
        )}
      >
        {options.map(({ id, description }) => (
          <MenuItem key={id} value={description}>
            {description}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

MultiSelectFilter.propTypes = {
  props: propTypes.object.isRequired,
  item: propTypes.object,
  applyValue: propTypes.func,
  options: propTypes.array.isRequired,
};

export default MultiSelectFilter;
