import React, { useState } from "react";
import propTypes from "prop-types";

import moment from "moment";

import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";

const CustomTimePicker = ({ params }) => {
  const [value, setValue] = useState(moment.utc(params.value * 1000));

  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <TimePicker
        value={value}
        onChange={newValue => {
          const { id, api, field } = params;

          // This gets the hours and minutes value as seconds
          const formattedValue = moment(
            newValue.format("hh:mm A"),
            "hh:mm A",
          ).diff(moment().startOf("day"), "seconds");

          setValue(newValue);

          api.setEditCellValue({
            id,
            field,
            value: formattedValue,
          });
        }}
        slotProps={{
          textField: { "data-testid": "custom-datagrid-time-picker" },
        }}
      />
    </LocalizationProvider>
  );
};

CustomTimePicker.propTypes = {
  params: propTypes.object.isRequired,
};

export default CustomTimePicker;
