import React from "react";
import PropTypes from "prop-types";
import { NumericFormat } from "react-number-format";

import { TextField, InputAdornment } from "@mui/material";

function NumberFormatCustom(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumericFormat
      {...other}
      ref={inputRef}
      onValueChange={values => {
        onChange({
          target: {
            value: values.value,
          },
        });
      }}
      thousandSeparator
      decimalScale={2}
      fixedDecimalScale
      allowNegative={false}
    />
  );
}

NumberFormatCustom.propTypes = {
  inputRef: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
};

function MoneyComponent(props) {
  return (
    <TextField
      variant="standard"
      {...props}
      InputProps={{
        inputComponent: NumberFormatCustom,
        // @todo cater for currency
        startAdornment: <InputAdornment position="start">£</InputAdornment>,
      }}
    />
  );
}

export default MoneyComponent;
