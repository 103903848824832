import * as React from "react";
import PropTypes from "prop-types";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";

const defaultOnClick = () => {};

export default function IconButtonComponent({
  color = "primary",
  disabled = false,
  icon,
  onClick = defaultOnClick,
  testId = "icon-button",
  tooltipText = null,
}) {
  const wrapInToolTip = child => {
    if (tooltipText) {
      return (
        <Tooltip title={tooltipText} data-testid={`${testId}-tooltip`}>
          {/* Buttons don't fire events, the span will. This is required for the tooltip */}
          <span>{child}</span>
        </Tooltip>
      );
    }
    return child;
  };

  return wrapInToolTip(
    <IconButton
      aria-label="icon-button"
      color={color}
      disabled={disabled}
      onClick={onClick}
      data-testid={testId}
    >
      {icon}
    </IconButton>,
  );
}

IconButtonComponent.propTypes = {
  color: PropTypes.string,
  disabled: PropTypes.bool,
  icon: PropTypes.element.isRequired,
  onClick: PropTypes.func,
  testId: PropTypes.string,
  tooltipText: PropTypes.string,
};
