import React from "react";
import PropTypes from "prop-types";

import Box from "@mui/material/Box";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";

import AlertDialog from "../../../../components/Dialog/AlertDialog";

const TEST_ID = "promo-add-edit";

const ValidationModal = ({
  alertState,
  alertWarnings,
  handleAlertResponse,
  setAlertState,
  isScheduleChange = false,
}) => {
  const renderItemsInStagingBucket = () =>
    alertWarnings.itemsInStagingBucket && (
      <li>
        <Typography data-testid="items-in-staging-bucket-warning">
          You have{" "}
          <Tooltip
            arrow
            title="Shortlisted items can be found in the Eligibility Rules section."
            placement="top-end"
          >
            <u>shortlisted</u>
          </Tooltip>{" "}
          items. These items will be discarded and will not be part of this
          promotion.
        </Typography>
      </li>
    );

  const renderNoSites = () =>
    alertWarnings.noSites && (
      <li>
        <Typography data-testid="no-sites-warning">
          Without sites selected a promotion <strong>cannot be active</strong>.
        </Typography>
      </li>
    );

  const renderNoDays = () =>
    alertWarnings.noDays && (
      <li>
        <Typography data-testid="no-days-warning">
          Without day and time rules a promotion{" "}
          <strong>cannot be active</strong>.
        </Typography>
      </li>
    );

  const renderInactiveSiteSelected = () =>
    alertWarnings.inactiveSiteSelected && (
      <li>
        <Typography data-testid="inactive-site-warning">
          A site has been made inactive since this promotion was edited.{" "}
          <strong>This site must be removed</strong> from the promotion before
          it can be made active.
        </Typography>
      </li>
    );

  const renderNoEligibilityRules = () =>
    alertWarnings.noEligibilityRules && (
      <li>
        <Typography data-testid="eligibility-rules-warning">
          Your promotion lacks any eligibility rules meaning that it{" "}
          <strong>cannot be active</strong>.
        </Typography>
      </li>
    );

  // This has reformatted to a curry function due to unit test errors failing when passing props.
  const formatAlertResponse = scheduleChange => present => {
    handleAlertResponse(scheduleChange, present);
  };

  return (
    <AlertDialog
      backButtonText="Go back and edit"
      notifyClosure={formatAlertResponse(isScheduleChange)}
      okButtonText={
        isScheduleChange ? "Ignore and schedule" : "Ignore and save"
      }
      present={alertState}
      setPresence={setAlertState}
      testId={TEST_ID}
      title="Are you sure?"
    >
      <Box mx={2}>
        <ul>
          {renderItemsInStagingBucket()}
          {renderNoSites()}
          {renderNoDays()}
          {renderInactiveSiteSelected()}
          {renderNoEligibilityRules()}
        </ul>
      </Box>
    </AlertDialog>
  );
};

ValidationModal.propTypes = {
  alertState: PropTypes.bool.isRequired,
  alertWarnings: PropTypes.shape({
    itemsInStagingBucket: PropTypes.bool.isRequired,
    noSites: PropTypes.bool.isRequired,
    noDays: PropTypes.bool.isRequired,
    inactiveSiteSelected: PropTypes.bool.isRequired,
    noEligibilityRules: PropTypes.bool.isRequired,
  }).isRequired,
  handleAlertResponse: PropTypes.func.isRequired,
  setAlertState: PropTypes.func.isRequired,
  isScheduleChange: PropTypes.bool,
};

export default ValidationModal;
