import React, { useState, useEffect } from "react";

import gql from "graphql-tag";
import { useQuery, useMutation } from "@apollo/client";
import { observer, inject, PropTypes as MobXPropTypes } from "mobx-react";

import { styled } from "@mui/material/styles";
import { useSnackbar } from "notistack";
import { v4 } from "uuid";
import { DataGrid, useGridApiRef } from "@mui/x-data-grid";

import CustomGridRow from "../../../components/Datagrid/CustomGridRow";
import CustomToolbar from "../../../components/Datagrid/CustomToolbar";
import { hasCharacterLengthValidationError } from "../../../components/Datagrid/utils/validation";

import {
  ADD_PDQ_PAYMENT_TYPE,
  GET_PAYMENT_TYPES,
  GET_PDQ_PAYMENT_TYPES,
  UPDATE_PDQ_PAYMENT_TYPE,
} from "../../../helpers/apollo/utils";

const StyledBox = styled("div")(({ theme }) => ({
  height: "70vh",
  width: "100%",
  "& .Mui-error": {
    backgroundColor: `rgb(126,10,15, ${theme.palette.mode === "dark" ? 0 : 0.1})`,
    color: theme.palette.error.main,
  },
}));

const getColumns = paymentTypes => [
  {
    field: "description",
    headerName: "Description",
    type: "text",
    editable: true,
    minWidth: 150,
    preProcessEditCellProps: params => {
      const {
        props,
        props: { value },
      } = params;

      return {
        ...props,
        error: hasCharacterLengthValidationError({ value, minLength: 2 }), // Min length of 2 as per the API
      };
    },
  },
  {
    field: "paymentType",
    headerName: "Payment Type",
    type: "singleSelect",
    valueOptions: paymentTypes,
    editable: true,
    minWidth: 150,
    getOptionLabel: ({ text }) => text,
    getOptionValue: ({ value }) => value,
    preProcessEditCellProps: params => {
      const { props } = params;
      const { value } = props;

      return {
        ...props,
        error: !value, // Required field
      };
    },
  },
];

export const generateNewRow = () => ({
  id: v4(),
  description: "",
  paymentType: "",
  // We use this when updating a row to tell us this department needs to be created
  createdRow: true,
});

const PDQPaymentTypesBulkEditor = ({ appStore }) => {
  const apiRef = useGridApiRef();
  const { enqueueSnackbar } = useSnackbar();

  const [columns, setColumns] = useState([]);
  const [createdRow, setCreatedRow] = useState(false);
  const [tableData, setTableData] = useState([]);

  const errorSnackbar = errorMsg => {
    enqueueSnackbar(`Error: ${errorMsg}`, {
      variant: "error",
      SnackbarProps: {
        "data-testid": "bulk-pdq-payment-types-error-snackbar",
      },
    });
  };

  useEffect(() => {
    // To avoid a setTimeout we have tested subscribing to the 'stateChange' event,
    // however, this causes cell jumping and breaks responsively and therefore
    // have resorted to a timeout, which is also used in the MUI docs for this feature.
    setTimeout(() => {
      apiRef?.current?.autosizeColumns({
        includeHeaders: true,
        includeOutliers: true,
      });
    }, 10);
  }, [tableData]);

  const { loading: loadingPdqPaymentTypes } = useQuery(
    gql(GET_PDQ_PAYMENT_TYPES()),
    {
      fetchPolicy: "cache-and-network",
      onCompleted: data => {
        const pdqPaymentTypes = data?.pdqPaymentTypesWithCount?.pdqPaymentTypes;

        if (pdqPaymentTypes) {
          const formattedData = pdqPaymentTypes.map(item => ({
            ...item,
            paymentType: item.paymentType.id,
          }));

          setTableData(formattedData);
        }
        appStore.setLoading(false);
      },
      onError: error => {
        appStore.setLoading(false);
        errorSnackbar(error.message);
      },
    },
  );

  const { loading: loadingPaymentTypes } = useQuery(gql(GET_PAYMENT_TYPES()), {
    fetchPolicy: "cache-and-network",
    onCompleted: data => {
      if (data.paymentTypes) {
        const paymentTypes = data.paymentTypes;

        const formattedData = paymentTypes.map(item => ({
          value: item.id,
          text: item.name,
        }));

        setColumns(getColumns(formattedData));
      }
      appStore.setLoading(false);
    },
    onError: error => {
      appStore.setLoading(false);
      errorSnackbar(error.message);
    },
  });

  const [updatePDQPaymentType] = useMutation(gql(UPDATE_PDQ_PAYMENT_TYPE()), {
    onCompleted: () => {
      enqueueSnackbar("Your changes have been saved", {
        SnackbarProps: {
          "data-testid": "bulk-pdq-payment-types-saved-snackbar",
        },
        variant: "success",
      });
    },
    refetchQueries: [gql(GET_PDQ_PAYMENT_TYPES()), "PdqPaymentTypesWithCount"],
  });

  const [addPDQPaymentType] = useMutation(gql(ADD_PDQ_PAYMENT_TYPE()), {
    onCompleted: () => {
      enqueueSnackbar("Your new entry has been created", {
        SnackbarProps: {
          "data-testid": "bulk-pdq-payment-types-added-snackbar",
        },
        variant: "success",
      });
    },
    refetchQueries: [gql(GET_PDQ_PAYMENT_TYPES()), "PdqPaymentTypesWithCount"],
  });

  const addRow = () => {
    const newRow = generateNewRow();

    const newTableData = [...tableData];
    newTableData.unshift(newRow);

    setTableData(newTableData);
    setCreatedRow(true);
  };

  const deleteRow = () => {
    const newTableData = tableData.filter(row => row.createdRow === undefined);

    setTableData(newTableData);
    setCreatedRow(false);
  };

  const handleSubmitRow = async (updatedRow, originalRow) => {
    const rowChanges = Object.fromEntries(
      Object.entries(updatedRow).filter(
        ([key, val]) => key in originalRow && originalRow[key] !== val,
      ),
    );

    const submitData = {
      variables: {
        input: {
          ...rowChanges,
        },
      },
    };

    if (Object.keys(rowChanges).length) {
      if (updatedRow.createdRow !== undefined) {
        return addPDQPaymentType(submitData).then(() => {
          setCreatedRow(false);
          return updatedRow;
        });
      } else {
        submitData.variables.input.id = updatedRow.id;
        return updatePDQPaymentType(submitData).then(() => {
          return updatedRow;
        });
      }
    } else {
      return updatedRow;
    }
  };

  return (
    <StyledBox>
      <DataGrid
        apiRef={apiRef}
        columns={columns}
        editMode="row"
        loading={loadingPdqPaymentTypes && loadingPaymentTypes}
        rows={tableData}
        slots={{
          row: CustomGridRow,
          toolbar: () => (
            <CustomToolbar
              addAction={addRow}
              deleteAction={deleteRow}
              isRowCreated={createdRow}
            />
          ),
        }}
        processRowUpdate={handleSubmitRow}
        onProcessRowUpdateError={errorSnackbar}
      />
    </StyledBox>
  );
};

PDQPaymentTypesBulkEditor.propTypes = {
  appStore: MobXPropTypes.objectOrObservableObject.isRequired,
};

export default inject("appStore")(observer(PDQPaymentTypesBulkEditor));
