export const dataMapper = item => ({
  ...item,
  siteName: item.site?.name,
  createdByEmail: item.createdBy?.email,
});

// This function converts the MUI DataGrid filter model to a filter object for the API
export const filterMapper = model => {
  let filter = {};

  model.items.forEach(item => {
    if (item.value !== undefined && item.value !== "") {
      if (item.field === "createdAt" || item.field === "expectedAt") {
        filter = {
          ...filter,
          [`${item.field}_between`]: {
            from: item.value.from,
            to: item.value.to,
          },
        };
      } else if (item.operator === "equals" || item.operator === "is") {
        if (item.field === "itemCode" || item.field === "itemModifierCode") {
          filter = { ...filter, [`${item.field}_in`]: [item.value] };
        } else {
          filter = { ...filter, [`${item.field}_is`]: item.value };
        }
      } else if (item.operator === "contains") {
        filter = { ...filter, [`${item.field}_contains`]: item.value };
      }
    }
  });

  return filter;
};

// This is used in the DataGrid filterFormProps to only allow one filter per column
export const filterColumns = ({ field, columns, currentFilters = {} }) => {
  const filteredFields = currentFilters.map(item => item.field);
  return columns
    .filter(
      colDef =>
        colDef.filterable &&
        (colDef.field === field || !filteredFields.includes(colDef.field)),
    )
    .map(column => column.field);
};

// This is used in the DataGrid filterPanel to only display one filter per column
export const getColumnForNewFilter = ({ currentFilters = {}, columns }) => {
  const filteredFields = currentFilters.map(({ field }) => field);
  const columnForNewFilter = columns
    .filter(
      colDef => colDef.filterable && !filteredFields.includes(colDef.field),
    )
    .find(colDef => colDef.filterOperators?.length);
  return columnForNewFilter?.field ?? null;
};
