import React, { useEffect, useState } from "react";

import { observer, inject, PropTypes as MobXPropTypes } from "mobx-react";

import gql from "graphql-tag";

import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import Box from "@mui/material/Box";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { useMutation, useQuery } from "@apollo/client";

import { useSnackbar } from "notistack";
import CopyToClipboardButton from "../../../../components/Adornments/CopyToClipboard";
import RegenerateButton from "./Components/RegenerateButton";
import {
  GET_SETTINGS,
  REGENERATE_SECRET,
  UPDATE_SETTINGS,
} from "../../../../helpers/apollo/utils";
import PositiveAction from "../../../../components/Button/PositiveAction";

const JustEatSettings = ({ appStore: { settingsStore } }) => {
  useEffect(() => {
    settingsStore.load(false);
  }, [settingsStore]);

  const [formData, setFormData] = useState({
    defaultDiscountId: "",
    xFlytApiKey: "",
  });

  const { enqueueSnackbar } = useSnackbar();

  useQuery(
    gql(GET_SETTINGS(`justEatCredentials {xFlytApiKey, defaultDiscountId}`)),
    {
      onCompleted: data => {
        setFormData({
          defaultDiscountId:
            data.settings.justEatCredentials.defaultDiscountId ?? "",
          xFlytApiKey: data.settings.justEatCredentials.xFlytApiKey ?? "",
        });
      },
    },
  );

  const [updateSettings] = useMutation(
    gql(UPDATE_SETTINGS(`justEatCredentials {xFlytApiKey, defaultDiscountId}`)),
    {
      onCompleted: data => {
        setFormData({
          defaultDiscountId:
            data.updateSettings.justEatCredentials.defaultDiscountId ?? "",
          xFlytApiKey: data.updateSettings.justEatCredentials.xFlytApiKey ?? "",
        });
        enqueueSnackbar("Settings saved", { variant: "success" });
      },
      onError: e => {
        enqueueSnackbar(`Error saving settings: ${e.message}`, {
          variant: "error",
        });
      },
    },
  );

  return (
    <>
      <TableRow>
        <TableCell>
          <Alert severity="warning">
            <AlertTitle>
              <strong>Caution!</strong>
            </AlertTitle>
            <Typography>
              These are settings we provide to Just Eat. If you regenerate them
              you will need to provide them to JustEat for this integration to
              continue working.
            </Typography>
          </Alert>
          <Box sx={{ m: 2 }} />
          <TextField
            name="apiKey"
            label="API Key"
            fullWidth
            value={settingsStore.justEatCredentials.apiKey}
            variant="standard"
            InputLabelProps={{ shrink: true }}
            InputProps={{
              "data-testid": "apiKey-input",
              endAdornment: (
                <Box sx={{ display: "flex" }}>
                  <CopyToClipboardButton
                    value={settingsStore.justEatCredentials.apiKey}
                  />
                  <RegenerateButton
                    apiField="justEatKey"
                    mutationDocument={gql(
                      REGENERATE_SECRET(`
                  justEatCredentials {
                    apiKey
                  }`),
                    )}
                    secretName="Just Eat API Key"
                    updateHandler={data => {
                      settingsStore.setJustEatCredentials({
                        apiKey: data.generateSecret.justEatCredentials.apiKey,
                        hmacKey: settingsStore.justEatCredentials.hmacKey,
                      });
                    }}
                  />
                </Box>
              ),
            }}
          />
          <Box sx={{ m: 2 }} />
          <TextField
            name="hmacKey"
            label="HMAC Key"
            fullWidth
            value={settingsStore.justEatCredentials.hmacKey}
            variant="standard"
            InputLabelProps={{ shrink: true }}
            InputProps={{
              "data-testid": "hmacKey-input",
              endAdornment: (
                <Box sx={{ display: "flex" }}>
                  <CopyToClipboardButton
                    value={settingsStore.justEatCredentials.apiKey}
                  />
                  <RegenerateButton
                    apiField="justEatHmac"
                    mutationDocument={gql(
                      REGENERATE_SECRET(`
                  justEatCredentials {
                    hmacKey
                  }`),
                    )}
                    secretName="Just Eat HMAC Key"
                    updateHandler={data => {
                      settingsStore.setJustEatCredentials({
                        apiKey: settingsStore.justEatCredentials.apiKey,
                        hmacKey: data.generateSecret.justEatCredentials.hmacKey,
                      });
                    }}
                  />
                </Box>
              ),
            }}
          />
          <Box sx={{ mt: 3 }}>
            <TextField
              name="xFlytApiKey"
              label="X-FLYT-API-KEY"
              fullWidth
              value={formData.xFlytApiKey}
              onChange={e => {
                setFormData({ ...formData, xFlytApiKey: e.target.value });
              }}
              variant="standard"
              InputLabelProps={{ shrink: true }}
              InputProps={{
                "data-testid": "xFlytApiKey-input",
                endAdornment: (
                  <Box sx={{ display: "flex" }}>
                    <CopyToClipboardButton
                      value={settingsStore.justEatCredentials.xFlytApiKey}
                    />
                  </Box>
                ),
              }}
            />
          </Box>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell>
          <Typography>Order Integration Settings</Typography>
          <Box sx={{ mt: 3 }}>
            <TextField
              name="defaultDiscountId"
              label="Default Discount ID"
              fullWidth
              value={formData.defaultDiscountId}
              onChange={e => {
                setFormData({ ...formData, defaultDiscountId: e.target.value });
              }}
              variant="standard"
              InputLabelProps={{ shrink: true }}
              InputProps={{
                "data-testid": "default-discount-id-input",
              }}
            />
          </Box>
          <Box sx={{ mt: 3 }}>
            <PositiveAction
              buttonText="Save"
              testId="save-just-eat"
              onClick={() =>
                updateSettings({
                  variables: { input: { justEatCredentials: formData } },
                })
              }
            />
          </Box>
        </TableCell>
      </TableRow>
    </>
  );
};

JustEatSettings.propTypes = {
  appStore: MobXPropTypes.objectOrObservableObject.isRequired,
};

const Component = inject("appStore")(observer(JustEatSettings));

export default Component;
