import React from "react";
import PropTypes from "prop-types";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { makeStyles } from "@mui/styles";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import moment from "moment";

const useStyles = makeStyles(() => ({
  paper: { marginTop: 8, marginBottom: 8 },
}));

const PriorityOrganiser = ({ promotions, update }) => {
  const classes = useStyles();

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };

  const onDragEnd = result => {
    if (!result.destination) {
      return;
    }

    const newItems = reorder(
      promotions,
      result.source.index,
      result.destination.index,
    );

    update(newItems);
  };

  const getItemStyle = (draggableStyle, isDragging) => ({
    background: isDragging ? "lightgray" : "white",
    ...draggableStyle,
  });

  return (
    <Paper className={classes.paper}>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable">
          {({ innerRef: droppableInnerRef, droppableProps, placeholder }) => (
            <Table
              // It says in the docs that this is required for drag drop tables (https://github.com/atlassian/react-beautiful-dnd/blob/master/docs/patterns/tables.md)
              style={{ tableLayout: "auto" }}
              ref={droppableInnerRef}
              {...droppableProps}
            >
              <TableHead>
                <TableRow>
                  <TableCell>Priority</TableCell>
                  <TableCell>Name</TableCell>
                  <TableCell>Start Date</TableCell>
                  <TableCell>End Date</TableCell>
                  <TableCell>Active</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {promotions.map((promotion, index) => (
                  <Draggable
                    key={promotion.id}
                    draggableId={promotion.id}
                    index={index}
                  >
                    {(
                      { draggableProps, dragHandleProps, innerRef },
                      { isDragging },
                    ) => (
                      <TableRow
                        ref={innerRef}
                        {...draggableProps}
                        {...dragHandleProps}
                        style={getItemStyle(draggableProps.style, isDragging)}
                        data-cy={`dragable-row-${index}`}
                      >
                        {/* this is also required for drag drop tables */}
                        <TableCell width="10%">{index}</TableCell>
                        <TableCell width="30%">{promotion.name}</TableCell>
                        <TableCell width="20%">
                          {moment(promotion.startAt).format("lll")}
                        </TableCell>
                        <TableCell width="20%">
                          {promotion.endAt
                            ? moment(promotion.endAt).format("lll")
                            : "None"}
                        </TableCell>
                        <TableCell width="20%">
                          {promotion.active ? "Yes" : "No"}
                        </TableCell>
                      </TableRow>
                    )}
                  </Draggable>
                ))}
                {placeholder}
              </TableBody>
            </Table>
          )}
        </Droppable>
      </DragDropContext>
    </Paper>
  );
};

PriorityOrganiser.propTypes = {
  promotions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      priority: PropTypes.number.isRequired,
    }),
  ).isRequired,
  update: PropTypes.func.isRequired,
};

export default PriorityOrganiser;
