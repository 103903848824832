import React, { useState, useEffect } from "react";
import { observer, inject, PropTypes as MobXPropTypes } from "mobx-react";
import validator from "validator";
import { useNavigate, useParams } from "react-router-dom";

import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import FormHelperText from "@mui/material/FormHelperText";

import { MobileDateTimePicker as DateTimePicker } from "@mui/x-date-pickers";

import ErrorBoundary from "../../components/ErrorBoundary";
import Page from "../../components/Page";
import PositiveAction from "../../components/Button/PositiveAction";
import NegativeAction from "../../components/Button/NegativeAction";

export const ERROR_MESSAGES = {
  required: {
    name: "Please specify a name",
    scheduledAt: "Please specify a schedule date for the change",
  },
  other: { scheduledAtPastDate: "Please select a date/time in the future" },
};

const AddEditComponent = ({ appStore }) => {
  const navigate = useNavigate();
  const { changesetId } = useParams();

  const [formData, setFormData] = useState({
    name: "",
    description: "",
    scheduledAt: null,
  });
  const [changeset, setChangeset] = useState(null);

  const [formDataErrors, setFormDataErrors] = useState({});
  const [queryError, setQueryError] = useState("");

  useEffect(() => {
    if (changesetId !== undefined) {
      appStore.changesetStore
        .find(changesetId)
        .then(result => {
          setChangeset(result);
          appStore.setLoading(false);
        })
        .catch(error => {
          appStore.log.error(error);
          navigate("/app/404");
        });
    } else {
      appStore.setLoading(false);
    }
  }, [changesetId]);

  const handleCancel = () => {
    appStore.setLoading();
    navigate(`/app/changesets`);
  };

  const handleInputChange = ({ target: { value, name } }) => {
    setFormData({ ...formData, [name]: value });
  };

  const handleScheduledAtChange = date => {
    if (!validator.isAfter(date.format())) {
      setFormDataErrors({
        ...formDataErrors,
        scheduledAt: ERROR_MESSAGES.other.scheduledAtPastDate,
      });
    } else {
      setFormData({ ...formData, scheduledAt: date });
    }
  };

  const validateFormData = data => {
    const validationErrors = {};

    if (validator.isEmpty(data.name.toString())) {
      validationErrors.name = ERROR_MESSAGES.required.name;
    }

    if (data.scheduledAt === null) {
      validationErrors.scheduledAt = ERROR_MESSAGES.required.scheduledAt;
    }

    return validationErrors;
  };

  const handleSubmit = () => {
    const validationErrors = validateFormData(formData);

    if (!Object.keys(validationErrors).length) {
      appStore.setLoading();

      if (changesetId !== undefined) {
        const { name, description, scheduledAt } = formData;
        changeset
          .setName(name)
          .setDescription(description)
          .setScheduledAt(scheduledAt)
          .save()
          .then(() => navigate("/app/changesets"))
          .catch(error => {
            appStore.log.error(error);
            setQueryError(error.message);
            appStore.setLoading(false);
          });
      } else {
        appStore.changesetStore
          .add(formData)
          .then(() => navigate(`/app/changesets`))
          .catch(error => {
            appStore.log.error(error);
            setQueryError(error.message);
            appStore.setLoading(false);
          });
      }
    } else {
      setFormDataErrors(validationErrors);
    }
  };

  if (appStore.isLoading) {
    return null;
  }

  const { name, description, scheduledAt } = formData;

  return (
    <ErrorBoundary>
      <Page title={`${changeset !== null ? "Edit" : "Add"} scheduled change`}>
        <Container maxWidth={false}>
          <form autoComplete="off" noValidate>
            <Card>
              <CardContent>
                <Grid container spacing={3}>
                  {queryError && (
                    <Grid item xs={12}>
                      <FormHelperText error>{queryError}</FormHelperText>
                    </Grid>
                  )}
                  <Grid item lg={3} md={6} xs={12}>
                    <TextField
                      name="name"
                      required
                      variant="standard"
                      fullWidth
                      label="Name"
                      value={name}
                      error={!!formDataErrors.name}
                      helperText={formDataErrors.name}
                      onChange={handleInputChange}
                      inputProps={{ "data-testid": "name" }}
                    />
                  </Grid>
                  <Grid item lg={3} md={6} xs={12}>
                    <DateTimePicker
                      format="D MMM YYYY HH:mm"
                      slotProps={{
                        textField: {
                          error: !!formDataErrors.scheduledAt,
                          helperText: formDataErrors.scheduledAt,
                          "data-testid": "scheduledAt",
                          required: true,
                        },
                      }}
                      label="Scheduled at"
                      clearable
                      ampm={false}
                      disablePast
                      minutesStep={5}
                      value={scheduledAt}
                      error={!!formDataErrors.scheduledAt}
                      helperText={formDataErrors.scheduledAt}
                      onChange={handleScheduledAtChange}
                    />
                  </Grid>
                  <Grid item xs={12} />
                  <Grid item lg={6} md={12} xs={12}>
                    <TextField
                      name="description"
                      fullWidth
                      label="Description"
                      variant="outlined"
                      multiline
                      rows={3}
                      value={description}
                      error={!!formDataErrors.description}
                      helperText={formDataErrors.description}
                      onChange={handleInputChange}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Box mr={1} display="inline">
                      <NegativeAction
                        buttonText="Cancel"
                        onClick={handleCancel}
                      />
                    </Box>
                    <PositiveAction buttonText="Save" onClick={handleSubmit} />
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </form>
        </Container>
      </Page>
    </ErrorBoundary>
  );
};

AddEditComponent.propTypes = {
  appStore: MobXPropTypes.objectOrObservableObject.isRequired,
};

export default inject("appStore")(observer(AddEditComponent));
