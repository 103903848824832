import { types, getEnv, getParent, flow } from "mobx-state-tree";
import gql from "graphql-tag";
import { find as lFind } from "lodash";

import { removeTypename, transformer } from "../helpers";
import Report, { fragments as ReportFragments } from "./models/Report";

export default types
  .model("ReportStore", {
    items: types.optional(types.map(Report), {}),
  })
  .views(self => ({
    get appStore() {
      return getParent(self);
    },
  }))
  .actions(self => {
    const { apolloClient } = getEnv(self);
    const findAllLoaded = false;

    return {
      removeSubscriberFromReports(recipientId) {
        self.items.forEach(report => {
          if (lFind(report.recipients, { id: recipientId })) {
            report.recipients = report.recipients.filter(
              ({ id }) => id !== recipientId,
            );
            self.track(report);
          }
        });
      },
      find: flow(function* find(id) {
        if (self.items.has(id)) {
          const report = self.items.get(id);
          return report.clearVolatileState();
        }

        try {
          const response = yield apolloClient.query({
            query: gql`
            {
              report(id: "${id}") {
                ...ReportDetails
              }
            }
            ${ReportFragments.fullDetails}
          `,
          });

          const item = self.track(response.data.report);

          return Promise.resolve(item);
        } catch (error) {
          return Promise.reject(error);
        }
      }),

      findAll: flow(function* findAll(limit = 0, offset = 0, filter = {}) {
        if (findAllLoaded) {
          return Promise.resolve(self.items);
        }

        try {
          const response = yield apolloClient.query({
            query: gql`
              {
                reports (
                  limit: ${limit},
                  offset: ${offset},
                  filter: {${transformer(filter)}}
                ) {
                  ...ReportDetails
                }
              }
              ${ReportFragments.fullDetails}
            `,
          });

          response.data.reports.forEach(item => self.track(item));

          return Promise.resolve(self.items);
        } catch (error) {
          return Promise.reject(error);
        }
      }),
      track(entity) {
        if (!self.items.has(entity.id)) {
          const input = removeTypename(entity);
          return self.items.put(input);
        }

        return self.items.get(entity.id);
      },
    };
  });
