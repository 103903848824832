import { types, getEnv, getParent, flow } from "mobx-state-tree";
import gql from "graphql-tag";

import Changeset, { fragments as changesetFragments } from "./models/Changeset";

import { removeTypename, transformer } from "../helpers";

export default types
  .model("ChangesetStore", {
    items: types.optional(types.map(Changeset), {}),
    count: types.optional(types.number, 0),
  })
  .views(self => ({
    get appStore() {
      return getParent(self);
    },
  }))
  .actions(self => {
    const { apolloClient } = getEnv(self);
    const findAllLoaded = false;

    return {
      find: flow(function* find(id) {
        if (self.items.has(id)) {
          return self.items.get(id);
        }

        try {
          const response = yield apolloClient.query({
            query: gql`
            {
              changeset(id: "${id}") {
                ...ChangesetFullDetails
              }
            }
            ${changesetFragments.fullDetails}
          `,
          });

          const item = self.track(response.data.changeset);

          return Promise.resolve(item);
        } catch (error) {
          return Promise.reject(error);
        }
      }),

      findAll: flow(function* findAll(limit = 1000, filter = {}) {
        if (findAllLoaded) {
          return Promise.resolve(self.items);
        }

        try {
          const response = yield apolloClient.query({
            query: gql`
              {
                changesets(limit: ${limit}, filter: {${transformer(filter)}}) {
                  ...ChangesetFullDetails
                }
              }
              ${changesetFragments.fullDetails}
            `,
          });

          response.data.changesets.forEach(item =>
            self.track(removeTypename(item)),
          );

          return Promise.resolve(self.items);
        } catch (error) {
          return Promise.reject(error);
        }
      }),

      add: flow(function* add(entity) {
        try {
          const input = transformer({
            name: entity.name,
            description: entity.description,
            scheduledAt: entity.scheduledAt.format(),
          });

          const response = yield apolloClient.mutate({
            mutation: gql`
            mutation add {
              addChangeset(input: {
                ${input}
              }) {
                ...ChangesetFullDetails
              }
            }
            ${changesetFragments.fullDetails}
          `,
          });

          return Promise.resolve(self.track(response.data.addChangeset));
        } catch (error) {
          return Promise.reject(error);
        }
      }),

      getRowCount: flow(function* getRowCount(filter = {}) {
        const response = yield apolloClient.query({
          query: gql`
            {
              changesets(filter: {${transformer(filter)}}) {
                id
              }
            }
          `,
        });

        self.count = response.data.changesets.length;

        return Promise.resolve(self.count);
      }),

      track(entity) {
        if (!self.items.has(entity.id)) {
          const input = removeTypename(entity);

          input.createdBy = self.appStore.userStore.track(input.createdBy);
          if (input.updatedBy) {
            input.updatedBy = self.appStore.userStore.track(input.updatedBy);
          }
          return self.items.put(input);
        }

        return self.items.get(entity.id);
      },
    };
  });
