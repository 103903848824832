import React, { useEffect } from "react";
import { Box, Container, Typography } from "@mui/material";
import { observer, inject, PropTypes as MobXPropTypes } from "mobx-react";
import { useLocation, useSearchParams } from "react-router-dom";

import Page from "../components/Page";

const scopeErrorMessage =
  "User does not have required scope to access this page";

const ErrorComponent = ({ appStore }) => {
  const location = useLocation();
  const [searchParams] = useSearchParams();

  useEffect(() => {
    appStore.setLoading(false);
  }, [appStore.isLoading]);

  const displayError = () => {
    if (searchParams.get("error") === "scopeError") {
      return scopeErrorMessage;
    }

    return location?.state?.msg ?? "";
  };

  return (
    <Page style={{ height: "100%", marginTop: "auto" }} title="Error Page">
      <Box
        display="flex"
        flexDirection="column"
        height="100%"
        justifyContent="center"
      >
        <Container maxWidth="md" data-cy="404">
          <Typography align="center" color="textPrimary" variant="h1">
            An Unexpected Error Occurred
          </Typography>
          <Typography align="center" color="textPrimary" variant="subtitle2">
            {displayError()}
          </Typography>
        </Container>
      </Box>
    </Page>
  );
};

ErrorComponent.propTypes = {
  appStore: MobXPropTypes.objectOrObservableObject.isRequired,
};

const ErrorPage = inject("appStore")(observer(ErrorComponent));

export default ErrorPage;
