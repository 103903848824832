import React, { useState } from "react";

import { observer, inject, PropTypes as MobXPropTypes } from "mobx-react";

import { makeStyles } from "@mui/styles";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";

import PositiveAction from "../../../../components/Button/PositiveAction";

const useStyles = makeStyles(() => ({
  icon: {
    verticalAlign: "middle",
  },
}));

const syncStatuses = {
  IDLE: "IDLE",
  LOADING: "LOADING",
  SUCCESS: "SUCCESS",
  FAILURE: "FAILURE",
};

const SyncPOSComponent = ({ appStore }) => {
  const classes = useStyles();
  const [syncStatus, setSyncStatus] = useState(syncStatuses.IDLE);

  const syncPOSVersions = async () => {
    setSyncStatus(syncStatuses.LOADING);
    try {
      await appStore.settingsStore.syncPOSVersions();
      setSyncStatus(syncStatuses.SUCCESS);
    } catch (err) {
      setSyncStatus(syncStatuses.FAILURE);
    }
  };

  return (
    <TableRow>
      <TableCell>Sync POS Versions to Sites:</TableCell>
      <TableCell>
        <Grid>
          <Box mr={1} display="inline">
            <PositiveAction
              buttonText="Sync"
              onClick={syncPOSVersions}
              disabled={syncStatus === syncStatuses.LOADING}
            />
          </Box>
          {syncStatus === syncStatuses.SUCCESS && (
            <CheckCircleIcon
              className={classes.icon}
              color="primary"
              fontSize="large"
            />
          )}
          {syncStatus === syncStatuses.FAILURE && (
            <CancelIcon
              className={classes.icon}
              color="error"
              fontSize="large"
            />
          )}
        </Grid>
      </TableCell>
    </TableRow>
  );
};

SyncPOSComponent.propTypes = {
  appStore: MobXPropTypes.objectOrObservableObject.isRequired,
};

const Component = inject("appStore")(observer(SyncPOSComponent));

export default Component;
