import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import moment from "moment";

import { DatePicker } from "@mui/x-date-pickers";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import FormLabel from "@mui/material/FormLabel";
import FormGroup from "@mui/material/FormGroup";

import Typography from "@mui/material/Typography";

export const dateError = "'From' must be before the selected 'To' date";

export const DateRangeFilter = ({
  column,
  index,
  label,
  onChangeHandler,
  values,
}) => {
  const [from, setFrom] = useState(
    values[0]?.from ? moment(values[0].from) : null,
  );
  const [to, setTo] = useState(values[0]?.to ? moment(values[0].to) : null);
  const [error, setError] = useState(null);

  const resetDate = (formattedDates, option) => {
    // If the field is cleared, call changeHandler to clear API filter.
    const newValues = [];

    if (option === "from") {
      if (to) {
        newValues[0] = { from: "", to: formattedDates.to };
      }
      setFrom(null);
    } else {
      if (from) {
        newValues[0] = { from: formattedDates.from, to: "" };
      }
      setTo(null);
    }

    onChangeHandler(newValues, index, column);
  };

  const handleSelection = option => date => {
    const formattedDates = {
      from: from ? from.toISOString(true) : null,
      to: to ? to.toISOString(true) : null,
    };

    if (date) {
      // If option is "to", we want the end of the day otherwise results in the daytime will be ignored.
      formattedDates[option] =
        option === "from"
          ? date.toISOString(true)
          : date.endOf("day").toISOString(true);

      // Update the selected date.
      if (option === "from") {
        setFrom(date);
      } else {
        setTo(date);
      }

      // Show an error if "to" date is selected before "from" date.
      if (formattedDates.to < formattedDates.from) {
        setError(dateError);
        return;
      }

      // Send the formatted dates back to the Basetable, to be used in the API filter.
      onChangeHandler([formattedDates], index, column);
    } else {
      resetDate(formattedDates, option);
    }

    setError(null);
  };

  // Used on filter resetting to clear state values.
  useEffect(() => {
    if (!values.length) {
      setFrom(null);
      setTo(null);
      setError(null);
    }
  }, [values]);

  return (
    <Box>
      <FormLabel sx={{ fontSize: "13px" }}>{label}</FormLabel>
      <FormGroup row sx={{ marginTop: "10px" }}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <DatePicker
              slotProps={{
                textField: { size: "small" },
                actionBar: {
                  actions: ["clear"],
                },
              }}
              value={from}
              label="From"
              format="DD/MM/YYYY"
              onChange={handleSelection("from")}
            />
          </Grid>

          <Grid item xs={12}>
            <DatePicker
              slotProps={{
                textField: { size: "small" },
                actionBar: {
                  actions: ["clear"],
                },
              }}
              value={to}
              label="To"
              format="DD/MM/YYYY"
              onChange={handleSelection("to")}
            />
          </Grid>
        </Grid>
      </FormGroup>
      {error && (
        <Typography
          sx={{ marginTop: "5px", fontSize: 14, color: "error.main" }}
        >
          {error}
        </Typography>
      )}
    </Box>
  );
};

DateRangeFilter.propTypes = {
  column: PropTypes.shape({}).isRequired,
  index: PropTypes.number.isRequired,
  label: PropTypes.string.isRequired,
  onChangeHandler: PropTypes.func.isRequired,
  values: PropTypes.arrayOf(
    PropTypes.shape({
      from: PropTypes.string,
      to: PropTypes.string,
    }),
  ).isRequired,
};
