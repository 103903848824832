import React from "react";
import PropTypes from "prop-types";
import { useParams, useNavigate } from "react-router-dom";
import { observer, inject, PropTypes as MobXPropTypes } from "mobx-react";

import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Grid from "@mui/material/Grid";

import Select from "../../../components/Select";

const defaultSiteArray = [];

const SiteSelector = ({ appStore, sites = defaultSiteArray }) => {
  const navigate = useNavigate();
  const { siteId } = useParams();

  const handleSiteChange = ({ target: { value } }) => {
    navigate(`/app/products/bulk/${value}`);
  };

  return (
    <Card>
      <CardContent>
        <Grid container item xs={12} md={6} lg={3}>
          <Select
            onChange={handleSiteChange}
            options={[{ text: "Default", value: "default" }, ...sites]}
            value={siteId ?? "default"}
            label="Site Selection"
          />
        </Grid>
      </CardContent>
    </Card>
  );
};

SiteSelector.propTypes = {
  appStore: MobXPropTypes.objectOrObservableObject.isRequired,
  sites: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    }),
  ),
};

export default inject("appStore")(observer(SiteSelector));
