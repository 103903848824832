import { types, getParent, flow, getEnv } from "mobx-state-tree";
import gql from "graphql-tag";

import PaymentType, { fragments as paymentTypeFragments } from "./PaymentType";
import BookingType, { fragments as bookingTypeFragments } from "./BookingType";
import User, { fragments as userFragments } from "./User";
import DateTime from "./DateTime";

import { transformer } from "../../helpers";

export const fragments = {
  fullDetails: gql`
    fragment OrganisationFullDetails on Organisation {
      id
      name
      code
      type
      orderMessageTimeoutMinutes
      POSPaymentType {
        ...PaymentTypeFullDetails
      }
      defaultBookingType {
        ...BookingTypeFullDetails
      }
      createdAt
      createdBy {
        ...UserFullDetails
      }
      updatedAt
      updatedBy {
        ...UserFullDetails
      }
    }
    ${paymentTypeFragments.fullDetails}
    ${bookingTypeFragments.fullDetails}
    ${userFragments.fullDetails}
  `,
};

export default types
  .model("Organisation", {
    id: types.identifier,
    name: types.string,
    type: types.string,
    code: types.string,
    orderMessageTimeoutMinutes: types.maybeNull(types.integer),
    POSPaymentType: types.maybeNull(types.reference(PaymentType)),
    defaultBookingType: types.maybeNull(types.reference(BookingType)),
    createdAt: DateTime,
    createdBy: types.reference(User),
    updatedAt: types.maybeNull(DateTime),
    updatedBy: types.maybeNull(types.reference(User)),
  })
  .views(self => ({
    get organisationStore() {
      return getParent(getParent(self));
    },
    get appStore() {
      return getParent(self.organisationStore);
    },
  }))
  .actions(self => {
    // const { apolloClient } = getEnv(self);
    return {
      setPOSPaymentType(value) {
        self.POSPaymentType = value;
      },

      setName(value) {
        self.name = value;
      },

      setDefaultBookingType(value) {
        self.defaultBookingType = value;
      },

      setType(value) {
        self.type = value;
      },

      setCode(value) {
        self.code = value;
      },

      setOrderMessageTimeoutMinutes(value) {
        self.orderMessageTimeoutMinutes = value;
      },

      save: flow(function* save() {
        // @todo this gets called on a clone so outside scope apolloClient is not available
        const { apolloClient } = getEnv(self);
        const params = transformer({
          id: self.id,
          orderMessageTimeoutMinutes: self.orderMessageTimeoutMinutes,
          POSPaymentTypeId:
            self.POSPaymentType !== null ? self.POSPaymentType.id : null,
          defaultBookingTypeId:
            self.defaultBookingType !== null
              ? self.defaultBookingType.id
              : null,
        });

        yield apolloClient.mutate({
          mutation: gql`
          mutation updateOrganisation {
            updateOrganisation(input: {
                ${params}
              }) {
                 id
              }
          }
      `,
        });
      }),
    };
  });
