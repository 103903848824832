import * as React from "react";
import PropTypes from "prop-types";

import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";

const defaultName = "select-component";

export default function SelectComponent({
  label = "",
  name = defaultName,
  onChange,
  options,
  testId = defaultName,
  value,
}) {
  return (
    <FormControl fullWidth>
      <InputLabel>{label}</InputLabel>
      <Select
        name={name}
        label={label}
        value={value}
        onChange={onChange}
        data-testid={testId}
      >
        {options.map(({ text: optionText, value: optionValue }, index) => (
          <MenuItem
            value={optionValue}
            key={optionValue}
            data-testid={`${testId}-option-${index}`}
          >
            {optionText}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

SelectComponent.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    }),
  ).isRequired,
  testId: PropTypes.string,
  value: PropTypes.string.isRequired,
};
