import React from "react";
import PropTypes from "prop-types";

import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";

import TableRowComponent from "./TableRow";

const TextInputComponent = ({
  error = null,
  name,
  label,
  value,
  updateCallback,
  testId,
  tooltip = "",
}) => {
  return (
    <TableRowComponent name={label} tooltip={tooltip} tooltipTestId={testId}>
      <TextField
        autoComplete="off"
        required
        variant="standard"
        fullWidth
        value={value && value}
        onChange={event => updateCallback(name, event.target.value)}
        sx={{ maxWidth: "166px" }}
        inputProps={{ "data-testid": testId }}
      />
      {error && (
        <Typography sx={{ fontSize: 14, color: "error.main" }}>
          {error}
        </Typography>
      )}
    </TableRowComponent>
  );
};

TextInputComponent.propTypes = {
  error: PropTypes.string,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  updateCallback: PropTypes.func.isRequired,
  testId: PropTypes.string.isRequired,
  tooltip: PropTypes.string,
};

export default TextInputComponent;
