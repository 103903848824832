import React from "react";
import PropTypes from "prop-types";
import { observer, inject, PropTypes as MobXPropTypes } from "mobx-react";
import { observable, action, makeObservable } from "mobx";

import { withStyles } from "@mui/styles";
import { TextField, Button, FormHelperText } from "@mui/material";

import validator from "validator";

import Money from "../../components/Form/Money";

const styles = theme => ({
  input: {
    margin: theme.spacing(1),
  },
  form: {
    padding: 12, // required due to https://material-ui.com/layout/grid/#limitations
  },
  formControl: {
    minWidth: 120,
    // maxWidth: 200,
  },
  button: {
    marginRight: theme.spacing(1),
  },
  inline: {
    display: "inline-block",
  },
  money: {
    width: 80,
  },
});

class RefundFormComponent extends React.Component {
  hasChanged = false;
  formError = "";

  amount = "";
  amountError = "";
  notes = "";
  notesError = "";

  constructor(props) {
    super(props);

    makeObservable(this, {
      hasChanged: observable,
      isLoading: observable,
      setLoading: action,
      formError: observable,
      setFormError: action,
      amount: observable,
      amountError: observable,
      handleAmountChange: action,
      notes: observable,
      notesError: observable,
      handleNotesChange: action,
      handleSubmit: action,
    });
  }

  componentDidMount() {}

  setLoading = value => {
    this.isLoading = value;
  };

  setFormError = value => {
    this.formError = value;
  };

  handleAmountChange = event => {
    this.amount = event.target.value;
    this.amountError = "";
    this.formError = "";
  };

  handleNotesChange = event => {
    this.notes = event.target.value;
    this.notesError = "";
    this.formError = "";
  };

  handleCancel = () => {
    const { onCancel } = this.props;

    if (this.hasChanged) {
      // @todo alert
    }
    onCancel();
  };

  handleSubmit = event => {
    const { appStore: app, deposit, onSuccess } = this.props;
    const that = this;
    let hasError = false;
    let amount;
    event.preventDefault();

    if (!validator.isFloat(this.amount)) {
      hasError = true;
      this.amountError = "Please specify amount";
    } else {
      amount = parseFloat(this.amount) * 100;

      if (amount + deposit.refundedAmount > deposit.amount) {
        hasError = true;
        this.setFormError("Total refunds can't exceed deposit value");
      }
    }

    if (!hasError) {
      app.setLoading();

      deposit
        .addRefund({
          amount: parseFloat(this.amount) * 100,
          notes: this.notes,
        })
        .then(() => {
          app.setLoading(false);
          onSuccess();
        })
        .catch(error => {
          app.log.error(error);

          that.setFormError(error.message);
          app.setLoading(false);
        });
    }
  };

  render() {
    const { classes } = this.props;

    return (
      <div>
        {this.formError && (
          <div>
            <FormHelperText error>{this.formError}</FormHelperText>
          </div>
        )}
        <div>
          Refund{" "}
          <Money
            className={classes.money}
            autoComplete="off"
            label="Amount"
            value={this.amount}
            error={!!this.amountError}
            helperText={this.amountError}
            onChange={this.handleAmountChange}
          />
        </div>
        <TextField
          variant="standard"
          autoComplete="off"
          fullWidth
          label="Notes (internal)"
          value={this.notes}
          error={!!this.notesError}
          helperText={this.notesError}
          onChange={this.handleNotesChange}
        />
        <div style={{ marginTop: 20 }}>
          <Button
            className={classes.button}
            type="submit"
            color="primary"
            variant="contained"
            size="small"
            onClick={this.handleSubmit}
          >
            Refund
          </Button>

          <Button
            className={classes.button}
            type="submit"
            variant="contained"
            size="small"
            onClick={this.handleCancel}
          >
            Cancel
          </Button>
        </div>
      </div>
    );
  }
}

RefundFormComponent.propTypes = {
  appStore: MobXPropTypes.objectOrObservableObject.isRequired,
  classes: PropTypes.shape({
    money: PropTypes.string,
    button: PropTypes.string,
    root: PropTypes.string,
    appFrame: PropTypes.string,
    content: PropTypes.string,
    paper: PropTypes.string,
  }).isRequired,
  deposit: MobXPropTypes.objectOrObservableObject.isRequired,
  onSuccess: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

const RefundForm = observer(RefundFormComponent);

export default withStyles(styles)(inject("appStore")(RefundForm));
