import React, { useState } from "react";
import moment from "moment";
import propTypes from "prop-types";

import { Grid, Box, FormGroup, FormLabel } from "@mui/material";

import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";

const DateTimeBetweenFilter = ({ props }) => {
  const { item, applyValue } = props;

  const [fromValue, setFromValue] = useState(
    item.value?.from !== undefined && item.value?.from !== null
      ? moment(item.value.from)
      : null,
  );
  const [toValue, setToValue] = useState(
    item.value?.to !== undefined && item.value?.to !== null
      ? moment(item.value.to)
      : null,
  );

  const dateTimeStyles = {
    "& .MuiInputAdornment-root": {
      "& .MuiButtonBase-root": {
        marginRight: "5px",
      },
      "& .MuiSvgIcon-root": {
        height: "20px",
        width: "20px",
      },
    },
  };

  const applyValues = (from, to) => {
    applyValue({
      ...item,
      value: {
        from: from !== null ? from.toISOString(true) : null,
        to: to !== null ? to.toISOString(true) : null,
      },
    });
  };

  return (
    <Box data-testid="custom-datagrid-between-filter">
      <FormGroup row>
        <Grid container>
          <Grid item xs={6}>
            <FormLabel
              sx={{ fontSize: "12px", lineHeight: "16px", display: "block" }}
            >
              From
            </FormLabel>
            <DateTimePicker
              format="DD/MM/YYYY hh:mm A"
              slotProps={{
                textField: {
                  variant: "standard",
                  inputProps: {
                    "data-testid": "custom-datagrid-between-filter-from",
                  },
                },
              }}
              sx={dateTimeStyles}
              value={fromValue}
              maxDateTime={toValue}
              onChange={newValue => {
                setFromValue(newValue);
                applyValues(newValue, toValue);
              }}
            />
          </Grid>

          <Grid item xs={6}>
            <FormLabel
              sx={{ fontSize: "12px", lineHeight: "16px", display: "block" }}
            >
              To
            </FormLabel>
            <DateTimePicker
              format="DD/MM/YYYY hh:mm A"
              slotProps={{
                textField: {
                  variant: "standard",
                  inputProps: {
                    "data-testid": "custom-datagrid-between-filter-to",
                  },
                },
              }}
              sx={dateTimeStyles}
              value={toValue}
              minDateTime={fromValue}
              onChange={newValue => {
                setToValue(newValue);
                applyValues(fromValue, newValue);
              }}
            />
          </Grid>
        </Grid>
      </FormGroup>
    </Box>
  );
};

DateTimeBetweenFilter.propTypes = {
  props: propTypes.object.isRequired,
  item: propTypes.object,
  applyValue: propTypes.func,
};

export default DateTimeBetweenFilter;
