import React from "react";
import PropTypes from "prop-types";

import TableRowComponent from "./TableRow";
import Switch from "../../../components/Switch";

const SwitchComponent = ({
  name,
  label,
  value,
  updateCallback,
  testId,
  tooltip = "",
}) => {
  return (
    <TableRowComponent name={label} tooltip={tooltip} tooltipTestId={testId}>
      <Switch
        active={value}
        onChange={event => updateCallback(name, event.target.checked)}
        colour="success"
        testId={testId}
      />
    </TableRowComponent>
  );
};

SwitchComponent.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  value: PropTypes.bool.isRequired,
  updateCallback: PropTypes.func.isRequired,
  testId: PropTypes.string.isRequired,
  tooltip: PropTypes.string,
};

export default SwitchComponent;
