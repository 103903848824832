import { types, getParent, flow, getEnv } from "mobx-state-tree";
import gql from "graphql-tag";

import User, { fragments as UserFragments } from "./User";
import DateTime from "./DateTime";

import { transformer } from "../../helpers";

export const fragments = {
  fullDetails: gql`
    fragment PaymentTypeFullDetails on PaymentType {
      id
      name
      POSPaymentTypeId
      fluxPaymentType
      createdAt
      createdBy {
        ...UserFullDetails
      }
      updatedAt
      updatedBy {
        ...UserFullDetails
      }
    }
    ${UserFragments.fullDetails}
  `,
};

export default types
  .model("PaymentType", {
    id: types.identifier,
    name: types.string,
    POSPaymentTypeId: types.maybeNull(types.number),
    fluxPaymentType: types.maybeNull(
      types.enumeration("fluxPaymentType", [
        "CASH",
        "CARD",
        "CHECQUE",
        "GIFTCARD",
      ]),
    ),
    createdAt: DateTime,
    createdBy: types.reference(User),
    updatedAt: types.maybeNull(DateTime),
    updatedBy: types.maybeNull(types.reference(User)),
  })
  .views(self => ({
    get paymentTypeStore() {
      return getParent(getParent(self));
    },
    get appStore() {
      return getParent(self.paymentTypeStore);
    },
  }))
  .actions(self => {
    // const { apolloClient } = getEnv(self);
    return {
      setName(value) {
        self.name = value;
      },

      setPOSPaymentTypeId(value) {
        self.POSPaymentTypeId = value;
      },

      setFluxPaymentType(value) {
        self.fluxPaymentType = value;
      },

      save: flow(function* save() {
        const { apolloClient } = getEnv(self);
        const params = transformer({
          id: self.id,
          name: self.name,
          POSPaymentTypeId: self.POSPaymentTypeId,
          fluxPaymentType: () => self.fluxPaymentType,
        });

        yield apolloClient.mutate({
          mutation: gql`
          mutation update {
            updatePaymentType(input: {
                ${params}
              }) {
                 id
              }
          }
      `,
        });
      }),
    };
  });
