import { types, getEnv, getParent, flow } from "mobx-state-tree";
import gql from "graphql-tag";

import AuditLog, { fragments as auditLogFragments } from "./models/AuditLog";

import { convertStringToEnum, removeTypename, transformer } from "../helpers";

export default types
  .model("AuditLogStore", {
    items: types.optional(types.map(AuditLog), {}),
  })
  .views(self => ({
    get appStore() {
      return getParent(self);
    },
  }))
  .actions(self => {
    const { apolloClient } = getEnv(self);

    return {
      acknowledgeAuditLogEntry: flow(function* acknowledgeAuditLogEntry(id) {
        try {
          yield apolloClient.mutate({
            mutation: gql`
            mutation AcknowledgeAuditLogEntry {
              acknowledgeAuditLogEntry(id: "${id}")
            }
          `,
          });

          return Promise.resolve(true);
        } catch (error) {
          return Promise.reject(error);
        }
      }),
      count: flow(function* count(filter = {}) {
        const { level_in: levelIn, ...restofFilter } = filter;

        if (levelIn) {
          restofFilter.level_in = convertStringToEnum(levelIn);
        }

        const transformedFilter = transformer(restofFilter);

        try {
          const response = yield apolloClient.query({
            query: gql`
              {
                countAuditLogs(filter: {${transformedFilter}})
              }
            `,
            fetchPolicy: "no-cache",
          });

          return Promise.resolve(response.data.countAuditLogs);
        } catch (error) {
          return Promise.reject(error);
        }
      }),
      find: flow(function* find(id) {
        try {
          const response = yield apolloClient.query({
            query: gql`
                {
                  auditLog(id: "${id}") {
                    ...AuditLogFullDetails
                  }
                }
                ${auditLogFragments.fullDetails}
              `,
          });

          return Promise.resolve(response.data.auditLog);
        } catch (error) {
          return Promise.reject(error);
        }
      }),
      findAll: flow(function* findAll({
        limit = 100,
        offset = 0,
        sort = {},
        filter = {},
        search = "",
      }) {
        try {
          const { level_in: levelIn, ...restofFilter } = filter;

          if (search) {
            restofFilter.message_contains = search;
          }

          if (levelIn) {
            restofFilter.level_in = convertStringToEnum(levelIn);
          }

          const transformedFilter = transformer(restofFilter);

          const { direction, ...restOfSort } = sort;

          if (direction) {
            restOfSort.direction = convertStringToEnum(direction);
          }

          const transformedSort = transformer(restOfSort);

          const response = yield apolloClient.query({
            query: gql`
                    {
                      auditLogs (
                        limit: ${limit},
                        offset: ${offset},
                        filter: {${transformedFilter}}
                        sort: {${transformedSort}}
                      ) {
                        ...AuditLogFullDetails
                      }
                    }
                    ${auditLogFragments.fullDetails}
                  `,
          });

          return Promise.resolve(response.data.auditLogs);
        } catch (error) {
          return Promise.reject(error);
        }
      }),
      track(entity) {
        if (!self.items.has(entity.id)) {
          const input = removeTypename(entity);

          input.createdBy = self.appStore.userStore.track(input.createdBy);

          if (input.updatedBy !== null) {
            input.updatedBy = self.appStore.userStore.track(input.updatedBy);
          }

          if (input.acknowledgedBy !== null) {
            input.acknowledgedBy = self.appStore.userStore.track(
              input.acknowledgedBy,
            );
          }

          return self.items.put(input);
        }

        return self.items.get(entity.id);
      },
    };
  });
