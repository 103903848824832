import debounce from "lodash/debounce";

const oneMinuteInMs = 60 * 1000;
const fiveMinutesInMs = oneMinuteInMs * 5;

let sessionExpiryTime = null;
let lastUserInput = null;

export const setSessionExpiryTime = newTimestamp => {
  sessionExpiryTime = newTimestamp;
};

const getCurrentTimestamp = () => {
  return new Date().valueOf();
};

const logoutActivityMonitor = (
  handleRefreshSession,
  setSessionExpiryCountdown,
) => {
  // Add event listeners for mouse movement and keypress
  window.addEventListener(
    "mousemove",
    debounce(
      () => {
        lastUserInput = getCurrentTimestamp();
      },
      500,
      // These options make it so that event listener
      // is called on mouse move start and end not spammed constantly
      { leading: true, trailing: true },
    ),
  );

  window.addEventListener("keypress", () => {
    lastUserInput = getCurrentTimestamp();
  });

  window.setInterval(() => {
    if (sessionExpiryTime && lastUserInput) {
      const currentTime = getCurrentTimestamp();

      const sessionIsntExpired = currentTime < sessionExpiryTime;
      const closeToExpiry = currentTime >= sessionExpiryTime - fiveMinutesInMs;

      if (sessionIsntExpired && closeToExpiry) {
        const expiryPopupTime = sessionExpiryTime - oneMinuteInMs * 2;
        const showExpiryPopup = currentTime >= expiryPopupTime;

        const userInputWithinFiveMinutes =
          lastUserInput + fiveMinutesInMs >= currentTime;

        // If there's 2 minutes left or less left in the session, show the expiry popup modal.
        if (showExpiryPopup) {
          setSessionExpiryCountdown(true);
        }
        // Otheriwse, if there's more than 2 minutes remaining and the user
        // has moved the mouse or completed a key press in the last 5 minutes of the session, refresh the session.
        else if (userInputWithinFiveMinutes) {
          handleRefreshSession();
        }
      }
    }
  }, oneMinuteInMs);
};

export default logoutActivityMonitor;
