import { types, getParent } from "mobx-state-tree";
import gql from "graphql-tag";

import User, { fragments as UserFragments } from "./User";
import DateTime from "./DateTime";

const logLevels = [
  "ERROR",
  "WARN",
  "INFO",
  "HTTP",
  "VERBOSE",
  "DEBUG",
  "TRACE",
];

export const fragments = {
  fullDetails: gql`
    fragment AuditLogFullDetails on AuditLog {
      id
      acknowledgedAt
      acknowledgedBy {
        ...UserFullDetails
      }
      level
      message
      source
      createdAt
      createdBy {
        ...UserFullDetails
      }
      updatedAt
      updatedBy {
        ...UserFullDetails
      }
    }
    ${UserFragments.fullDetails}
  `,
};

export default types
  .model("AuditLog", {
    id: types.identifier,
    acknowledgedAt: types.maybeNull(DateTime),
    acknowledgedBy: types.maybeNull(types.reference(User)),
    level: types.enumeration("LogLevel", logLevels),
    message: types.string,
    source: types.string,
    createdAt: DateTime,
    createdBy: types.reference(User),
    updatedAt: types.maybeNull(DateTime),
    updatedBy: types.maybeNull(types.reference(User)),
  })
  .views(self => ({
    get auditLogStore() {
      return getParent(getParent(self));
    },
    get appStore() {
      return getParent(self.auditLogStore);
    },
  }))
  .actions(self => {
    // "no-param-reassign" has to be disabled here as "self" needs to be modified to change the mobx state values

    return {
      setAcknowledgedAt(value) {
        self.acknowledgedAt = value;
        return self;
      },
      setAcknowledgedBy(value) {
        self.acknowledgedBy = value;
        return self;
      },
    };
  });
