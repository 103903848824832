import { types, getParent, getEnv, flow } from "mobx-state-tree";
import gql from "graphql-tag";

import User, { fragments as UserFragments } from "./User";

import { transformer } from "../../helpers";
import DateTime from "./DateTime";

export const fragments = {
  fullDetails: gql`
    fragment BookingTypeFullDetails on BookingType {
      id
      POSEventTypeId
      name
      isRoom
      isEnabled
      createdAt
      createdBy {
        ...UserFullDetails
      }
      updatedAt
      updatedBy {
        ...UserFullDetails
      }
    }
    ${UserFragments.fullDetails}
  `,
};

export default types
  .model("BookingType", {
    id: types.identifier,
    POSEventTypeId: types.number,
    name: types.string,
    isRoom: types.boolean,
    isEnabled: types.boolean,
    createdAt: DateTime,
    createdBy: types.reference(User),
    updatedAt: types.maybeNull(DateTime),
    updatedBy: types.maybeNull(types.reference(User)),
  })
  .views(self => ({
    get bookingTypeStore() {
      return getParent(getParent(self));
    },
    get appStore() {
      return getParent(self.bookingTypeStore);
    },
  }))
  .actions(self => {
    // const { apolloClient } = getEnv(self);

    return {
      setPOSEventTypeId(value) {
        self.POSEventTypeId = value;
        return self;
      },

      setName(value) {
        self.name = value;
        return self;
      },

      setIsRoom(value) {
        self.isRoom = value;
        return self;
      },

      setIsEnabled(value) {
        self.isEnabled = value;
        return self;
      },

      save: flow(function* save() {
        // @todo this gets called on a clone so outside scope apolloClient is not available
        const { apolloClient } = getEnv(self);
        const params = transformer({
          id: self.id,
          POSEventTypeId: self.POSEventTypeId,
          name: self.name,
          isRoom: self.isRoom,
          isEnabled: self.isEnabled,
        });

        yield apolloClient.mutate({
          mutation: gql`
          mutation updateBookingType {
              updateBookingType(input: {
                ${params}
              }) {
                 id
              }
          }
      `,
        });
      }),
    };
  });
