import React, { useState } from "react";
import { observer, inject, PropTypes as MobXPropTypes } from "mobx-react";
import { Dialog, DialogContent } from "@mui/material";

import LoginForm from "./Form/Login";

const ExpiredLoginModal = ({ appStore: app }) => {
  const [formError, setFormError] = useState("");

  const handleSubmit = (email, password) => {
    app
      .authenticate(email, password)
      .then(() => {
        app.setLoading(false);
        setFormError("");
        app.setExpiredLogin(false);
      })
      .catch(error => {
        app.log.error(error);
        setFormError(error.message.replace("GraphQL error: ", ""));
        app.setLoading(false);
      });
  };

  return (
    <Dialog open={app.expiredLogin}>
      <DialogContent>
        <LoginForm
          allowCancel
          displayName={app.displayName}
          formError={formError}
          submit={handleSubmit}
        />
      </DialogContent>
    </Dialog>
  );
};

ExpiredLoginModal.propTypes = {
  appStore: MobXPropTypes.objectOrObservableObject.isRequired,
};

export default inject("appStore")(observer(ExpiredLoginModal));
