export const posStatuses = [
  { text: "Negative Price", value: "negative_price" },
  { text: "Red Print", value: "red_print" },
  { text: "Open Description", value: "open_description" },
  { text: "Open Price", value: "open_price" },
  { text: "Modifier", value: "modifier" },
  { text: "Promotional Trigger", value: "promotional_trigger" },
  { text: "Receipt Suppress", value: "receipt_suppress" },
];

export const convertStatusArrayToObject = dbPosStatuses =>
  dbPosStatuses.reduce(
    (acc, curr) => ({ ...acc, [curr.name]: curr.enabled }),
    {},
  );

export const convertStatusObjectToArray = posStatusesObject =>
  Object.keys(posStatusesObject).map(posStatusKey => ({
    name: posStatusKey,
    enabled: posStatusesObject[posStatusKey],
  }));

export const convertTagArrayForDatabase = tagArray => {
  const tagsAsObject = { PRODUCTGROUP: [], SUBGROUP: [], GENERIC: [] };

  tagArray.forEach(({ id, type }) => {
    if (!tagsAsObject[type]) {
      tagsAsObject[type] = [id];
    } else {
      tagsAsObject[type].push(id);
    }
  });

  return tagsAsObject;
};

export const convertModfierGroups = modifierGroups =>
  modifierGroups.map(({ modifierGroup, required }) => ({
    ...modifierGroup,
    required,
  }));
