import React from "react";
import PropTypes from "prop-types";
import { observer, inject, PropTypes as MobXPropTypes } from "mobx-react";
import { observable, action, makeObservable } from "mobx";
import { Link } from "react-router-dom";

import { withStyles } from "@mui/styles";

import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import Container from "@mui/material/Container";
import IconButton from "@mui/material/IconButton";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

import EditIcon from "@mui/icons-material/Edit";

import Page from "../../components/Page";
import ErrorBoundary from "../../components/ErrorBoundary";
import AddAction from "../../components/Button/AddAction";

const styles = theme => ({
  margin: {
    margin: theme.spacing(1),
  },
});

class ListComponent extends React.Component {
  items = [];

  constructor(props) {
    super(props);

    makeObservable(this, {
      items: observable,
      setItems: action,
    });
  }

  componentDidMount() {
    const { appStore: app } = this.props;
    const that = this;
    app.paymentTypeStore.findAll().then(items => {
      that.setItems([...items.values()]);
      app.setLoading(false);
    });
  }

  setItems = items => {
    this.items = items;
  };

  render() {
    const { classes } = this.props;
    return (
      <ErrorBoundary>
        <Page title="Payment types">
          <Container maxWidth={false}>
            <Grid container rowSpacing={3}>
              <Grid container item xs={12} justifyContent="end" mt={3}>
                <Link to="/app/payment-types/add">
                  <AddAction buttonText="Add payment type" />
                </Link>
              </Grid>
              <Grid item xs={12}>
                <Card>
                  <CardHeader title="Payment types" />
                  <CardContent>
                    <Table className={classes.margin}>
                      <TableHead>
                        <TableRow>
                          <TableCell>Name</TableCell>
                          <TableCell>POS Media Id</TableCell>
                          <TableCell>Flux Payment Type</TableCell>
                          <TableCell />
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {this.items.map(record => (
                          <TableRow key={record.id}>
                            <TableCell>{record.name} </TableCell>
                            <TableCell>{record.POSPaymentTypeId} </TableCell>
                            <TableCell>{record.fluxPaymentType} </TableCell>
                            <TableCell align="right">
                              <Link to={`/app/payment-types/${record.id}`}>
                                <IconButton size="medium">
                                  <EditIcon />
                                </IconButton>
                              </Link>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </Container>
        </Page>
      </ErrorBoundary>
    );
  }
}
ListComponent.propTypes = {
  appStore: MobXPropTypes.objectOrObservableObject.isRequired,
  classes: PropTypes.shape({
    margin: PropTypes.string,
    button: PropTypes.string,
  }).isRequired,
};

const List = withStyles(styles)(inject("appStore")(observer(ListComponent)));

export default List;
