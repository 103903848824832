import React, { useState, useEffect, useReducer } from "react";
import PropTypes from "prop-types";
import { observer, inject, PropTypes as MobXPropTypes } from "mobx-react";
import debounce from "lodash/debounce";
import { uniqBy } from "lodash";

import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import CircularProgress from "@mui/material/CircularProgress";

import { Typography } from "@mui/material";
import parse from "../helpers/highlight/parse";
import match from "../helpers/highlight/match";

const AutocompleteComponent = ({
  appStore,
  onSelect,
  includeGroups = false,
  label = "default",
  useFrozenProducts = false,
  required = false,
  errorMessage = "",
}) => {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [intervalId, setIntervalId] = useState(null);
  const [searchValue, setSearchValue] = useState("");
  const [selectedValue, setSelectedValue] = useState(null);

  const [filtersUsed, dispatchFilter] = useReducer((state, action) => {
    return [...state, action.payload];
  }, []);

  const [options, dispatchOptions] = useReducer((state, action) => {
    const updatedList = [...state, ...action.payload];
    return uniqBy(updatedList, "id");
  }, []);

  const getProducts = async filter => {
    if (filter === "") {
      setLoading(false);
      return;
    }

    setLoading(true);
    clearTimeout(intervalId);
    const timeoutId = setTimeout(() => {
      const proceed = filtersUsed.map(filterUsed => {
        if (filter.includes(filterUsed)) {
          return false;
        }
        return true;
      });
      if (proceed.includes(false)) {
        setLoading(false);
        return;
      }
      dispatchFilter({ payload: filter });
      if (includeGroups) {
        appStore.productTagStore.findAll().then(groups => {
          appStore.productStore
            .findAll(
              50000,
              { tillProductName_contains: filter },
              useFrozenProducts ? "autocomplete" : null,
            )
            .then(fetchedProducts => {
              dispatchOptions({
                payload: [...groups.values()]
                  .map(item => ({
                    id: item.id,
                    POSId: `${item.POSId}`,
                    name: item.name,
                    type: item.type,
                    parentTagId: item.parentTag?.id,
                  }))
                  .concat(
                    [...fetchedProducts.values()].map(item => ({
                      ...item,
                      // We overwrite "name" with "tillProductName" as the former is irrelevant to
                      // what the user will be searching for
                      name: item.tillProductName,
                      type: "PRODUCT",
                    })),
                  ),
              });
              setLoading(false);
            });
        });
      } else {
        appStore.productStore
          .findAll(
            50000,
            { tillProductName_contains: filter },
            useFrozenProducts ? "autocomplete" : null,
          )
          .then(fetchedProducts => {
            dispatchOptions({
              payload: [...fetchedProducts.values()].map(item => ({
                ...item,
                // We overwrite "name" with "tillProductName" as the former is irrelevant to
                // what the user will be searching for
                name: item.tillProductName,
              })),
            });
            setLoading(false);
          });
      }
    }, 500);
    setIntervalId(timeoutId);
  };

  const onChange = (event, value) => {
    if (value !== null) {
      setSearchValue("");
      onSelect(value);
      setSelectedValue(null);
    }
  };

  useEffect(() => {
    if (!open) {
      appStore.productTagStore.findAll();
      dispatchOptions({ payload: [] });
    }
  }, [open, appStore.productTagStore]);

  return (
    <Autocomplete
      open={open}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      getOptionLabel={option => `PLU ${option.POSId} => ${option.name}`}
      onChange={onChange}
      options={options.sort((a, b) => {
        return a.type
          ? a.type.localeCompare(b.type)
          : b.name.localeCompare(a.name);
      })}
      loading={loading}
      onInputChange={debounce((event, value) => {
        if (!event || event.type === "click") {
          // We don't want to trigger an event if this is not a type input
          return () => {};
        }
        return (
          getProducts(value),
          250,
          {
            leading: true,
          }
        );
      })}
      value={selectedValue}
      inputValue={searchValue}
      isOptionEqualToValue={(option, value) => {
        return option.name === value.name;
      }}
      groupBy={option => option.type}
      renderInput={params => (
        <div id={`autocomplete-${label}`}>
          <TextField
            {...params}
            required={required}
            error={!!errorMessage}
            helperText={errorMessage ?? ""}
            label="Search for products"
            variant="outlined"
            onChange={event => setSearchValue(event.target.value)}
            InputProps={{
              ...params.InputProps,
              "data-testid": `autocomplete-${label}`,
              endAdornment: (
                <>
                  {loading ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : null}
                </>
              ),
            }}
          />
        </div>
      )}
      renderOption={(props, option, { inputValue }) => {
        const idMatches = match(option.POSId, inputValue);
        const idParts = parse(option.POSId, idMatches);

        const nameMatches = match(option.name, inputValue);
        const nameParts = parse(option.name, nameMatches);

        return (
          <div
            data-cy={`autocomplete-options-${label}`}
            {...props}
            style={{ display: "block" }}
          >
            <div style={{ width: "100%" }}>
              {nameParts.map((part, index) => (
                <span key={`a-${index}`}>{part.text}</span>
              ))}
            </div>
            <div style={{ display: "block" }}>
              <Typography variant="caption">
                POS Id:
                {idParts.map((part, index) => (
                  <span key={`a-${index}`}>{part.text}</span>
                ))}
              </Typography>
            </div>
          </div>
        );
      }}
    />
  );
};

AutocompleteComponent.propTypes = {
  appStore: MobXPropTypes.objectOrObservableObject.isRequired,
  onSelect: PropTypes.func.isRequired,
  includeGroups: PropTypes.bool,
  label: PropTypes.string,
  useFrozenProducts: PropTypes.bool,
  required: PropTypes.bool,
  errorMessage: PropTypes.string,
};

const Component = inject("appStore")(observer(AutocompleteComponent));

export default Component;
