import React, { useState, useEffect } from "react";
import { useQuery } from "@apollo/client";
import PropTypes from "prop-types";
import gql from "graphql-tag";
import {
  Card,
  CardContent,
  Grid,
  Typography,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
} from "@mui/material";

import { useSnackbar } from "notistack";
import PricesTable from "./PricesTable";
import { GET_PRODUCT_OPTIONS_BY_PRODUCT_ID } from "../../../../helpers/apollo/utils";

const Prices = ({ productId = null, pricesData, updateFormData }) => {
  const [productOptions, setProductOptions] = useState([]);
  const [selectedProdOptionId, setSelectedProdOptionId] = useState("default");

  const {
    data = { product: { productOptionGroups: [] } },
    error,
    loading,
  } = useQuery(gql(GET_PRODUCT_OPTIONS_BY_PRODUCT_ID()), {
    fetchPolicy: "cache-and-network",
    skip: !productId,
    variables: {
      productId,
    },
  });

  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (loading) {
      return;
    }

    if (error) {
      enqueueSnackbar(`Error: ${error.message}`, {
        variant: "error",
        SnackbarProps: {
          "data-testid": "product-tags-error-snackbar",
        },
      });
    }

    const dupeCheck = [];
    // make array of unique productId entries and alpha sort for select drop-down
    setProductOptions(
      data.product.productOptionGroups
        .flatMap(
          ({ productOptions: linkedProductOptions }) => linkedProductOptions,
        )
        .filter(({ id }) => {
          if (dupeCheck.includes(id)) {
            return false;
          }
          dupeCheck.push(id);
          return true;
        })
        .sort((a, b) => a.name.localeCompare(b.name)),
    );
  }, [loading, data]);

  return (
    <Card>
      <CardContent>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography>Prices</Typography>
          </Grid>
          <Grid item xs={12}>
            <FormControl sx={{ minWidth: 200 }}>
              <InputLabel id="option-select-label">Product Option</InputLabel>
              <Select
                label="Product Option"
                labelId="option-select-label"
                data-testid="test-select-box"
                defaultValue="default"
                onChange={({ target: { value } }) =>
                  setSelectedProdOptionId(value)
                }
              >
                <MenuItem value="default">Default</MenuItem>
                {productOptions.map(option => (
                  <MenuItem key={option.id} value={option.id}>
                    {option.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <PricesTable
              pricesData={pricesData}
              productOptionId={selectedProdOptionId}
              updateFormData={updateFormData}
            />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

Prices.propTypes = {
  productId: PropTypes.string,
  pricesData: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      level: PropTypes.number.isRequired,
      price: PropTypes.string,
    }),
  ).isRequired,
  updateFormData: PropTypes.func.isRequired,
};

export default Prices;
