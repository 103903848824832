import { types } from "mobx-state-tree";
import gql from "graphql-tag";

export default types.model("Recipient", {
  id: types.identifier,
  email: types.maybeNull(types.string),
});

export const fragments = {
  fullDetails: gql`
    fragment UserRecipientDetails on User {
      id
      email
    }
    fragment SubscriberRecipientDetails on Subscriber {
      id
      email
    }
  `,
};
