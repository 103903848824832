import React from "react";
import { Link } from "react-router-dom";

import EditIcon from "@mui/icons-material/Edit";

import IconButton from "../../../../components/Button/IconButton";
import { hasNumberValidationError } from "../../../../components/Datagrid/utils/validation";

export const formatColumns = columns => {
  return columns.map(column => {
    const {
      field,
      headerName,
      type,
      minWidth,
      maxWidth,
      required,
      editable,
      renderCell,
    } = column;

    const defaultProps = {
      field,
      headerName,
      type,
      editable: editable !== undefined ? editable : true,
      minWidth: minWidth || 75,
      maxWidth: maxWidth || 1000,
    };

    switch (type) {
      case "number":
        return {
          ...defaultProps,
          preProcessEditCellProps: params => {
            const { props } = params;
            const { value } = props;

            return {
              ...props,
              error:
                hasNumberValidationError(value, true) || (required && !value),
            };
          },
        };
      default:
        return {
          ...defaultProps,
          preProcessEditCellProps: params => {
            const { props } = params;
            const { value } = props;

            return { ...props, error: required && !value.length > 0 };
          },
          ...(renderCell && { renderCell }),
        };
    }
  });
};

export const columns = [
  {
    field: "tillId",
    headerName: "Till No.",
    type: "number",
    required: true,
  },
  {
    field: "tillName",
    headerName: "Name",
    type: "text",
    required: true,
  },
  {
    field: "canBeMaster",
    headerName: "Can Be Master",
    type: "boolean",
    minWidth: 150,
  },
  {
    field: "apiServer",
    headerName: "API Server",
    type: "boolean",
  },
  {
    field: "apiTill",
    headerName: "API Till",
    type: "boolean",
  },
  {
    field: "takeOut",
    headerName: "Takeout",
    type: "boolean",
  },
  {
    field: "pdqTerminal",
    headerName: "PDQ",
    type: "boolean",
  },
  {
    field: "rabbitMq",
    headerName: "RabbitMQ",
    type: "boolean",
  },
  {
    field: "logonLength",
    headerName: "Logon Length",
    type: "number",
    required: true,
    minWidth: 150,
  },
  {
    field: "machineId",
    headerName: "Machine ID",
    type: "text",
  },
  {
    field: "licence",
    headerName: "Licence",
    type: "text",
  },
  {
    field: "days",
    headerName: "Days",
    type: "number",
  },
  {
    field: "oposPort",
    headerName: "Drawer",
    type: "text",
  },
  {
    field: "scannerName",
    headerName: "Scanner",
    type: "text",
  },
  {
    field: "id",
    headerName: "Config",
    type: "custom",
    editable: false,
    maxWidth: 75,
    renderCell: params => {
      const { row, value } = params;

      // Only show the edit icon if the row exists in the database
      return (
        !row.createdRow && (
          <Link to={`/app/tills/${value}`}>
            <IconButton
              icon={<EditIcon data-testid="edit-till-config-button" />}
            />
          </Link>
        )
      );
    },
  },
];
