import { types, getParent } from "mobx-state-tree";
import gql from "graphql-tag";

import User, { fragments as UserFragments } from "./User";

export const fragments = {
  fullDetails: gql`
    fragment SessionFullDetails on Session {
      id
      user {
        ...UserFullDetails
      }
      device
      scopes
    }
    ${UserFragments.fullDetails}
  `,
};

export default types
  .model("Session", {
    id: types.identifier,
    user: types.reference(User),
    device: types.string,
    scopes: types.maybeNull(types.string),
  })
  .views(self => ({
    get sessionStore() {
      return getParent(getParent(self));
    },
    get appStore() {
      return getParent(self.sessionStore);
    },
  }));
