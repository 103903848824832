import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Chip from "@mui/material/Chip";
import Autocomplete from "@mui/material/Autocomplete";

import TableRowComponent from "./TableRow";

const MultiTextInputComponent = ({
  error = null,
  name,
  label,
  values,
  updateCallback,
  testId,
  tooltip = "",
}) => {
  const [hasChanged, setHasChanged] = useState(false);
  const [chipValues, setChipValues] = useState(values);
  const [inputValue, setInputValue] = useState("");

  useEffect(() => {
    // If the last value is the current input value, we don't treat it as a chip
    // this is so when saving, we can send the current input value as well as the chip values
    if (inputValue !== "" && values[values.length - 1] === inputValue) {
      const newChips = values.slice(0, values.length - 1);
      setChipValues(newChips);
    } else {
      setChipValues(values);
      setInputValue("");
    }
  }, [values]);

  const formatCallback = (overrideValues = null, changesMade = false) => {
    // This prevents the callback being sent when first rendered
    // which would display the dialog saying changes have been made on switching tabs
    if (!changesMade && !overrideValues) {
      return;
    }

    const currentValues = overrideValues ?? chipValues;

    const callbackArgs = inputValue
      ? [...currentValues, inputValue]
      : currentValues;

    updateCallback(name, callbackArgs);
  };

  useEffect(() => {
    const changesMade = inputValue || hasChanged;

    if (changesMade && !hasChanged) {
      setHasChanged(true);
    }

    formatCallback(null, changesMade);
  }, [inputValue]);

  return (
    <TableRowComponent name={label} tooltip={tooltip} tooltipTestId={testId}>
      <Autocomplete
        clearIcon={false}
        options={[]}
        freeSolo
        multiple
        value={chipValues}
        onChange={({ type }, newValue) => {
          // On clicking delete chip icon
          if (type === "click") {
            formatCallback(newValue);
          }
        }}
        renderTags={(value, getTagProps) =>
          value.map((option, index) => {
            const tagProps = getTagProps({ index });
            const { key, ...otherProps } = tagProps;

            return (
              <Chip
                key={`${key}-${option}`}
                size="small"
                label={option}
                {...otherProps}
              />
            );
          })
        }
        renderInput={params => {
          const onChange = event => {
            const newValue = event.target.value;
            setInputValue(newValue);
          };

          const { inputProps, ...otherParams } = params;
          const updatedInputProps = {
            ...inputProps,
            value: inputValue,
            onChange,
            "data-testid": testId,
          };

          return (
            <TextField
              {...otherParams}
              variant="standard"
              onKeyDown={e => {
                // On Enter key press, create the new chip
                if (e.code === "Enter") {
                  setChipValues(values);
                  setInputValue("");
                }
              }}
              inputProps={updatedInputProps}
            />
          );
        }}
      />
      {error && (
        <Typography
          data-testid={`${testId}-error`}
          sx={{ fontSize: 14, color: "error.main" }}
        >
          {error}
        </Typography>
      )}
    </TableRowComponent>
  );
};

MultiTextInputComponent.propTypes = {
  error: PropTypes.string,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  values: PropTypes.array.isRequired,
  updateCallback: PropTypes.func.isRequired,
  testId: PropTypes.string.isRequired,
  tooltip: PropTypes.string,
};

export default MultiTextInputComponent;
