import React, { useState } from "react";

import { observer, inject, PropTypes as MobXPropTypes } from "mobx-react";

import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";

import LoyaltyConnection from "../../../../components/LoyaltyConnection";

const LoyaltyConnectionComponent = ({ appStore }) => {
  const [loyaltyUrl, setLoyaltyUrl] = useState(
    appStore.settingsStore.loyaltyUrl || "",
  );

  const [loyaltyUsername, setLoyaltyUsername] = useState(
    appStore.settingsStore.loyaltyUsername || "",
  );

  const [loyaltyPassword, setLoyaltyPassword] = useState(
    appStore.settingsStore.loyaltyPassword || "",
  );

  return (
    <TableRow>
      <TableCell>
        <LoyaltyConnection
          allowSave
          loyaltyUrl={loyaltyUrl}
          loyaltyPassword={loyaltyPassword}
          loyaltyUsername={loyaltyUsername}
          setLoyaltyPassword={setLoyaltyPassword}
          setLoyaltyUrl={setLoyaltyUrl}
          setLoyaltyUsername={setLoyaltyUsername}
          setLoyaltyError={() => {}}
          setFormError={() => {}}
          siteId=""
        />
      </TableCell>
    </TableRow>
  );
};

LoyaltyConnectionComponent.propTypes = {
  appStore: MobXPropTypes.objectOrObservableObject.isRequired,
};

const Component = inject("appStore")(observer(LoyaltyConnectionComponent));

export default Component;
