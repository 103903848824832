import { types } from "mobx-state-tree";
import gql from "graphql-tag";
import { v4 } from "uuid";

import User, { fragments as userFragments } from "./User";
import DateTime from "./DateTime";

export const fragments = {
  fullDetails: gql`
    fragment ChangesetFullDetails on Changeset {
      id
      name
      description
      scheduledAt
      status
      errorReason
      createdAt
      createdBy {
        ...UserFullDetails
      }
      appliedAt
      appliedBy {
        ...UserFullDetails
      }
    }
    ${userFragments.fullDetails}
  `,
};

export default types.model("Changeset", {
  id: types.optional(types.identifier, v4()),
  name: types.maybeNull(types.string),
  description: types.maybeNull(types.string),
  scheduledAt: DateTime,
  status: types.enumeration("Status", ["PENDING", "APPLIED", "ERROR"]),
  errorReason: types.maybeNull(types.string),
  createdAt: DateTime,
  createdBy: types.reference(User),
  updatedAt: types.maybeNull(DateTime),
  updatedBy: types.maybeNull(types.reference(User)),
});
