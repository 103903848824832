import { types, getParent, getRoot } from "mobx-state-tree";
import gql from "graphql-tag";
import ModifierGroup from "./ModifierGroup";
import ProductTag from "./ProductTag";

export const fragments = {
  fullDetails: gql`
    fragment ProductDetails on Product {
      id
      name
      POSId
      price
      sites {
        id
        name
      }
      modifierGroup {
        id
      }
      tags {
        id
      }
      tillProductName
    }
  `,
  productsForSearch: gql`
    fragment SearchProducts on Product {
      id
      name
      POSId
      tillProductName
      tags {
        id
      }
    }
  `,
};

export default types
  .model("Product", {
    id: types.identifier,
    name: types.string,
    POSId: types.string,
    price: types.maybeNull(types.number),
    sites: types.maybeNull(
      types.array(
        types.model({
          id: types.identifier,
          name: types.string,
        }),
      ),
    ),
    modifierGroup: types.maybeNull(
      types.reference(ModifierGroup, {
        get(id, parent) {
          return getRoot(parent).modifierGroupStore.getOrFetch(id);
        },
        set(modifierGroup) {
          return modifierGroup.id;
        },
      }),
    ),
    tags: types.maybeNull(
      types.array(
        types.reference(ProductTag, {
          get(id, parent) {
            return getRoot(parent).productTagStore.getOrFetch(id);
          },
          set(productTag) {
            return productTag.id;
          },
        }),
      ),
    ),
    tillProductName: types.string,
  })
  .views(self => ({
    get parent() {
      return getParent(self);
    },
    asPromoRow() {
      return {
        id: self.id,
        tillProductName: self.tillProductName,
        name: self.name,
        POSId: self.POSId,
        productGroup:
          self.tags?.find?.(tag => tag?.type === "PRODUCTGROUP")?.name ?? "",
        type: "PRODUCT",
      };
    },
  }))
  .actions(self => {
    return {
      setName: value => {
        self.name = value;
        return self;
      },

      setPOSId: value => {
        self.POSId = value;
        return self;
      },

      setPrice: value => {
        self.price = value;
        return self;
      },
    };
  });
