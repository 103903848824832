import { Grid, TextField } from "@mui/material";
import PropTypes from "prop-types";
import React from "react";

function JustEatSettings({
  handleJustEatDiscountIdChange,
  handleJustEatSiteIdChange,
  justEatDiscountId = "",
  justEatDiscountIdError = null,
  justEatSiteId = "",
  justEatSiteIdError = null,
}) {
  return (
    <>
      <Grid item md={6} xs={12}>
        <TextField
          variant="standard"
          inputProps={{ "data-testid": "just-eat-discount-id" }}
          id="justEatDiscountId"
          fullWidth
          label="Just Eat Discount Id"
          value={justEatDiscountId ?? ""}
          error={!!justEatDiscountIdError}
          helperText={justEatDiscountIdError}
          onChange={handleJustEatDiscountIdChange}
        />
      </Grid>
      <Grid item md={6} xs={12}>
        <TextField
          variant="standard"
          fullWidth
          label="Just Eat Site Id"
          value={justEatSiteId}
          error={!!justEatSiteIdError}
          helperText={justEatSiteIdError}
          id="justEatSiteId"
          onChange={handleJustEatSiteIdChange}
        />
      </Grid>
    </>
  );
}

JustEatSettings.propTypes = {
  handleJustEatDiscountIdChange: PropTypes.func.isRequired,
  handleJustEatSiteIdChange: PropTypes.func.isRequired,
  justEatDiscountId: PropTypes.string,
  justEatDiscountIdError: PropTypes.string,
  justEatSiteId: PropTypes.string,
  justEatSiteIdError: PropTypes.string,
};

export default JustEatSettings;
