import React from "react";
import PropTypes from "prop-types";
import { CircularProgress } from "@mui/material";

const loadingWrapperStyle = {
  position: "fixed",
  textAlign: "center",
  // halfway minus the height of the loader
  marginTop: "calc(50vh - 40px)",
  width: "100%",
  height: "100%",
};

// to my knowledge the previous block ui didnt actually stop user input
// if required im sure that could be added here
const loadingRenderChildrenWrapper = { opacity: "0.6" };

const BlockUi = ({
  classes = "",
  blocking = false,
  renderChildren = true,
  children = <></>,
}) => {
  return (
    <>
      {blocking ? (
        <div style={loadingWrapperStyle}>
          <CircularProgress data-testid="block-ui-loader" />
        </div>
      ) : (
        <></>
      )}
      {renderChildren || !blocking ? (
        <div
          className={classes}
          style={blocking ? loadingRenderChildrenWrapper : {}}
          data-testid="block-ui-body"
        >
          {children}
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

BlockUi.propTypes = {
  classes: PropTypes.string,
  blocking: PropTypes.bool,
  renderChildren: PropTypes.bool,
  children: PropTypes.element,
};

export default BlockUi;
