import { v4 } from "uuid";

const stateStructure = {
  sites: {},
  days: {
    1: false,
    2: false,
    3: false,
    4: false,
    5: false,
    6: false,
    7: false,
  },
  // This differs from the backend JSON in that there it's called products.
  // Also, the items in here (the buckets) have properties named 'products' that we will call 'items'
  // in here because that better reflects what they are.
  buckets: {
    default: {
      items: {},
      name: "Default Bucket",
      quantity: 1,
    },
    staging: {
      items: {},
      name: "Staging Bucket",
    },
    exclusions: {
      items: {},
      name: "Exclusions",
    },
  },
  loyaltyProfiles: {},
  // This differs from the backend JSON in that there it's called productExclusions.  It's renamed here
  // because it's not only for products.
  exclusions: {},
};
export const setInitialRules = rulesJSON => {
  if (!rulesJSON) {
    return stateStructure;
  }

  // Build from the back end structure into the new data structure.
  // This is temporary until we've refactored the backend.
  return {
    ...stateStructure,
    ...Object.entries(JSON.parse(rulesJSON)).reduce((acc, [key, value]) => {
      if (key === "productExclusions") {
        stateStructure.buckets.exclusions.items = value;
      } else if (key === "products") {
        acc.buckets = Object.entries(value).reduce(
          (buckets, [bucketKey, bucketValue]) => {
            return {
              ...stateStructure.buckets,
              ...buckets,
              [bucketKey]: {
                ...Object.entries(bucketValue).reduce(
                  (
                    newBucketContents,
                    [bucketContentsKey, bucketContentsValues],
                  ) => {
                    if (bucketContentsKey === "products") {
                      return {
                        ...newBucketContents,
                        items: bucketContentsValues,
                      };
                    }

                    return {
                      ...newBucketContents,
                      [bucketContentsKey]: bucketContentsValues,
                    };
                  },
                  {},
                ),
              },
            };
          },
          {},
        );
      } else {
        acc[key] = value;
      }

      return acc;
    }, {}),
  };
};

export const rulesReducer = (state, action) => {
  switch (action.type) {
    /** State initialisation - not ideal but would need a big refactor to avoid */
    case "initialiseRules":
      if (state.initialised) {
        throw new Error("Cannot initialise rules more than once.");
      }

      return {
        ...action.payload.dbRules,
        buckets: {
          ...state.buckets,
          ...Object.entries(
            action.payload.dbRules.buckets ?? state.buckets,
          ).reduce((acc, [bucketName, bucket]) => {
            if (!bucket.quantity) {
              return { ...acc, [bucketName]: { ...bucket, quantity: 1 } };
            }
            return { ...acc, [bucketName]: bucket };
          }, {}),
        },
        initialised: true,
      };

    /** Date stuff */

    // Enable, or disable a whole day
    // Payload: { day, checked }
    case "updateFullDay":
      return {
        ...state,
        days: {
          ...state.days,
          [action.payload.day]: action.payload.checked
            ? {
                intervals: [],
              }
            : false,
        },
      };

    // Enable all disabled days, or disable all enabled days that don't have intervals
    case "applyToAllDays":
      // If any days have intervals then leave them,
      // if not then enable them if action.payload.enable is true
      // If action.payload.enable is false then disable only
      // those that don't have intervals on
      return {
        ...state,
        days: Object.entries(state.days).reduce(
          (acc, [key, currentDayInState]) => {
            // If this day has intervals we're leaving it alone
            // whatever happens
            if (currentDayInState.intervals?.length) {
              return { ...acc, [key]: currentDayInState };
            }
            // Enable the day by adding an empty intervals array if
            // we've been asked to
            if (action.payload.enable) {
              return {
                ...acc,
                [key]: action.payload.enable ? { intervals: [] } : {},
              };
            }

            // If we're not enabling we're removing, so don't return this day
            return { ...acc, [key]: false };
          },
          {},
        ),
      };

    // Add an interval to a day
    // Payload: { day, startTime, endTime }
    case "addTimeInterval":
      return {
        ...state,
        days: {
          ...state.days,
          [action.payload.day]: {
            intervals: [
              ...(state.days[action.payload.day]?.intervals ?? []),
              {
                start: action.payload.startTime,
                end: action.payload.endTime,
              },
            ],
          },
        },
      };

    // Remove an interval from a day
    // Payload: { day, index }
    case "removeTimeInterval":
      return {
        ...state,
        days: {
          ...state.days,
          [action.payload.day]: {
            intervals: state.days[action.payload.day].intervals.filter(
              (value, index) => index !== action.payload.index,
            ),
          },
        },
      };

    /** Conditional stuff related to specific promotions */
    // Set the minimum order amount
    // Payload: { minimumOrderAmount }
    case "setMinimumOrderAmount":
      return {
        ...state,
        minimumOrderAmount: action.payload.minimumOrderAmount,
      };

    // Remove the minimum order amount
    // Payload: none
    case "removeMinimumOrderAmount":
      return Object.entries(state).reduce((acc, [key, value]) => {
        if (key !== "minimumOrderAmount") {
          acc[key] = value;
        }
        return acc;
      }, {});

    /** Bucket stuff */
    // Remove a bucket
    // Payload: { bucket } - the id of the bucket to remove
    case "removeBucket":
      return {
        ...state,
        buckets: {
          ...Object.entries(state.buckets).reduce((acc, [key, value]) => {
            if (key !== action.payload.bucket) {
              acc[key] = value;
            }
            return acc;
          }, {}),
        },
      };

    // Add a bucket
    // Payload: none
    case "addBucket":
      return {
        ...state,
        buckets: {
          ...state.buckets,
          [action.payload.name ?? v4()]: {
            quantity: 1,
            name: action.payload.name ?? "",
            items: {},
          },
        },
      };

    // Change a field in a bucket
    // payload: { id, field, value }
    case "changeBucketField":
      return {
        ...state,
        buckets: {
          ...state.buckets,
          [action.payload.id]: {
            ...state.buckets[action.payload.id],
            [action.payload.field]: action.payload.value,
          },
        },
      };

    // Move an item from the source bucket to the destaination bucket
    // Payload: { id, source, destination }
    case "moveItem":
      return {
        ...state,
        buckets: {
          ...state.buckets,
          [action.payload.destination]: {
            ...state.buckets[action.payload.destination],
            items: {
              ...state.buckets[action.payload.destination].items,
              [action.payload.id]:
                state.buckets[action.payload.source].items[action.payload.id],
            },
          },
          [action.payload.source]: {
            ...state.buckets[action.payload.source],
            items: Object.entries(
              state.buckets[action.payload.source].items,
            ).reduce((acc, [key, value]) => {
              if (key !== action.payload.id) {
                acc[key] = value;
              }
              return acc;
            }, {}),
          },
        },
      };

    // Remove an item from a bucket
    // Payload: { bucket, item }
    case "removeItem":
      return {
        ...state,
        buckets: {
          ...state.buckets,
          [action.payload.bucket]: {
            ...state.buckets[action.payload.bucket],
            items: Object.entries(
              state.buckets[action.payload.bucket].items,
            ).reduce((acc, [key, value]) => {
              if (key !== action.payload.item.id) {
                acc[key] = value;
              }
              return acc;
            }, {}),
          },
        },
      };

    // Add an item to a bucket
    // Payload: { bucket, item }
    case "addItem":
      return {
        ...state,
        buckets: {
          ...state.buckets,
          [action.payload.bucket]: {
            ...state.buckets[action.payload.bucket],
            items: {
              ...state.buckets[action.payload.bucket].items,
              [action.payload.item.id]: action.payload.item,
            },
          },
        },
      };

    // sets the status of allowing any product to trigger a promotion
    // Payload: { status: Boolean }
    case "setAllowAnyProduct":
      if (action.payload.status) {
        return {
          ...state,
          allowAnyProduct: true,
        };
      }

      return Object.entries(state).reduce((acc, [key, value]) => {
        if (key !== "allowAnyProduct") {
          acc[key] = value;
        }
        return acc;
      }, {});

    /** sites */
    // Set all sites in one go
    // Payload: { sites, checked }
    case "setSites":
      return {
        ...state,
        sites: action.payload.sites,
      };

    /** loyalty  */
    // These loyalty ones are guesswork really, never seen them working and no docs!
    // Set a loyalty profile
    // Payload: { allLoyaltyProfiles, siteLoyaltyProfiles, checked }
    case "updateLoyaltyProfiles":
      return {
        ...state,
        loyaltyProfiles: {
          ...state.loyaltyProfiles,
          [action.payload.id]: action.payload.checked,
        },
      };

    // Set all loyalty profiles in one go
    // Payload: { allLoyaltyProfiles, siteLoyaltyProfiles, checked }
    case "setAllLoyaltyProfiles":
      return {
        ...state,
        loyaltyProfiles: [
          ...action.payload.allLoyaltyProfiles,
          ...action.payload.siteLoyaltyProfiles,
        ].reduce((acc, profile) => {
          acc[profile.id] = action.payload.checked;
          return acc;
        }, {}),
      };
    default:
      throw new Error(`Rules action ${action.type} unknown`);
  }
};

// We're using a new data structure in React for rules to clean things up
// the database and Promotions package are behind. This function will export
// the current data structure in a format that is compatible with the database
// and promotions expectations
export const buildForDb = state => {
  /**
   * Currently the DB has:
   * products is an object - in the UI we've moved to buckets (because that's what they are)
   * products contains value that represent buckets, which themselves are objects that have a products property again. This is items in the UI because again, they aren't only products
   * productExclusions - in the UI we've moved to exclusions (because they aren't only products)
   */
  return JSON.stringify({
    sites: state.sites,
    days: state.days,
    products: Object.entries(state.buckets).reduce((acc, [key, value]) => {
      // The staging bucket is for component use only
      if (["staging", "exclusions"].includes(key)) {
        return acc;
      }
      return {
        ...acc,
        [key]: Object.entries(value).reduce(
          (bucket, [bucketPropKey, bucketPropValue]) => {
            if (bucketPropKey === "items") {
              return {
                ...bucket,
                products: bucketPropValue,
              };
            }

            return {
              ...bucket,
              [bucketPropKey]: bucketPropValue,
            };
          },
          {},
        ),
      };
    }, {}),
    loyaltyProfiles: state.loyaltyProfiles,
    productExclusions: state.buckets.exclusions.items,
    ...(state.allowAnyProduct && {
      allowAnyProduct: state.allowAnyProduct,
    }),
    ...(state.minimumOrderAmount && {
      minimumOrderAmount: state.minimumOrderAmount,
    }),
  });
};

export const getAllItemsFromBuckets = (state, excluding = ["discountable"]) => {
  return Object.entries(state.buckets)
    .filter(([bucketName]) => !excluding.includes(bucketName))
    .reduce((acc, [, bucket]) => {
      return [...acc, ...Object.values(bucket.items)];
    }, []);
};
