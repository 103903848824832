import React, { useState, useEffect, useCallback, useMemo } from "react";
import PropTypes from "prop-types";
import gql from "graphql-tag";
import { useLazyQuery, useMutation } from "@apollo/client";
import { useParams } from "react-router-dom";
import { v4 } from "uuid";
import { styled } from "@mui/material/styles";
import { useSnackbar } from "notistack";
import { flushSync } from "react-dom";

import Box from "@mui/material/Box";
import {
  DataGridPro,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarDensitySelector,
  GridToolbarExport,
  GridRow,
  useGridApiRef,
  GRID_DETAIL_PANEL_TOGGLE_FIELD,
} from "@mui/x-data-grid-pro";

import PositiveAction from "../../../components/Button/AddAction";
import NegativeAction from "../../../components/Button/NegativeAction";
import ProductAtSitesPanel from "./ProductAtSitesPanel";

import {
  ADD_PRODUCT,
  GET_FLAT_PRODUCTS_WITH_COUNT,
  UPDATE_PRODUCT,
} from "../../../helpers/apollo/utils";

import { mapFieldsToInput, mapNullAssociatedFields } from "../utils/dataMapper";

import {
  priceFields,
  modifierFields,
  getColumns,
  statusColumns,
} from "../utils/columns";

export const getNewRow = () => {
  const id = v4();

  const newRow = {
    id,
    description: "",
    tillDescription: "",
    productCode: "",
    subGroup: "",
    productGroup: "",
    modifierOf: "",
    maxMod: null,
    vatCode: "",
    // We use this when updating a row to tell us this product needs to be created
    createdRow: true,
  };

  priceFields.forEach(price => {
    newRow[price] = null;
  });

  modifierFields.forEach(modifier => {
    newRow[modifier] = "";
  });

  statusColumns.forEach(({ field }) => {
    newRow[field] = false;
  });

  return newRow;
};

export const modifiersErrorMessage =
  "A product cannot be both a modifier and part of modifier groups";

const StyledBox = styled("div")(({ theme }) => ({
  height: "65vh",
  width: "100%",
  "& .Mui-error": {
    backgroundColor: `rgb(126,10,15, ${theme.palette.mode === "dark" ? 0 : 0.1})`,
    color: theme.palette.error.main,
  },
}));

const defaultOptionsArray = [];

const slotProps = {
  loadingOverlay: {
    variant: "linear-progress",
    noRowsVariant: "linear-progress",
  },
};

const dataGridStyling = {
  "& .MuiDataGrid-detailPanel": {
    overflow: "visible",
  },
  "& .MuiDataGrid-detailPanelToggleCell": {
    width: "30px",
  },
  "& .MuiDataGrid-row--detailPanelExpanded": {
    backgroundColor: "#f8f8f8",
  },
  "& .MuiDataGrid-cell--pinnedLeft": {
    backgroundColor: "#f8f8f8",
  },
};

const startingRowLimit = 50;

const datagridInitialState = {
  pagination: {
    paginationModel: { pageSize: startingRowLimit, page: 0 },
  },
  pinnedColumns: {
    left: [GRID_DETAIL_PANEL_TOGGLE_FIELD, "description"],
  },
};

function BulkEditorTable({
  // productOptions will be required down the line
  // eslint-disable-next-line no-unused-vars
  productOptions = defaultOptionsArray,
  modifierGroups = defaultOptionsArray,
  // vatRates will be a dropdown in the future
  // eslint-disable-next-line no-unused-vars
  vatRates = defaultOptionsArray,
  subGroups = defaultOptionsArray,
  productGroups = defaultOptionsArray,
}) {
  const { siteId } = useParams();

  const hasSite = siteId !== "default" && siteId !== undefined;

  const { enqueueSnackbar } = useSnackbar();
  const apiRef = useGridApiRef();

  const [rows] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [createdRow, setCreatedRow] = useState(false);
  const [limit, setLimit] = useState(startingRowLimit);
  const [sort, setSort] = useState([]);
  const [totalRows, setTotalRows] = useState(0);
  const [detailPanelExpandedRowIds, setDetailPanelExpandedRowIds] = useState(
    [],
  );
  const [isLoading, setIsLoading] = useState(true);
  const [refetchAtSite, setRefetchAtSite] = useState(false);

  const columns = useMemo(
    () => getColumns(modifierGroups, subGroups, productGroups, hasSite),
    [modifierGroups, subGroups, productGroups, hasSite],
  );

  const [getProducts, { loading }] = useLazyQuery(
    gql(GET_FLAT_PRODUCTS_WITH_COUNT()),
    {
      fetchPolicy: "cache-and-network",
      onError: error =>
        enqueueSnackbar(`${error.message}`, {
          variant: "error",
          SnackbarProps: {
            "data-testid": "bulk-product-error-snackbar",
          },
        }),
    },
  );

  const autosizeColumns = async () => {
    return new Promise(resolve => {
      setTimeout(() => {
        resolve();
      }, 0);
    })
      .then(() =>
        apiRef?.current?.autosizeColumns({
          includeHeaders: true,
          includeOutliers: true,
        }),
      )
      .then(() => {
        // !! You MUST disable virtualization here after autosizing because that enables it.
        apiRef?.current?.unstable_setColumnVirtualization(false);
      });
  };

  const loadData = useCallback(
    ({ limit, offset, sort, siteId }) => {
      setIsLoading(true);
      setDetailPanelExpandedRowIds([]);
      getProducts({
        variables: {
          limit,
          offset,
          ...(sort.length > 0 && { sort }),
          siteId,
        },
      })
        .then(({ data }) => {
          if (data?.flatProducts?.flatProducts && !loading) {
            flushSync(() => {
              setTotalRows(data.flatProducts.totalRows);
            });

            flushSync(() => {
              apiRef?.current?.setRows(
                data.flatProducts.flatProducts.map(row =>
                  mapNullAssociatedFields(row),
                ),
              );
            });
          }
        })
        .then(() => {
          setIsLoading(false);
          autosizeColumns();
        });
    },
    [apiRef],
  );

  // Load the first page on first render and when siteId is changed
  useEffect(() => {
    loadData({ limit: limit, offset: currentPage * limit, sort, siteId });
  }, [siteId, loadData]);

  const [addProduct] = useMutation(gql(ADD_PRODUCT()), {
    onCompleted: () => {
      enqueueSnackbar("Your new product has been created", {
        SnackbarProps: { "data-testid": "bulk-product-added-snackbar" },
        variant: "success",
      });

      // Re-load the data after a new product has been added
      // This is because it needs to be re-indexed in the right position
      // e.g. if a sort/filter is enabled
      loadData({
        limit: limit,
        offset: currentPage * limit,
        sort: sort,
        siteId,
      });
    },
  });

  const [updateProduct] = useMutation(gql(UPDATE_PRODUCT()), {
    onCompleted: () => {
      enqueueSnackbar("Your changes have been saved", {
        SnackbarProps: { "data-testid": "bulk-product-saved-snackbar" },
        variant: "success",
      });

      // We only want to refetch the FlatProductAtSites query if the detail panel is open
      if (detailPanelExpandedRowIds.length) {
        setRefetchAtSite(prev => !prev);
      }

      // Autosize columns once the data has been updated
      // We require this 0 second timeout before resize,
      // otherwise this leads to a bug on firefox
      return new Promise(resolve => {
        setTimeout(() => {
          resolve();
        }, 0);
      }).then(() => autosizeColumns());
    },
  });

  useEffect(() => {
    // After the refetch has been completed, we reset it's value so it can be done multiple times
    if (refetchAtSite) {
      setRefetchAtSite(false);
    }
  }, [refetchAtSite]);

  const validateModifierGroups = row => {
    let hasModifierChanges = false;

    modifierFields.forEach(modifier => {
      if (row[modifier] !== "") {
        hasModifierChanges = true;
      }
    });

    if (hasModifierChanges && row.modifierOf !== "") {
      return false;
    }

    return true;
  };

  const updateRow = useCallback(
    (newRow, oldRow) => {
      return new Promise((resolve, reject) => {
        // Stop rows being updated where a product is part of a modifier group
        // as well as having modifier groups attached to it.
        if (!validateModifierGroups(newRow)) {
          reject(new Error(modifiersErrorMessage));
          return;
        }

        // Get any changed fields
        const rowUpdates = Object.fromEntries(
          Object.entries(newRow).filter(
            ([key, val]) => key in oldRow && oldRow[key] !== val,
          ),
        );

        if (Object.keys(rowUpdates).length) {
          // If a price level or modifier group change is included, we need to include
          // all price levels and modifier groups, as the mutation accepts an array of all values
          let hasModifierChanges = false;
          let hasPriceChanges = false;

          Object.keys(rowUpdates).forEach(key => {
            if (modifierFields.includes(key)) {
              hasModifierChanges = true;
            }

            if (priceFields.includes(key)) {
              hasPriceChanges = true;
            }
          });

          if (hasModifierChanges) {
            modifierFields.forEach(modifierField => {
              rowUpdates[modifierField] = newRow[modifierField];
            });
          }

          if (hasPriceChanges) {
            priceFields.forEach(priceField => {
              rowUpdates[priceField] = newRow[priceField];
            });
          }

          // Both sub and product group tags are currently required in the mutation, otherwise the missing one is lost
          if (
            rowUpdates.subGroup !== undefined ||
            rowUpdates.productGroup !== undefined
          ) {
            rowUpdates.subGroup = newRow.subGroup;
            rowUpdates.productGroup = newRow.productGroup;
          }

          if (newRow.createdRow !== undefined) {
            const fields = mapFieldsToInput(rowUpdates);

            if (fields.prices === undefined) {
              fields.prices = [];
            }

            addProduct({
              variables: {
                input: {
                  ...fields,
                },
              },
            })
              .then(() => {
                setCreatedRow(false);
                resolve(newRow);
              })
              .catch(error => {
                // The API returns an error, so we send this to the onProcessRowUpdateError handler
                // this stops the row from going off edit mode, showing the user there's a problem
                const errorMsg = error?.message || "Please try again later.";
                reject(new Error(errorMsg));
              });
          } else {
            let inputFields = {
              id: newRow.id,
              ...mapFieldsToInput(rowUpdates),
            };

            // This tells us the update request has come from the master detail panel
            if (newRow.productId && newRow.siteId) {
              inputFields.id = newRow.productId;
              inputFields.siteId = newRow.siteId;
            }
            // If the site is selected using the site selector, we include the site ID as usual
            else if (hasSite) {
              inputFields.siteId = siteId;
            }

            updateProduct({
              variables: {
                input: inputFields,
              },
            })
              .then(() => {
                resolve(newRow);
              })
              .catch(error => {
                const errorMsg = error?.message || "Please try again later.";
                reject(new Error(errorMsg));
              });
          }
        } else {
          // No updates to be made, resolve with row as exists
          resolve(newRow);
        }
      });
    },
    [validateModifierGroups, addProduct, updateProduct, hasSite, siteId],
  );

  const EditToolbar = useCallback(() => {
    const handleAddProduct = () => {
      const newRow = getNewRow();
      const currentData = Array.from(apiRef.current.getRowModels().values());
      apiRef?.current?.setRows([newRow, ...currentData]);
      setCreatedRow(true);
    };

    const handleRemoveProduct = () => {
      const currentData = Array.from(apiRef.current.getRowModels().values());
      const newTableData = currentData.filter(
        row => row.createdRow === undefined,
      );
      apiRef?.current?.setRows(newTableData);
      setCreatedRow(false);
    };

    return (
      <GridToolbarContainer sx={{ m: 1 }}>
        <GridToolbarColumnsButton />
        <GridToolbarDensitySelector
          slotProps={{ tooltip: { title: "Change density" } }}
        />
        <GridToolbarExport printOptions={{ disableToolbarButton: true }} />
        <Box sx={{ flexGrow: 1 }} />
        <Box>
          <Box sx={{ mr: 1, display: "inline" }}>
            <NegativeAction
              buttonText="Remove Product"
              disabled={!createdRow}
              onClick={handleRemoveProduct}
              testId="delete-product-button"
            />
          </Box>
          <PositiveAction
            buttonText="Add Product"
            disabled={createdRow || hasSite}
            onClick={handleAddProduct}
            testId="add-product-button"
          />
        </Box>
      </GridToolbarContainer>
    );
  }, [createdRow, hasSite, apiRef]);

  const CustomGridRow = props => {
    // eslint-disable-next-line react/prop-types
    const { index } = props;
    return <GridRow {...props} data-testid={`row-${index}`} />;
  };

  // We disable the detail panel if a site is selected, or the product doesn't exist yet
  const renderDetailPanel = useCallback(
    ({ row }) =>
      row.createdRow || hasSite ? null : (
        <ProductAtSitesPanel
          columns={columns}
          productId={row.id}
          rowUpdate={updateRow}
          triggerRefetch={refetchAtSite}
        />
      ),
    [columns, updateRow, refetchAtSite, hasSite],
  );

  const getDetailPanelHeight = useCallback(() => "auto", []);

  const handleDetailPanelExpandedRowIdsChange = useCallback(newIds => {
    setDetailPanelExpandedRowIds(
      newIds.length > 1 ? [newIds[newIds.length - 1]] : newIds,
    );
  }, []);

  const handlePaginationChange = useCallback(
    ({ page, pageSize }) => {
      setCurrentPage(page);
      setLimit(pageSize);
      loadData({ limit: pageSize, offset: page * pageSize, sort, siteId });
    },
    [sort, siteId, loadData],
  );

  const handleSortChange = useCallback(
    sortModel => {
      const formattedSort = sortModel.map(({ field, sort }) => ({
        field,
        direction: sort.toUpperCase(),
      }));

      loadData({
        limit,
        offset: currentPage * limit,
        sort: formattedSort,
        siteId,
      });

      setSort(formattedSort);
    },
    [currentPage, limit, loadData, siteId],
  );

  const onProcessRowUpdateError = ({ message }) => {
    enqueueSnackbar(`${message}`, {
      variant: "error",
      SnackbarProps: {
        "data-testid": "bulk-product-update-error-snackbar",
      },
    });
  };

  return (
    <StyledBox>
      <DataGridPro
        /* This is disabled for now because Firefox (and poss others) are very snaggy when scrolling with expand rows*/
        disableVirtualization
        disableColumnFilter
        disableAutosize
        apiRef={apiRef}
        pagination
        editMode="row"
        columns={columns}
        loading={isLoading || loading}
        rowCount={totalRows}
        rows={rows}
        rowHeight={35}
        detailPanelExpandedRowIds={detailPanelExpandedRowIds}
        onDetailPanelExpandedRowIdsChange={
          handleDetailPanelExpandedRowIdsChange
        }
        getDetailPanelHeight={getDetailPanelHeight}
        getDetailPanelContent={renderDetailPanel}
        initialState={datagridInitialState}
        onPaginationModelChange={handlePaginationChange}
        onProcessRowUpdateError={onProcessRowUpdateError}
        processRowUpdate={updateRow}
        paginationMode="server"
        slots={{
          toolbar: EditToolbar,
          row: CustomGridRow,
        }}
        slotProps={slotProps}
        sortingMode="server"
        onSortModelChange={handleSortChange}
        sx={dataGridStyling}
      />
    </StyledBox>
  );
}

const dropdownListValidation = PropTypes.arrayOf(
  PropTypes.shape({
    text: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
  }),
);

BulkEditorTable.propTypes = {
  productOptions: dropdownListValidation,
  modifierGroups: dropdownListValidation,
  vatRates: dropdownListValidation,
  subGroups: dropdownListValidation,
  productGroups: dropdownListValidation,
};

export default BulkEditorTable;
