import validator from "validator";

const errorMessages = {
  genericEmpty: "This field cannot be empty.",
  invalidPOSId: "POSId must be at least 5 numbers long.",
};

export const checkForInvalidIntegerInput = fieldToCheck => {
  const fieldAsString = fieldToCheck.toString();
  return (
    fieldAsString.includes(".") ||
    (!validator.isNumeric(fieldAsString) && fieldAsString !== "")
  );
};

export const checkForInvalidFloatInput = fieldToCheck => {
  const fieldAsString = fieldToCheck.toString();
  return (
    fieldAsString === "." ||
    (!validator.isNumeric(fieldAsString) &&
      fieldAsString !== "" &&
      (fieldAsString.slice(-1) !== "." || fieldAsString.split(".").length > 2))
  );
};

export const checkRequiredFields = formData => {
  const requiredFields = ["name", "POSId"];

  return requiredFields.reduce(
    (acc, fieldName) => ({
      ...acc,
      [`${fieldName}Empty`]:
        !formData[fieldName] && formData[fieldName] !== 0
          ? errorMessages.genericEmpty
          : false,
    }),
    {},
  );
};

export const checkForInvalidPOSId = POSIdToCheck =>
  POSIdToCheck.length < 5 ? errorMessages.invalidPOSId : false;
