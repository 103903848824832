import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import PropTypes from "prop-types";
import NegativeAction from "../Button/NegativeAction";
import PositiveAction from "../Button/PositiveAction";

export default function AlertDialog({
  backButtonText = "Cancel",
  children,
  notifyClosure,
  okButtonText = "OK",
  present,
  setPresence,
  testId = "alert-dialog",
  title,
}) {
  const handleClose = proceed => {
    setPresence(false);
    notifyClosure(proceed);
  };

  return (
    <Dialog
      open={present}
      onClose={handleClose}
      data-testid={`${testId}-alert-dialog`}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
      <DialogContent>{children}</DialogContent>
      <DialogActions>
        <NegativeAction
          buttonText={backButtonText}
          onClick={() => handleClose(false)}
          testId={`${testId}-alert-dialog-back-button`}
        />
        <PositiveAction
          buttonText={okButtonText}
          onClick={() => handleClose(true)}
          testId={`${testId}-alert-dialog-ok-button`}
        />
      </DialogActions>
    </Dialog>
  );
}

AlertDialog.propTypes = {
  backButtonText: PropTypes.string,
  children: PropTypes.node.isRequired,
  notifyClosure: PropTypes.func.isRequired,
  okButtonText: PropTypes.string,
  present: PropTypes.bool.isRequired,
  setPresence: PropTypes.func.isRequired,
  testId: PropTypes.string,
  title: PropTypes.string.isRequired,
};
