import React from "react";
import { inject } from "mobx-react";

import { makeStyles } from "@mui/styles";

import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import Container from "@mui/material/Container";

import ErrorBoundary from "../../components/ErrorBoundary";
import PasswordChange from "../../components/PasswordChange";
import Page from "../../components/Page";

const useStyles = makeStyles({
  container: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
  },
});

const ChangePassword = () => {
  const classes = useStyles();
  return (
    <ErrorBoundary>
      <Page title="Change Password">
        <Container maxWidth={false}>
          <Box mt={3}>
            <Card>
              <div className={classes.container}>
                <PasswordChange />
              </div>
            </Card>
          </Box>
        </Container>
      </Page>
    </ErrorBoundary>
  );
};

export default inject("appStore")(ChangePassword);
