import React from "react";
import PropTypes from "prop-types";
import moment from "moment";

import BaseTable from "../../../components/Basetable/Basetable";

const defaultColumns = [
  { label: "Name", name: "name" },
  { label: "Scheduled at", name: "scheduledAt", filter: false },
  { label: "Status", name: "status", filter: true },
  { label: "Created by", name: "createdBy" },
  { label: "Error Reason", name: "errorReason" },
];
const defaultColumnSort = [
  {
    field: "scheduledAt",
    direction: "DESC",
  },
];

const defaultFilterObject = {};

const defaultMapper = item => ({
  ...item,
  scheduledAt: moment(item.scheduledAt).format("Do MMM YYYY, HH:mm"),
  createdBy: item.createdBy.email,
});

const defaultQuery = `query Changesets(
  $limit: Int
  $offset: Int
  $sort: [SortOptionInput]
) {
  changesetsWithCount(limit: $limit, offset: $offset, sort: $sort ) {
    changesets {
      id
      name
      description
      status
      scheduledAt
      createdBy {
        firstName
        lastName
        email
      }
    }
    totalRows
  }
}`;

const defaultRowsPerPageOptions = [10, 25, 50, 100];

const defaultSortFieldMapper = name => name;

function ChangesetsTable({
  columns = defaultColumns,
  defaultSort = defaultColumnSort,
  defaultFilter = defaultFilterObject,
  handleRowClick = () => {},
  mapper = defaultMapper,
  rowsPerPageOptions = defaultRowsPerPageOptions,
  searchBy = null,
  sortFieldMapper = defaultSortFieldMapper,
  query = defaultQuery,
  viewColumns = true,
}) {
  return (
    <BaseTable
      columns={columns}
      defaultSort={defaultSort}
      defaultFilter={defaultFilter}
      getTableData={d => {
        return d.changesetsWithCount.changesets;
      }}
      getRowCount={d => {
        return d.changesetsWithCount.totalRows;
      }}
      handleRowClick={handleRowClick}
      mapper={mapper}
      rowsPerPageOptions={rowsPerPageOptions}
      searchBy={searchBy}
      query={query}
      sortFieldMapper={sortFieldMapper}
      viewColumns={viewColumns}
    />
  );
}

ChangesetsTable.propTypes = {
  columns: PropTypes.arrayOf(
    PropTypes.shape({ label: PropTypes.string, name: PropTypes.string }),
  ),
  defaultSort: PropTypes.arrayOf(
    PropTypes.shape({
      field: PropTypes.string,
      direction: PropTypes.oneOf(["ASC", "DESC"]),
    }),
  ),
  defaultFilter: PropTypes.shape({ filter: PropTypes.string }),
  handleRowClick: PropTypes.func,
  mapper: PropTypes.func,
  rowsPerPageOptions: PropTypes.arrayOf(PropTypes.number),
  searchBy: PropTypes.string,
  sortFieldMapper: PropTypes.func,
  query: PropTypes.string,
  viewColumns: PropTypes.bool,
};

export default ChangesetsTable;
