import React, { useEffect, useState, useCallback } from "react";
import PropTypes from "prop-types";
import { observer, inject, PropTypes as MobXPropTypes } from "mobx-react";

import validator from "validator";
import {
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  Input,
  InputAdornment,
  InputLabel,
  TextField,
  Box,
} from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import Button from "@mui/material/Button";

import PositiveAction from "./Button/PositiveAction";

const LoyaltyConnection = ({
  appStore,
  allowSave = false,
  loyaltyUrl,
  loyaltyUsername,
  loyaltyPassword,
  setLoyaltyError,
  setLoyaltyUrl,
  setLoyaltyUsername,
  setLoyaltyPassword,
  setFormError,
  siteId = "",
}) => {
  const [loyaltyUrlError, setLoyaltyUrlError] = useState("");
  const [loyaltyUsernameError, setLoyaltyUsernameError] = useState("");
  const [loyaltyPasswordError, setLoyaltyPasswordError] = useState("");

  const [testingLoyaltyConnectionActive, setTestingLoyaltyConnectionActive] =
    useState(false);

  const [testLoyaltyConnectionResult, setTestLoyaltyConnectionResult] =
    useState(null);

  const [showLoyaltyPassword, setShowLoyaltyPassword] = useState(false);

  const handleLoyaltyUrlChange = event => {
    setLoyaltyUrl(event.target.value);
    setLoyaltyUrlError("");
    setFormError("");
    setTestingLoyaltyConnectionActive(false);
  };

  const handleLoyaltyUsernameChange = event => {
    setLoyaltyUsername(event.target.value);
    setLoyaltyUsernameError("");
    setFormError("");
    setTestingLoyaltyConnectionActive(false);
  };

  const handleLoyaltyPasswordChange = event => {
    setLoyaltyPassword(event.target.value);
    setLoyaltyPasswordError("");
    setFormError("");
    setTestingLoyaltyConnectionActive(false);
  };

  const handleTestLoyaltyConnectionResultClose = () => {
    setTestLoyaltyConnectionResult(null);
  };

  const handleMouseDownPassword = event => {
    event.preventDefault();
  };

  const validateLoyaltySettings = useCallback(
    required => {
      let isValid = true;
      let hasLoyaltySettings = false;

      if (
        required === true ||
        !validator.isEmpty(loyaltyUrl) ||
        !validator.isEmpty(loyaltyUsername) ||
        !validator.isEmpty(loyaltyPassword)
      ) {
        hasLoyaltySettings = true;
      }

      if (hasLoyaltySettings && !validator.isURL(loyaltyUrl)) {
        isValid = false;
        setLoyaltyUrlError("Please provide a valid URL");
      }

      if (hasLoyaltySettings && validator.isEmpty(loyaltyUsername)) {
        isValid = false;
        setLoyaltyUsernameError("Please provide a username");
      }

      if (hasLoyaltySettings && validator.isEmpty(loyaltyPassword)) {
        isValid = false;
        setLoyaltyPasswordError("Please provide a password");
      }

      setLoyaltyError(!isValid);
      return isValid;
    },
    [loyaltyUrl, loyaltyUsername, loyaltyPassword, setLoyaltyError],
  );

  useEffect(() => {
    validateLoyaltySettings(false);
  }, [validateLoyaltySettings]);

  const handleLoyaltyConnectionTest = () => {
    if (validateLoyaltySettings(true)) {
      setTestingLoyaltyConnectionActive(true);

      appStore.settingsStore
        .testLoyaltyConnection({
          loyaltyUrl,
          loyaltyUsername,
          loyaltyPassword,
          siteId,
        })
        .then(result => {
          setTestLoyaltyConnectionResult(
            result.success
              ? { result: "success", message: "Connected successfully" }
              : { result: "error", message: result.message },
          );
          setTestingLoyaltyConnectionActive(false);
        })
        .catch(error => {
          appStore.log.error(error);
          setTestLoyaltyConnectionResult({
            result: "error",
            message: error.message,
          });
          setTestingLoyaltyConnectionActive(false);
        });
    }
  };

  const saveSettings = () => {
    appStore.setLoading();

    return new Promise((resolve, reject) => {
      appStore.settingsStore
        .save()
        .then(() => {
          appStore.setLoading(false);
          resolve(true);
        })
        .catch(error => {
          appStore.log.error(error);
          appStore.setLoading(false);
          reject(error);
        });
    });
  };

  const handleSubmit = event => {
    event.preventDefault();
    if (!loyaltyUrlError && !loyaltyUsernameError && !loyaltyPasswordError) {
      appStore.settingsStore
        .setLoyaltyUrl(loyaltyUrl)
        .setLoyaltyUsername(loyaltyUsername)
        .setLoyaltyPassword(loyaltyPassword);
      saveSettings().catch(error => {
        appStore.log.error(error);
        appStore.setLoading(false);
      });
    }
  };

  return (
    <Card>
      <CardHeader title="Loyalty connection settings" />
      <CardContent>
        <Grid container spacing={3}>
          {testLoyaltyConnectionResult && (
            <>
              <Grid item xs={3}>
                <MuiAlert
                  elevation={6}
                  variant="filled"
                  severity={testLoyaltyConnectionResult.result}
                  onClose={handleTestLoyaltyConnectionResultClose}
                >
                  {testLoyaltyConnectionResult.message}
                </MuiAlert>
              </Grid>
              <Grid item xs={12} />
            </>
          )}
          <Grid item md={6} xs={12}>
            <TextField
              variant="standard"
              fullWidth
              label="URL"
              value={loyaltyUrl}
              error={!!loyaltyUrlError}
              helperText={loyaltyUrlError}
              onChange={handleLoyaltyUrlChange}
            />
          </Grid>
          <Grid item xs={12} />
          <Grid item lg={3} md={6} xs={12}>
            <TextField
              variant="standard"
              fullWidth
              label="Username"
              value={loyaltyUsername}
              error={!!loyaltyUsernameError}
              helperText={loyaltyUsernameError}
              onChange={handleLoyaltyUsernameChange}
            />
          </Grid>
          <Grid item lg={4} md={8} xs={12}>
            <FormControl variant="standard" fullWidth>
              <InputLabel htmlFor="loyalty-password">Password</InputLabel>
              <Input
                id="loyalty-password"
                type={showLoyaltyPassword ? "text" : "password"}
                value={loyaltyPassword}
                onChange={handleLoyaltyPasswordChange}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() =>
                        setShowLoyaltyPassword(!showLoyaltyPassword)
                      }
                      onMouseDown={handleMouseDownPassword}
                      size="medium"
                    >
                      {showLoyaltyPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                }
              />
              {loyaltyPasswordError && (
                <FormHelperText error>{loyaltyPasswordError}</FormHelperText>
              )}
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            {testingLoyaltyConnectionActive ? (
              <CircularProgress size={35} />
            ) : (
              <>
                <Button
                  aria-label="test-connection-action-button"
                  color="secondary"
                  onClick={handleLoyaltyConnectionTest}
                  type="button"
                  variant="outlined"
                >
                  Test settings
                </Button>
                {allowSave && (
                  <Box ml={1} display="inline">
                    <PositiveAction buttonText="Save" onClick={handleSubmit} />
                  </Box>
                )}
              </>
            )}
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

LoyaltyConnection.propTypes = {
  appStore: MobXPropTypes.objectOrObservableObject.isRequired,
  allowSave: PropTypes.bool,
  loyaltyPassword: PropTypes.string.isRequired,
  loyaltyUrl: PropTypes.string.isRequired,
  loyaltyUsername: PropTypes.string.isRequired,
  setLoyaltyUsername: PropTypes.func.isRequired,
  setLoyaltyPassword: PropTypes.func.isRequired,
  setLoyaltyUrl: PropTypes.func.isRequired,
  setFormError: PropTypes.func.isRequired,
  setLoyaltyError: PropTypes.func.isRequired,
  siteId: PropTypes.string,
};

const Component = inject("appStore")(observer(LoyaltyConnection));

export default Component;
