import React from "react";
import { Link } from "react-router-dom";

import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

import Page from "../../components/Page";
import ErrorBoundary from "../../components/ErrorBoundary";
import AddAction from "../../components/Button/AddAction";
import ChangesetsTable from "./Components/ChangesetsTable";

const ListComponent = () => {
  return (
    <ErrorBoundary>
      <Page title="Product Categories">
        <Container maxWidth={false}>
          <Grid container rowSpacing={3}>
            <Grid container item xs={12} justifyContent="end" mt={3}>
              <Link to="/app/changesets/add">
                <AddAction buttonText="Add changeset" />
              </Link>
            </Grid>
            <Grid item xs={12}>
              <Card>
                <CardHeader title="Changesets" />
                <CardContent>
                  <ChangesetsTable />
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Container>
      </Page>
    </ErrorBoundary>
  );
};

export default ListComponent;
