import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { observer, inject, PropTypes as MobXPropTypes } from "mobx-react";
import { observable, action, makeObservable } from "mobx";
import { Link } from "react-router-dom";

import { withStyles } from "@mui/styles";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import Container from "@mui/material/Container";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import EditIcon from "@mui/icons-material/Edit";

import ErrorBoundary from "../../components/ErrorBoundary";
import AddAction from "../../components/Button/AddAction";

const styles = theme => ({
  margin: {
    margin: theme.spacing(1),
  },

  bolder: {
    fontWeight: 800,
  },
  inline: {
    display: "inline-block",
  },
});

class ListComponent extends React.Component {
  items = [];
  cancelReason = "";

  constructor(props) {
    super(props);

    makeObservable(this, {
      items: observable,
      setItems: action,
    });
  }

  componentDidMount() {
    const { appStore: app } = this.props;
    const that = this;
    app.organisationStore
      .findAll()
      .then(items => {
        that.setItems([...items.values()]);
        app.setLoading(false);
      })
      .catch(error => {
        app.log.error(error);
        app.setLoading(false);
      });
  }

  setItems = items => {
    this.items = items;
  };

  render() {
    const { classes } = this.props;
    return (
      <ErrorBoundary>
        <Container maxWidth={false}>
          <Grid container rowSpacing={3}>
            <Grid container item xs={12} justifyContent="end" mt={3}>
              <Link to="/app/organisations/add">
                <AddAction buttonText=" Add organisation" />
              </Link>
            </Grid>
            <Grid item xs={12}>
              <Card>
                <CardHeader title="Organisations" />
                <CardContent>
                  <Table size="small" className={classes.inline}>
                    <TableHead>
                      <TableRow>
                        <TableCell className={classes.bolder}>Name</TableCell>
                        <TableCell className={classes.bolder}>Type</TableCell>
                        <TableCell className={classes.bolder}>Code</TableCell>
                        <TableCell className={classes.bolder}>
                          Payment Type
                        </TableCell>
                        <TableCell className={classes.bolder}>
                          Default Booking Type
                        </TableCell>
                        <TableCell className={classes.bolder}>
                          Message Timeout
                        </TableCell>
                        <TableCell />
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {this.items.map(record => (
                        <Fragment key={record.id}>
                          <TableRow>
                            <TableCell>{record.name} </TableCell>
                            <TableCell>{record.type} </TableCell>
                            <TableCell>{record.code ?? "NOT SET"}</TableCell>
                            <TableCell>
                              {record.POSPaymentType?.name ?? "NOT SET"}
                            </TableCell>
                            <TableCell>
                              {record.defaultBookingType?.name ?? "NOT SET"}
                            </TableCell>
                            <TableCell>
                              {record.orderMessageTimeoutMinutes ?? "NOT SET"}
                            </TableCell>
                            <TableCell>
                              <Link to={`/app/organisations/${record.id}`}>
                                <IconButton size="medium">
                                  <EditIcon />
                                </IconButton>
                              </Link>
                            </TableCell>
                          </TableRow>
                        </Fragment>
                      ))}
                    </TableBody>
                  </Table>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Container>
      </ErrorBoundary>
    );
  }
}

ListComponent.propTypes = {
  appStore: MobXPropTypes.objectOrObservableObject.isRequired,
  classes: PropTypes.shape({
    inline: PropTypes.string,
    button: PropTypes.string,
    bolder: PropTypes.string,
  }).isRequired,
};

const List = withStyles(styles)(inject("appStore")(observer(ListComponent)));

export default List;
