import React from "react";

import Box from "@mui/material/Box";

import {
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarDensitySelector,
  GridToolbarExport,
} from "@mui/x-data-grid-pro";

const CustomToolbar = () => (
  <GridToolbarContainer sx={{ m: 1 }}>
    <GridToolbarColumnsButton />
    <GridToolbarFilterButton />
    <GridToolbarDensitySelector
      slotProps={{ tooltip: { title: "Change density" } }}
    />
    <Box sx={{ flexGrow: 1 }} />
    <GridToolbarExport printOptions={{ disableToolbarButton: true }} />
  </GridToolbarContainer>
);

export default CustomToolbar;
