import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useParams } from "react-router-dom";
import gql from "graphql-tag";
import { useQuery, useMutation } from "@apollo/client";
import { useSnackbar } from "notistack";

import Alert from "@mui/material/Alert";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

import {
  GET_MODIFIER_GROUPS_WITH_COUNT,
  GET_PRODUCT_BY_ID_MODIFER_GROUPS,
  UPDATE_PRODUCT,
} from "../../../helpers/apollo/utils";
import { convertModfierGroups } from "./ComponentHelpers";

import PositiveAction from "../../../components/Button/PositiveAction";
import Switch from "../../../components/Switch";
import BaseTable from "../../../components/Basetable/Basetable";

const ModifierGroupManager = ({ disabled, setHasGroups }) => {
  const { productId, siteId } = useParams();
  const { enqueueSnackbar } = useSnackbar();

  const [currentPage, setCurrentPage] = useState(0);
  const [savedModifierGroups, setSavedModifierGroups] = useState([]);
  const [selectedModifierGroups, setSelectedModifierGroups] = useState([]);
  const [showSelected, setShowSelected] = useState(false);
  const [isRequired, setIsRequired] = useState({});

  const isEnabled = {};

  const { error: getQueryError, loading: getQueryLoading } = useQuery(
    gql(GET_PRODUCT_BY_ID_MODIFER_GROUPS()),

    {
      onCompleted: ({ product }) => {
        if (product?.modifierGroups) {
          const modifierGroups = convertModfierGroups(product.modifierGroups);
          setSavedModifierGroups(modifierGroups);
          setSelectedModifierGroups(modifierGroups);

          const modifierGroupsRequiredStatus = modifierGroups.reduce(
            (acc, { id, required }) => ({ ...acc, [id]: required }),
            {},
          );
          setIsRequired(modifierGroupsRequiredStatus);
          setHasGroups(modifierGroups.length > 0);
        }
      },
      fetchPolicy: "cache-and-network",
      skip: !productId,
      variables: {
        productId,
        siteId,
      },
    },
  );

  const [
    submitToServer,
    { error: updateProductError, loading: updateProductLoading },
  ] = useMutation(gql(UPDATE_PRODUCT()), {
    onCompleted: () => {
      enqueueSnackbar("Your changes have been saved", {
        SnackbarProps: { "data-testid": "modifier-groups-saved-snackbar" },
        variant: "success",
      });
    },
  });

  const handleSubmit = updatedModifierGroups =>
    submitToServer({
      variables: {
        input: {
          id: productId,
          modifierGroups: updatedModifierGroups,
          siteId,
        },
      },
    });

  const toggleSelectedFilter = (items, value) => {
    setSelectedModifierGroups(value ? items : []);
    setShowSelected(value);
    setCurrentPage(0);
  };

  const parseSelectedItems = items => {
    return items.map(([id, { name, POSClassId }]) => {
      return {
        id,
        name,
        POSClassId,
        required: !!isRequired[id],
      };
    });
  };

  const columns = [
    { label: "Name", name: "name", options: { filter: false, sort: true } },
    {
      label: "POS Class Id",
      name: "POSClassId",
      options: { filter: false, sort: true },
    },
    {
      label: "Required",
      name: "required",
      options: {
        filter: false,
        sort: false,
        customBodyRender: tableColumn =>
          tableColumn.component(isEnabled[tableColumn.id]),
      },
    },
  ];

  const handleRequiredSwitchRender = modifierGroup => {
    return {
      ...modifierGroup,
      required: {
        component: enabled => (
          <Switch
            disabled={!enabled}
            active={enabled === false ? false : !!isRequired[modifierGroup.id]}
            onChange={() =>
              setIsRequired({
                ...isRequired,
                [modifierGroup.id]: !isRequired[modifierGroup.id],
              })
            }
            colour="success"
            testId={`modifier-required-switch-${modifierGroup.id}`}
          />
        ),
        id: modifierGroup.id,
      },
    };
  };

  const handleUpdateEnabled = selectionState => {
    Object.keys(isEnabled).forEach(id => {
      isEnabled[id] = false;
    });

    selectionState.forEach((value, key) => {
      isEnabled[key] = true;
    });
  };

  useEffect(() => {
    if (getQueryError || updateProductError) {
      const fallbackErrorMessage = "Please try again later.";

      const errorMsg =
        getQueryError?.message ||
        updateProductError?.message ||
        fallbackErrorMessage;

      enqueueSnackbar(`An unexpected error has occured: ${errorMsg}`, {
        variant: "error",
        SnackbarProps: {
          "data-testid": "modifier-groups-error-snackbar",
        },
      });
    }
  }, [getQueryError, updateProductError, enqueueSnackbar]);

  return (
    <Card>
      <CardContent>
        <Grid container spacing={2}>
          <Grid item container alignItems="center" xs={12}>
            <Grid item>
              <Typography>Modifer Groups</Typography>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            {!productId || disabled ? (
              <Alert
                severity="info"
                data-testid={
                  disabled
                    ? "modifier-group-manager-disabled-message"
                    : "no-product-id-message"
                }
              >
                {disabled
                  ? "This product cannot have modifier groups attached when it's a part of an existing modifier group."
                  : "In order to assign modifer groups you must first create this product."}
              </Alert>
            ) : (
              <BaseTable
                columns={columns}
                defaultSort={[{ field: "name", direction: "ASC" }]}
                overrideData={
                  showSelected
                    ? selectedModifierGroups.map(modifierGroup =>
                        handleRequiredSwitchRender(modifierGroup),
                      )
                    : null
                }
                getTableData={d => {
                  return d.modifierGroupsWithCount.modifierGroups.map(
                    modifierGroup => handleRequiredSwitchRender(modifierGroup),
                  );
                }}
                getRowCount={d => d.modifierGroupsWithCount.totalRows}
                multiSelectOptions={selectionState => ({
                  customToolbar: () => {
                    let itemsToAdd = false;

                    selectionState.forEach((value, key) => {
                      if (!savedModifierGroups.some(({ id }) => id === key)) {
                        itemsToAdd = true;
                      }
                    });

                    if (!itemsToAdd) {
                      itemsToAdd = !!savedModifierGroups.some(
                        ({ id, required }) => isRequired[id] !== required,
                      );
                    }

                    const itemsToRemove = savedModifierGroups.find(
                      ({ id }) => !selectionState.has(id),
                    );

                    handleUpdateEnabled(selectionState);

                    return (
                      <Box sx={{ width: "100%" }}>
                        <Box sx={{ mt: "12px" }}>
                          <PositiveAction
                            disabled={
                              !itemsToAdd &&
                              !itemsToRemove &&
                              !getQueryLoading &&
                              !updateProductLoading &&
                              !updateProductError
                            }
                            buttonText="Save Changes"
                            onClick={() => {
                              const iteratorToArray = Array.from(
                                selectionState.entries(),
                              ).map(([id]) => ({
                                id,
                                required: !!isRequired[id],
                              }));

                              const modifierGroupsRequiredStatus =
                                iteratorToArray.reduce(
                                  (acc, { id, required }) => ({
                                    ...acc,
                                    [id]: required,
                                  }),
                                  {},
                                );

                              handleSubmit(iteratorToArray);

                              setSavedModifierGroups(
                                parseSelectedItems(
                                  Array.from(selectionState.entries()),
                                ),
                              );
                              setIsRequired(modifierGroupsRequiredStatus);
                              setHasGroups(iteratorToArray.length > 0);
                            }}
                            testId="save-modifier-groups-btn"
                          />
                        </Box>
                      </Box>
                    );
                  },
                  customToolbarSelect: () => {
                    const items = parseSelectedItems(
                      Array.from(selectionState.entries()),
                    );

                    return (
                      <Grid
                        container
                        item
                        justifyContent="flex-start"
                        xl={10}
                        lg={9}
                        xs={6}
                        sx={{ mr: 8 }}
                      >
                        <Switch
                          active={showSelected}
                          disabled={!items.length}
                          label="Show selected only"
                          onChange={() =>
                            toggleSelectedFilter(items, !showSelected)
                          }
                          testId="show-selected-switch"
                        />
                      </Grid>
                    );
                  },
                  onRowSelectionChange: (cur, allRowsSelected) => {
                    if (!allRowsSelected.length) {
                      toggleSelectedFilter(null, false);
                    }
                  },
                  selectableRows: "multiple",
                  selectToolbarPlacement: "above",
                })}
                onPageChange={curPage => setCurrentPage(curPage)}
                page={currentPage}
                searchBy="name_contains"
                query={GET_MODIFIER_GROUPS_WITH_COUNT()}
                defaultSelectedItems={savedModifierGroups}
              />
            )}
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

ModifierGroupManager.propTypes = {
  disabled: PropTypes.bool.isRequired,
  setHasGroups: PropTypes.func.isRequired,
};

export default ModifierGroupManager;
