import React from "react";
import PropTypes from "prop-types";

import Grid from "@mui/material/Grid";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import Typography from "@mui/material/Typography";

import SettingTooltip from "./SettingTooltip";

const TableRowComponent = ({
  name,
  tooltip = "",
  tooltipTestId = "",
  children,
}) => {
  return (
    <TableRow>
      <TableCell>
        <Grid container>
          <Typography>{name}:</Typography>
          {tooltip && (
            <SettingTooltip tooltip={tooltip} testId={tooltipTestId} />
          )}
        </Grid>
      </TableCell>
      <TableCell>{children}</TableCell>
    </TableRow>
  );
};

TableRowComponent.propTypes = {
  name: PropTypes.string.isRequired,
  tooltip: PropTypes.string,
  tooltipTestId: PropTypes.string,
  children: PropTypes.node.isRequired,
};

export default TableRowComponent;
