import React, { useState, useEffect } from "react";
import { observer, inject, PropTypes as MobXPropTypes } from "mobx-react";
import { difference } from "lodash";
import { useParams, useNavigate } from "react-router-dom";

import { makeStyles } from "@mui/styles";

import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Container from "@mui/material/Container";
import FormHelperText from "@mui/material/FormHelperText";
import Grid from "@mui/material/Grid";
import OutlinedInput from "@mui/material/OutlinedInput";
import MenuItem from "@mui/material/MenuItem";
import ListItemText from "@mui/material/ListItemText";
import Select from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import Typography from "@mui/material/Typography";

import ErrorBoundary from "../../components/ErrorBoundary";
import Page from "../../components/Page";
import NegativeAction from "../../components/Button/NegativeAction";
import PositiveAction from "../../components/Button/PositiveAction";

const useStyles = makeStyles(theme => ({
  form: {
    padding: 12,
  },
  button: {
    marginRight: theme.spacing(1),
  },
}));

const EditComponent = ({ appStore }) => {
  const navigate = useNavigate();
  const { reportId } = useParams();
  const classes = useStyles();
  const [formError, setFormError] = useState("");

  const [recipients, setRecipients] = useState([]);
  const [selectedRecipients, setSelectedRecipients] = useState([]);
  const [originalRecipients, setOriginalRecipients] = useState([]);
  const [report, setReport] = useState({ name: "" });
  const [selected, setSelected] = useState(false);

  useEffect(() => {
    appStore.reportStore
      .find(reportId)
      .then(result => {
        if (result) {
          const reportRecipients = result.recipients.map(({ id }) => id);
          setSelectedRecipients(reportRecipients);
          setOriginalRecipients(reportRecipients);
          setReport(result);
        }
        appStore.setLoading(false);
      })
      .catch(error => {
        appStore.log.error(error);
        navigate("/app/404");
      });
    appStore.recipientStore
      .findAll(0, 0, null, { field: "email" })
      .then(() => {
        const sortedResults = appStore.recipientStore.sorted;
        const allRecipients = sortedResults
          .map(({ id, email }) => ({
            id,
            email,
          }))
          .filter(({ email }) => email);

        setRecipients(allRecipients);
      })
      .catch(error => {
        appStore.log.error(error);
        navigate("/app/404");
      });
  }, [appStore]);

  const handleCancel = event => {
    event.preventDefault();
    appStore.setLoading();
    navigate("/app/reports");
  };

  const handleUpdateNo = () => {
    setSelected(false);
  };

  const handleUpdateYes = () => {
    setSelected(false);
    report.setRecipients(
      selectedRecipients.map(id => ({
        id,
      })),
    );
    report.save();
    navigate("/app/reports");
    // update report now
    setFormError("");
  };

  const handleSubmit = event => {
    event.preventDefault();
    setSelected(true);
  };

  const handleChange = ({ target: { value } }) => {
    setSelectedRecipients(typeof value === "string" ? value.split(",") : value);
  };

  if (appStore.isLoading) {
    return null;
  }

  const MenuProps = {
    getContentAnchorEl: () => null,
    PaperProps: {
      style: {
        maxHeight: 500,
        width: 250,
      },
    },
  };

  const getRemovals = () => {
    return originalRecipients.filter(id => !selectedRecipients.includes(id))
      .length;
  };

  const getAdditions = () => {
    return selectedRecipients.filter(id => !originalRecipients.includes(id))
      .length;
  };

  const disableButton = () => {
    const inSelectedNotRecipients = difference(
      selectedRecipients,
      originalRecipients,
    );

    return (
      !inSelectedNotRecipients.length &&
      selectedRecipients.length >= originalRecipients.length
    );
  };

  const getConfirmMessage = () => {
    const removalsLength = getRemovals();
    const additionsLength = getAdditions();
    const removalsWord = removalsLength === 1 ? "recipient" : "recipients";
    const additionsWord = additionsLength === 1 ? "recipient" : "recipients";

    if (removalsLength && additionsLength) {
      return `You are about to remove ${removalsLength} ${removalsWord} from and add ${additionsLength} ${additionsWord} to this report. Continue?`;
    }
    if (removalsLength) {
      return `You are about to remove ${removalsLength} ${removalsWord} from this report. Continue?`;
    }
    return `You are about to add ${additionsLength} ${additionsWord} to this report. Continue?`;
  };

  return (
    <ErrorBoundary>
      <Page title="Add Subscriber">
        {selected && (
          <Dialog open>
            <DialogTitle>Update Recipients</DialogTitle>
            <DialogContent>
              <DialogContentText>{getConfirmMessage()}</DialogContentText>
            </DialogContent>
            <DialogActions>
              <NegativeAction buttonText="No" onClick={handleUpdateNo} />
              <PositiveAction buttonText="Yes" onClick={handleUpdateYes} />
            </DialogActions>
          </Dialog>
        )}
        <Container maxWidth={false}>
          <form autoComplete="off" noValidate className={classes.form}>
            <Card>
              <CardContent>
                <Typography variant="h2" gutterBottom>
                  Edit Report
                </Typography>
                <Typography gutterBottom>
                  {report.name.toUpperCase()}
                </Typography>
                <Grid container spacing={3}>
                  {formError && (
                    <Grid item xs={12}>
                      <FormHelperText error>{formError}</FormHelperText>
                    </Grid>
                  )}

                  <Grid item lg={6} md={6} xs={12}>
                    <Select
                      variant="standard"
                      fullWidth
                      label="Select Recipients"
                      id="demo-multiple-checkbox"
                      multiple
                      displayEmpty
                      value={selectedRecipients}
                      onChange={handleChange}
                      input={<OutlinedInput label="Tag" />}
                      renderValue={selectedValues =>
                        `${
                          selectedValues.length > 0
                            ? selectedValues.length
                            : "Select"
                        } ${
                          selectedValues.length === 1
                            ? "Recipient"
                            : "Recipients"
                        }`
                      }
                      MenuProps={MenuProps}
                    >
                      {recipients.map(({ email, id }) => (
                        <MenuItem key={id} value={id}>
                          <Checkbox checked={selectedRecipients.includes(id)} />
                          <ListItemText primary={email} />
                        </MenuItem>
                      ))}
                    </Select>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
            <Box py={2}>
              <Box mr={1} display="inline">
                <NegativeAction buttonText="Cancel" onClick={handleCancel} />
              </Box>
              <PositiveAction
                buttonText="Update Subscribers"
                onClick={handleSubmit}
                disabled={disableButton()}
              />
            </Box>
          </form>
        </Container>
      </Page>
    </ErrorBoundary>
  );
};

EditComponent.propTypes = {
  appStore: MobXPropTypes.objectOrObservableObject.isRequired,
};

export default inject("appStore")(observer(EditComponent));
