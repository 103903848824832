import React, { useState, useEffect } from "react";

import { observer, inject, PropTypes as MobXPropTypes } from "mobx-react";

import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";

import ErrorBoundary from "../../components/ErrorBoundary";
import Page from "../../components/Page";
import TabPanel from "./Components/TabPanel";
import AlertDialog from "../../components/Dialog/AlertDialog";
import settingsConfig from "./SettingsConfig";

const SettingsComponent = ({ appStore }) => {
  const defaultAlert =
    "You have unsaved settings, are you sure you wish to continue?";
  const [alertText, setAlertText] = useState(defaultAlert);
  const [currentMenu, setCurrentMenu] = useState(0);
  const [nextMenu, setNextMenu] = useState(0);
  const [fieldsSaved, setFieldsSaved] = useState(true);
  const [showSaveDialog, setShowSaveDialog] = useState(false);
  const [resetFields, setResetFields] = useState(false);
  const settings = settingsConfig({ appStore });

  const JUST_EAT_TAB_NUMBER = 5;

  const isLeavingJustEatWithVisible = current => {
    const regex = /\*\*\*/;
    const keyVisible =
      appStore.settingsStore.justEatCredentials.apiKey.length > 0 &&
      !appStore.settingsStore.justEatCredentials.apiKey.match(regex);

    const hmacVisible =
      appStore.settingsStore.justEatCredentials.hmacKey.length > 0 &&
      !appStore.settingsStore.justEatCredentials.hmacKey.match(regex);

    return current === JUST_EAT_TAB_NUMBER && (hmacVisible || keyVisible);
  };

  const handleMenuChange = (event, newValue) => {
    if (isLeavingJustEatWithVisible(currentMenu)) {
      setAlertText(
        "Once you leave this page you'll no longer be able to see your API keys. Are you sure you want to continue?",
      );
      setNextMenu(newValue);
      setShowSaveDialog(true);
    } else if (fieldsSaved) {
      setCurrentMenu(newValue);
    } else {
      setAlertText(defaultAlert);
      setNextMenu(newValue);
      setShowSaveDialog(true);
    }
  };

  const handleSaveDialogClose = value => {
    if (value) {
      setResetFields(true);
      setFieldsSaved(true);
      setCurrentMenu(nextMenu);
    }
  };

  useEffect(() => {
    appStore.setLoading(false);
  }, [appStore]);

  return (
    <ErrorBoundary>
      <Page title="Settings">
        <Container maxWidth={false}>
          <Grid item mt={3}>
            <Typography variant="h2">Settings</Typography>
          </Grid>
          <Box mt={3}>
            <Card>
              <Tabs
                value={currentMenu}
                onChange={handleMenuChange}
                variant="scrollable"
                scrollButtons="auto"
                aria-label="settings menu tabs"
              >
                {settings.map(setting => {
                  return (
                    <Tab
                      key={`tab-${setting.category}`}
                      label={setting.category}
                      data-testid={`settings-tab-${setting.category}`}
                    />
                  );
                })}
              </Tabs>
            </Card>
          </Box>
          <Box mt={0} pb={4}>
            <TabPanel
              settings={settings[currentMenu]}
              isSaved={setFieldsSaved}
              resetFields={resetFields}
            />
          </Box>
        </Container>

        <AlertDialog
          backButtonText="Go Back"
          okButtonText="Continue"
          notifyClosure={handleSaveDialogClose}
          present={showSaveDialog}
          setPresence={setShowSaveDialog}
          testId="settings-unsaved"
          title="Are you sure?"
        >
          <Box>
            <Typography>{alertText}</Typography>
          </Box>
        </AlertDialog>
      </Page>
    </ErrorBoundary>
  );
};

SettingsComponent.propTypes = {
  appStore: MobXPropTypes.objectOrObservableObject.isRequired,
};

const Component = inject("appStore")(observer(SettingsComponent));

export default Component;
