const DefaultBucket = () => ({
  "& .droppableOuter": {
    pr: 3,
    pt: 3,
  },
  "& .droppableInner": {
    marginTop: "10px",
    minHeight: "250px",
  },
  ".droppableTitleBar": {
    minHeight: "40px",
  },
});

const ExclusionBucket = theme => ({
  "& .droppableOuter": {
    pr: 3,
    pt: 3,
  },
  "& .droppableInner": {
    marginTop: "10px",
    minHeight: "250px",
    borderColor: theme.palette.error.main,
    borderStyle: "dashed",
  },
  ".droppableTitleBar": {
    minHeight: "40px",
  },
});

export default ({ bucketstyle, theme }) => {
  switch (bucketstyle) {
    case "ExclusionBucket":
      return ExclusionBucket(theme);
    case "DefaultBucket":
    default:
      return DefaultBucket(theme);
  }
};
