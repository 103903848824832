import React from "react";
import PropTypes from "prop-types";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import Snackbar from "@mui/material/Snackbar";
import ButtonGroup from "@mui/material/ButtonGroup";

import PositiveAction from "../../components/Button/PositiveAction";
import NegativeAction from "../../components/Button/NegativeAction";

const ViewChangeManager = ({ currentView, handleChangeView, handleExit }) => {
  return (
    <Snackbar anchorOrigin={{ vertical: "top", horizontal: "right" }} open>
      <Alert icon={false} severity="info">
        <AlertTitle textAlign="center">View Change Manager</AlertTitle>
        <ButtonGroup variant="contained" disableElevation sx={{ mr: 1 }}>
          <PositiveAction
            buttonText="Before"
            onClick={() => handleChangeView("before")}
            disabled={currentView === "before"}
            testId="view-before-change"
          />
          <PositiveAction
            buttonText="After"
            onClick={() => handleChangeView("after")}
            disabled={currentView === "after"}
            testId="view-after-change"
          />
        </ButtonGroup>
        <NegativeAction
          buttonText="Exit"
          onClick={handleExit}
          testId="exit-view-change"
        />
      </Alert>
    </Snackbar>
  );
};

ViewChangeManager.propTypes = {
  currentView: PropTypes.string.isRequired,
  handleChangeView: PropTypes.func.isRequired,
  handleExit: PropTypes.func.isRequired,
};

export default ViewChangeManager;
