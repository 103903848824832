import { hasNumberValidationError } from "../../../components/Datagrid/utils/validation";

export const generateTableColumn = ({
  headerName,
  field,
  type,
  dropdownOptions,
  onlyInteger,
  sortable = false,
  required = false,
  editable = true,
  minWidth = 20,
}) => {
  const defaultProps = {
    headerName,
    field,
    type,
    sortable,
    editable,
    minWidth,
  };

  switch (type) {
    case "text":
      if (required) {
        return {
          ...defaultProps,
          preProcessEditCellProps: params => {
            const { props } = params;
            const { value } = props;

            return { ...props, error: !value.length > 0 };
          },
        };
      }

      if (field === "productCode") {
        return {
          ...defaultProps,
          preProcessEditCellProps: params => {
            const { props } = params;
            const { value } = props;

            return { ...props, error: value.length < 5 };
          },
        };
      }

      return defaultProps;
    case "boolean":
      return defaultProps;
    case "number":
      return {
        ...defaultProps,
        preProcessEditCellProps: params => {
          const { props } = params;
          const { value } = props;

          return {
            ...props,
            error: hasNumberValidationError(value, onlyInteger),
          };
        },
      };
    case "singleSelect":
      return {
        ...defaultProps,
        getOptionLabel: ({ text }) => text,
        getOptionValue: ({ value }) => value,
        valueOptions: [{ text: "-", value: "" }, ...dropdownOptions],
      };
    default:
      throw new Error("Bad column config.");
  }
};

export const priceFields = Array.from(Array(6)).map(
  (item, index) => `price${index + 1}`,
);

export const modifierFields = Array.from(Array(6)).map(
  (item, index) => `modifierGroup${index + 1}`,
);

export const statusColumns = [
  { headerName: "Negative Price", field: "negative_price", minWidth: 120 },
  { headerName: "Red Print", field: "red_print", minWidth: 90 },
  { headerName: "Open Description", field: "open_description", minWidth: 140 },
  { headerName: "Open Price", field: "open_price", minWidth: 110 },
  { headerName: "Modifier", field: "modifier", minWidth: 90 },
  {
    headerName: "Promotional Trigger",
    field: "promotional_trigger",
    minWidth: 160,
  },
  { headerName: "Receipt Suppress", field: "receipt_suppress", minWidth: 145 },
].map(({ headerName, field, minWidth }) => ({
  headerName,
  field,
  type: "boolean",
  minWidth: minWidth,
}));

export const sitePickedColumn = {
  headerName: "Picked",
  field: "picked",
  type: "boolean",
  minWidth: 80,
  editable: true,
};

export const siteEnabledColumn = {
  headerName: "Enabled",
  field: "enabled",
  type: "boolean",
  minWidth: 80,
  editable: true,
};

const priceColumns = priceFields.map((item, index) => ({
  headerName: `Price ${index + 1}`,
  field: item,
  type: "number",
}));

export const getColumns = (
  modifierGroups,
  subGroups,
  productGroups,
  isAtSite,
) => {
  const modifierColumns = modifierFields.map((item, index) => ({
    headerName: `Modifier ${index + 1}`,
    field: item,
    type: "singleSelect",
    dropdownOptions: modifierGroups,
  }));

  return [
    {
      headerName: "Name",
      field: "description",
      type: "text",
      sortable: true,
      required: true,
    },
    {
      headerName: "Till Description",
      field: "tillDescription",
      type: "text",
      sortable: true,
      required: true,
    },
    {
      headerName: "Product Code",
      field: "productCode",
      type: "text",
      sortable: true,
      editable: !isAtSite,
    },
    {
      headerName: "Sub Group",
      field: "subGroup",
      type: "singleSelect",
      dropdownOptions: subGroups,
    },
    {
      headerName: "Product Group",
      field: "productGroup",
      type: "singleSelect",
      dropdownOptions: productGroups,
    },
    {
      headerName: "Modifier Of",
      field: "modifierOf",
      type: "singleSelect",
      dropdownOptions: modifierGroups,
    },
    ...priceColumns,
    ...modifierColumns,
    {
      headerName: "Max Mod",
      field: "maxMod",
      type: "number",
      onlyInteger: true,
      sortable: true,
    },
    // In the future this will be a dropdown, currently API accepts string
    {
      headerName: "VAT Rate",
      field: "vatCode",
      type: "text",
      sortable: true,
      editable: !isAtSite,
    },
    ...statusColumns,
    ...(isAtSite ? [sitePickedColumn] : []),
  ].map(generateTableColumn);
};
