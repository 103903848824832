import React, { useState } from "react";
import PropTypes from "prop-types";

import FormHelperText from "@mui/material/FormHelperText";
import FormControl from "@mui/material/FormControl";
import Input from "@mui/material/Input";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import InputLabel from "@mui/material/InputLabel";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

const PasswordInput = ({
  error = "",
  id,
  inputProps = null,
  label = "Password",
  onChange,
  value,
}) => {
  const [showPassword, setShowPassword] = useState(false);

  return (
    <FormControl variant="standard" fullWidth>
      <InputLabel htmlFor={id}>{label}</InputLabel>
      <Input
        data-testid={inputProps?.["data-testid"]}
        id={id}
        onChange={onChange}
        type={showPassword ? "text" : "password"}
        value={value}
        endAdornment={
          <InputAdornment position="end">
            <IconButton
              onClick={() => setShowPassword(!showPassword)}
              onMouseDown={event => event.preventDefault()}
              size="medium"
            >
              {showPassword ? <Visibility /> : <VisibilityOff />}
            </IconButton>
          </InputAdornment>
        }
      />
      {error && <FormHelperText error>{error}</FormHelperText>}
    </FormControl>
  );
};

PasswordInput.propTypes = {
  error: PropTypes.string,
  id: PropTypes.string.isRequired,
  inputProps: PropTypes.object,
  label: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
};

export default PasswordInput;
