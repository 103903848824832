import React, { useEffect, useState } from "react";
import { inject, PropTypes as MobXPropTypes } from "mobx-react";
import { withStyles, makeStyles } from "@mui/styles";
import { Helmet } from "react-helmet";
import { Card, CardContent } from "@mui/material";
import { useNavigate } from "react-router-dom";

import LoginForm from "../components/Form/Login";

const styles = () => ({
  container: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
  },
});

const useStyles = makeStyles(() => ({
  container: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    height: "100%",
  },
  card: { marginBottom: "100px" },
}));

const LoginComponentF = ({ appStore: app }) => {
  const navigate = useNavigate();
  const classes = useStyles();

  const [formError, setFormError] = useState("");

  useEffect(() => {
    app.setLoading(false);
  }, [app.isLoading]);

  const handleSubmit = (email, password) => {
    app.setLoading();

    app
      .authenticate(email, password)
      .then(() => {
        navigate("/app");
      })
      .catch(error => {
        app.log.error(error);
        setFormError(error.message.replace("GraphQL error: ", ""));
        app.setLoading(false);
      });
  };

  return (
    <div className={classes.container}>
      <Helmet title="Login" />
      <Card variant="outlined" className={classes.card}>
        <CardContent>
          <LoginForm
            displayName={app.displayName}
            formError={formError}
            submit={handleSubmit}
          />
        </CardContent>
      </Card>
    </div>
  );
};

LoginComponentF.propTypes = {
  appStore: MobXPropTypes.objectOrObservableObject.isRequired,
};

export default withStyles(styles)(inject("appStore")(LoginComponentF));
