import { types, getEnv, getParent, flow } from "mobx-state-tree";
import gql from "graphql-tag";

import Organisation, {
  fragments as organisationFragments,
} from "./models/Organisation";

import { removeTypename, transformer } from "../helpers";

export default types
  .model("OrganisationStore", {
    items: types.optional(types.map(Organisation), {}),
  })
  .views(self => ({
    get appStore() {
      return getParent(self);
    },
  }))
  .actions(self => {
    const { apolloClient } = getEnv(self);
    const findAllLoaded = false;

    return {
      find: flow(function* find(id) {
        if (self.items.has(id)) {
          return self.items.get(id);
        }

        try {
          const response = yield apolloClient.query({
            query: gql`
            {
              organisation(id: "${id}") {
                ...OrganisationFullDetails
              }
            }
            ${organisationFragments.fullDetails}
          `,
          });

          const item = self.track(response.data.organisation);

          return Promise.resolve(item);
        } catch (error) {
          return Promise.reject(error);
        }
      }),

      findAll: flow(function* findAll(filter = {}) {
        if (findAllLoaded) {
          return Promise.resolve(self.items);
        }

        try {
          const response = yield apolloClient.query({
            query: gql`
              {
                organisations (filter: {${transformer(filter)}}) {
                  ...OrganisationFullDetails
                }
              }
              ${organisationFragments.fullDetails}
            `,
          });

          response.data.organisations.forEach(item => self.track(item));

          return Promise.resolve(self.items);
        } catch (error) {
          return Promise.reject(error);
        }
      }),

      add: flow(function* add(entity) {
        try {
          const input = transformer({
            name: entity.name,
            code: entity.code,
            type: () => entity.type,
            POSPaymentTypeId: entity.POSPaymentType.id,
            ...(entity?.defaultBookingType?.id && {
              defaultBookingTypeId: entity.defaultBookingType.id,
            }),
            ...(entity?.orderMessageTimeoutMinutes && {
              orderMessageTimeoutMinutes: entity.orderMessageTimeoutMinutes,
            }),
          });

          const response = yield apolloClient.mutate({
            mutation: gql`
            mutation addOrganisation {
              addOrganisation(input: {
                ${input}
              }) {
                ...OrganisationFullDetails
              }
            }
            ${organisationFragments.fullDetails}
          `,
          });

          return Promise.resolve(self.track(response.data.addOrganisation));
        } catch (error) {
          return Promise.reject(error);
        }
      }),

      track(entity) {
        if (!self.items.has(entity.id)) {
          const input = removeTypename(entity);

          if (input.POSPaymentType) {
            input.POSPaymentType = self.appStore.paymentTypeStore.track(
              input.POSPaymentType,
            );
          }

          if (input.defaultBookingType) {
            input.defaultBookingType = self.appStore.bookingTypeStore.track(
              input.defaultBookingType,
            );
          }

          input.createdBy = self.appStore.userStore.track(input.createdBy);
          if (input.updatedBy) {
            input.updatedBy = self.appStore.userStore.track(input.updatedBy);
          }

          return self.items.put(input);
        }

        return self.items.get(entity.id);
      },
    };
  });
