const errorMessages = {
  genericEmpty: "This field cannot be empty.",
};

const checkRequiredFields = formData => {
  const requiredFields = ["name"];

  return requiredFields.reduce(
    (acc, fieldName) => ({
      ...acc,
      [`${fieldName}Empty`]:
        !formData[fieldName] && formData[fieldName] !== 0
          ? errorMessages.genericEmpty
          : false,
    }),
    {},
  );
};

export default checkRequiredFields;
