import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Checkbox from "@mui/material/Checkbox";
import Collapse from "@mui/material/Collapse";
import FormControlLabel from "@mui/material/FormControlLabel";
import Grid from "@mui/material/Grid";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import IconButton from "@mui/material/IconButton";
import TextField from "@mui/material/TextField";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";

import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";

import NegativeAction from "../../../../components/Button/NegativeAction";
import Switch from "../../../../components/Switch";

const SiteChooser = ({
  showCollapsibleContent,
  handleChange,
  siteRules,
  sites,
  toggleCollapsed,
  readOnly = false,
}) => {
  const [initialLoad, setInitialLoad] = useState(true);

  const [filter, setFilter] = useState("");
  const [filteredSites, setFilteredSites] = useState(sites);

  // this boolean is used to check if all current selected sites
  // match the "sites" props length
  const [allSitesSelected, setAllSitesSelected] = useState(false);
  const [inactiveSiteSelected, setInactiveSiteSelected] = useState(false);

  const getSelectedSiteCount = () => {
    if (siteRules.allSites) {
      return filteredSites.length;
    }

    return Object.keys(siteRules).length;
  };

  useEffect(
    () =>
      setFilteredSites(
        sites.filter(
          site =>
            (site.active === true || siteRules[site.id] === true) &&
            site.name.toLowerCase().includes(filter.toLowerCase()),
        ),
      ),
    [sites, filter, siteRules],
  );

  const handleAllSiteToggle = () => {
    const anySitesToggled = !siteRules.allSites;

    if (anySitesToggled) {
      setFilter("");
      handleChange({
        allSites: anySitesToggled,
      });
    } else {
      handleChange({});
    }
  };

  const handleSiteToggle = (selectedSite, id) => {
    const { [id]: thisSite, ...rest } = siteRules;
    handleChange(selectedSite ? { ...rest, [id]: true } : rest);
  };

  const handleDeselectAll = () => handleChange({});

  const handleSelectAll = () => {
    const sitesToMarkActive = sites
      .filter(({ active }) => active)
      .reduce((acc, { id }) => ({ ...acc, [id]: true }), {});

    handleChange(sitesToMarkActive);
  };

  useEffect(() => {
    const currentSelectedSites = Object.keys(siteRules).length;
    const availableSites = sites.filter(
      site => site.active === true || siteRules[site.id] === true,
    ).length;

    setAllSitesSelected(currentSelectedSites === availableSites);
  }, [siteRules, sites]);

  useEffect(() => {
    const inactiveSiteFound = sites
      .filter(({ active, id }) => active === true || siteRules[id] === true)
      .find(({ active }) => active === false);

    setInactiveSiteSelected(!!inactiveSiteFound);

    if (initialLoad && inactiveSiteFound) {
      setInitialLoad(false);
      if (!showCollapsibleContent) {
        toggleCollapsed();
      }
    }
  }, [sites, siteRules, initialLoad, toggleCollapsed, showCollapsibleContent]);

  const generateVerticalGrid = () => {
    // divide the desired row width by the total
    //  row width to get the amount of columns
    const gridItemRowWidth = 3;
    const columns = 12 / gridItemRowWidth;

    const itemsPerColumn = Math.ceil(filteredSites.length / columns);

    // create deep copy that we will splice from
    const mutableSiteList = [...filteredSites];

    // create an array based on the amount of columns, map over it and populate with sites
    const renderSites = Array.from(Array(columns).keys()).map(columnNumber => {
      const itemsToMap = mutableSiteList.splice(0, itemsPerColumn);

      return (
        <Grid
          container
          item
          xs={gridItemRowWidth}
          spacing={3}
          alignContent="flex-start"
          key={columnNumber}
        >
          {itemsToMap.map(({ id, name, active }, index) => (
            <Grid item key={id} xs={12}>
              <FormControlLabel
                disabled={siteRules.allSites}
                control={
                  <Checkbox
                    checked={
                      siteRules[id] === true || siteRules.allSites === true
                    }
                    color="primary"
                    inputProps={{
                      "data-testid": `site-checkbox-${columnNumber}-${index}`,
                    }}
                    key={`${id}-${siteRules[id]}`}
                    onChange={({ target: { checked: selectedSite } }) =>
                      handleSiteToggle(selectedSite, id)
                    }
                  />
                }
                label={`${name}${!active ? " (Inactive)" : ""}`}
              />
            </Grid>
          ))}
        </Grid>
      );
    });

    return (
      <Grid container data-testid="site-checkbox-container">
        {renderSites}
      </Grid>
    );
  };

  return (
    <Card id="site-chooser">
      <CardContent>
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid container item xs={3} alignItems="center">
            <Typography>Sites&nbsp;</Typography>
            <Tooltip arrow title="The sites this promotion is active for.">
              <HelpOutlineIcon fontSize="small" />
            </Tooltip>
            <Grid container item>
              <Typography data-testid="site-count">
                You have {getSelectedSiteCount()} selected
              </Typography>
            </Grid>
          </Grid>
          <Grid item justifyContent="flex-end">
            <Switch
              active={siteRules.allSites === true}
              colour="success"
              onChange={handleAllSiteToggle}
              testId="enable-any-sites"
              label="Any Site"
            />
            <IconButton
              onClick={toggleCollapsed}
              data-testid="site-collapse-toggle"
            >
              {showCollapsibleContent ? <ExpandLess /> : <ExpandMore />}
            </IconButton>
          </Grid>
        </Grid>
        <Collapse in={showCollapsibleContent}>
          {!siteRules.allSites ? (
            <>
              <Grid container justifyContent="space-between">
                {!readOnly && (
                  <>
                    <Grid item>
                      <Typography
                        variant="body2"
                        color={sites.length ? "textSecondary" : "darkgrey"}
                        sx={sites.length ? { cursor: "pointer" } : {}}
                        data-testid="all-sites-toggle-selection-button"
                        onClick={() =>
                          allSitesSelected
                            ? handleDeselectAll()
                            : handleSelectAll()
                        }
                      >
                        {allSitesSelected ? "Deselect all" : "Select all"}
                      </Typography>
                    </Grid>
                    <Grid item justifyContent="flex-end" mb={2} mt={1}>
                      <TextField
                        label="Site filter"
                        onChange={({ target: { value: searchString } }) =>
                          setFilter(searchString)
                        }
                        value={filter}
                        variant="outlined"
                        size="small"
                      />
                      {filter ? (
                        <Box ml={1}>
                          <NegativeAction
                            buttonText="Clear"
                            onClick={() => setFilter("")}
                          />
                        </Box>
                      ) : (
                        <></>
                      )}
                    </Grid>
                  </>
                )}
                {inactiveSiteSelected ? (
                  <Grid item xs={12} marginBottom={2}>
                    <Alert severity="warning">
                      <AlertTitle>
                        <strong>
                          Warning: A selected site has been made inactive
                        </strong>
                      </AlertTitle>
                      <Typography>
                        When deselected it will be removed from the user
                        interface until it is marked as active again.
                      </Typography>
                    </Alert>
                  </Grid>
                ) : (
                  <></>
                )}
                {generateVerticalGrid()}
              </Grid>
            </>
          ) : (
            <Alert severity="info" data-testid="any-site-alert">
              <AlertTitle>
                <strong>Any Site Enabled</strong>
              </AlertTitle>
              <Typography>
                This means that any current or future sites will be applicable
                to this promotion.
              </Typography>
            </Alert>
          )}
        </Collapse>
      </CardContent>
    </Card>
  );
};

SiteChooser.propTypes = {
  showCollapsibleContent: PropTypes.bool.isRequired,
  handleChange: PropTypes.func.isRequired,
  siteRules: PropTypes.objectOf(PropTypes.bool).isRequired,
  sites: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    }),
  ).isRequired,
  toggleCollapsed: PropTypes.func.isRequired,
  readOnly: PropTypes.bool,
};

export default SiteChooser;
