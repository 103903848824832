import { types, getParent } from "mobx-state-tree";
import gql from "graphql-tag";

export const fragments = {
  fullDetails: gql`
    fragment SubscriberDetails on Subscriber {
      id
      email
    }
  `,
};

export default types
  .model("Subscriber", {
    id: types.identifier,
    email: types.string,
  })
  .views(self => ({
    get parent() {
      return getParent(self);
    },
  }));
