import React from "react";
import PropTypes from "prop-types";
import moment from "moment";

import Typography from "@mui/material/Typography";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";

import TableRowComponent from "./TableRow";

const TimeInputComponent = ({
  ampm = true,
  error = null,
  name,
  label,
  value = null,
  updateCallback,
  testId,
  tooltip = "",
}) => {
  return (
    <TableRowComponent name={label} tooltip={tooltip} tooltipTestId={testId}>
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <TimePicker
          ampm={ampm}
          value={value && moment.utc(value * 1000)}
          onChange={newValue => {
            const formattedValue = newValue
              ? moment(newValue.format("hh:mm A"), "hh:mm A")
                  .diff(moment().startOf("day"), "seconds")
                  .toString()
              : null;

            updateCallback(
              name,
              !isNaN(formattedValue) ? formattedValue : null,
            );
          }}
          sx={{ maxWidth: "166px" }}
          slotProps={{
            textField: { "data-testid": testId, size: "small" },
          }}
        />
      </LocalizationProvider>
      {error && (
        <Typography sx={{ fontSize: 14, color: "error.main" }}>
          {error}
        </Typography>
      )}
    </TableRowComponent>
  );
};

TimeInputComponent.propTypes = {
  ampm: PropTypes.bool,
  error: PropTypes.string,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  value: PropTypes.string,
  updateCallback: PropTypes.func.isRequired,
  testId: PropTypes.string.isRequired,
  tooltip: PropTypes.string,
};

export default TimeInputComponent;
