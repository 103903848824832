import React, { useEffect, useState } from "react";
import { observer, inject, PropTypes as MobXPropTypes } from "mobx-react";
import gql from "graphql-tag";
import PropTypes from "prop-types";
import { useQuery } from "@apollo/client";

import DataTable from "../Datatable/Datatable";

const defaultColumnSort = [
  {
    field: "createdAt",
    direction: "DESC",
  },
];

const defaultSelectedItemArray = [];

const defaultFilterObject = {};

const defaultMapper = item => ({
  ...item,
});

const defaultRowsPerPageOptions = [10, 25, 50, 100];

const defaultSortFieldMapper = item => {
  return item;
};

function BaseTable({
  appStore,
  columns,
  defaultSelectedItems = defaultSelectedItemArray,
  defaultSort = defaultColumnSort,
  defaultFilter = defaultFilterObject,
  getTableData,
  getRowCount,
  handleRowClick = () => {},
  mapper = defaultMapper,
  multiSelectOptions = () => ({
    selectableRows: "none",
    selectToolbarPlacement: "none",
  }),
  onPageChange = null,
  overrideData = null,
  page = 0,
  rowsPerPageOptions = defaultRowsPerPageOptions,
  searchBy = null, // When set to null will disable the search
  sortFieldMapper = defaultSortFieldMapper,
  query,
  viewColumns = true,
}) {
  const [limit, setLimit] = useState(rowsPerPageOptions[0]);
  const [offset, setOffset] = useState(0);
  const [sort, setSort] = useState(defaultSort);
  const [filter, setFilter] = useState({});
  const [filterBy, setFilterBy] = useState(null);
  const [currentPage, setCurrentPage] = useState(page);
  const [totalRows, setTotalRows] = useState(0);
  const [tableData, setTableData] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (page === 0) {
      setOffset(0);
    }

    setCurrentPage(page);
  }, [page]);

  // Fetch the first page of data
  const { fetchMore, data } = useQuery(gql(query), {
    // Use the cache, but also fetch from the API and if the API is newer
    // this will auto-update what's rendered
    fetchPolicy: "cache-and-network",
    onCompleted: () => {
      appStore.setLoading(false);

      // When we've completed the initial request we'll load the next page into the cache
      // so we can render it quickly. It won't go stale because Apollo will still use the API
      // and update this cache and view if the API is newer.
      fetchMore({
        variables: {
          limit,
          offset: offset + limit,
          ...(sort.length > 0 && { sort }),
          filter: { ...defaultFilter, ...filter },
        },
      });
    },
    onError: err => {
      setError(err);
    },
    variables: {
      limit,
      offset,
      ...(sort.length > 0 && { sort }),
      filter: { ...defaultFilter, ...filter },
    },
  });

  useEffect(() => {
    if (overrideData) {
      const sortKey = sort[0].field;
      const sortDirection = sort[0].direction;

      setTableData(
        overrideData
          .sort((a, b) => {
            if (typeof a[sortKey] === "string") {
              return (sortDirection === "ASC" ? a : b)[sortKey].localeCompare(
                (sortDirection === "ASC" ? b : a)[sortKey],
              );
            }
            return (sortDirection === "ASC" ? a : b)[sortKey] >
              (sortDirection === "ASC" ? b : a)[sortKey]
              ? 1
              : -1;
          })
          .slice(offset, limit + offset),
      );

      setTotalRows(overrideData.length);
    } else if (data) {
      setTableData(getTableData(data, limit, offset).map(mapper));
      setTotalRows(getRowCount(data));
    }
  }, [
    data,
    getRowCount,
    getTableData,
    limit,
    mapper,
    offset,
    overrideData,
    sort,
  ]);

  const handleFilterChange = filterList => {
    // Find the first column that's been changed by the filter, as we only want to apply one.
    const filteredCol = filterList.findIndex(col => col.length);

    if (filteredCol > -1) {
      const filterValue = filterList[filteredCol][0];
      const newFilterBy = columns[filteredCol].options.filterBy;

      if (newFilterBy !== undefined) {
        const updateFilter = {
          ...filter,
          [newFilterBy]: filterValue,
        };

        setFilter(updateFilter);
        setFilterBy(newFilterBy);
      }
    } else if (filterBy) {
      // If there is a filter currently set in state but no filters are applied to the table, then remove it.
      const { [filterBy]: discardFilter, ...updateFilter } = filter;
      setFilter(updateFilter);
      setFilterBy(null);
    }

    setCurrentPage(0);
  };

  const handleTableChange = (action, tableState) => {
    // For this table this is all we're interested in.
    if (
      ![
        "changeRowsPerPage",
        "changePage",
        "sort",
        "filterChange",
        "resetFilters",
        "search",
      ].includes(action)
    ) {
      return;
    }

    if (tableState.sortOrder.name) {
      setSort([
        {
          field: sortFieldMapper(tableState.sortOrder.name),
          direction: tableState.sortOrder.direction.toUpperCase(),
        },
      ]);
    }

    if (action === "filterChange" || action === "resetFilters") {
      handleFilterChange(tableState.filterList);
    } else if (action === "search") {
      if (tableState.searchText) {
        const updateFilter = {
          ...filter,
          [searchBy]: tableState.searchText,
        };

        setFilter(updateFilter);
      } else {
        const { [searchBy]: discardSearch, ...updateFilter } = filter;
        setFilter(updateFilter);
      }
    }

    setLimit(tableState.rowsPerPage);
    setOffset(tableState.page * tableState.rowsPerPage);
  };

  useEffect(() => {
    if (error) {
      // todo - render an error here
      throw error;
    }
  }, [error]);

  return (
    <DataTable
      columns={columns}
      defaultSelectedItems={defaultSelectedItems}
      elevation={1}
      tableData={tableData}
      handleFilterChange={
        columns.some(col => col.options?.filter) ? () => {} : null
      }
      handleChangePage={
        onPageChange !== null
          ? onPageChange
          : curPage => setCurrentPage(curPage)
      }
      handleRowClick={handleRowClick}
      handleSearchChange={searchBy ? () => {} : null}
      handleTableChange={handleTableChange}
      multiSelectOptions={multiSelectOptions}
      page={currentPage}
      rowsPerPageOptions={rowsPerPageOptions}
      totalRowCount={totalRows}
      tableOptions={{
        rowsPerPage: limit,
      }}
      viewColumns={viewColumns}
    />
  );
}

BaseTable.propTypes = {
  appStore: MobXPropTypes.objectOrObservableObject.isRequired,
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      name: PropTypes.string,
      options: PropTypes.shape({
        filterBy: PropTypes.string,
      }),
    }),
  ).isRequired,
  defaultSelectedItems: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.arrayOf(PropTypes.shape({})),
  ]),
  defaultSort: PropTypes.arrayOf(
    PropTypes.shape({
      field: PropTypes.string,
      direction: PropTypes.oneOf(["ASC", "DESC"]),
    }),
  ),
  defaultFilter: PropTypes.shape({ filter: PropTypes.string }),
  getTableData: PropTypes.func.isRequired,
  getRowCount: PropTypes.func.isRequired,
  handleRowClick: PropTypes.func,
  mapper: PropTypes.func,
  multiSelectOptions: PropTypes.func,
  onPageChange: PropTypes.func,
  overrideData: PropTypes.arrayOf(PropTypes.shape({})),
  page: PropTypes.number,
  rowsPerPageOptions: PropTypes.arrayOf(PropTypes.number),
  searchBy: PropTypes.string,
  sortFieldMapper: PropTypes.func,
  query: PropTypes.string.isRequired,
  viewColumns: PropTypes.bool,
};

export default inject("appStore")(observer(BaseTable));
