import React, { useState } from "react";
import gql from "graphql-tag";
import { useQuery } from "@apollo/client";
import { useSnackbar } from "notistack";

import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

import ErrorBoundary from "../../components/ErrorBoundary";
import Page from "../../components/Page";
import SiteSelector from "../../components/SiteSelector";
import PriceLevelSetupsBulkEditor from "./Components/PriceLevelSetupsBulkEditor";

import { GET_SALES_AREAS } from "../../helpers/apollo/utils";

const TillsList = () => {
  const { enqueueSnackbar } = useSnackbar();

  const title = "Price Level Setups";

  const [siteId, setSiteId] = useState("");
  const [salesAreas, setSalesAreas] = useState([]);

  useQuery(gql(GET_SALES_AREAS()), {
    fetchPolicy: "cache-and-network",
    onCompleted: data => {
      if (data.salesAreasWithCount?.salesAreas !== undefined) {
        setSalesAreas(data.salesAreasWithCount.salesAreas);
      }
    },
    onError: error => {
      enqueueSnackbar(`Error: ${error.message}`, {
        variant: "error",
        SnackbarProps: {
          "data-testid": "get-sales-areas-error-snackbar",
        },
      });
    },
    variables: {
      sort: [
        {
          field: "description",
          direction: "ASC",
        },
      ],
    },
  });

  return (
    <ErrorBoundary>
      <Page title={title}>
        <Container maxWidth={false}>
          <Grid container rowSpacing={3}>
            <Grid
              container
              item
              xs={12}
              justifyContent="space-between"
              alignItems="center"
              mt={3}
            >
              <Grid item>
                <Typography variant="h2">{title}</Typography>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <SiteSelector siteSelectCallback={setSiteId} />
            </Grid>
            <Grid item xs={12} sx={{ width: "0px" }}>
              <PriceLevelSetupsBulkEditor
                salesAreas={salesAreas}
                site={siteId}
              />
            </Grid>
          </Grid>
        </Container>
      </Page>
    </ErrorBoundary>
  );
};

export default TillsList;
