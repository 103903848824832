import React from "react";
import PropTypes from "prop-types";
import { observer, inject, PropTypes as MobXPropTypes } from "mobx-react";
import { observable, action, makeObservable } from "mobx";

import { withStyles } from "@mui/styles";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import Select from "@mui/material/Select";

import DatePicker from "@mui/lab/DesktopDatePicker";

import PhoneIcon from "@mui/icons-material/Phone";
import EmailIcon from "@mui/icons-material/Email";

import validator from "validator";

import TimePicker from "../../components/TimePicker";

const styles = theme => ({
  input: {
    margin: theme.spacing(1),
  },
  form: {
    padding: 12,
  },
  button: {
    marginRight: theme.spacing(1),
  },
});

/**
 * @todo all buttons should be exposed as overridable props
 * @todo all routerStore.push has been commented out as it is being dropped, if this page
 * is to be used, it should be reworked.
 */
class AddComponent extends React.Component {
  hasChanged = false;
  customer = "";
  formError = "";

  firstName = "";
  errorFirstName = "";

  lastName = "";
  errorLastName = "";

  phone = "";
  errorPhone = "";

  email = "";
  errorEmail = "";

  sites = [];
  site = "";
  errorSite = "";

  arrivalDate = null;
  errorArrivalDate = "";

  expectedGuests = "";
  errorExpectedGuests = "";

  constructor(props) {
    super(props);

    makeObservable(this, {
      hasChanged: observable,
      customer: observable,
      formError: observable,
      setFormError: action,
      handleSubmit: action,
      firstName: observable,
      errorFirstName: observable,
      handleFirstNameChange: action,
      lastName: observable,
      errorLastName: observable,
      handleLastNameChange: action,
      phone: observable,
      errorPhone: observable,
      handlePhoneChange: action,
      email: observable,
      errorEmail: observable,
      sites: observable,
      site: observable,
      setSites: action,
      handleSiteChange: action,
      handleEmailChange: action,
      arrivalDate: observable,
      errorArrivalDate: observable,
      handleArrivalDateChange: action,
      handleArrivalTimeChange: action,
      expectedGuests: observable,
      errorExpectedGuests: observable,
      handleExpectedGuestsChange: action,
    });
  }

  componentDidMount() {
    const { appStore: app } = this.props;
    const that = this;

    app.siteStore
      .findAll()
      .then(sites => {
        that.setSites([...sites.values()]);
        app.setLoading(false);
      })
      .catch(error => {
        app.log.error(error);
        // app.routerStore.push("/app");
      });
  }

  setFormError = value => {
    this.error = value;
  };

  handleFirstNameChange = event => {
    this.firstName = event.target.value;
    this.errorFirstName = "";
    this.error = "";
  };

  handleLastNameChange = event => {
    this.lastName = event.target.value;
    this.errorLastName = "";
    this.error = "";
  };

  handlePhoneChange = event => {
    this.phone = event.target.value;
    this.errorPhone = "";
    this.error = "";
  };

  handleEmailChange = event => {
    this.email = event.target.value;
    this.errorEmail = "";
    this.error = "";
  };

  setSites = value => {
    this.sites = value;
  };

  handleSiteChange = event => {
    this.site = event.target.value;
    this.errorSite = "";
    this.error = "";
  };

  handleArrivalDateChange = date => {
    if (date !== null && this.arrivalDate !== null) {
      const old = this.arrivalDate.clone();
      this.arrivalDate = old
        .year(date.year())
        .month(date.month())
        .date(date.date());
    } else {
      this.arrivalDate = date;
    }
    this.errorArrivalDate = "";
    this.error = "";
    this.hasChanged = true;
  };

  handleArrivalTimeChange = date => {
    if (this.arrivalDate !== null) {
      const old = this.arrivalDate.clone();
      this.arrivalDate = old.hour(date.hour()).minute(date.minute());
    } else {
      this.arrivalDate = date;
    }
    this.errorArrivalDate = "";
    this.error = "";
    this.hasChanged = true;
  };

  handleExpectedGuestsChange = event => {
    this.expectedGuests = event.target.value;
    this.errorExpectedGuests = "";
    this.error = "";
  };

  handleCancel = () => {
    const { appStore: app } = this.props;

    if (this.hasChanged) {
      // @todo alert
    }

    app.setLoading();
    if (app.returnUrl) {
      app.goBack();
    } else {
      // app.routerStore.push("/app/bookings");
    }
  };

  handleSubmit = event => {
    const { appStore: app } = this.props;
    const that = this;
    let hasError = false;
    event.preventDefault();

    /**
     * @todo ideally share validation schema/sub-schema with server
     * JOI not working on browser for now
     */

    if (validator.isEmpty(this.firstName) && validator.isEmpty(this.lastName)) {
      hasError = true;
      this.error = "Please provide First and/or Last name";
    }

    if (!validator.isEmpty(this.email) && !validator.isEmail(this.email)) {
      hasError = true;
      this.errorEmail = "Please provide a valid email address";
    }

    if (validator.isEmpty(this.site)) {
      hasError = true;
      this.errorSite = "Please select a site";
    }

    if (validator.isEmpty(this.expectedGuests)) {
      hasError = true;
      this.errorExpectedGuests = "Please specify number of guests";
    }

    if (this.arrivalDate === null) {
      hasError = true;
      this.errorArrivalDate = "Please specify arrival date";
    }

    if (!hasError) {
      app.setLoading();

      app.bookingStore
        .add({
          site: this.site,
          customer: {
            firstName: this.firstName,
            lastName: this.lastName,
            email: this.email,
            phone: this.phone,
          },
          expectedAt: this.arrivalDate.toISOString(),
          expectedGuests: this.expectedGuests,
        })
        // .then(() => app.routerStore.push("/app/bookings"))
        .catch(error => {
          app.log.error(error);
          that.setFormError(error.message);
          app.setLoading(false);
        });
    }
  };

  render() {
    const { appStore: app, classes } = this.props;
    return (
      <form onSubmit={this.handleSubmit} className={classes.form}>
        <Grid container spacing={3}>
          {this.error && (
            <Grid item xs={12}>
              <FormHelperText error>{this.error}</FormHelperText>
            </Grid>
          )}
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              variant="standard"
              fullWidth
              autoComplete="off"
              label="First name"
              value={this.firstName}
              error={!!this.errorFirstName}
              helperText={this.errorFirstName}
              onChange={this.handleFirstNameChange}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              variant="standard"
              fullWidth
              autoComplete="off"
              label="Last name"
              value={this.lastName}
              error={!!this.errorLastName}
              helperText={this.errorLastName}
              onChange={this.handleLastNameChange}
            />
          </Grid>
          <Grid item xs={12}>
            {" "}
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              variant="standard"
              fullWidth
              autoComplete="off"
              label="Phone"
              value={this.phone}
              error={!!this.errorPhone}
              helperText={this.errorPhone}
              onChange={this.handlePhoneChange}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <PhoneIcon />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              variant="standard"
              fullWidth
              autoComplete="off"
              label="Email"
              value={this.email}
              error={!!this.errorEmail}
              helperText={this.errorEmail}
              onChange={this.handleEmailChange}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <EmailIcon />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={12}>
            {" "}
          </Grid>
          <Grid item xs={12} md={3}>
            <FormControl variant="standard" fullWidth error={!!this.errorSite}>
              <InputLabel>Site</InputLabel>
              <Select
                variant="standard"
                value={this.site}
                onChange={this.handleSiteChange}
              >
                <MenuItem value="">
                  <em>Select site...</em>
                </MenuItem>
                {this.sites.map(site => (
                  <MenuItem key={`site-${site.id}`} value={site.id}>
                    {site.name}
                  </MenuItem>
                ))}
                {}
              </Select>
              <FormHelperText>{this.errorSite}</FormHelperText>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            {" "}
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <DatePicker
              slotProps={{
                textField: {
                  label: "Arrival date",
                  helperText: this.errorArrivalDate,
                },
              }}
              // @todo disable backdrop dismiss with click
              style={{ width: 130, marginRight: 10 }}
              minDate={app.currentDateTime}
              value={this.arrivalDate}
              error={!!this.errorArrivalDate}
              onChange={this.handleArrivalDateChange}
              clearable
              inputFormat="MMM Do YYYY"
            />
            <TimePicker
              value={this.arrivalDate}
              onChange={this.handleArrivalTimeChange}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              variant="standard"
              fullWidth
              autoComplete="off"
              label="Expected guests"
              value={this.expectedGuests}
              error={!!this.errorExpectedGuests}
              helperText={this.errorExpectedGuests}
              onChange={this.handleExpectedGuestsChange}
            />
          </Grid>
          <Grid item xs={12}>
            <Button
              className={classes.button}
              type="submit"
              color="primary"
              variant="contained"
              onClick={this.handleSubmit}
            >
              Save
            </Button>

            <Button
              className={classes.button}
              type="submit"
              variant="contained"
              onClick={this.handleCancel}
            >
              Cancel
            </Button>
          </Grid>
        </Grid>
      </form>
    );
  }
}

AddComponent.propTypes = {
  appStore: MobXPropTypes.objectOrObservableObject.isRequired,
  classes: PropTypes.shape({
    form: PropTypes.string,
    button: PropTypes.string,
    root: PropTypes.string,
    appFrame: PropTypes.string,
    content: PropTypes.string,
    paper: PropTypes.string,
  }).isRequired,
};

const Add = observer(AddComponent);

export default withStyles(styles)(inject("appStore")(Add));
