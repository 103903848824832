import React from "react";
import PropTypes from "prop-types";

import Tooltip from "@mui/material/Tooltip";

export const tooltipText =
  "This field can only be edited on the default product";

const DefaultOnlyField = ({ children, disabled }) => {
  return disabled ? (
    <Tooltip title={tooltipText}>{children}</Tooltip>
  ) : (
    children
  );
};

DefaultOnlyField.propTypes = {
  children: PropTypes.node.isRequired,
  disabled: PropTypes.bool.isRequired,
};

export default DefaultOnlyField;
