import React, { useState, useEffect } from "react";
import { observer, inject, PropTypes as MobXPropTypes } from "mobx-react";
import { useParams, useNavigate } from "react-router-dom";
import gql from "graphql-tag";
import { useQuery, useMutation } from "@apollo/client";
import moment from "moment";

import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Collapse from "@mui/material/Collapse";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

import { DateRangeFilter } from "../../components/Basetable/Filters/DateRangeFilter";
import ErrorBoundary from "../../components/ErrorBoundary";
import NegativeAction from "../../components/Button/NegativeAction";
import Page from "../../components/Page";
import PositiveAction from "../../components/Button/PositiveAction";
import ProductOptionsTable from "../ProductOptions/Components/ProductOptionsTable";

import {
  ADD_PRODUCT_OPTION_GROUP,
  ADD_PRODUCT_OPTIONS_TO_GROUP,
  GET_PRODUCT_OPTION_GROUP_BY_ID,
  UPDATE_PRODUCT_OPTION_GROUP,
  REMOVE_PRODUCT_OPTIONS_FROM_GROUP,
} from "../../helpers/apollo/utils";
import checkRequiredFields from "./Components/AddEditValidation";

const AddEditComponent = ({ appStore }) => {
  const navigate = useNavigate();
  const { productOptionGroupId } = useParams();

  const [apolloError, setApolloError] = useState("");
  const [formData, setFormData] = useState({
    name: "",
  });
  const [formErrors, setFormErrors] = useState({});
  const [savedAlert, setSavedAlert] = useState(false);

  const productOptionsColumns = [
    { label: "Name", name: "name", options: { filter: false, sort: true } },
    { label: "POS Id", name: "POSId", options: { filter: false, sort: true } },
    {
      label: "Created at",
      name: "createdAt",
      options: { filter: false, sort: true },
    },
    {
      label: "Created between",
      name: "createdBetween",
      options: {
        display: false,
        sort: true,
        filter: true,
        filterType: "custom",
        filterBy: "createdAt_between",
        customFilterListOptions: {
          render: v =>
            `Created from: ${v[0]?.from ? moment(v[0].from).format("DD/MM/YYYY") : "Past"} ${v[0]?.to ? `- ${moment(v[0].to).format("DD/MM/YYYY")}` : ""}`,
        },
        filterOptions: {
          display: (filterList, onChange, index, column) => {
            return (
              <DateRangeFilter
                column={column}
                index={index}
                label="Created at"
                onChangeHandler={onChange}
                values={filterList[index]}
              />
            );
          },
        },
        viewColumns: false,
      },
    },
  ];

  // This is being done as when refreshing on the "add" version of this page
  // the loading will never be set to false, instead using local loading values from
  // apollo client.
  useEffect(() => {
    appStore.setLoading(false);
  });

  const [associatedOptions, setAssociatedOptions] = useState([]);

  const { error: getQueryError, loading: getQueryLoading } = useQuery(
    gql(GET_PRODUCT_OPTION_GROUP_BY_ID()),
    {
      fetchPolicy: "cache-and-network",
      onCompleted: ({ productOptionGroup }) => {
        setAssociatedOptions(productOptionGroup.productOptions ?? []);
        setFormData({
          ...formData,
          name: productOptionGroup.name,
        });
      },
      skip: !productOptionGroupId,
      variables: {
        productOptionGroupId,
      },
    },
  );

  const setSavedAlertTimeout = () => {
    // Clear any existing timeout
    if (savedAlert) {
      clearTimeout(savedAlert);
    }

    setApolloError(false);

    // Create a new timeout that hides the alert after 3 seconds
    const timeoutId = setTimeout(() => {
      setSavedAlert(false);
    }, 3000);

    // set the id in the state so that if resubmitted the previous one can be cleared
    setSavedAlert(timeoutId);
  };

  const [
    addGroupProductOptions,
    { error: addOptionsMutationError, loading: addOptionsMutationLoading },
  ] = useMutation(gql(ADD_PRODUCT_OPTIONS_TO_GROUP()), {
    onCompleted: () => {
      setSavedAlertTimeout();
    },
    refetchQueries: [
      gql(GET_PRODUCT_OPTION_GROUP_BY_ID()), // DocumentNode object
      "ProductOptionGroup", // Query name
    ],
  });

  const [
    removeGroupProductOptions,
    {
      error: removeOptionsMutationError,
      loading: removeOptionsMutationLoading,
    },
  ] = useMutation(gql(REMOVE_PRODUCT_OPTIONS_FROM_GROUP()), {
    onCompleted: () => {
      setSavedAlertTimeout();
    },
    refetchQueries: [
      gql(GET_PRODUCT_OPTION_GROUP_BY_ID()), // DocumentNode object
      "ProductOptionGroup", // Query name
    ],
  });

  const [
    submitToServer,
    { error: addEditMutationError, loading: addEditMutationLoading },
  ] = useMutation(
    gql(
      productOptionGroupId
        ? UPDATE_PRODUCT_OPTION_GROUP()
        : ADD_PRODUCT_OPTION_GROUP(),
    ),
    {
      onCompleted: response => {
        setSavedAlertTimeout();

        if (!productOptionGroupId) {
          const {
            addProductOptionGroup: { id },
          } = response;

          navigate(`/app/product-option-groups/${id}`);
        }
      },
    },
  );

  useEffect(() => {
    if (
      getQueryError ||
      addEditMutationError ||
      addOptionsMutationError ||
      removeOptionsMutationError
    ) {
      const fallbackErrorMessage = "Please try again.";

      setApolloError(
        getQueryError?.message ||
          addEditMutationError?.message ||
          addOptionsMutationError?.message ||
          removeOptionsMutationError?.message ||
          fallbackErrorMessage,
      );
    }
  }, [
    getQueryError,
    addEditMutationError,
    addOptionsMutationError,
    removeOptionsMutationError,
  ]);

  const handleChange = ({ target: { name: fieldName, value } }) => {
    switch (fieldName) {
      default:
        setFormData({ ...formData, [fieldName]: value });
        break;
    }
  };

  const handleFormCancel = () => navigate(`/app/product-option-groups`);

  const handleValidatePayload = dataToCheck => {
    const errors = {
      ...checkRequiredFields(dataToCheck),
    };

    return errors;
  };

  const handleFormSubmit = () => {
    const errors = handleValidatePayload(formData);

    setFormErrors(errors);

    if (!Object.values(errors).filter(error => error).length) {
      const dataToSubmit = {
        name: formData.name.trim(),
      };

      if (productOptionGroupId) {
        dataToSubmit.id = productOptionGroupId;
      }

      submitToServer({
        variables: {
          input: dataToSubmit,
        },
      });
    }
  };

  const updateProductOptions = (itemsToAdd, itemsToRemove) => {
    if (itemsToAdd.length) {
      addGroupProductOptions({
        variables: {
          input: {
            groupId: productOptionGroupId,
            productOptionIds: itemsToAdd,
          },
        },
      });
    }

    if (itemsToRemove.length) {
      removeGroupProductOptions({
        variables: {
          input: {
            groupId: productOptionGroupId,
            productOptionIds: itemsToRemove,
          },
        },
      });
    }
  };

  return (
    <ErrorBoundary>
      <Page
        title={`${productOptionGroupId !== null ? "Edit" : "Add"} Product Option Group`}
      >
        <Container maxWidth={false} sx={{ mt: 3, pb: 3 }}>
          <form autoComplete="off" noValidate>
            <Card>
              <CardContent>
                <Grid container spacing={3}>
                  <Grid
                    item
                    container
                    justifyContent="space-between"
                    alignItems="center"
                    xs={12}
                  >
                    <Grid item>
                      <Typography>Basic Information</Typography>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      data-testid="name-input"
                      error={!!formErrors.nameEmpty}
                      fullWidth
                      name="name"
                      label="Name"
                      helperText={formErrors.nameEmpty}
                      InputLabelProps={{ shrink: true }}
                      onChange={handleChange}
                      value={formData.name}
                      variant="outlined"
                    />
                  </Grid>
                </Grid>
                {productOptionGroupId && (
                  <Box display="flex" justifyContent="flex-end" mt={2}>
                    <PositiveAction
                      disabled={getQueryLoading || addEditMutationLoading}
                      buttonText="Save"
                      onClick={handleFormSubmit}
                      testId="save-info-btn"
                    />
                  </Box>
                )}
              </CardContent>
            </Card>
            {productOptionGroupId && (
              <Card sx={{ mt: 3 }}>
                <CardContent>
                  <Grid container spacing={3}>
                    <Grid
                      item
                      container
                      justifyContent="space-between"
                      alignItems="center"
                      xs={12}
                    >
                      <Grid item>
                        <Typography>Product Options</Typography>
                      </Grid>
                    </Grid>
                    <Grid item xs={12}>
                      <ProductOptionsTable
                        columns={productOptionsColumns}
                        multiSelectOptions={selectionState => {
                          const itemsToAdd = [];

                          selectionState.forEach((value, key) => {
                            if (
                              !associatedOptions.some(({ id }) => id === key)
                            ) {
                              itemsToAdd.push(key);
                            }
                          });

                          const itemsToRemove = associatedOptions
                            .filter(({ id }) => !selectionState.has(id))
                            .map(({ id }) => id);

                          const disabled =
                            !itemsToAdd.length && !itemsToRemove.length;

                          return {
                            customToolbar: () => {
                              return (
                                <Box sx={{ width: "100%" }}>
                                  {productOptionGroupId && (
                                    <Box sx={{ mt: "12px" }}>
                                      <PositiveAction
                                        disabled={
                                          disabled ||
                                          getQueryLoading ||
                                          removeOptionsMutationLoading ||
                                          addOptionsMutationLoading
                                        }
                                        buttonText="Update Group"
                                        onClick={() => {
                                          updateProductOptions(
                                            itemsToAdd,
                                            itemsToRemove,
                                          );
                                        }}
                                        testId="save-options-btn"
                                      />
                                    </Box>
                                  )}
                                </Box>
                              );
                            },
                            customToolbarSelect: () => {},

                            selectableRows: "multiple",
                            selectToolbarPlacement: "above",
                          };
                        }}
                        defaultSelectedItems={associatedOptions}
                      />
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            )}
            <Collapse in={!!savedAlert}>
              <Alert data-testid="submit-success-alert" sx={{ mt: 2 }}>
                Your changes have been saved.
              </Alert>
            </Collapse>
            {apolloError ? (
              <Alert
                severity="error"
                data-testid="apollo-error-alert"
                sx={{ mt: 2 }}
              >
                <AlertTitle>
                  <strong>An unexpected error has occured</strong>
                </AlertTitle>
                {apolloError}
              </Alert>
            ) : (
              <></>
            )}
            <Box display="flex" justifyContent="flex-end" mt={2}>
              <Box mr={1}>
                <NegativeAction
                  buttonText="Cancel"
                  onClick={handleFormCancel}
                  testId="cancel-button"
                />
              </Box>
              {!productOptionGroupId && (
                <PositiveAction
                  disabled={getQueryLoading || addEditMutationLoading}
                  buttonText="Submit"
                  onClick={handleFormSubmit}
                  testId="submit-button"
                />
              )}
            </Box>
          </form>
        </Container>
      </Page>
    </ErrorBoundary>
  );
};

AddEditComponent.propTypes = {
  appStore: MobXPropTypes.objectOrObservableObject.isRequired,
};

const AddEditProduct = inject("appStore")(observer(AddEditComponent));

export default AddEditProduct;
