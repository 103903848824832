import React from "react";
import PropTypes from "prop-types";

import { GridRow } from "@mui/x-data-grid";

const CustomGridRow = (props = {}) => {
  const { index } = props;
  return <GridRow {...props} data-testid={`row-${index}`} />;
};

CustomGridRow.propTypes = {
  index: PropTypes.number.isRequired,
};

export default CustomGridRow;
