import { types, getEnv, getParent, flow } from "mobx-state-tree";
import gql from "graphql-tag";

import Product, { fragments as ProductFragments } from "./models/Product";

import { removeTypename, transformer } from "../helpers";

export default types
  .model("ProductStore", {
    items: types.optional(types.map(Product), {}),
    autocomplete: types.optional(types.frozen(types.array(Product)), []),
    promotionProductSearch: types.optional(types.array(Product), []),
  })
  .views(self => ({
    get appStore() {
      return getParent(self);
    },

    applyOptions({
      orderBy = "name",
      order = "ASC",
      rowsPerPage = 25,
      offset = 0,
      filter = "",
      site = 0,
      modelType = "items",
    }) {
      // converting data to js array
      let items = [...self[modelType].values()];

      items = items
        .filter(
          item =>
            // filter based on name or POS ID
            ((item.name &&
              item.name.toLowerCase().includes(filter.toLowerCase())) ||
              (item.POSId && item.POSId.toString() === filter)) &&
            ((site && item.sites.find(siteItem => siteItem.id === site)) ||
              !site),
        )
        .sort(({ [orderBy]: itemA }, { [orderBy]: itemB }) =>
          order === "ASC"
            ? itemA.localeCompare(itemB)
            : itemB.localeCompare(itemA),
        );

      return {
        count: items.length,
        items: items.slice(0 + offset, rowsPerPage + offset),
      };
    },
  }))
  .actions(self => {
    const { apolloClient } = getEnv(self);

    return {
      findAll: flow(function* findAll(
        limit = 1000,
        filter = {},
        frozenModelType = null,
      ) {
        try {
          const query = gql`
              {
                products(limit: ${limit}, filter: {${transformer(filter)}}) {
                  ...${frozenModelType ? "SearchProducts" : "ProductDetails"}
                }
              }
              ${frozenModelType ? ProductFragments.productsForSearch : ProductFragments.fullDetails}
            `;

          const response = yield apolloClient.query({
            query,
          });

          if (frozenModelType) {
            self[frozenModelType] = response.data.products
              .filter(
                ({ tillProductName }) => !tillProductName.match(/^[zZ]+$/),
              )
              .map(product => ({
                ...product,
                tags: product.tags.map(tag => tag.id),
              }));
          } else {
            response.data.products.forEach(item => self.track(item));
          }

          return Promise.resolve(
            frozenModelType ? self[frozenModelType] : self.items,
          );
        } catch (error) {
          return Promise.reject(error);
        }
      }),

      track(entity) {
        if (!self.items.has(entity.id)) {
          const input = removeTypename(entity);

          return self.items.put({
            ...input,
            modifierGroup: input.modifierGroup?.id,
            tags: input.tags?.map(({ id }) => id) ?? [],
          });
        }

        return self.items.get(entity.id);
      },
    };
  });
