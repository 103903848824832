import moment from "moment";

/** The "transformer" function below requires an enum to be a function that returns the value
 *  this function converts either a string or array to the required value(s) */
export const convertStringToEnum = toCovert => {
  if (Array.isArray(toCovert)) {
    return toCovert.map(item => {
      return () => item;
    });
  }
  return () => toCovert;
};

export const removeTypename = obj => {
  const result = {};

  if (typeof obj === "string") {
    return obj;
  }

  Object.getOwnPropertyNames(obj).forEach(key => {
    if (key !== "__typename") {
      if (Array.isArray(obj[key])) {
        result[key] = obj[key].map(item => removeTypename(item));
      } else if (obj[key] !== null && typeof obj[key] === "object") {
        result[key] = removeTypename(obj[key]);
      } else {
        result[key] = obj[key];
      }
    }
  });
  return result;
};

export const toTestId = string =>
  string
    .replace(/ /g, "-") // Replace spaces with dashes
    .replace(/[^a-z0-9-]/gi, "") // Strip all non-alphanumeric characters except dashes
    .toLowerCase();

export const toTitleCase = string => string[0].toUpperCase() + string.slice(1);

export const transformer = (input, parentIsArray = false) =>
  Object.keys(input)
    .map(key => {
      let value = "";
      if (input[key] === null) {
        value = null;
      } else if (moment.isMoment(input[key])) {
        // format() implies a default format(formatString)
        value = JSON.stringify(input[key].format());
      } else if (input[key] instanceof Date) {
        value = JSON.stringify(input[key].toISOString());
      } else if (typeof input[key] === "function") {
        value = input[key]();
      } else if (Array.isArray(input[key])) {
        value = `[ ${transformer(input[key], true)} ]`;
      } else if (typeof input[key] === "object") {
        value = `{${transformer(input[key])}}`;
      } else {
        value = JSON.stringify(input[key]);
      }
      if (parentIsArray) {
        return `${value}`;
      }
      return `${key}: ${value}`;
    })
    .join(", ");

export default transformer;
