import React from "react";
import PropTypes from "prop-types";

import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";

import TableRowComponent from "./TableRow";

const DropdownComponent = ({
  name,
  label,
  options,
  value,
  updateCallback,
  testId,
  tooltip = "",
}) => {
  return (
    <TableRowComponent name={label} tooltip={tooltip} tooltipTestId={testId}>
      <Select
        value={value}
        onChange={event => updateCallback(name, event.target.value)}
        variant="standard"
        data-testid={testId}
      >
        {options.map(item => (
          <MenuItem key={item.id} value={item.id}>
            {item.name}
          </MenuItem>
        ))}
      </Select>
    </TableRowComponent>
  );
};

DropdownComponent.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  value: PropTypes.string.isRequired,
  updateCallback: PropTypes.func.isRequired,
  testId: PropTypes.string.isRequired,
  tooltip: PropTypes.string,
};

export default DropdownComponent;
