import * as React from "react";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import Add from "@mui/icons-material/Add";

const defaultOnClick = () => {};

export default function AddAction({
  buttonText = "Add",
  disabled = false,
  onClick = defaultOnClick,
  testId = "add-action-button",
}) {
  return (
    <Button
      aria-label="add-action-button"
      color="primary"
      disabled={disabled}
      onClick={onClick}
      type="button"
      variant="contained"
      data-testid={testId}
      startIcon={<Add />}
    >
      {buttonText}
    </Button>
  );
}

AddAction.propTypes = {
  buttonText: PropTypes.string,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  testId: PropTypes.string,
};
