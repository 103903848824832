import React, { useEffect, useState } from "react";
import { v4 } from "uuid";
import { inject, observer, PropTypes as MobXPropTypes } from "mobx-react";
import { getEnv } from "mobx-state-tree";
import moment from "moment";

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Avatar,
  Card,
  CardContent,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  Input,
  InputAdornment,
  InputLabel,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  IconButton,
} from "@mui/material";

import { MobileDateTimePicker as DateTimePicker } from "@mui/x-date-pickers";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import DeleteIcon from "@mui/icons-material/Delete";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Autocomplete from "../../components/Autocomplete";

import ErrorBoundary from "../../components/ErrorBoundary";
import Page from "../../components/Page";
import PositiveAction from "../../components/Button/PositiveAction";
import Switch from "../../components/Switch";

const totalFor = (items, field, override) => {
  if (!items) return 0;
  return items.reduce((acc, item) => {
    return (
      acc +
      ((override && item[override] && parseFloat(item[override])) ??
        (item[field] && parseFloat(item[field])) ??
        0)
    );
  }, 0);
};

const PLU_SALE_ITEM_TYPE = 1;
const CT_NORMAL_CHARGE_TYPE = 16;

const PromotionsDebug = ({ appStore }) => {
  const [selectedSiteId, setSelectedSiteId] = useState("");
  const [selectedPromotion, setSelectedPromotion] = useState();
  const [promotionProducts, setPromotionProducts] = useState();
  const [allSites, setAllSites] = useState([]);
  const [allPromotions, setAllPromotions] = useState([]);
  const [basket, setBasket] = useState({});
  const [response, setResponse] = useState({});
  const [responsePromotionTotals, setResponsePromotionTotals] = useState([]);
  const [debug, setDebug] = useState(false);
  const [rawRequest, setRawRequest] = useState({});
  const [orderTime, setOrderTime] = useState(moment());
  const [recentRequests, setRecentRequests] = useState();
  const [showRecent, setShowRecent] = useState(false);

  const gqlDomain = ["staging", "production"].includes(process.env.NODE_ENV)
    ? `https://gql.${window.location.hostname}`
    : "http://localhost:3000";

  const handleAddToBasket = item => {
    const newItem = { ...item };
    newItem.quantity = 1;
    newItem.price = "5.00";
    setBasket({ ...basket, [v4()]: newItem });
  };

  const handlePromotionProductSelect = event => {
    handleAddToBasket(promotionProducts.find(i => i.id === event.target.value));
  };

  const handlePromotionSelected = event => {
    if (event.target.value === "ALL") {
      setSelectedPromotion(null);
      return;
    }
    appStore.promotionStore
      .find(event.target.value)
      .then(promotion => setSelectedPromotion(promotion));
  };

  const handleValueChange = (id, field) => event => {
    const newItem = { ...basket[id] };
    newItem[field] = event.target.value;
    setBasket({ ...basket, [id]: newItem });
  };

  const handleOrderTimeChange = date => {
    setOrderTime(date);
  };

  useEffect(() => {
    setRecentRequests(
      JSON.parse(window.localStorage.getItem("recentRequests")),
    );
  }, []);

  useEffect(() => {
    if (recentRequests && Object.keys(recentRequests).length) {
      window.localStorage.setItem(
        "recentRequests",
        JSON.stringify(recentRequests),
      );
    }
  }, [recentRequests]);

  useEffect(() => {
    if (!selectedPromotion) {
      setPromotionProducts([]);
    } else {
      const products = Object.values(
        JSON.parse(selectedPromotion.rules).products,
      ).flatMap(bucket =>
        Object.values(bucket.products).filter(p => p.type === "PRODUCT"),
      );
      setPromotionProducts(products);
    }
  }, [selectedPromotion]);

  useEffect(() => {
    const request = {
      Order: {
        OrderId: v4(),
        OrderTime: orderTime,
        POSHouseNumber: allSites.find(s => s.id === selectedSiteId)
          ?.POSHouseNumber,
        Items: Object.values(basket).map((item, index) => ({
          Id: `${index + 1}`,
          ItemType: PLU_SALE_ITEM_TYPE,
          ChargeType: CT_NORMAL_CHARGE_TYPE,
          OrderTime: orderTime,
          RecordId: item.POSId,
          Description: item.name,
          Quantity: parseInt(item.quantity, 10),
          Gross: parseFloat(item.price),
          Discounted: parseFloat(item.price),
        })),
      },
    };
    if (debug) {
      request.Debug = true;
    }
    if (selectedPromotion) {
      request.PromotionId = selectedPromotion.id;
    }
    setRawRequest(request);
  }, [basket, allSites, selectedSiteId, debug, orderTime, selectedPromotion]);

  const handleSiteSelect = event => {
    setSelectedSiteId(event.target.value);
  };

  const handleSubmit = async () => {
    const { SESSION_TOKEN_NAME } = getEnv(appStore);
    const token = window.localStorage.getItem(SESSION_TOKEN_NAME);
    const apiResponse = await fetch(`${gqlDomain}/pos-promotions`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(rawRequest),
    });

    const { status } = apiResponse;
    const responseJson = await apiResponse.json();
    setRecentRequests({
      [`${Date.now()}`]: {
        request: rawRequest,
        response: responseJson,
        responseMeta: { status },
      },
      ...recentRequests,
    });
    const mergedItems = rawRequest.Order.Items.map(item => {
      return (
        responseJson.Items.find(
          i => parseInt(i.Id, 10) === parseInt(item.Id, 10),
        ) || item
      );
    });
    mergedItems.push(
      ...responseJson.Items.filter(i => {
        return (
          rawRequest.Order.Items.find(
            o => parseInt(o.Id, 10) === parseInt(i.Id, 10),
          ) === undefined
        );
      }),
    );
    responseJson.mergedItems = mergedItems;
    setResponse(responseJson);
    setResponsePromotionTotals(
      responseJson.Promotions.map(promotion => {
        let totalDiscount = 0;

        mergedItems.forEach(item => {
          if (item.Promotions?.length) {
            item.Promotions.filter(
              itemPromotion => itemPromotion.Id === promotion.Id,
            ).forEach(itemPromotion => {
              if (itemPromotion.Value) {
                totalDiscount += itemPromotion.Value;
              }
            });
          }
        });

        totalDiscount = totalDiscount.toFixed(2);
        return { name: promotion.ShortName, totalDiscount };
      }),
    );
  };

  useEffect(() => {
    appStore.siteStore.findAll().then(sites => {
      setAllSites(
        [...sites.values()].sort((a, b) =>
          a.name.toLowerCase().localeCompare(b.name.toLowerCase()),
        ),
      );
      setSelectedSiteId([...sites.values()][0].id);
    });

    appStore.promotionStore.findAll().then(promotions => {
      setAllPromotions([...promotions.values()]);
    });
    appStore.setLoading(false);
  }, [appStore]);

  return (
    <ErrorBoundary>
      <Page title="Promotions Test">
        <Container maxWidth={false}>
          <Card>
            <CardContent>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Typography variant="h2">Promotion Tester</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <FormControl variant="standard" fullWidth>
                        <InputLabel id="demo-simple-select-label">
                          Select promotion
                        </InputLabel>
                        <Select
                          variant="standard"
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={selectedPromotion?.id || "ALL"}
                          onChange={handlePromotionSelected}
                        >
                          <MenuItem value="ALL">All promotions</MenuItem>
                          {allPromotions.map(s => (
                            <MenuItem key={s.id} value={`${s.id}`}>
                              {s.name} [{Object.keys(JSON.parse(s.actions))[0]}]
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                      {promotionProducts?.length ? (
                        <FormControl variant="standard" fullWidth>
                          <InputLabel id="promo-product-label">
                            Promotion Products
                          </InputLabel>
                          <Select
                            variant="standard"
                            labelId="promo-product-label"
                            id="promo-product"
                            value="1"
                            onChange={handlePromotionProductSelect}
                          >
                            {promotionProducts &&
                              promotionProducts.map(s => (
                                <MenuItem key={s.id} value={`${s.id}`}>
                                  {s.name}
                                </MenuItem>
                              ))}
                          </Select>
                        </FormControl>
                      ) : (
                        <></>
                      )}
                    </Grid>
                    <Grid item xs={12}>
                      <Typography>
                        {selectedPromotion?.getActionSummary()}
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography variant="h3" gutterBottom>
                        Configure order
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid container spacing={3}>
                    <Grid item xs={4}>
                      <FormControl variant="standard" fullWidth>
                        <InputLabel id="demo-simple-select-label">
                          Site
                        </InputLabel>
                        <Select
                          variant="standard"
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={selectedSiteId}
                          onChange={handleSiteSelect}
                        >
                          {allSites.map(s => (
                            <MenuItem key={s.id} value={`${s.id}`}>
                              [{s.POSHouseNumber}] {s.name}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={5}>
                      <DateTimePicker
                        format="D MMM YYYY HH:mm"
                        label="OrderTime"
                        ampm={false}
                        value={orderTime}
                        onChange={handleOrderTimeChange}
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <Switch
                        active={debug}
                        onChange={() => setDebug(!debug)}
                        colour="success"
                        label={debug ? "Debug On" : "Debug Off"}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Autocomplete
                        fullWidth
                        onSelect={handleAddToBasket}
                        useFrozenProducts
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="h3" gutterBottom>
                    Promotions returned
                  </Typography>
                  <Grid container spacing={3}>
                    <List>
                      {(response.Promotions?.length &&
                        response.Promotions.map((promo, index) => (
                          <ListItem key={index}>
                            <ListItemAvatar>
                              <Avatar>
                                {!promo.meta || promo.meta.applicable ? (
                                  <CheckCircleIcon color="primary" />
                                ) : (
                                  <HighlightOffIcon color="error" />
                                )}
                              </Avatar>
                            </ListItemAvatar>
                            <ListItemText
                              primary={promo.ShortName}
                              secondary={promo.Description}
                            />
                          </ListItem>
                        ))) || (
                        <ListItem>
                          <ListItemAvatar>
                            <Avatar>
                              <HighlightOffIcon />
                            </Avatar>
                          </ListItemAvatar>
                          <ListItemText primary="No results" />
                        </ListItem>
                      )}
                    </List>
                  </Grid>
                </Grid>
                <Grid container item xs={6}>
                  <Grid item xs={12}>
                    <Grid container spacing={2} alignItems="center">
                      <Grid item xs={7}>
                        <Typography variant="h2" gutterBottom>
                          Basket
                        </Typography>
                      </Grid>
                      <Grid item xs={5}>
                        <Dialog
                          open={showRecent}
                          onClose={() => setShowRecent(false)}
                          aria-labelledby="form-dialog-title"
                        >
                          <DialogTitle id="form-dialog-title">
                            Recent requests
                          </DialogTitle>
                          <DialogContent>
                            <List>
                              {recentRequests &&
                                Object.entries(recentRequests).map(
                                  ([id, item]) => (
                                    <ListItem key={id}>
                                      {moment(parseInt(id, 10)).format(
                                        "D/M HH:mm:ss",
                                      )}{" "}
                                      - {item.request.Order.Items.length} Items
                                    </ListItem>
                                  ),
                                )}
                            </List>
                          </DialogContent>
                          <DialogActions>
                            <PositiveAction
                              buttonText="Cancel"
                              onClick={() => setShowRecent(false)}
                            />
                          </DialogActions>
                        </Dialog>
                      </Grid>
                    </Grid>
                    <Paper>
                      <Table>
                        <TableHead>
                          <TableRow>
                            <TableCell>Product</TableCell>
                            <TableCell>Quantity</TableCell>
                            <TableCell>Price</TableCell>
                            <TableCell align="center">Options</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {Object.entries(basket).map(([id, item]) => (
                            <TableRow key={`${id}`}>
                              <TableCell>
                                <Typography>{item.name}</Typography>
                                <Typography variant="caption" color="secondary">
                                  {item.POSId}
                                </Typography>
                              </TableCell>
                              <TableCell>
                                <Input
                                  style={{ width: 50 }}
                                  type="number"
                                  value={item.quantity}
                                  onChange={handleValueChange(id, "quantity")}
                                />
                              </TableCell>
                              <TableCell>
                                <Input
                                  style={{ width: 100 }}
                                  type="number"
                                  startAdornment={
                                    <InputAdornment position="start">
                                      £
                                    </InputAdornment>
                                  }
                                  value={item.price}
                                  onChange={handleValueChange(id, "price")}
                                />
                              </TableCell>
                              <TableCell align="center">
                                <IconButton
                                  aria-label="delete"
                                  color="primary"
                                  onClick={() => {
                                    const newBasket = { ...basket };

                                    delete newBasket[id];

                                    setBasket(newBasket);
                                  }}
                                >
                                  <DeleteIcon />
                                </IconButton>
                              </TableCell>
                            </TableRow>
                          ))}
                          <TableRow>
                            <TableCell>&nbsp;</TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </Paper>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    pt={2}
                    display="flex"
                    justifyContent="space-between"
                  >
                    <PositiveAction
                      buttonText="Recent requests"
                      onClick={() => setShowRecent(true)}
                    />
                    <PositiveAction
                      buttonText="Submit Basket"
                      onClick={handleSubmit}
                    />
                  </Grid>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="h2" gutterBottom>
                    Response
                  </Typography>
                  <Paper>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell>Product</TableCell>
                          <TableCell>Quantity</TableCell>
                          <TableCell>Promotional Price</TableCell>
                          <TableCell>Gross Price</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {response.mergedItems &&
                          response.mergedItems.map(item => (
                            <TableRow key={`${item.Id}`}>
                              <TableCell>
                                <Typography>{item.Description}</Typography>
                                <Typography variant="caption" color="secondary">
                                  {item.RecordId}
                                </Typography>
                              </TableCell>
                              <TableCell>{item.Quantity}</TableCell>
                              <TableCell>
                                <Typography>
                                  £
                                  {parseFloat(
                                    item.PromotionPrice ?? item.Gross,
                                  ).toFixed(2)}
                                </Typography>
                              </TableCell>
                              <TableCell>
                                £{parseFloat(item.Gross ?? "0.00").toFixed(2)}
                              </TableCell>
                            </TableRow>
                          ))}
                        <TableRow>
                          <TableCell>Subtotal</TableCell>
                          <TableCell />
                          <TableCell>
                            £
                            {totalFor(
                              response.mergedItems,
                              "Gross",
                              "PromotionPrice",
                            ).toFixed(2)}
                          </TableCell>
                          <TableCell>
                            £
                            {totalFor(response.mergedItems, "Gross").toFixed(2)}
                          </TableCell>
                        </TableRow>
                        {responsePromotionTotals.map((promo, index) => (
                          <TableRow key={index}>
                            <TableCell>Discount - {promo.name}</TableCell>
                            <TableCell />
                            <TableCell />
                            <TableCell>
                              -£{promo.totalDiscount || "0.00"}
                            </TableCell>
                          </TableRow>
                        ))}
                        {responsePromotionTotals && (
                          <TableRow>
                            <TableCell>Total</TableCell>
                            <TableCell />
                            <TableCell />
                            <TableCell style={{ fontWeight: 900 }}>
                              £
                              {(
                                totalFor(response.mergedItems, "Gross") -
                                responsePromotionTotals.reduce(
                                  (acc, promo) =>
                                    acc + parseFloat(promo.totalDiscount || 0),
                                  0,
                                )
                              ).toFixed(2)}
                            </TableCell>
                          </TableRow>
                        )}
                      </TableBody>
                    </Table>
                  </Paper>
                </Grid>
                <Grid item xs={6}>
                  <Accordion fullWidth>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <Typography>Raw request</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <div>
                        <pre>{JSON.stringify(rawRequest, null, 2)}</pre>
                      </div>
                    </AccordionDetails>
                  </Accordion>
                </Grid>
                <Grid item xs={6}>
                  <Accordion fullWidth>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <Typography>Raw response</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <div>
                        <pre>
                          {JSON.stringify(
                            {
                              Promotions: response.Promotions,
                              Items: response.Items,
                            },
                            null,
                            2,
                          )}
                        </pre>
                      </div>
                    </AccordionDetails>
                  </Accordion>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Container>
      </Page>
    </ErrorBoundary>
  );
};

PromotionsDebug.propTypes = {
  appStore: MobXPropTypes.objectOrObservableObject.isRequired,
};

export default inject("appStore")(observer(PromotionsDebug));
