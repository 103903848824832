import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { TextField, Grid, InputAdornment } from "@mui/material";

const PriceInputBox = ({ priceData: { level, price, id }, updatePrices }) => {
  const [showAddLabel, setShowAddLabel] = useState(true);
  const [boxFocused, setBoxFocused] = useState(false);

  useEffect(() => {
    if (!boxFocused) setShowAddLabel(!price);
  }, [price]);

  return (
    <Grid item xs={2}>
      <TextField
        data-testid={`price-input-level-${level}`}
        name={`level${level}`}
        label={
          showAddLabel ? `Add Price Level ${level}` : `Price Level ${level}`
        }
        variant="outlined"
        onFocus={event => {
          if (!event.target.value) setShowAddLabel(false);
          setBoxFocused(true);
        }}
        onBlur={event => {
          if (!event.target.value) setShowAddLabel(true);
          setBoxFocused(false);
        }}
        onChange={event => {
          setShowAddLabel(false);
          updatePrices({
            id,
            level,
            price: event.target.value,
          });
        }}
        InputProps={{
          startAdornment: (!showAddLabel || price) && (
            <InputAdornment position="start">£</InputAdornment>
          ),
        }}
        value={price || ""}
      />
    </Grid>
  );
};

PriceInputBox.propTypes = {
  priceData: PropTypes.shape({
    level: PropTypes.number.isRequired,
    price: PropTypes.string,
    id: PropTypes.string,
  }).isRequired,
  updatePrices: PropTypes.func.isRequired,
};

export default PriceInputBox;
