import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { observer, inject, PropTypes as MobXPropTypes } from "mobx-react";
import validator from "validator";

import Checkbox from "@mui/material/Checkbox";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import FormHelperText from "@mui/material/FormHelperText";
import { Box, Card, FormControlLabel } from "@mui/material";

import PositiveAction from "../../components/Button/PositiveAction";
import NegativeAction from "../../components/Button/NegativeAction";

export const ERROR_MESSAGES = {
  required: {
    POSEventTypeId: "Please provide a POS event type ID",
    name: "Please provide a name",
  },
  isNumeric: { POSEventTypeId: "Please specify a numeric POS event type ID" },
};

const AddEditComponent = ({ appStore }) => {
  const navigate = useNavigate();
  const { bookingTypeId } = useParams();

  const [formData, setFormData] = useState({
    POSEventTypeId: "",
    name: "",
    isRoom: false,
    isEnabled: false,
  });
  const [bookingType, setBookingType] = useState(null);

  const [formDataErrors, setFormDataErrors] = useState({});
  const [queryError, setQueryError] = useState("");

  useEffect(() => {
    if (bookingTypeId !== undefined) {
      appStore.bookingTypeStore
        .find(bookingTypeId)
        .then(res => {
          setBookingType(res);
          setFormData({
            POSEventTypeId: res.POSEventTypeId,
            name: res.name,
            isRoom: res.isRoom,
            isEnabled: res.isEnabled,
          });
          appStore.setLoading(false);
        })
        .catch(error => {
          appStore.log.error(error);
          navigate("/app/404");
        });
    } else {
      appStore.setLoading(false);
    }
  }, [bookingTypeId]);

  const handleCancel = () => {
    appStore.setLoading();
    navigate("/app/bookingtypes");
  };

  const handleInputChange = ({ target: { value, name } }) => {
    setFormData({ ...formData, [name]: value });
  };

  const handlePOSEventTypeIdChange = ({ target: { value } }) => {
    const fieldHasValue = value !== "";
    const fieldNotValid = !validator.isInt(value, {
      min: 1,
    });

    if (fieldHasValue && fieldNotValid) {
      setFormDataErrors({
        ...formDataErrors,
        POSEventTypeId: ERROR_MESSAGES.isNumeric.POSEventTypeId,
      });
    } else {
      const newValue = parseInt(value, 10);

      setFormData({
        ...formData,
        POSEventTypeId: fieldHasValue ? newValue : "",
      });
    }
  };

  const handleBooleanChange = (name, value) => {
    setFormData({ ...formData, [name]: value });
  };

  const validateFormData = data => {
    const validationErrors = {};

    if (!validator.isInt(data.POSEventTypeId.toString())) {
      validationErrors.POSEventTypeId = ERROR_MESSAGES.required.POSEventTypeId;
    }

    if (validator.isEmpty(data.name)) {
      validationErrors.name = ERROR_MESSAGES.required.name;
    }

    return validationErrors;
  };

  const handleSubmit = () => {
    const validationErrors = validateFormData(formData);

    if (!Object.keys(validationErrors).length) {
      appStore.setLoading();

      if (bookingTypeId !== undefined) {
        const { POSEventTypeId, name, isRoom, isEnabled } = formData;

        bookingType
          .setPOSEventTypeId(POSEventTypeId)
          .setName(name)
          .setIsRoom(isRoom)
          .setIsEnabled(isEnabled)
          .save()
          .then(() => navigate("/app/bookingtypes"))
          .catch(error => {
            appStore.log.error(error);
            setQueryError(error.message);
            appStore.setLoading(false);
          });
      } else {
        appStore.bookingTypeStore
          .add(formData)
          .then(() => navigate("/app/bookingtypes"))
          .catch(error => {
            appStore.log.error(error);
            setQueryError(error.message);
            appStore.setLoading(false);
          });
      }
    } else {
      setFormDataErrors(validationErrors);
    }
  };

  if (appStore.isLoading) {
    return null;
  }

  const { POSEventTypeId, name, isRoom, isEnabled } = formData;

  return (
    <Card sx={{ padding: "12px" }}>
      <form>
        <Grid container spacing={3}>
          {queryError && (
            <Grid item xs={12}>
              <FormHelperText error>{queryError}</FormHelperText>
            </Grid>
          )}
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              autoComplete="off"
              error={!!formDataErrors.POSEventTypeId}
              fullWidth
              helperText={formDataErrors.POSEventTypeId}
              inputProps={{ "data-testid": "POSEventTypeId" }}
              label="POS Event Type Id"
              onChange={handlePOSEventTypeIdChange}
              required
              variant="standard"
              value={POSEventTypeId}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              autoComplete="off"
              error={!!formDataErrors.name}
              fullWidth
              helperText={formDataErrors.name}
              inputProps={{ "data-testid": "name" }}
              label="Name"
              name="name"
              onChange={handleInputChange}
              required
              variant="standard"
              value={name}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={isRoom}
                  onChange={() => handleBooleanChange("isRoom", !isRoom)}
                />
              }
              label="Is a Room"
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={isEnabled}
                  label="Is Enabled"
                  onChange={() => handleBooleanChange("isEnabled", !isEnabled)}
                />
              }
              label="Is Enabled"
            />
          </Grid>
          <Grid item xs={12}>
            <Box mr={1} display="inline">
              <NegativeAction buttonText="Cancel" onClick={handleCancel} />
            </Box>
            <PositiveAction buttonText="Save" onClick={handleSubmit} />
          </Grid>
        </Grid>
      </form>
    </Card>
  );
};

AddEditComponent.propTypes = {
  appStore: MobXPropTypes.objectOrObservableObject.isRequired,
};

export default inject("appStore")(observer(AddEditComponent));
