import React, { useState } from "react";
import propTypes from "prop-types";

import { MenuItem, Select } from "@mui/material";

const CustomMultiSelect = ({ params, options }) => {
  const [selectedOptions, setSelectedOptions] = useState(params.value);

  return (
    <Select
      sx={{ width: "100%" }}
      multiple
      value={selectedOptions}
      onChange={e => {
        const { id, api, field } = params;

        setSelectedOptions(e.target.value);

        api.setEditCellValue({
          id,
          field,
          value: e.target.value,
        });
      }}
      data-testid="custom-datagrid-multi-select"
    >
      {options.map(({ id, description }) => (
        <MenuItem key={id} value={id}>
          {description}
        </MenuItem>
      ))}
    </Select>
  );
};

CustomMultiSelect.propTypes = {
  params: propTypes.object.isRequired,
  options: propTypes.array.isRequired,
};

export default CustomMultiSelect;
