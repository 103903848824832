import { types, getEnv, getParent, flow } from "mobx-state-tree";
import gql from "graphql-tag";

import PaymentType, {
  fragments as paymentTypeFragments,
} from "./models/PaymentType";

import { removeTypename, transformer } from "../helpers";

export default types
  .model("PaymentTypeStore", {
    items: types.optional(types.map(PaymentType), {}),
  })
  .views(self => ({
    get appStore() {
      return getParent(self);
    },
  }))
  .actions(self => {
    const { apolloClient } = getEnv(self);
    let findAllLoaded = false;

    return {
      find: flow(function* find(id) {
        if (self.items.has(id)) {
          return self.items.get(id);
        }

        try {
          const response = yield apolloClient.query({
            query: gql`
            {
              paymentType(id: "${id}") {
                ...PaymentTypeFullDetails
              }
            }
            ${paymentTypeFragments.fullDetails}
          `,
          });

          const item = self.track(response.data.paymentType);

          return Promise.resolve(item);
        } catch (error) {
          return Promise.reject(error);
        }
      }),

      findAll: flow(function* findAll(filter = {}) {
        if (findAllLoaded) {
          return Promise.resolve(self.items);
        }

        try {
          const response = yield apolloClient.query({
            query: gql`
              {
                paymentTypes {
                  ...PaymentTypeFullDetails
                }
              }
              ${paymentTypeFragments.fullDetails}
            `,
          });

          response.data.paymentTypes.forEach(item =>
            self.track(removeTypename(item)),
          );

          findAllLoaded = true;

          return Promise.resolve(self.items);
        } catch (error) {
          return Promise.reject(error);
        }
      }),

      add: flow(function* add(entity) {
        try {
          const input = transformer({
            name: entity.name,
            POSPaymentTypeId: parseInt(entity.POSPaymentTypeId, 10),
            fluxPaymentType: () => entity.fluxPaymentType,
          });

          const response = yield apolloClient.mutate({
            mutation: gql`
            mutation addPaymentType {
              addPaymentType(input: {
                ${input}
              }) {
                ...PaymentTypeFullDetails
              }
            }
            ${paymentTypeFragments.fullDetails}
          `,
          });

          return Promise.resolve(self.track(response.data.addPaymentType));
        } catch (error) {
          return Promise.reject(error);
        }
      }),

      track(entity) {
        if (!self.items.has(entity.id)) {
          const input = removeTypename(entity);

          input.createdBy = self.appStore.userStore.track(input.createdBy);
          if (input.updatedBy) {
            input.updatedBy = self.appStore.userStore.track(input.updatedBy);
          }
          return self.items.put(input);
        }

        return self.items.get(entity.id);
      },
    };
  });
