import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useSnackbar } from "notistack";
import gql from "graphql-tag";
import { useQuery, useMutation } from "@apollo/client";
import { observer, inject, PropTypes as MobXPropTypes } from "mobx-react";
import { GridActionsCellItem } from "@mui/x-data-grid";
import DeleteIcon from "@mui/icons-material/Delete";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

import SiteSelector from "../../../components/SiteSelector.js";
import PrintTextDataTable from "./PrintTextDatatable.js";

import NegativeAction from "../../../components/Button/NegativeAction.js";
import PositiveAction from "../../../components/Button/PositiveAction.js";

import {
  ADD_PRINT_TEXT,
  DELETE_PRINT_TEXT,
  GET_PRINT_TEXTS,
  UPDATE_PRINT_TEXT,
} from "../../../helpers/apollo/utils.js";

const printTextTypes = [
  { text: "Receipt Header", value: "receiptHeader" },
  { text: "Receipt Footer", value: "receiptFooter" },
  { text: "Ent Slip", value: "entSlip" },
];

export const generateNewRow = (type, lineNumber) => ({
  id: "new-print-text-row",
  type,
  lineNumber,
  textContent: "",
  doubleWidth: false,
  doubleHeight: false,
});

const PrintTextsManager = ({ appStore, salesArea = null }) => {
  const { enqueueSnackbar } = useSnackbar();

  const [site, setSite] = useState(null);
  const [columns, setColumns] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [addRow, setAddRow] = useState(null);
  const [queryUsedFallback, setQueryUsedFallback] = useState(false);

  const errorSnackbar = errorMsg => {
    enqueueSnackbar(`Error: ${errorMsg}`, {
      variant: "error",
      SnackbarProps: {
        "data-testid": "print-texts-error-snackbar",
      },
    });
  };

  const getColumns = () => [
    {
      field: "textContent",
      headerName: "Text",
      type: "text",
      editable: true,
      minWidth: 150,
      sortable: false,
      filterable: false,
    },
    {
      field: "doubleWidth",
      headerName: "Double Width",
      type: "boolean",
      editable: true,
      minWidth: 150,
      sortable: false,
      filterable: false,
    },
    {
      field: "doubleHeight",
      headerName: "Double Height",
      type: "boolean",
      editable: true,
      minWidth: 150,
      sortable: false,
      filterable: false,
    },
    {
      field: "lineNumber",
      headerName: "Line Number",
      type: "number",
      editable: false,
      sortable: true,
      display: false,
    },
    {
      field: "actions",
      headerName: "",
      type: "actions",
      editable: false,
      minWidth: 150,
      getActions: ({ id, row }) =>
        id === "new-print-text-row" ||
        (row.salesArea === null && row.site === null) // this will only be the case for a default
          ? []
          : [
              <GridActionsCellItem
                key={`delete-${id}`}
                icon={<DeleteIcon />}
                label="Delete"
                onClick={() => handleDeleteClick(row)}
              />,
            ],
    },
  ];

  const { loading } = useQuery(gql(GET_PRINT_TEXTS()), {
    fetchPolicy: "cache-and-network",
    onCompleted: ({ getPrintTexts: printTexts }) => {
      if (printTexts) {
        setTableData(printTexts);

        // This checks that if any of the queried sites and salesArea don't match
        // the only instance in which this should occur is when a fallback is used
        const checkForFallback = !!printTexts.find(
          ({ salesArea: resSalesArea, site: resSite }) =>
            salesArea !== (resSalesArea?.id ?? null) ||
            site !== (resSite?.id ?? null),
        );

        setQueryUsedFallback(checkForFallback);
      }

      setColumns(getColumns());

      appStore.setLoading(false);
    },
    onError: error => {
      appStore.setLoading(false);
      errorSnackbar(error.message);
    },
    variables: {
      site,
      salesArea,
    },
  });

  const [deletePrintText] = useMutation(gql(DELETE_PRINT_TEXT()), {
    onCompleted: () => {
      enqueueSnackbar("Selected item has been deleted", {
        SnackbarProps: {
          "data-testid": "bulk-print-texts-delete-snackbar",
        },
        variant: "success",
      });
    },
    onError: error => {
      errorSnackbar(error.message);
    },
    refetchQueries: [gql(GET_PRINT_TEXTS()), "GetPrintTexts"],
  });

  const [updatePrintText] = useMutation(gql(UPDATE_PRINT_TEXT()), {
    onCompleted: () => {
      enqueueSnackbar("Your changes have been saved", {
        SnackbarProps: {
          "data-testid": "bulk-print-texts-saved-snackbar",
        },
        variant: "success",
      });
    },
    refetchQueries: [gql(GET_PRINT_TEXTS()), "GetPrintTexts"],
  });

  const [addPrintText] = useMutation(gql(ADD_PRINT_TEXT()), {
    onCompleted: () => {
      enqueueSnackbar("Your new entry has been created", {
        SnackbarProps: {
          "data-testid": "bulk-print-texts-added-snackbar",
        },
        variant: "success",
      });
    },
    refetchQueries: [gql(GET_PRINT_TEXTS()), "GetPrintTexts"],
  });

  const handleSiteChange = site => setSite(site === "default" ? null : site);

  const handleAddRowToggle = type => {
    if (addRow) {
      setTableData(tableData.filter(row => row.id !== "new-print-text-row"));
      setAddRow(null);
    } else {
      const getAllLineNumbersForType = tableData.reduce(
        (acc, { type: tdType, lineNumber }) =>
          tdType === type ? [...acc, lineNumber] : acc,
        [],
      );
      const nearestFreeLineNumber = getAllLineNumbersForType.length
        ? Math.max.apply(0, getAllLineNumbersForType) + 1
        : 1;

      setTableData([...tableData, generateNewRow(type, nearestFreeLineNumber)]);
      setAddRow(type);
    }
  };

  // if the user is attempting to view a different site / sale area
  // delete the current new row
  useEffect(() => {
    if (addRow) {
      handleAddRowToggle();
    }
  }, [site, salesArea]);

  const handleSubmitRow = async (type, updatedRow, originalRow) => {
    const fieldsToCheck = ["textContent", "doubleWidth", "doubleHeight"];
    const hasChanges = fieldsToCheck.some(
      field => updatedRow[field] !== originalRow[field],
    );
    const isNewRow =
      updatedRow.id === "new-print-text-row" || queryUsedFallback;

    if (isNewRow || hasChanges) {
      const {
        actions,
        __typename,
        id: updatedRowId,
        ...updatedRowDetails
      } = updatedRow;

      const input = queryUsedFallback
        ? tableData.map(({ id, __typename, ...row }) => ({
            ...row,
            ...(id === updatedRowId && updatedRowDetails),
            site,
            salesArea,
          }))
        : {
            ...updatedRowDetails,
            site,
            salesArea,
            type,
          };

      const mutation = isNewRow ? addPrintText : updatePrintText;
      return mutation({ variables: { input } }).then(() => {
        if (isNewRow) {
          setAddRow(null);
        }

        return updatedRow;
      });
    }

    return updatedRow;
  };

  const handleDeleteClick = ({ lineNumber, type }) =>
    deletePrintText({
      variables: { input: { lineNumber, type, site, salesArea } },
    });

  return (
    <Container maxWidth={false}>
      <Grid container rowSpacing={3}>
        <Grid container item xs={12} justifyContent="space-between" my={3}>
          <Grid item xs={6}>
            <Typography variant="h2" data-testid="main-header">
              Print Texts
            </Typography>
          </Grid>
          <Grid item xs={6} container justifyContent="flex-end">
            <div>
              <SiteSelector
                siteSelectCallback={handleSiteChange}
                prependOptions={
                  !salesArea ? [{ id: "default", name: "Global Default" }] : []
                }
              />
            </div>
          </Grid>
        </Grid>
      </Grid>
      <Grid container rowSpacing={2}>
        {printTextTypes.map(
          ({ text: printTextTypeHeader, value: printTextType }) => (
            <Grid item xs={12} key={printTextType}>
              <Grid container item xs={12} mb={2} alignItems="center">
                <Grid item xs={6}>
                  <Typography variant="h5" data-testid="sub-header">
                    {printTextTypeHeader}
                  </Typography>
                </Grid>
                <Grid item container xs={6} justifyContent="flex-end">
                  {addRow === printTextType ? (
                    <NegativeAction
                      buttonText={"Remove Row"}
                      onClick={() => handleAddRowToggle(printTextType)}
                      testId={`remove-${printTextType}-button`}
                    />
                  ) : (
                    <PositiveAction
                      buttonText={"Add Row"}
                      disabled={!!addRow || (!salesArea && !site)}
                      onClick={() => handleAddRowToggle(printTextType)}
                      testId={`add-${printTextType}-button`}
                    />
                  )}
                </Grid>
              </Grid>
              <Grid
                item
                xs={12}
                data-testid={`${printTextType}-datatable-container`}
              >
                <PrintTextDataTable
                  tableData={tableData.filter(
                    ({ type }) => type === printTextType,
                  )}
                  columns={columns}
                  loading={loading}
                  processRowUpdate={(updatedRow, originalRow) =>
                    handleSubmitRow(printTextType, updatedRow, originalRow)
                  }
                  errorSnackbar={errorSnackbar}
                />
              </Grid>
            </Grid>
          ),
        )}
      </Grid>
    </Container>
  );
};

PrintTextsManager.propTypes = {
  appStore: MobXPropTypes.objectOrObservableObject.isRequired,
  salesArea: PropTypes.string,
};

export default inject("appStore")(observer(PrintTextsManager));
