import React, { useState, useEffect } from "react";
import propTypes from "prop-types";
import gql from "graphql-tag";
import { useQuery, useMutation } from "@apollo/client";
import { styled } from "@mui/material/styles";
import { observer, inject, PropTypes as MobXPropTypes } from "mobx-react";
import { useSnackbar } from "notistack";
import { v4 } from "uuid";

import { DataGrid, useGridApiRef } from "@mui/x-data-grid";

import CustomGridRow from "../../../components/Datagrid/CustomGridRow";
import CustomToolbar from "../../../components/Datagrid/CustomToolbar";

import getColumns from "../utils/columns";
import {
  ADD_PRICE_LEVEL_SETUP,
  GET_PRICE_LEVEL_SETUPS,
  UPDATE_PRICE_LEVEL_SETUP,
} from "../../../helpers/apollo/utils";

const StyledBox = styled("div")(({ theme }) => ({
  height: "70vh",
  width: "100%",
  "& .Mui-error": {
    backgroundColor: `rgb(126,10,15, ${theme.palette.mode === "dark" ? 0 : 0.1})`,
    color: theme.palette.error.main,
  },
}));

export const getNewRow = () => ({
  id: v4(),
  enabled: true,
  salesAreas: [],
  description: "",
  day: "1",
  startTime: 43200,
  endTime: 46800,
  priceLevel: "1",
  // We use this when updating a row to tell us this department needs to be created
  createdRow: true,
});

const PriceLevelSetupsBulkEditor = ({ appStore, salesAreas, site }) => {
  const apiRef = useGridApiRef();
  const { enqueueSnackbar } = useSnackbar();

  const [siteId, setSiteId] = useState(site);
  const [columns, setColumns] = useState([]);
  const [createdRow, setCreatedRow] = useState(false);
  const [loading, setLoading] = useState(true);
  const [tableData, setTableData] = useState([]);

  const errorSnackbar = errorMsg => {
    enqueueSnackbar(`Error: ${errorMsg}`, {
      variant: "error",
      SnackbarProps: {
        "data-testid": "bulk-price-level-setups-error-snackbar",
      },
    });
  };

  const resizeColumns = () => {
    apiRef?.current?.autosizeColumns({
      includeHeaders: true,
      includeOutliers: true,
    });
  };

  const dataMapper = data => ({
    ...data,
    salesAreas: data.salesAreas.map(({ id }) => id),
  });

  useEffect(() => {
    // Look at this in Tills/Components/TillsBulkEditor.js to reference of using a timeout
    setTimeout(() => {
      resizeColumns();
    }, 10);
  }, [tableData]);

  useEffect(() => {
    if (site) {
      setLoading(true);
      setSiteId(site);
      setCreatedRow(false);
    }
  }, [site]);

  useEffect(() => {
    setColumns(getColumns(salesAreas));
  }, [salesAreas]);

  useQuery(gql(GET_PRICE_LEVEL_SETUPS()), {
    fetchPolicy: "cache-and-network",
    onCompleted: data => {
      if (data?.priceLevelSetupsWithCount?.priceLevelSetups) {
        setTableData(
          data.priceLevelSetupsWithCount.priceLevelSetups.map(dataMapper),
        );
      }

      setLoading(false);
      appStore.setLoading(false);
    },
    onError: error => {
      appStore.setLoading(false);
      errorSnackbar(error.message);
    },
    skip: !siteId,
    variables: {
      filter: {
        site_is: siteId,
      },
    },
  });

  const [updatePriceLevelSetup] = useMutation(gql(UPDATE_PRICE_LEVEL_SETUP()), {
    onCompleted: () => {
      enqueueSnackbar("Your changes have been saved", {
        SnackbarProps: {
          "data-testid": "bulk-price-level-setups-saved-snackbar",
        },
        variant: "success",
      });
    },
    refetchQueries: [
      gql(GET_PRICE_LEVEL_SETUPS()),
      "PriceLevelSetupsWithCount",
    ],
  });

  const [addPriceLevelSetup] = useMutation(gql(ADD_PRICE_LEVEL_SETUP()), {
    onCompleted: () => {
      enqueueSnackbar("Your new price level setup has been created", {
        SnackbarProps: {
          "data-testid": "bulk-price-level-setups-added-snackbar",
        },
        variant: "success",
      });
    },
    refetchQueries: [
      gql(GET_PRICE_LEVEL_SETUPS()),
      "PriceLevelSetupsWithCount",
    ],
  });

  const addRow = () => {
    const newRow = getNewRow();

    const newTableData = [...tableData];
    newTableData.unshift(newRow);

    setTableData(newTableData);
    setCreatedRow(true);
  };

  const deleteRow = () => {
    const newTableData = tableData.filter(row => row.createdRow === undefined);

    setTableData(newTableData);
    setCreatedRow(false);
  };

  const updateRow = async (newRow, oldRow) => {
    return new Promise((resolve, reject) => {
      const rowUpdates = Object.fromEntries(
        Object.entries(newRow).filter(
          ([key, val]) => key in oldRow && oldRow[key] !== val,
        ),
      );

      if (newRow.salesAreas.length === 0) {
        reject("You must select at least one Sales Area");
        return;
      }

      // Only mutate if there are changes made
      if (Object.keys(rowUpdates).length) {
        if (newRow.createdRow !== undefined) {
          const input = {
            site: siteId,
            ...newRow,
          };

          delete input.id;
          delete input.createdRow;

          addPriceLevelSetup({
            variables: {
              input,
            },
          })
            .then(() => {
              setCreatedRow(false);
              resolve(newRow);
            })
            .catch(error => {
              reject(error.message);
            });
        } else {
          updatePriceLevelSetup({
            variables: {
              input: {
                id: newRow.id,
                ...rowUpdates,
              },
            },
          })
            .then(() => {
              // This calls a refresh query, so we must disable the createdRow flag following
              setCreatedRow(false);
              resolve(newRow);
            })
            .catch(error => {
              reject(error.message);
            });
        }
      } else {
        resolve(newRow);
      }
    });
  };

  return (
    <StyledBox>
      <DataGrid
        apiRef={apiRef}
        columns={columns}
        editMode="row"
        loading={loading}
        rows={tableData}
        slots={{
          row: CustomGridRow,
          toolbar: () => (
            <CustomToolbar
              addAction={addRow}
              deleteAction={deleteRow}
              isRowCreated={createdRow}
            />
          ),
        }}
        processRowUpdate={updateRow}
        onProcessRowUpdateError={errorSnackbar}
      />
    </StyledBox>
  );
};

PriceLevelSetupsBulkEditor.propTypes = {
  appStore: MobXPropTypes.objectOrObservableObject.isRequired,
  salesAreas: propTypes.arrayOf(propTypes.object).isRequired,
  site: propTypes.string.isRequired,
};

export default inject("appStore")(observer(PriceLevelSetupsBulkEditor));
