import React, { useEffect } from "react";
import { Box, Container, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { observer, inject, PropTypes as MobXPropTypes } from "mobx-react";

import Page from "../components/Page";

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    height: "100%",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
  image: {
    marginTop: 50,
    display: "inline-block",
    maxWidth: "100%",
    width: 560,
  },
}));

const NotFoundComponent = ({ appStore }) => {
  const classes = useStyles();

  useEffect(() => {
    appStore.setLoading(false);
  }, [appStore.isLoading]);

  return (
    <Page className={classes.root} title="404">
      <Box
        display="flex"
        flexDirection="column"
        height="100%"
        justifyContent="center"
      >
        <Container maxWidth="md" data-cy="404">
          <Typography align="center" color="textPrimary" variant="h1">
            404: The page you are looking for isn’t here
          </Typography>
          <Typography align="center" color="textPrimary" variant="subtitle2">
            You either tried some shady route or you came here by mistake.
            Whichever it is, try using the navigation
          </Typography>
        </Container>
      </Box>
    </Page>
  );
};

NotFoundComponent.propTypes = {
  appStore: MobXPropTypes.objectOrObservableObject.isRequired,
};

const NotFound = inject("appStore")(observer(NotFoundComponent));

export default NotFound;
