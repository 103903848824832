import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { Link } from "react-router-dom";

import EditIcon from "@mui/icons-material/Edit";

import BaseTable from "../../../components/Basetable/Basetable";
import { DateRangeFilter } from "../../../components/Basetable/Filters/DateRangeFilter";
import IconButton from "../../../components/Button/IconButton";

import { GET_PRODUCT_OPTION_GROUPS_WITH_COUNT } from "../../../helpers/apollo/utils";

const defaultColumns = [
  { label: "Name", name: "name", options: { filter: false, sort: true } },
  {
    label: "Created at",
    name: "createdAt",
    options: { filter: false, sort: true },
  },
  {
    label: "Created between",
    name: "createdBetween",
    options: {
      display: false,
      sort: true,
      filter: true,
      filterType: "custom",
      filterBy: "createdAt_between",
      customFilterListOptions: {
        render: v =>
          `Created from: ${v[0]?.from ? moment(v[0].from).format("DD/MM/YYYY") : "Past"} ${v[0]?.to ? `- ${moment(v[0].to).format("DD/MM/YYYY")}` : ""}`,
      },
      filterOptions: {
        display: (filterList, onChange, index, column) => {
          return (
            <DateRangeFilter
              column={column}
              index={index}
              label="Created at"
              onChangeHandler={onChange}
              values={filterList[index]}
            />
          );
        },
      },
      viewColumns: false,
    },
  },
  {
    name: "id",
    label: "Actions",
    options: {
      viewColumns: false,
      filter: false,
      sort: false,
      customBodyRender: value => (
        <Link to={`/app/product-option-groups/${value}`}>
          <IconButton icon={<EditIcon />} />
        </Link>
      ),
    },
  },
];

const defaultData = [];

const defaultColumnsSort = [
  {
    field: "createdAt",
    direction: "DESC",
  },
];

const defaultSelectedItemArray = [];

const defaultFilterObject = {};

const defaultMapper = item => ({
  ...item,
  createdAt: moment(item.createdAt).format("Do MMM YYYY, HH:mm"),
});

const defaultRowsPerPageOptions = [10, 25, 50, 100];

const defaultSortFieldMapper = name => name;

function ProductOptionGroupsTable({
  columns = defaultColumns,
  overrideData = defaultData,
  defaultSelectedItems = defaultSelectedItemArray,
  defaultSort = defaultColumnsSort,
  defaultFilter = defaultFilterObject,
  handleRowClick = () => {},
  mapper = defaultMapper,
  multiSelectOptions = () => ({
    selectableRows: "none",
    selectToolbarPlacement: "none",
  }),
  onPageChange = null,
  page = 0,
  rowsPerPageOptions = defaultRowsPerPageOptions,
  searchBy = "name_contains", // Can be set to null to disable search
  sortFieldMapper = defaultSortFieldMapper,
  query = GET_PRODUCT_OPTION_GROUPS_WITH_COUNT(),
  viewColumns = true,
}) {
  return (
    <BaseTable
      columns={columns}
      defaultSelectedItems={defaultSelectedItems}
      defaultSort={defaultSort}
      defaultFilter={defaultFilter}
      getTableData={d => {
        return d.productOptionGroupsWithCount.productOptionGroups;
      }}
      getRowCount={d => {
        return d.productOptionGroupsWithCount.totalRows;
      }}
      handleRowClick={handleRowClick}
      mapper={mapper}
      multiSelectOptions={multiSelectOptions}
      overrideData={overrideData}
      onPageChange={onPageChange}
      page={page}
      rowsPerPageOptions={rowsPerPageOptions}
      searchBy={searchBy}
      query={query}
      sortFieldMapper={sortFieldMapper}
      viewColumns={viewColumns}
    />
  );
}

ProductOptionGroupsTable.propTypes = {
  columns: PropTypes.arrayOf(
    PropTypes.shape({ label: PropTypes.string, name: PropTypes.string }),
  ),
  overrideData: PropTypes.arrayOf(PropTypes.shape({})),
  defaultSelectedItems: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.arrayOf(PropTypes.shape({})),
  ]),
  defaultSort: PropTypes.arrayOf(
    PropTypes.shape({
      field: PropTypes.string,
      direction: PropTypes.oneOf(["ASC", "DESC"]),
    }),
  ),
  defaultFilter: PropTypes.shape({ filter: PropTypes.string }),
  handleRowClick: PropTypes.func,
  mapper: PropTypes.func,
  multiSelectOptions: PropTypes.func,
  onPageChange: PropTypes.func,
  page: PropTypes.number,
  rowsPerPageOptions: PropTypes.arrayOf(PropTypes.number),
  searchBy: PropTypes.string,
  sortFieldMapper: PropTypes.func,
  query: PropTypes.string,
  viewColumns: PropTypes.bool,
};

export default ProductOptionGroupsTable;
