import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

import { withStyles } from "@mui/styles";
import Typography from "@mui/material/Typography";

export const styles = theme => ({
  root: {
    padding: 15,
    marginBottom: theme.spacing(1),
    border: "1px solid transparent",
    borderRadius: 3,
  },
  info: {
    color: "#004085",
    backgroundColor: "#cce5ff",
    borderColor: "#b8daff",
  },

  success: {
    backgroundColor: "#d4edda",
    borderColor: "#c3e6cb",
    color: "#155724",
  },
  warning: {
    backgroundColor: "#fff3cd",
    borderColor: "#ffeeba",
    color: "#856404",
  },
  error: {
    backgroundColor: "#f8d7da",
    borderColor: "#f5c6cb",
    color: "#721c24",
  },
});

const Alert = ({ variant = "info", children, classes }) => {
  const className = clsx(classes.root, {
    [classes.info]: variant === "info",
    [classes.success]: variant === "success",
    [classes.warning]: variant === "warning",
    [classes.error]: variant === "error",
  });

  return (
    <Typography variant="subtitle1" className={className} role="alert">
      {children}
    </Typography>
  );
};

Alert.propTypes = {
  variant: PropTypes.string,
  children: PropTypes.node.isRequired,
  classes: PropTypes.shape({
    info: PropTypes.string,
    success: PropTypes.string,
    warning: PropTypes.string,
    error: PropTypes.string,
    root: PropTypes.string.isRequired,
  }).isRequired,
};

export default withStyles(styles)(Alert);
