import React, { useState } from "react";
import PropTypes from "prop-types";

import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import Box from "@mui/material/Box";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

import Grid from "@mui/material/Grid";
import { Typography } from "@mui/material";

import NegativeAction from "../../../../components/Button/NegativeAction";
import PositiveAction from "../../../../components/Button/PositiveAction";

const PromotionControls = ({
  handleCancel,
  handleDelete,
  handleDuplicate,
  handleSubmit,
  showScheduleBtn = true,
  isLoading = false,
  submitError,
  toggleActiveStatus,
}) => {
  const [open, setOpen] = useState(false);

  const handleDeleteWithDialog = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleConfirmDelete = () => {
    setOpen(false);
    handleDelete();
  };

  return (
    <Grid container justifyContent="space-between">
      {Object.keys(submitError).length > 0 && (
        <Grid xs={12} item>
          <Box mb={2}>
            <Alert severity="error">
              <AlertTitle>Error saving promotion</AlertTitle>
              {Object.values(submitError).flatMap(error => (
                <Typography key={error}>{error}</Typography>
              ))}
            </Alert>
          </Box>
        </Grid>
      )}
      <Grid item xs>
        <Box mr={1} display="inline">
          <NegativeAction
            buttonText="DELETE PROMOTION"
            onClick={handleDeleteWithDialog}
          />
        </Box>
        <PositiveAction
          buttonText="DUPLICATE PROMOTION"
          onClick={handleDuplicate}
        />
      </Grid>
      <Grid container justifyContent="flex-end" item xs>
        {toggleActiveStatus()}
        <Box mr={1} display="inline">
          <NegativeAction
            buttonText="CANCEL"
            onClick={handleCancel}
            disabled={isLoading}
          />
        </Box>
        <PositiveAction
          buttonText="SAVE"
          onClick={handleSubmit(false)}
          disabled={isLoading}
          testId="promotion-form-submit"
        />
        {showScheduleBtn && (
          <Box ml={1} display="inline">
            <PositiveAction
              buttonText="SCHEDULE CHANGE"
              onClick={handleSubmit(true)}
              disabled={isLoading}
              testId="promotion-schedule-select-button"
            />
          </Box>
        )}
      </Grid>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Are you sure you want to delete?
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you wish to delete this promotion. The promotion cannot
            be recovered if you choose YES
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <NegativeAction onClick={handleClose} />
          <PositiveAction onClick={handleConfirmDelete} />
        </DialogActions>
      </Dialog>
    </Grid>
  );
};

PromotionControls.propTypes = {
  handleCancel: PropTypes.func.isRequired,
  handleDelete: PropTypes.func.isRequired,
  handleDuplicate: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  showScheduleBtn: PropTypes.bool,
  isLoading: PropTypes.bool,
  submitError: PropTypes.shape().isRequired,
  toggleActiveStatus: PropTypes.func.isRequired,
};

export default PromotionControls;
