import React from "react";
import Chip from "@mui/material/Chip";
import PropTypes from "prop-types";
import { TableFilterList } from "mui-datatables";

const CustomChip = ({ label, onDelete, ...props }) => {
  const columnName = props.columnNames[props.index].name;

  return (
    <Chip
      variant="outlined"
      color="secondary"
      label={`${columnName}: ${label}`}
      onDelete={onDelete}
    />
  );
};

CustomChip.propTypes = {
  label: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  onDelete: PropTypes.func.isRequired,
  columnNames: PropTypes.arrayOf({
    name: PropTypes.string.isRequired,
  }).isRequired,
};

const CustomFilterList = props => {
  return <TableFilterList {...props} ItemComponent={CustomChip} />;
};

export default CustomFilterList;
