import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import gql from "graphql-tag";
import { useQuery, useMutation } from "@apollo/client";
import { styled } from "@mui/material/styles";
import { useSnackbar } from "notistack";
import { v4 as uuid } from "uuid";

import { DataGrid, useGridApiRef } from "@mui/x-data-grid";

import CustomGridRow from "../../../components/Datagrid/CustomGridRow";
import CustomToolbar from "../../../components/Datagrid/CustomToolbar";

import { createColumns } from "../utils/columns.js";
import {
  ADD_SERVER,
  GET_DEPARTMENTS,
  GET_SERVER_ROLES,
  GET_SERVERS_WITH_COUNT,
  UPDATE_SERVER,
} from "../../../helpers/apollo/utils.js";

const StyledBox = styled("div")(({ theme }) => ({
  height: "70vh",
  width: "100%",
  "& .Mui-error": {
    backgroundColor: `rgb(126,10,15, ${theme.palette.mode === "dark" ? 0 : 0.1})`,
    color: theme.palette.error.main,
  },
}));

const flattenServerData = (data = []) =>
  data.map(server => ({
    ...server,
    department: server?.department?.id ?? "",
    userLevel: server.userLevel.id,
    authLevel: server?.authLevel?.id ?? "",
  }));

export const getNewRow = () => ({
  authCode: "",
  authLevel: "",
  department: "",
  hourlyCost: 0,
  id: uuid(),
  isAutomated: false,
  isTraining: false,
  logonCode: "",
  name: "",
  userLevel: "",
  createdRow: true,
});

const validateFields = (oldRow, newRow, tableData = null) => {
  const { name, logonCode, authCode, authLevel } = newRow;

  if (!name.match(/^[a-zA-Z0-9\-_\s-]+$/)) {
    return "Invalid name input";
  }
  if (!logonCode.toString().match(/^\d{3,}$/)) {
    return "Logon Code must be 3 or more numbers";
  }
  if (
    parseInt(oldRow.logonCode) !== logonCode &&
    tableData.some(
      obj =>
        obj.logonCode === parseInt(logonCode) ||
        obj.authCode === parseInt(logonCode),
    )
  ) {
    return "Logon Code already in use";
  }
  if (
    authCode &&
    authCode !== "NULL" &&
    !authCode.toString().match(/^\d{3,}$/)
  ) {
    return "Auth Code must be 3 or more numbers";
  }
  if (
    parseInt(oldRow.authCode) !== authCode &&
    tableData.some(
      obj =>
        obj.logonCode === parseInt(authCode) ||
        obj.authCode === parseInt(authCode),
    )
  ) {
    return "Auth Code already in use";
  }
  if ((!authLevel || authLevel === "NULL") && authCode) {
    return "Please select an Auth Level";
  }
  if (authLevel && authLevel !== "NULL" && !authCode) {
    return "Please enter an Auth Code";
  }
};

const BulkEditor = ({ site }) => {
  const apiRef = useGridApiRef();
  const { enqueueSnackbar } = useSnackbar();

  const [columns, setColumns] = useState([]);
  const [createdRow, setCreatedRow] = useState(false);
  const [siteId, setSiteId] = useState(site);
  const [tableData, setTableData] = useState([]);

  const errorSnackbar = errorMsg => {
    enqueueSnackbar(`${errorMsg}`, {
      variant: "error",
      SnackbarProps: {
        "data-testid": "bulk-servers-error-snackbar",
      },
    });
  };

  useEffect(() => {
    if (site) {
      setSiteId(site);
      setCreatedRow(false);
    }
  }, [site]);

  const { loading: serversLoading } = useQuery(gql(GET_SERVERS_WITH_COUNT()), {
    fetchPolicy: "cache-and-network",
    onCompleted: data => {
      if (data?.serversWithCount?.servers) {
        setTableData(flattenServerData(data.serversWithCount.servers));
      }
    },
    onError: error => errorSnackbar(error.message),
    skip: !siteId,
    variables: {
      filter: {
        site_is: siteId,
      },
    },
  });

  const { loading: departmentLoading, data: departments } = useQuery(
    gql(GET_DEPARTMENTS()),
    {
      onError: error => errorSnackbar(error.message),
    },
  );

  const { loading: authLevelLoading, data: serverRoles } = useQuery(
    gql(GET_SERVER_ROLES()),
    {
      onError: error => errorSnackbar(error.message),
    },
  );

  const [addServer] = useMutation(gql(ADD_SERVER()), {
    onCompleted: () => {
      enqueueSnackbar("Your new server has been created", {
        SnackbarProps: { "data-testid": "bulk-servers-added-snackbar" },
        variant: "success",
      });
    },
    refetchQueries: [gql(GET_SERVERS_WITH_COUNT()), "GetServersWithCount"],
  });

  const [updateServer] = useMutation(gql(UPDATE_SERVER()), {
    onCompleted: () => {
      enqueueSnackbar("Your changes have been saved", {
        SnackbarProps: { "data-testid": "bulk-servers-updated-snackbar" },
        variant: "success",
      });
    },
    refetchQueries: [gql(GET_SERVERS_WITH_COUNT()), "GetServersWithCount"],
  });

  useEffect(() => {
    if (departmentLoading || authLevelLoading) {
      return;
    }

    setColumns(
      createColumns(
        serverRoles?.serverRolesWithCount?.serverRoles,
        departments?.departmentsWithCount?.departments,
      ),
    );
  }, [departmentLoading, authLevelLoading]);

  if (departmentLoading || authLevelLoading || !columns) return null;

  const addRow = () => {
    const newRow = getNewRow();

    const NewTableData = [...tableData];
    NewTableData.unshift(newRow);

    setTableData(NewTableData);
    setCreatedRow(true);
  };

  const deleteRow = () => {
    const newTableData = tableData.filter(row => row.createdRow === undefined);

    setTableData(newTableData);
    setCreatedRow(false);
  };

  const updateRow = async (newRow, oldRow) => {
    const validationError = validateFields(oldRow, newRow, tableData);
    if (validationError) {
      throw new Error(validationError);
    } else {
      const rowUpdates = Object.fromEntries(
        Object.entries(newRow).filter(
          ([key, val]) => key in oldRow && oldRow[key] !== val,
        ),
      );

      if (Object.keys(rowUpdates).length) {
        try {
          if (newRow.createdRow !== undefined) {
            await addServer({
              variables: {
                input: {
                  name: newRow.name,
                  logonCode: parseInt(newRow.logonCode),
                  userLevel: newRow.userLevel,
                  hourlyCost: parseInt(newRow.hourlyCost),
                  departmentId: newRow.department,
                  ...(newRow.authCode && {
                    authCode: parseInt(newRow.authCode),
                  }),
                  authLevel: newRow.authLevel,
                  isTraining: newRow.isTraining,
                  isAutomated: newRow.isAutomated,
                  site: siteId,
                },
              },
            });
            setCreatedRow(false);
            return newRow;
          } else {
            await updateServer({
              variables: {
                input: {
                  id: newRow.id,
                  name: newRow.name,
                  logonCode: parseInt(newRow.logonCode),
                  userLevel: newRow.userLevel,
                  authCode: newRow.authCode ? parseInt(newRow.authCode) : null,
                  authLevel: newRow.authLevel,
                  isTraining: newRow.isTraining,
                  hourlyCost: parseInt(newRow.hourlyCost),
                  departmentId: newRow.department,
                  isAutomated: newRow.isAutomated,
                },
              },
            });
            return newRow;
          }
        } catch (error) {
          const errorMsg = error?.message || "Please try again later.";
          throw new Error(errorMsg);
        }
      } else {
        return newRow;
      }
    }
  };

  return (
    <StyledBox>
      <DataGrid
        apiRef={apiRef}
        columns={columns}
        editMode="row"
        initialState={{
          sorting: {
            sortModel: [{ field: "name", sort: "asc" }],
          },
        }}
        loading={serversLoading || departmentLoading || authLevelLoading}
        rows={tableData}
        slots={{
          row: CustomGridRow,
          toolbar: () => (
            <CustomToolbar
              addAction={addRow}
              deleteAction={deleteRow}
              isRowCreated={createdRow}
            />
          ),
        }}
        processRowUpdate={updateRow}
        onProcessRowUpdateError={errorSnackbar}
      />
    </StyledBox>
  );
};

BulkEditor.propTypes = {
  site: PropTypes.string.isRequired,
};

export default BulkEditor;
