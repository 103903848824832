import { types, getParent, flow, getEnv } from "mobx-state-tree";
import gql from "graphql-tag";

import User, { fragments as UserFragments } from "./User";
import DateTime from "./DateTime";
import { transformer } from "../../helpers";

export const fragments = {
  fullDetails: gql`
    fragment ProductTagFullDetails on ProductTag {
      id
      name
      POSId
      type
    }
  `,
  allAssociatedProducts: gql`
    fragment AllAssociatedProducts on ProductTag {
      allAssociatedProducts
    }
  `,
  productTagMeta: gql`
    fragment ProductTagMeta on ProductTag {
      createdAt
      createdBy {
        ...UserFullDetails
      }
      updatedAt
      updatedBy {
        ...UserFullDetails
      }
    }
    ${UserFragments.fullDetails}
  `,
  productTagParent: gql`
    fragment ProductTagParent on ProductTag {
      parentTag {
        id
        name
      }
    }
  `,
};

const ProductTagParent = types.model({
  id: types.identifier,
  name: types.string,
});

const ProductTag = types
  .model("ProductTag", {
    id: types.identifier,
    products: types.maybeNull(
      types.frozen(types.array(types.maybeNull(types.string))),
    ),
    name: types.string,
    POSId: types.maybeNull(types.number),
    type: types.enumeration("type", [
      "MAJORGROUP",
      "PRODUCTGROUP",
      "SUBGROUP",
      "GENERIC",
    ]),
    parentTag: types.maybeNull(ProductTagParent),
    createdAt: types.maybeNull(DateTime),
    createdBy: types.maybeNull(types.reference(User)),
    updatedAt: types.maybeNull(DateTime),
    updatedBy: types.maybeNull(types.reference(User)),
  })
  .preProcessSnapshot(snapshot => {
    const updatedSnapshot = { ...snapshot };

    if (updatedSnapshot.parentTag === undefined) {
      updatedSnapshot.parentTag = null;
    }

    return updatedSnapshot;
  })
  .views(self => ({
    get siteStore() {
      return getParent(getParent(self));
    },
    get appStore() {
      return getParent(self.ProductTagStore);
    },
    asPromoRow() {
      return {
        id: self.id,
        name: self.name,
        POSId: self.POSId,
        type: self.type,
        parentTagId: self.parentTag?.id,
      };
    },
  }))
  .actions(self => {
    const { apolloClient } = getEnv(self);

    return {
      getAllAssociatedProducts: flow(function* getChildren() {
        if (self.products) {
          return Promise.resolve(self);
        }
        try {
          const response = yield apolloClient.query({
            query: gql`
            {
              productTag(id: "${self.id}") {
                ...AllAssociatedProducts
              }
            }
            ${fragments.allAssociatedProducts}
          `,
          });

          self.products = response.data.productTag.allAssociatedProducts;

          return Promise.resolve(self);
        } catch (error) {
          return Promise.reject(error);
        }
      }),
      getMeta: flow(function* getMeta() {
        try {
          const response = yield apolloClient.query({
            query: gql`
          {
            productTag(id: "${self.id}") {
              ...ProductTagMeta
            }
          }
          ${fragments.productTagMeta}`,
          });
          const { createdAt, createdBy, updatedAt, updatedBy } =
            response.data.productTag;
          self.createdAt = createdAt;
          self.createdBy = createdBy;
          self.updatedAt = updatedAt;
          self.updatedBy = updatedBy;
          return Promise.resolve(self);
        } catch (err) {
          return Promise.reject(err);
        }
      }),

      getParentTag: flow(function* getParentTag() {
        if (self.parentTag !== null) {
          return self;
        }
        try {
          const response = yield apolloClient.query({
            query: gql`
          {
            productTag(id: "${self.id}") {
              ...ProductTagParent
            }
          }
          ${fragments.productTagParent}`,
          });

          self.parentTag = response.data.productTag.parentTag;
          return Promise.resolve(self);
        } catch (err) {
          return Promise.reject(err);
        }
      }),

      setName(value) {
        self.name = value;
        return self;
      },

      setFilter(isFiltered) {
        self.isFiltered = isFiltered;
      },

      setPOSId(value) {
        self.POSId = value;
        return self;
      },

      setType(value) {
        self.type = value;
        return self;
      },

      setParentTag(value) {
        if (value) {
          const { id, name } = value;
          self.parentTag = { id, name };
        } else {
          self.parentTag = value;
        }
        return self;
      },

      save: flow(function* save() {
        delete self.isFiltered;
        const args = {
          id: self.id,
          name: self.name,
          POSId: self.POSId,
          type: () => self.type,
          parentTagId: self.parentTag?.id ?? null,
        };

        if (args.parentTagId === null) {
          delete args.parentTagId;
        }
        const params = transformer(args);

        yield apolloClient.mutate({
          mutation: gql`
          mutation update {
            updateProductTag(input: {
                ${params}
              }) {
                 id
              }
          }
      `,
        });
      }),
    };
  });
export default ProductTag;
