import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { observer, inject, PropTypes as MobXPropTypes } from "mobx-react";
import { observable, action, reaction, toJS, makeObservable } from "mobx";
import { Link } from "react-router-dom";

import { withStyles } from "@mui/styles";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import IconButton from "@mui/material/IconButton";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { red } from "@mui/material/colors";

import MUIDataTable from "mui-datatables";

import ErrorBoundary from "../../components/ErrorBoundary";
import Page from "../../components/Page";
import MuiDatatablesFilters from "../../components/Filter/MuiDatatablesFilters";
import AddAction from "../../components/Button/AddAction";
import PositiveAction from "../../components/Button/PositiveAction";
import NegativeAction from "../../components/Button/NegativeAction";

import { FRONTEND_DATE_FORMAT } from "../../constants";

const styles = theme => ({
  margin: {
    margin: theme.spacing(1),
  },
  danger: {
    color: red[500],
    "&:hover": {
      backgroundColor: red[50],
    },
  },
  warning: {
    backgroundColor: "#fff3cd",
  },
  warningMessage: {
    height: "auto",
    borderBottom: 0,
  },
});

class ListComponent extends React.Component {
  items = [];
  selected = null;

  listReaction = () => {};
  deleteReason = "";
  columns = [
    { name: "Name", options: { filter: true, sort: true } },
    { name: "POS Event Type Id", options: { filter: true, sort: true } },
    { name: "Created", options: { filter: false, sort: true } },
    {
      name: "Actions",
      options: {
        filter: false,
        sort: false,
        customBodyRender: bookingType => {
          const { classes } = this.props;
          return (
            <Fragment key={bookingType.id}>
              <Link to={`/app/bookingtypes/${bookingType.id}`}>
                <IconButton size="medium">
                  <EditIcon />
                </IconButton>
              </Link>
              <IconButton
                className={classes.danger}
                onClick={() => this.handleDeleteClick(bookingType)}
                size="medium"
              >
                <DeleteIcon />
              </IconButton>
            </Fragment>
          );
        },
      },
    },
  ];

  options = {
    filterType: "dropdown",
    selectableRows: "none",
    search: false,
  };

  setFormError = value => {
    this.error = value;
  };

  constructor(props) {
    super(props);

    makeObservable(this, {
      items: observable,
      selected: observable,
      handleDeleteClick: action,
      handleDeleteNo: action,
      handleDeleteYes: action,
      setItems: action,
    });
  }

  componentDidMount() {
    const { appStore: app } = this.props;
    const that = this;
    app.bookingTypeStore
      .findAll()
      .then(bookingTypes => {
        this.listReaction = reaction(
          () => [...bookingTypes.values()].length,
          () => this.setItems([...bookingTypes.values()]),
          { fireImmediately: true },
        );
        that.setItems([...bookingTypes.values()]);
        app.setLoading(false);
      })
      .catch(error => {
        app.log.error(error);
        that.setFormError(error.message);
        app.setLoading(false);
      });
  }

  componentWillUnmount() {
    this.listReaction();
  }

  setItems = items => {
    this.items = items.map(bookingType => [
      bookingType.name,
      bookingType.POSEventTypeId,
      bookingType.createdAt.format(FRONTEND_DATE_FORMAT),
      toJS(bookingType),
    ]);
  };

  handleDeleteClick = bookingType => {
    this.selected = toJS(bookingType);
  };

  handleDeleteYes = () => {
    const { appStore: app } = this.props;
    const that = this;

    app.setLoading();

    app.bookingTypeStore
      .remove(this.selected.id)
      .then(() => {
        that.handleDeleteClick(null);
        app.setLoading(false);
      })
      .catch(error => {
        app.log.error(error);
        that.setFormError(error.message);
        app.setLoading(false);
      });
  };

  handleDeleteNo = () => {
    this.selected = null;
  };

  render() {
    return (
      <ErrorBoundary>
        <Page title="Booking Types">
          <Container maxWidth={false}>
            <Grid container rowSpacing={3}>
              <Grid container item xs={12} justifyContent="end" mt={3}>
                <Link to="/app/bookingtypes/add">
                  <AddAction buttonText="Add Booking Type" />
                </Link>
              </Grid>
              <Grid item xs={12}>
                <section>
                  {this.selected && (
                    <Dialog open onClose={this.handleClose}>
                      <DialogTitle>Delete Booking Type</DialogTitle>
                      <DialogContent>
                        <DialogContentText>
                          Are you sure you want to delete the following booking
                          type?
                          <br />
                          <b>{this.selected.name}</b>
                          <br />
                        </DialogContentText>
                      </DialogContent>
                      <DialogActions>
                        <NegativeAction
                          buttonText="No"
                          onClick={this.handleDeleteNo}
                        />
                        <PositiveAction
                          buttonText="Yes"
                          onClick={this.handleDeleteYes}
                        />
                      </DialogActions>
                    </Dialog>
                  )}
                  {this.items.length > 0 && (
                    <MUIDataTable
                      data={toJS(this.items)}
                      columns={this.columns}
                      options={this.options}
                      components={{
                        TableFilterList: MuiDatatablesFilters,
                      }}
                    />
                  )}
                </section>
              </Grid>
            </Grid>
          </Container>
        </Page>
      </ErrorBoundary>
    );
  }
}
ListComponent.propTypes = {
  appStore: MobXPropTypes.objectOrObservableObject.isRequired,
  classes: PropTypes.shape({
    danger: PropTypes.string,
  }).isRequired,
};

const List = withStyles(styles)(inject("appStore")(observer(ListComponent)));

export default List;
