import React, { useState, useEffect, useReducer } from "react";
import PropTypes from "prop-types";
import { observer, inject, PropTypes as MobXPropTypes } from "mobx-react";
import validator from "validator";
import { useParams, useNavigate } from "react-router-dom";
import { v4 } from "uuid";
import moment from "moment";

import { makeStyles } from "@mui/styles";

import Alert from "@mui/material/Alert";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Container from "@mui/material/Container";
import Collapse from "@mui/material/Collapse";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import Grid from "@mui/material/Grid";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";

import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";

import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import Close from "@mui/icons-material/Close";
import PositiveAction from "../../components/Button/PositiveAction";
import NegativeAction from "../../components/Button/NegativeAction";

import ErrorBoundary from "../../components/ErrorBoundary";
import Page from "../../components/Page";
import ProductChooser from "./components/Form/ProductChooser";
import BucketProductChooser from "./components/Form/BucketProductChooser";
import DayTimeChooser from "./components/Form/DayTimeChooser";
import SiteChooser from "./components/Form/SiteChooser";
import PromotionControls from "./components/Form/PromotionControls";
import checkBucketRules from "./bucketUtils";
import DiscountBucketBuilder from "./components/Form/DiscountBucketBuilder";
import ChangesetsTable from "../Changesets/Components/ChangesetsTable";
import {
  rulesReducer,
  setInitialRules,
  buildForDb,
} from "./components/RuleReducer";
import Switch from "../../components/Switch";
import {
  checkForErrors,
  checkForItemsInStagingBucket,
  checkForNoSites,
  checkForNoDays,
  checkForInactiveSite,
  checkEmptyNonDefaultBucket,
  checkForNoEligibilityRules,
  checkStringForInvalidCharacters,
} from "./components/AddEditValidation";
import ValidationModal from "./components/Form/ValidationModal";
import ChangeSection from "./components/ChangeSection";
import { Checkbox, FormControlLabel } from "@mui/material";

export const PROMOTION_ACTIONS = {
  fixed_discount: {
    id: "fixed_discount",
    label: "Fixed amount off order",
    help: "Discounts the total bill by a fixed amount if the rules of the promotion are met. Additionally you can provide a minimum order amount that will cause the promotion to trigger only if the order total matches or exceeds that number.",
  },
  percent_discount: {
    id: "percent_discount",
    label: "Percentage off order",
    help: "Discounts the bill by a specified percentage. For example entering 50% will cut the bill in half",
  },
  free_products: {
    id: "free_products",
    label: "Free products",
    help: "Adds the specified product(s) to the order free of charge",
  },
  fixed_price: {
    id: "fixed_price",
    label: "Fixed price",
    help: `Items that satisfy the promotion are sold for a fixed price.
    When buckets are used the combination of products are given the fixed price.
    Any burger for £5, 3 courses for £15`,
  },
  multibuy: {
    id: "multibuy",
    label: "Multibuy",
    help: `3 for 2 style promotions. Select the number of items required to be present, and the
    number of those which will be sold at full price. In addition choose whether the cheapest
    item should be free, or the most recently added item.`,
  },
  qualifying_discount_bucket: {
    id: "qualifying_discount_bucket",
    label: "Percent discount on eligible items in qualified order",
    help: `This promotion allows a discount to be applied to any item(s) from a list of products when other specified items are present in an order.
    Examples: Buy any starter, any main and dessert is on us.  Buy any two mains and have a half price bottle of wine.`,
  },
};

export const ERROR_MESSAGES = {
  required: {
    action: "Please select a promotion type",
    name: "Please specify a name",
    startDate: "Please specify a start date",
  },
  isNumeric: {
    percentDiscount: "Please specify a numerical percentage discount",
    fixedDiscount: "Please specify a numerical fixed discount greater than 0",
    fixedPrice: "Please specify a numerical fixed price greater than 0",
    minimumOrderAmount:
      "Please specify a numerical value greater than or equal to 0",
  },
  other: {
    discountable: "This promotion requires a list of discountable products",
    endDate: "Please specify an end date",
    freeProducts: "Please select at least one free item",
    invalidStartEndDate:
      "Please ensure that the start date occurs before the end date",
    emptyNonDefaultBucket: "Additional buckets must contain items.",
    invalidNameField: "An invalid character has been entered in this field",
    invalidDescriptionField:
      "An invalid character has been entered in this field",
  },
};

const useStyles = makeStyles(() => ({
  formControl: {
    minWidth: 300,
  },
  fullHeight: {
    height: "100%",
  },
}));

const errorReducer = (state, action) => {
  let errorMessage = "An error occured";

  // return the first error message it finds
  Object.keys(ERROR_MESSAGES).every(category => {
    if (ERROR_MESSAGES[category][action.key]) {
      errorMessage = ERROR_MESSAGES[category][action.key];
      return false;
    }

    return true;
  });

  // action.key
  if (action.type === "set") {
    return {
      ...state,
      [action.key]: action.message || errorMessage,
    };
  }

  if (action.type === "unset") {
    const newState = { ...state };
    delete newState[action.key];
    return newState;
  }
  return state;
};

const AddEditComponent = ({ appStore, viewChangeOverride = null }) => {
  const navigate = useNavigate();
  const { promotionId } = useParams();
  const classes = useStyles();

  const [submitError, dispatchError] = useReducer(errorReducer, {});
  const [showCollapsibleContent, setShowCollapsibleContent] = useState({
    eligibilityRules: true,
    sites: true,
    dayAndTimeRules: true,
    dateRange: true,
    savedAlert: false,
  });

  const [changesetAlertState, setChangesetAlertState] = useState(false);
  const [alertState, setAlertState] = useState(false);
  const [scheduleSaveMode, setScheduleSaveMode] = useState(false);
  const [alertWarnings, setAlertWarnings] = useState({
    itemsInStagingBucket: false,
    noSites: false,
    noDays: false,
    inactiveSiteSelected: false,
    noEligibilityRules: false,
  });

  const [canIncreasePrice, setCanIncreasePrice] = useState(false);

  const [savedAlertNotificationTimeout, setSavedAlertNotificationTimeout] =
    useState(null);

  const handleToggleCollapse = componentName => () => {
    setShowCollapsibleContent({
      ...showCollapsibleContent,
      [componentName]: !showCollapsibleContent[componentName],
    });
  };

  const handleUpdateCollapsedContent = ({ name, value }) => {
    setShowCollapsibleContent({
      ...showCollapsibleContent,
      [name]: value,
    });
  };

  const [promotion, setPromotion] = useState(null);

  const [allSites, setAllSites] = useState([]);

  /** Name */
  const [name, setName] = useState("");

  const handleNameChange = ({ target: { value: newName } }) => {
    setName(newName);
    dispatchError({ type: "unset", key: "name" });
  };

  const [active, setActive] = useState(false);
  const handleActiveToggle = () => {
    setActive(!active);
  };

  const [description, setDescription] = useState("");
  const handleDescriptionChange = ({ target: { value: newDescription } }) => {
    setDescription(newDescription);
  };

  /** Applicability date range */

  const [startAt, setStartAt] = useState(null);
  const [endAt, setEndAt] = useState(null);
  const [showEndDate, setShowEndDate] = useState(false);

  const handleStartDateChange = date => {
    setStartAt(date);
    dispatchError({ type: "unset", key: "startDate" });
    dispatchError({ type: "unset", key: "invalidStartEndDate" });
  };

  const handleEndDateChange = date => {
    setEndAt(date);
    dispatchError({ type: "unset", key: "endDate" });
    dispatchError({ type: "unset", key: "invalidStartEndDate" });
  };

  const handleEndDateToggle = ({ target: { checked } }) => {
    if (!checked) {
      setEndAt(null);
      setShowEndDate(false);
      dispatchError({ type: "unset", key: "endDate" });
      dispatchError({ type: "unset", key: "invalidStartEndDate" });
    } else {
      setShowEndDate(true);
    }
  };

  // This has been commented out for now, nobody knows what loyalty profiles do or how they work.

  /** Loyalty */
  // const [allLoyaltyProfiles] = useState(appStore.settingsStore.loyaltyProfiles);
  // const [allLoyaltyProfilesChecked, setAllLoyaltyProfilesChecked] =
  //   useState(false);

  // const [siteLoyaltyProfiles, setSiteLoyaltyProfiles] = useState([]);

  /** Rules */

  const [rulesState, updateRules] = useReducer(rulesReducer, setInitialRules());

  /** Day rules */

  const handleDayChange =
    day =>
    ({ target: { checked } }) => {
      updateRules({
        type: "updateFullDay",
        payload: { day, checked },
      });
    };

  const handleApplyToAllDays = ({ target: { checked: enable } }) => {
    updateRules({
      type: "applyToAllDays",
      payload: { enable },
    });
  };

  // @todo validate intervals don't overlap, if so auto-merge, etc etc... all the nice things
  const handleAddTimeInterval = (day, startTime, endTime) => {
    updateRules({
      type: "addTimeInterval",
      payload: { day, startTime, endTime },
    });
  };

  const handleRemoveTimeInterval = (day, index) => {
    updateRules({
      type: "removeTimeInterval",
      payload: { day, index },
    });
  };

  /** Actions */

  const [action, setAction] = useState({});
  const [freeProducts, setFreeProducts] = useState({});

  const removeFreeProduct = id => {
    delete freeProducts[id];
    setFreeProducts({ ...freeProducts });
  };

  const handleAddFreeProduct = value => {
    setFreeProducts({ ...freeProducts, [value.id]: value });

    dispatchError({ type: "unset", key: "freeProducts" });
  };

  /** Percentage discount */
  const [percentDiscount, setPercentDiscount] = useState("");

  const handlePercentDiscountChange = ({ target: { value: newValue } }) => {
    if (
      newValue !== "" &&
      newValue.slice(-1) !== "." &&
      !validator.isNumeric(newValue.toString())
    ) {
      dispatchError({ type: "set", key: "percentDiscount" });
    } else {
      dispatchError({ type: "unset", key: "percentDiscount" });
    }
    setPercentDiscount(newValue);
  };

  /** Fixed discount */
  const [fixedDiscount, setFixedDiscount] = useState("");

  const handleFixedDiscountChange = ({ target: { value: newValue } }) => {
    if (
      newValue !== "" &&
      newValue.slice(-1) !== "." &&
      !validator.isNumeric(newValue.toString())
    ) {
      dispatchError({ type: "set", key: "fixedDiscount" });
    } else {
      dispatchError({ type: "unset", key: "fixedDiscount" });
    }
    setFixedDiscount(newValue);
  };

  const handleMinimumOrderAmountChange = ({
    target: { value: minimumOrderAmount },
  }) => {
    if (
      minimumOrderAmount !== "" &&
      minimumOrderAmount.slice(-1) !== "." &&
      !validator.isNumeric(minimumOrderAmount.toString())
    ) {
      dispatchError({ type: "set", key: "minimumOrderAmount" });
    } else {
      dispatchError({ type: "unset", key: "minimumOrderAmount" });
    }

    updateRules({
      type: "setMinimumOrderAmount",
      payload: { minimumOrderAmount },
    });
  };

  const [fixedPrice, setFixedPrice] = useState("");

  const handleFixedPriceChange = ({ target: { value: newValue } }) => {
    if (
      newValue !== "" &&
      newValue.slice(-1) !== "." &&
      !validator.isNumeric(newValue.toString())
    ) {
      dispatchError({ type: "set", key: "fixedPrice" });
    } else {
      dispatchError({ type: "unset", key: "fixedPrice" });
    }
    setFixedPrice(newValue);
  };

  const [multibuy, setMultibuy] = useState({
    qualifyingCount: 2,
    fullPriceCount: 1,
    mode: "cheapest",
  });

  const handleMultiBuyValueChange =
    field =>
    ({ target: { value: newValue } }) => {
      if (
        ["qualifyingCount", "fullPriceCount"].includes(field) &&
        newValue !== "" &&
        !validator.isNumeric(newValue.toString())
      ) {
        dispatchError({
          type: "set",
          key: "multibuyError",
          message: "Count must be a number",
        });
      } else {
        const newMultibuy = { ...multibuy, [field]: newValue };
        setMultibuy(newMultibuy);

        if (newMultibuy.qualifyingCount <= newMultibuy.fullPriceCount) {
          dispatchError({
            type: "set",
            key: "multibuyError",
            message: "Qualifying count must be more than full price count",
          });
        } else {
          dispatchError({ type: "unset", key: "multibuyError" });
        }
      }
    };

  const handleBucketAdd = () => {
    updateRules({
      type: "addBucket",
      payload: {},
    });
  };

  const addNamedBucket = bucketName => {
    updateRules({
      type: "addBucket",
      payload: { name: bucketName },
    });
  };

  const handleRemoveBucket = bucket => {
    updateRules({
      type: "removeBucket",
      payload: { bucket },
    });
  };

  const handleSetAllowAnyProduct = status => {
    updateRules({
      type: "setAllowAnyProduct",
      payload: { status },
    });
  };

  const handleActionSelect = ({ target: { value: selectedAction } }) => {
    dispatchError({ type: "unset", key: "action" });

    handlePercentDiscountChange({ target: { value: "" } });
    handleFixedPriceChange({ target: { value: "0" } });
    handleFixedDiscountChange({ target: { value: "0" } });
    setMultibuy({
      qualifyingCount: 2,
      fullPriceCount: 1,
      mode: "cheapest",
    });
    dispatchError({ type: "unset", key: "multibuyError" });
    setFreeProducts({});
    dispatchError({ type: "unset", key: "freeProducts" });

    // remove non default bucket error if selecting multibuy as only defaults are used
    if (selectedAction === PROMOTION_ACTIONS.multibuy.id) {
      dispatchError({ type: "unset", key: "emptyNonDefaultBucket" });
    }

    // removing "minimumOrderAmount" from rules if fixed amount isnt selected anymore
    if (PROMOTION_ACTIONS.fixed_discount.id !== selectedAction) {
      dispatchError({ type: "unset", key: "minimumOrderAmount" });
      updateRules({
        type: "removeMinimumOrderAmount",
      });
    } else {
      // if fixed amount is selected set the "minimumOrderAmount" to zero
      updateRules({
        type: "setMinimumOrderAmount",
        payload: { minimumOrderAmount: 0 },
      });
    }

    // Do we need a discountable bucket
    const addOrRemoveDiscountable =
      PROMOTION_ACTIONS.qualifying_discount_bucket.id !== selectedAction
        ? handleRemoveBucket
        : addNamedBucket;
    addOrRemoveDiscountable("discountable");

    setAction(PROMOTION_ACTIONS[selectedAction]);
  };

  const handleBucketChange =
    (id, field) =>
    ({ target: { value } }) => {
      // This is a field change, i.e. qty
      updateRules({
        type: "changeBucketField",
        payload: { id, field, value },
      });
    };

  const [bucketErrors, setBucketErrors] = useState([]);

  useEffect(() => {
    // Todo - this needs to be better. It would be good to have a fn in the reducer import that fetches
    // the relevant buckets
    const { staging, discountable, ...checkTheseBuckets } = rulesState.buckets;

    checkBucketRules(
      appStore,
      checkTheseBuckets,
      dispatchError,
      setBucketErrors,
    ).then(() => {
      appStore.setLoading(false);
    });
  }, [rulesState, appStore]);

  const handleBucketDrop = (draggableId, source, destination) => {
    updateRules({
      type: "moveItem",
      payload: { id: draggableId, source, destination },
    });
  };

  const handleProductRuleRemove = bucket => item => () => {
    updateRules({
      type: "removeItem",
      payload: { bucket, item },
    });
  };

  const parseRules = jsonRules => {
    const parsedRules = JSON.parse(jsonRules);
    // Make sure each item has a meta id to refer to this specific instance
    // Needed for drag and drop support
    if (parsedRules.products) {
      Object.values(parsedRules.products).forEach(bucket => {
        Object.values(bucket.products).forEach(item => {
          if (!item.meta) {
            item.meta = { id: v4() };
          }
        });
      });
    }
    return parsedRules;
  };

  const handleInitialLoadStateChanges = promotionData => {
    const dbRules = parseRules(promotionData.rules);
    const dbAction = JSON.parse(promotionData.actions);
    setPromotion(promotionData);
    setName(promotionData.name);
    setActive(promotionData.active);
    setDescription(promotionData.description);
    setStartAt(promotionData.startAt);
    setEndAt(promotionData.endAt);
    setShowEndDate(!!promotionData.endAt);
    setAction(PROMOTION_ACTIONS[Object.keys(dbAction)[0]] || {});
    setCanIncreasePrice(promotionData.canIncreasePrice);

    if (dbAction?.fixed_discount) {
      setFixedDiscount(dbAction.fixed_discount);
      dbRules.minimumOrderAmount = dbRules.minimumOrderAmount ?? 0;
    }

    if (dbAction?.percent_discount) {
      setPercentDiscount(dbAction.percent_discount);
    }

    if (dbAction?.qualifying_discount_bucket) {
      setPercentDiscount(dbAction.qualifying_discount_bucket * 100);
    }

    if (dbAction?.free_products) {
      setFreeProducts(dbAction.free_products);
    }

    if (dbAction?.fixed_price) {
      setFixedPrice(dbAction.fixed_price);
    }

    if (dbAction?.multibuy) {
      setMultibuy(dbAction.multibuy);
    }

    const rulesInStateStructure = setInitialRules(JSON.stringify(dbRules));

    updateRules({
      type: "initialiseRules",
      payload: { dbRules: rulesInStateStructure },
    });

    if (!rulesState.buckets.default.name) {
      updateRules({
        type: "changeBucketField",
        payload: {
          id: "default",
          field: "name",
          value: "Default Bucket",
        },
      });
    }
  };

  /** initial load, updated when promotion changes */
  useEffect(() => {
    appStore.siteStore.findAll().then(sites => {
      const dbAllSites = [...sites.values()].sort((a, b) =>
        a.name.toLowerCase().localeCompare(b.name.toLowerCase()),
      );
      // This has been commented out for now, nobody knows what loyalty profiles do or how they work.
      // setSiteLoyaltyProfiles(
      //   [...sites.values()]
      //     .filter(site => site.loyaltyProfiles)
      //     .flatMap(site =>
      //       site.loyaltyProfiles.map(profile => ({ ...profile, site })),
      //     ),
      // );
      setAllSites(dbAllSites);

      if (viewChangeOverride) {
        handleInitialLoadStateChanges(viewChangeOverride);
        appStore.setLoading(false);
      } else {
        if (dbAllSites.length > 10 && showCollapsibleContent.sites) {
          handleUpdateCollapsedContent({
            name: "sites",
            value: false,
          });
        }

        if (promotionId !== undefined) {
          appStore.promotionStore
            .find(promotionId)
            .then(result => {
              handleInitialLoadStateChanges(result);
              appStore.setLoading(false);
            })
            .catch(error => {
              appStore.log.error(error);
              navigate("/app/404");
            });
        } else {
          const activeSites = dbAllSites.filter(
            ({ active: activeSite }) => activeSite,
          );

          if (activeSites.length) {
            const defaultSiteState = activeSites.reduce(
              (curr, { id }) => ({ ...curr, [id]: true }),
              {},
            );

            updateRules({
              type: "setSites",
              payload: {
                sites: defaultSiteState,
              },
            });
          }

          appStore.setLoading(false);
        }
      }
    });
  }, [promotionId]);

  const handleCancel = () => {
    appStore.setLoading();
    navigate("/app/promotions");
  };

  const handleDelete = () => {
    appStore.promotionStore.remove(promotion.id).then(() => {
      appStore.setLoading();
      navigate("/app/promotions");
    });
  };

  const handleDuplicate = event => {
    event.preventDefault();
    appStore.promotionStore.duplicate(promotion).then(() => {
      appStore.setLoading();
      navigate("/app/promotions");
    });
  };

  const handleAddRuleProduct = product => {
    updateRules({
      type: "addItem",
      payload: {
        item: { ...product, meta: { id: product.id } },
        bucket: "staging",
      },
    });
  };

  const handleAddDiscountableProduct = product => {
    dispatchError({
      type: "unset",
      key: "discountable",
    });

    updateRules({
      type: "addItem",
      payload: { item: product, bucket: "discountable" },
    });
  };

  const savePromotion = (warnings = {}, scheduledChangesetId = null) => {
    appStore.setLoading();

    // If the promotion is a multibuy we need to mutate the buckets we submit, we cant
    // use the state here as the api call is made before the state updates
    let bucketsToSubmit = { ...rulesState.buckets };

    const saveAction = {};
    switch (action.id) {
      case "fixed_discount":
        saveAction[action.id] = fixedDiscount;
        break;
      case "percent_discount":
        saveAction[action.id] = percentDiscount;
        break;
      case "qualifying_discount_bucket":
        saveAction[action.id] = percentDiscount / 100;
        break;
      case "free_products":
        saveAction[action.id] = Object.values(freeProducts);
        break;
      case "fixed_price":
        saveAction[action.id] = fixedPrice;
        break;
      case "multibuy":
        saveAction[action.id] = multibuy;
        // If the promo is a multibuy only allow the default bucket with
        // qty of 1.
        bucketsToSubmit = Object.entries(rulesState.buckets).reduce(
          (acc, [key, value]) => {
            if (["default", "staging", "exclusions"].includes(key)) {
              updateRules({
                type: "changeBucketField",
                payload: { id: key, field: "quantity", value: 1 },
              });

              return { ...acc, [key]: { ...value, quantity: 1 } };
            }

            updateRules({
              type: "removeBucket",
              payload: { bucket: key },
            });

            return acc;
          },
          {},
        );
        break;
      default:
        break;
    }

    const rulesToSubmit = { ...rulesState, buckets: bucketsToSubmit };

    let activeStatusToSubmit = active;

    if (
      warnings.noSites ||
      warnings.noDays ||
      warnings.inactiveSiteSelected ||
      warnings.noEligibilityRules
    ) {
      activeStatusToSubmit = false;
      setActive(false);
    }

    if (promotion === null) {
      appStore.promotionStore
        .add({
          name,
          description,
          startAt,
          endAt,
          active: activeStatusToSubmit,
          rules: buildForDb(rulesToSubmit),
          actions: JSON.stringify(saveAction),
          canIncreasePrice,
        })
        .then(({ id }) => navigate(`/app/promotions/${id}`))
        .catch(error => {
          appStore.log.error(error);
          appStore.setLoading(false);
        });
    } else {
      promotion
        .setName(name)
        .setActive(activeStatusToSubmit)
        .setDescription(description)
        .setStartAt(startAt)
        .setEndAt(endAt)
        .setRules(buildForDb(rulesToSubmit))
        .setActions(JSON.stringify(saveAction))
        .setCanIncreasePrice(canIncreasePrice)
        .save(scheduledChangesetId)
        .then(() => {
          appStore.setLoading(false);

          handleUpdateCollapsedContent({ name: "savedAlert", value: true });

          // Clear any existing timeout
          clearTimeout(savedAlertNotificationTimeout);

          // Create a new timeout that hides the alert after 3 seconds
          const timeoutId = setTimeout(() => {
            handleUpdateCollapsedContent({
              name: "savedAlert",
              value: false,
            });
          }, 3000);

          // set the id in the state so that if resubmitted the previous one can be cleared
          setSavedAlertNotificationTimeout(timeoutId);
        })
        .catch(error => {
          setSavedAlertNotificationTimeout(null);
          handleUpdateCollapsedContent({ name: "savedAlert", value: false });

          appStore.log.error(error);
          appStore.setLoading(false);
        });
    }
  };

  /* GraphQL and functions for schedule change changeset table */
  const changesetQuery = `query Changesets(
      $limit: Int
      $offset: Int
      $sort: [SortOptionInput]
    ) {
      changesetsWithCount(limit: $limit, offset: $offset, sort: $sort, filter: {status_in: PENDING} ) {
        changesets {
          id
          name
          status
          scheduledAt
          createdBy {
            email
          }
          changes {
            targetId
          }
        }
        totalRows
      }
    }`;

  const updateChangeset = id => {
    setChangesetAlertState(false);
    savePromotion(alertWarnings, id);
  };

  const changesetMapper = (item, index) => ({
    ...item,
    createdBy: item.createdBy.email,
    scheduledAt: moment(item.scheduledAt).format("Do MMM YYYY, HH:mm"),
    select: item.changes.some(({ targetId }) => targetId === promotion?.id) ? (
      <Tooltip title="A change for this Promotion already exists for this time">
        <div>
          <PositiveAction
            buttonText="Select"
            disabled
            onClick={() => updateChangeset(item.id)}
            testId={`promotions-changeset-select-dialog-select-button-${index}`}
          />
        </div>
      </Tooltip>
    ) : (
      <PositiveAction
        buttonText="Select"
        onClick={() => updateChangeset(item.id)}
        testId={`promotions-changeset-select-dialog-select-button-${index}`}
      />
    ),
  });

  const sortChangesetMapper = item => {
    if (item === "createdBy") {
      return "createdBy.email";
    }
    return item;
  };
  /* */

  const handleAlertResponse = (isScheduleChange, proceed) => {
    if (proceed) {
      if (isScheduleChange) {
        setChangesetAlertState(true);
      } else {
        savePromotion(alertWarnings);
      }
    }

    setAlertState(false);
  };

  const handleValidation = isScheduleChange => event => {
    event.preventDefault();

    // TODO: improve the passing of errors to the legacy handler
    const {
      emptyNonDefaultBucket,
      invalidNameField,
      invalidDescriptionField,
      ...legacyErrors
    } = submitError;

    const legacyValidation = checkForErrors({
      dispatchError,
      legacyErrors,
      validator,
      data: {
        action,
        name,
        startAt,
        showEndDate,
        endAt,
        percentDiscount,
        rulesState,
        fixedDiscount,
        fixedPrice,
        freeProducts,
      },
    });

    const errors = {
      emptyNonDefaultBucket: rulesState.allowAnyProduct
        ? false
        : checkEmptyNonDefaultBucket(action.id, rulesState.buckets),
      invalidNameField: checkStringForInvalidCharacters(name),
      invalidDescriptionField: checkStringForInvalidCharacters(description),
    };

    // Update the state with the new error status, but only update if it is different to what
    // we current have stored.
    Object.keys(errors).forEach(errorName => {
      if (submitError[errorName] !== errors[errorName]) {
        const type = errors[errorName] ? "set" : "unset";

        dispatchError({ type, key: errorName });
      }
    });

    const noErrors =
      !legacyValidation && !Object.values(errors).find(error => error === true);

    if (noErrors) {
      const warnings = {
        itemsInStagingBucket: rulesState.allowAnyProduct
          ? false
          : checkForItemsInStagingBucket(rulesState.buckets.staging.items),
        noSites: checkForNoSites(rulesState.sites),
        noDays: checkForNoDays(rulesState.days),
        inactiveSiteSelected: checkForInactiveSite(allSites, rulesState.sites),
        noEligibilityRules: rulesState.allowAnyProduct
          ? false
          : checkForNoEligibilityRules(rulesState.buckets),
      };

      setAlertWarnings(warnings);

      const noWarnings = !Object.values(warnings).find(
        warning => warning === true,
      );

      if (noWarnings) {
        if (isScheduleChange) {
          setChangesetAlertState(true);
        } else {
          savePromotion();
        }
      } else {
        setScheduleSaveMode(isScheduleChange);
        setAlertState(true);
      }
    }
  };

  const renderActiveStatusToggleSwitch = () => (
    <Switch
      active={active}
      onChange={handleActiveToggle}
      label={active ? "Active" : "Inactive"}
      colour="success"
    />
  );

  return (
    <ErrorBoundary>
      <Page
        title={
          viewChangeOverride
            ? `Viewing Promotion Change`
            : `${promotion !== null ? "Edit" : "Add"} Promotion`
        }
      >
        <Container maxWidth={false}>
          <form autoComplete="off" noValidate className={classes.form}>
            <Box mt={3} />
            <Card>
              <CardContent>
                <Grid container justifyContent="space-between" spacing={3}>
                  <Grid alignItems="center" item container xs={6}>
                    <Typography>Promotion name&nbsp;</Typography>
                    <Tooltip
                      arrow
                      title="The name of the promotion, gets sent to the tills"
                    >
                      <HelpOutlineIcon fontSize="small" />
                    </Tooltip>
                  </Grid>
                  <Grid alignItems="center" container item xs={4}>
                    <Typography>Description&nbsp;</Typography>
                    <Tooltip
                      arrow
                      title={`The description of the promotion, use this to explain how it
                      is configured.`}
                    >
                      <HelpOutlineIcon fontSize="small" />
                    </Tooltip>
                  </Grid>
                  <Grid container item justifyContent="flex-end" xs={2}>
                    {renderActiveStatusToggleSwitch()}
                  </Grid>
                </Grid>
                <Box mt={2} />
                <Grid container spacing={3}>
                  <Grid item xs={6}>
                    <TextField
                      inputProps={{ "data-testid": "name-input" }}
                      error={
                        !!submitError.name || !!submitError.invalidNameField
                      }
                      fullWidth
                      helperText={
                        submitError.name || submitError.invalidNameField
                      }
                      label="Name"
                      onChange={handleNameChange}
                      required
                      value={name}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      error={!!submitError.invalidDescriptionField}
                      helperText={submitError.invalidDescriptionField}
                      fullWidth
                      label="Description"
                      minRows={3}
                      multiline
                      onChange={handleDescriptionChange}
                      value={description}
                      variant="outlined"
                    />
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
            <Box mt={3} />
            <Card>
              <CardContent>
                <Grid container alignItems="center">
                  <Typography>Promotion type&nbsp;</Typography>
                  <Tooltip
                    arrow
                    title={`The type of benefit this promotion give. Choose from the
                        list for a detailed explanation of each type.`}
                  >
                    <HelpOutlineIcon fontSize="small" />
                  </Tooltip>
                </Grid>
                <Box m={2} />
                <Grid container spacing={2} justifyContent="space-between">
                  <Grid item xs={12} lg={4}>
                    <FormControl className={classes.formControl}>
                      <InputLabel id="promotion-type-label" required>
                        Select a promotion type
                      </InputLabel>
                      <Select
                        variant="standard"
                        error={!!submitError.action}
                        labelId="promotion-type-label"
                        value={action?.id ?? ""}
                        onChange={handleActionSelect}
                        label="Select a promotion type"
                        inputProps={{ "aria-label": "promotion-select" }}
                      >
                        {Object.entries(PROMOTION_ACTIONS).map(([id, pa]) => (
                          <MenuItem value={id} key={id}>
                            {pa.label}
                          </MenuItem>
                        ))}
                      </Select>
                      {submitError.action && (
                        <FormHelperText error>
                          {submitError.action}
                        </FormHelperText>
                      )}
                    </FormControl>
                    <Box m={2} />
                    {action?.id === PROMOTION_ACTIONS.fixed_discount.id && (
                      <Grid container spacing={3}>
                        <Grid item xs={6}>
                          <TextField
                            required
                            variant="standard"
                            label="Amount"
                            value={fixedDiscount}
                            error={!!submitError.fixedDiscount}
                            helperText={submitError.fixedDiscount}
                            onChange={handleFixedDiscountChange}
                            inputProps={{
                              "data-testid": "fixedDiscount-amount",
                            }}
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  £
                                </InputAdornment>
                              ),
                            }}
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <TextField
                            required
                            variant="standard"
                            label="Minimum Order Amount"
                            value={rulesState.minimumOrderAmount}
                            error={!!submitError.minimumOrderAmount}
                            helperText={submitError.minimumOrderAmount}
                            onChange={handleMinimumOrderAmountChange}
                            inputProps={{
                              "data-testid": "minimumOrderAmount",
                            }}
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  £
                                </InputAdornment>
                              ),
                            }}
                          />
                        </Grid>
                      </Grid>
                    )}
                    {action?.id === PROMOTION_ACTIONS.percent_discount.id && (
                      <TextField
                        required
                        variant="standard"
                        label="Percent"
                        value={percentDiscount}
                        error={!!submitError.percentDiscount}
                        helperText={submitError.percentDiscount}
                        onChange={handlePercentDiscountChange}
                        inputProps={{
                          "data-testid": "percentDiscount-amount",
                        }}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">%</InputAdornment>
                          ),
                        }}
                      />
                    )}

                    {action?.id === PROMOTION_ACTIONS.free_products.id && (
                      <ProductChooser
                        store={freeProducts}
                        handleAdd={handleAddFreeProduct}
                        handleRemove={removeFreeProduct}
                        prefix="free"
                        required
                        errorMessage={submitError.freeProducts}
                      />
                    )}
                    {action?.id === PROMOTION_ACTIONS.fixed_price.id && (
                      <>
                        <TextField
                          required
                          variant="standard"
                          label="Amount"
                          value={fixedPrice}
                          error={!!submitError.fixedPrice}
                          helperText={submitError.fixedPrice}
                          onChange={handleFixedPriceChange}
                          inputProps={{
                            "data-testid": "fixedPrice-amount",
                          }}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                £
                              </InputAdornment>
                            ),
                          }}
                        />
                        <FormControlLabel
                          sx={{ display: "block", mt: 2 }}
                          control={
                            <Checkbox
                              checked={canIncreasePrice}
                              color="primary"
                              onChange={({ target: { checked } }) =>
                                setCanIncreasePrice(checked)
                              }
                            />
                          }
                          label="Allow prices to increase?"
                        />
                      </>
                    )}
                    {action?.id === PROMOTION_ACTIONS.multibuy.id && (
                      <Grid container spacing={3}>
                        <Grid item xs={6}>
                          <TextField
                            required
                            variant="standard"
                            fullWidth
                            type="number"
                            label="Qualifying item count"
                            error={!!submitError.multibuyError}
                            value={multibuy.qualifyingCount}
                            inputProps={{
                              min: 2,
                              "data-testid": "qualifyingCount-amount",
                            }}
                            min="0"
                            onChange={handleMultiBuyValueChange(
                              "qualifyingCount",
                            )}
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <TextField
                            variant="standard"
                            fullWidth
                            type="number"
                            label="Full price items"
                            error={!!submitError.multibuyError}
                            value={multibuy.fullPriceCount}
                            inputProps={{ min: 1 }}
                            min="0"
                            onChange={handleMultiBuyValueChange(
                              "fullPriceCount",
                            )}
                          />
                        </Grid>
                        {!!submitError.multibuyError && (
                          <Grid item xs={12}>
                            <Typography color="error">
                              {submitError.multibuyError}
                            </Typography>
                          </Grid>
                        )}
                        <Grid item xs={12}>
                          <FormControl className={classes.formControl}>
                            <InputLabel id="promotion-type-label">
                              Select multibuy mode
                            </InputLabel>
                            <Select
                              variant="standard"
                              labelId="promotion-type-label"
                              value={multibuy.mode}
                              onChange={handleMultiBuyValueChange("mode")}
                              label="Select a promotion type"
                            >
                              <MenuItem value="cheapest">
                                Cheapest item(s) free
                              </MenuItem>
                              <MenuItem value="latest">
                                Most recently added item(s) free
                              </MenuItem>
                            </Select>
                          </FormControl>
                        </Grid>
                      </Grid>
                    )}
                  </Grid>
                  <Grid item xs={12} lg={7}>
                    <Typography>{action?.help}</Typography>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
            {action.id === "qualifying_discount_bucket" && (
              <Box mt={3} id="discount-bucket-builder">
                <DiscountBucketBuilder
                  percentDiscount={percentDiscount}
                  submitError={submitError}
                  handlePercentDiscountChange={handlePercentDiscountChange}
                  buckets={rulesState.buckets}
                  handleAdd={handleAddDiscountableProduct}
                  handleRemove={handleProductRuleRemove}
                  prefix="product-rule"
                />
              </Box>
            )}
            <Box mt={3}>
              <BucketProductChooser
                buckets={rulesState.buckets}
                allowAnyProduct={rulesState.allowAnyProduct}
                handleAdd={handleAddRuleProduct}
                handleRemove={handleProductRuleRemove}
                handleBucketDrop={handleBucketDrop}
                handleRemoveBucket={handleRemoveBucket}
                handleBucketAdd={handleBucketAdd}
                handleBucketChange={handleBucketChange}
                handleSetAllowAnyProduct={handleSetAllowAnyProduct}
                bucketErrors={bucketErrors}
                submitError={submitError}
                prefix="product-rule"
                action={action}
                showCollapsibleContent={showCollapsibleContent.eligibilityRules}
                toggleCollapsed={handleToggleCollapse("eligibilityRules")}
                readOnly={!!viewChangeOverride}
              />
            </Box>
            <Box mt={3}>
              <SiteChooser
                sites={allSites}
                siteRules={rulesState.sites}
                handleChange={sites => {
                  updateRules({
                    type: "setSites",
                    payload: { sites },
                  });
                }}
                showCollapsibleContent={showCollapsibleContent.sites}
                toggleCollapsed={handleToggleCollapse("sites")}
                readOnly={!!viewChangeOverride}
              />
            </Box>
            <Box mt={3}>
              <DayTimeChooser
                dayRules={rulesState.days}
                handleAddTimeInterval={handleAddTimeInterval}
                handleRemoveTimeInterval={handleRemoveTimeInterval}
                handleDayChange={handleDayChange}
                handleApplyToAllDays={handleApplyToAllDays}
                showCollapsibleContent={showCollapsibleContent.dayAndTimeRules}
                toggleCollapsed={handleToggleCollapse("dayAndTimeRules")}
                readOnly={!!viewChangeOverride}
              />
            </Box>
            <Box mt={3}>
              <Card>
                <CardContent>
                  <Grid container justifyContent="space-between">
                    <Grid container alignItems="center" item xs={9}>
                      <Typography>Date range&nbsp;</Typography>
                      <Tooltip
                        arrow
                        title="The dates this promotion runs between. End can be left blank to make the promotion open-ended"
                      >
                        <HelpOutlineIcon fontSize="small" />
                      </Tooltip>
                    </Grid>
                    <Grid container item xs={3} justifyContent="flex-end">
                      <Switch
                        active={showEndDate}
                        colour="success"
                        onChange={handleEndDateToggle}
                        testId="end-date-switch"
                        label="End date"
                      />
                      <IconButton
                        onClick={handleToggleCollapse("dateRange")}
                        data-testid="date-range-collapse-toggle"
                      >
                        {showCollapsibleContent.dateRange ? (
                          <ExpandLess />
                        ) : (
                          <ExpandMore />
                        )}
                      </IconButton>
                    </Grid>
                  </Grid>
                  <Collapse in={showCollapsibleContent.dateRange}>
                    <Grid container spacing={3}>
                      <Grid item xs={12} />
                      <Grid item lg={3} md={6} xs={12}>
                        <DateTimePicker
                          format="MMMM Do YYYY HH:mm"
                          label="Start date"
                          disableHighlightToday
                          step={5}
                          ampm={false}
                          maxDateTime={endAt || null}
                          value={startAt}
                          onChange={handleStartDateChange}
                          slotProps={{
                            openPickerIcon: {
                              "data-testid": "day-time-picker-start-date-icon",
                            },
                            textField: {
                              name,
                              error:
                                !!submitError.startDate ||
                                !!submitError.invalidStartEndDate,
                              helperText: submitError.startDate ?? undefined,
                              "data-testid": "start-date-input",
                              required: true,
                            },
                          }}
                        />
                      </Grid>
                      <Grid item lg={3} md={6} xs={12}>
                        {showEndDate && (
                          <DateTimePicker
                            format="MMMM Do YYYY HH:mm"
                            label="End date"
                            step={5}
                            ampm={false}
                            minDateTime={startAt || null}
                            value={endAt}
                            error={!!submitError.endDate}
                            onChange={handleEndDateChange}
                            slotProps={{
                              openPickerIcon: {
                                "data-testid": "day-time-picker-end-date-icon",
                              },
                              textField: {
                                error:
                                  !!submitError.endDate ||
                                  !!submitError.invalidStartEndDate,
                                helperText: submitError.endDate ?? undefined,
                                "data-testid": "end-date-input",
                              },
                            }}
                          />
                        )}
                      </Grid>
                    </Grid>
                  </Collapse>
                </CardContent>
              </Card>
            </Box>
            {/* This has been commented out for now, nobody knows what loyalty
            profiles do or how they work. */}
            {/* <Box mt={3}>
              <Card>
                <CardContent>
                  <Grid container justifyContent="space-between">
                    <Grid item container alignItems="center" xs={9}>
                      <Typography>Loyalty profiles&nbsp;</Typography>
                      <Tooltip
                        arrow
                        title="The Loyalty profiles for which the promotion applies"
                      >
                        <HelpOutlineIcon fontSize="small" />
                      </Tooltip>
                    </Grid>
                    <Grid container item xs={3} justifyContent="flex-end">
                      <FormControlLabel
                        control={
                          <Switch
                            color="primary"
                            checked={allLoyaltyProfilesChecked}
                            onChange={({ target: { checked } }) => {
                              // State handles the toggle switch
                              setAllLoyaltyProfilesChecked(checked);
                              updateRules({
                                type: "setAllLoyaltyProfiles",
                                payload: {
                                  checked,
                                  allLoyaltyProfiles,
                                  siteLoyaltyProfiles,
                                },
                              });
                            }}
                          />
                        }
                        label="All"
                      />
                    </Grid>
                  </Grid>
                  <Grid container>
                    {allLoyaltyProfiles.map(profile => (
                      <Grid item lg={3} md={6} xs={12} key={profile.id}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              key={`${profile.id}-${
                                rulesState.loyaltyProfiles[profile.id]
                              }`}
                              color="primary"
                              checked={rulesState.loyaltyProfiles[profile.id]}
                              onChange={({ target: { checked } }) => {
                                // State handles the toggle switch
                                setAllLoyaltyProfilesChecked(false);
                                updateRules({
                                  type: "updateLoyaltyProfiles",
                                  payload: {
                                    id: profile.id,
                                    checked,
                                  },
                                });
                              }}
                            />
                          }
                          label={profile.name}
                        />
                      </Grid>
                    ))}
                  </Grid>
                  <Grid container>
                    {siteLoyaltyProfiles && (
                      <Grid item container alignItems="center" xs={12}>
                        <Typography>Site Loyalty profiles&nbsp;</Typography>
                      </Grid>
                    )}
                    {siteLoyaltyProfiles.map(profile => (
                      <Grid item lg={3} md={6} xs={12} key={profile.id}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              key={`${profile.id}-${
                                rulesState.loyaltyProfiles[profile.id]
                              }`}
                              color="primary"
                              checked={rulesState.loyaltyProfiles[profile.id]}
                              onChange={({ target: { checked } }) => {
                                // State handles the toggle switch
                                setAllLoyaltyProfilesChecked(false);

                                updateRules({
                                  type: "updateLoyaltyProfiles",
                                  payload: {
                                    id: profile.id,
                                    checked,
                                  },
                                });
                              }}
                            />
                          }
                          label={`${profile.name} (${profile.site.name})`}
                        />
                      </Grid>
                    ))}
                  </Grid>
                </CardContent>
              </Card>
            </Box> */}
            <Box mb={2} />
            <Collapse in={showCollapsibleContent.savedAlert}>
              <Alert
                action={
                  <IconButton
                    aria-label="close"
                    color="inherit"
                    size="small"
                    onClick={() =>
                      handleUpdateCollapsedContent({
                        name: "savedAlert",
                        value: false,
                      })
                    }
                  >
                    <Close fontSize="inherit" />
                  </IconButton>
                }
                data-testid="success-alert"
                sx={{ mb: 2 }}
              >
                Your changes have been saved.
              </Alert>
            </Collapse>
            {!viewChangeOverride && (
              <>
                <PromotionControls
                  handleCancel={handleCancel}
                  handleDelete={handleDelete}
                  handleDuplicate={handleDuplicate}
                  handleSubmit={handleValidation}
                  showScheduleBtn={
                    promotion?.id && appStore.settingsStore.showChangesets
                  }
                  isLoading={appStore.isLoading}
                  submitError={submitError}
                  toggleActiveStatus={renderActiveStatusToggleSwitch}
                />
                <Box pt={6} />
              </>
            )}
          </form>
          {!viewChangeOverride &&
            promotion?.id &&
            appStore.settingsStore.showChangesets && (
              <Box pb={3}>
                <Card>
                  <CardContent>
                    <Typography sx={{ mb: 3 }}>Changes</Typography>
                    <ChangeSection targetId={promotion.id} />
                  </CardContent>
                </Card>
              </Box>
            )}
        </Container>
        <ValidationModal
          alertState={alertState}
          alertWarnings={alertWarnings}
          handleAlertResponse={handleAlertResponse}
          setAlertState={setAlertState}
          isScheduleChange={scheduleSaveMode}
        />
        <Dialog
          maxWidth="xl"
          open={changesetAlertState}
          onClose={() => {}}
          data-testid="promotions-changeset-select-alert-dialog"
          aria-labelledby="changeset-alert-dialog-title"
          aria-describedby="changeset-alert-dialog-description"
        >
          <DialogTitle
            mb={1}
            id="promotions-changeset-select-alert-dialog-title"
          >
            Select a changeset to apply this change to:
          </DialogTitle>
          <DialogContent>
            <Card>
              <ChangesetsTable
                columns={[
                  { label: "Name", name: "name" },
                  { label: "Scheduled at", name: "scheduledAt" },
                  { label: "Status", name: "status" },
                  { label: "Created by", name: "createdBy" },
                  { label: " ", name: "select", options: { sort: false } },
                ]}
                mapper={changesetMapper}
                query={changesetQuery}
                rowsPerPageOptions={[5, 10]}
                sortFieldMapper={sortChangesetMapper}
                viewColumns={false}
              />
            </Card>
          </DialogContent>
          <DialogActions>
            <NegativeAction
              buttonText="Close"
              onClick={() => setChangesetAlertState(false)}
              testId="promotions-changeset-select-table-dialog-back-button"
            />
          </DialogActions>
        </Dialog>
      </Page>
    </ErrorBoundary>
  );
};

AddEditComponent.propTypes = {
  appStore: MobXPropTypes.objectOrObservableObject.isRequired,
  viewChangeOverride: PropTypes.shape({}),
};

export default inject("appStore")(observer(AddEditComponent));
