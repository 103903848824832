import React, { useState } from "react";

import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

import ErrorBoundary from "../../components/ErrorBoundary";
import Page from "../../components/Page";
import VoidRefundReasonsBulkEditor from "./components/VoidRefundReasonsBulkEditor";

const VoidRefundReasonsList = () => {
  const title = "Void & Refund Reasons";

  return (
    <ErrorBoundary>
      <Page title={title}>
        <Container maxWidth={false}>
          <Grid container rowSpacing={3}>
            <Grid
              container
              item
              xs={12}
              justifyContent="space-between"
              alignItems="center"
              mt={3}
            >
              <Grid item>
                <Typography variant="h2">{title}</Typography>
              </Grid>
            </Grid>
            <Grid item xs={12} sx={{ width: "0px" }}>
              <VoidRefundReasonsBulkEditor />
            </Grid>
          </Grid>
        </Container>
      </Page>
    </ErrorBoundary>
  );
};

export default VoidRefundReasonsList;
