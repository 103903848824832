import React, { useEffect, useReducer, useState } from "react";
import PropTypes from "prop-types";
import MUIDataTable, { debounceSearchRender } from "mui-datatables";

import {
  CustomCheckbox,
  handleRenderConfigForSelectedRows,
  selectChangeManagement,
  selectedItemsReducer,
} from "./MultiSelect";

const defaultColumns = [];
const defaultRowsPerPageOptions = [10, 25, 50, 100];
const defaultSelectedItemArray = [];
const defaultTableData = [];
const defaultTableOptions = {};

/**
 * @typedef MultiSelectOptions
 * @type {object}
 * @property {string} [selectableRows] Indicates if rows can be selected. Options are "multiple", "single", "none". (Defaults to none)
 * @property {string} [selectToolbarPlacement] Controls the visibility of the Select Toolbar, options are 'replace' (select toolbar replaces default toolbar when a row is selected), 'above' (select toolbar will appear above default toolbar when a row is selected) and 'none' (select toolbar will never appear). Default to 'none'
 * @property {func} [customToolbar] Render a custom toolbar function({displayData}) => React Component. Renders a custom toolbar that is always present (unless overriden by selectToolbarPlacement). Not added if not provided.
 */

/**
 * @typedef MultiSelectOptionsCallable
 * @type {func}
 * @param {object} selectionState - which items are selected in the table
 * @returns {MultiSelectOptions}
 */

/**
 * A reusable Listing component that makes use of the 'mui-datatables' package
 * @param {object} options The configurable options for this component
 * @param {array} options.columns The column data and their associated configuration
 * @param {boolean} option.downloadable Toggles if the table can be download as a csv
 * @param {boolean} option.debounceSearchTime Sets the time (in ms) for how long to wait after typing to trigger "handleSearchChange"
 * @param {array} option.defaultSelectedItems Sets the initial item selection on the checkboxes, should be used with "multiSelectOptions"
 * @param {function} options.handleChangePage Deals with changing the current page
 * @param {function} options.handleChangeRowsPerPage Deals with changing how many items are being shown per page
 * @param {function} options.handleFilterChange Deals with changing the filter options
 * @param {function} options.handleRenderCustomFilterDialogFooter Deals with the rendering of the custom filter dialog footer
 * @param {function} options.handleRenderExpandableRow Deals with the rendering of an expandable row.
 * @param {function} options.handleRowClick Deals with what happens when the user clicks a table item
 * @param {function} options.handleSearchChange Deals with changing the search input
 * @param {function} options.handleSortChange Deals with changing the sort direction and column used to sort
 * @param {MultiSelectOptionsCallable} options.multiSelectOptions A callable that is given the selection state and must return Select options for the table, i.e. is it enabled, what do we render for options etc
 * @param {boolean} options.printable Toggles if the table can be printed
 * @param {array} options.rowsPerPageOptions An array of the options to provide the user for page size
 * @param {array} options.tableData The data to be rendered in the rows
 * @param {object} options.tableOptions The page, rows per, filter, search and sort details
 * @param {string} options.testId The test-id to be associated to the table component
 * @param {number} options.totalRowCount The total count of all rows
 * @param {number} options.viewColumns Toggles the ability to select which columns are viewable
 * @returns Configured table component
 */
const DataTable = ({
  columns = defaultColumns,
  debounceSearchTime = 500,
  defaultSelectedItems = defaultSelectedItemArray,
  downloadable = false,
  elevation = 4,
  handleChangePage = () => {},
  handleChangeRowsPerPage = () => {},
  handleFilterChange = null,
  handleRenderCustomFilterDialogFooter = null,
  handleRenderExpandableRow = null,
  handleRowClick,
  handleSearchChange = null,
  handleSortChange = null,
  handleTableChange = null,
  multiSelectOptions = () => ({
    selectableRows: "none",
    selectToolbarPlacement: "none",
  }),
  page = 0,
  printable = false,
  rowsPerPageOptions = defaultRowsPerPageOptions,
  tableData = defaultTableData,
  tableOptions = defaultTableOptions,
  testId = "MUI-Datatable",
  totalRowCount,
  viewColumns = false,
}) => {
  // A reducer for selected items state
  const [selectedItems, changeSelectedItems] = useReducer(
    selectedItemsReducer,
    new Map(),
  );

  // This tells the DataTable which rows to show as checked in the current page
  const [rowsSelected, setRowsSelected] = useState([]);
  const [currentPage, setCurrentPage] = useState(page);

  useEffect(() => {
    setCurrentPage(page);
  }, [page]);

  // Track what to render in terms of selected rows on each page
  useEffect(() => {
    handleRenderConfigForSelectedRows(
      selectedItems,
      setRowsSelected,
      tableData,
      tableOptions.rowsPerPage,
    );
  }, [selectedItems, tableData, tableOptions.rowsPerPage]);

  // These props are expected to be the same across all tables
  const staticOptions = {
    elevation,
    serverSide: true,
    setTableProps: () => ({
      "data-testid": testId,
    }),
  };

  // If "handleRenderExpandableRow" expandable rows will be enabled
  const expandableRows = {
    expandableRows: !!handleRenderExpandableRow,
    expandableRowsHeader: false,
    renderExpandableRow: handleRenderExpandableRow,
  };

  // Controlling the current page, changing page and displaying pagination info to user
  const pageManagement = {
    count: totalRowCount,
    onChangePage: handleChangePage,
    onChangeRowsPerPage: handleChangeRowsPerPage,
    rowsPerPageOptions,
  };

  if (tableOptions.rowsPerPage) {
    pageManagement.rowsPerPage = tableOptions.rowsPerPage;
  }

  pageManagement.onTableChange = (...args) => {
    selectChangeManagement(...args, { tableData, changeSelectedItems });
    if (handleTableChange) {
      handleTableChange(...args);
    }
  };

  // Sort order management
  const pageSorting = {
    onColumnSortChange: handleSortChange,
    sortOrder: tableOptions.sort
      ? {
          name: tableOptions.sort.sortCol,
          direction: tableOptions.sort.direction,
        }
      : {},
  };

  // handles the filter dialog rendering, application and filtering from search
  const pageFiltering = {
    confirmFilters: !!handleRenderCustomFilterDialogFooter,
    customSearchRender: debounceSearchRender(debounceSearchTime),
    customFilterDialogFooter: handleRenderCustomFilterDialogFooter,
    filter: !!handleFilterChange,
    onFilterChange: handleFilterChange,
    onSearchChange: handleSearchChange,
    search: !!handleSearchChange,
    viewColumns,
  };

  const multiSelectConfiguration = multiSelectOptions(selectedItems);

  useEffect(() => {
    changeSelectedItems({
      type: "set",
      items: defaultSelectedItems,
    });
  }, [defaultSelectedItems]);

  // All above options mereged into one
  const options = {
    ...expandableRows,
    // TODO - pass state in here
    ...multiSelectConfiguration,
    rowsSelected,
    ...pageFiltering,
    ...pageManagement,
    ...pageSorting,
    ...staticOptions,
    page: currentPage,
    download: downloadable,
    onRowClick: handleRowClick,
    print: printable,
  };

  return (
    <MUIDataTable
      data={tableData}
      columns={columns}
      options={{ ...options }}
      components={{ Checkbox: CustomCheckbox }}
    />
  );
};

DataTable.propTypes = {
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      options: PropTypes.shape({
        filter: PropTypes.bool,
        filterList: PropTypes.arrayOf(PropTypes.string),
        filterOptions: PropTypes.shape({
          names: PropTypes.arrayOf(PropTypes.string),
        }),
        setCellProps: PropTypes.func,
        sort: PropTypes.bool,
        customBodyRender: PropTypes.func,
        customBodyRenderLite: PropTypes.func,
        customFilterListOptions: PropTypes.shape({
          render: PropTypes.func,
        }),
      }),
    }),
  ),
  downloadable: PropTypes.bool,
  debounceSearchTime: PropTypes.number,
  defaultSelectedItems: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.arrayOf(PropTypes.shape({})),
  ]),
  elevation: PropTypes.number,
  handleChangePage: PropTypes.func,
  handleChangeRowsPerPage: PropTypes.func,
  handleFilterChange: PropTypes.func,
  handleRenderCustomFilterDialogFooter: PropTypes.func,
  handleRenderExpandableRow: PropTypes.func,
  handleRowClick: PropTypes.func.isRequired,
  handleSearchChange: PropTypes.func,
  handleSortChange: PropTypes.func,
  handleTableChange: PropTypes.func,
  multiSelectOptions: PropTypes.func,
  page: PropTypes.number,
  printable: PropTypes.bool,
  rowsPerPageOptions: PropTypes.arrayOf(PropTypes.number),
  tableData: PropTypes.arrayOf(PropTypes.shape({})),
  tableOptions: PropTypes.shape({
    rowsPerPage: PropTypes.number.isRequired,
    filter: PropTypes.shape({}),
    search: PropTypes.string,
    sort: PropTypes.shape({
      sortCol: PropTypes.string,
      direction: PropTypes.string,
    }),
  }),
  testId: PropTypes.string,
  totalRowCount: PropTypes.number.isRequired,
  viewColumns: PropTypes.bool,
};

export default DataTable;
