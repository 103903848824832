import React, { useEffect, useCallback } from "react";

import { useSnackbar } from "notistack";

import { observer, inject, PropTypes as MobXPropTypes } from "mobx-react";

import gql from "graphql-tag";
import { useQuery } from "@apollo/client";

import ErrorBoundary from "../../components/ErrorBoundary";
import Page from "../../components/Page";
import {
  GET_MODIFIER_GROUPS,
  GET_PRODUCT_OPTIONS,
  GET_PRODUCT_TAGS,
  GET_SITES,
  GET_VAT_RATES,
} from "../../helpers/apollo/utils";
import BulkEditor from "./BulkEditor";
import recurseApolloQuery from "../../helpers/apollo/recurseApolloQuery";

const defaultErrorMessage = "An unexpected error occured when querying";

const fetchPolicy = "cache-and-network";

const DataWrapper = ({ appStore }) => {
  const { enqueueSnackbar } = useSnackbar();

  // #region FETCHING DATA

  // Set the sorting direction to be alphabetical for all queries
  const direction = "ASC";

  const {
    data: productOptionsResponse = { productOptions: [] },
    error: getProductOptionsError,
    fetchMore: fetchMoreProductOptions,
  } = useQuery(gql(GET_PRODUCT_OPTIONS()), {
    fetchPolicy,
    onCompleted: ({ productOptions }) =>
      recurseApolloQuery({
        fetchMore: fetchMoreProductOptions,
        modelName: "productOptions",
        offset: productOptions?.length ?? 0,
        sort: [{ field: "name", direction }],
      }),
    variables: { sort: [{ field: "name", direction }] },
  });

  const {
    data: modifierGroupResponse = { modifierGroups: [] },
    error: getModifierGroupsError,
    fetchMore: fetchMoreModifierGroups,
  } = useQuery(gql(GET_MODIFIER_GROUPS()), {
    fetchPolicy,
    onCompleted: ({ modifierGroups }) =>
      recurseApolloQuery({
        fetchMore: fetchMoreModifierGroups,
        modelName: "modifierGroups",
        offset: modifierGroups?.length ?? 0,
        sort: [{ field: "name", direction }],
      }),
    variables: { sort: [{ field: "name", direction }] },
  });

  const {
    data: sitesResponse = { sites: [] },
    error: getSitesError,
    fetchMore: fetchMoreSites,
  } = useQuery(gql(GET_SITES()), {
    fetchPolicy,
    onCompleted: ({ sites }) =>
      recurseApolloQuery({
        fetchMore: fetchMoreSites,
        modelName: "sites",
        offset: sites?.length ?? 0,
        sort: [{ field: "name", direction }],
      }),
    variables: { sort: [{ field: "name", direction }] },
  });

  const {
    data: vatRatesResponse = { vatRates: [] },
    error: getVatRatesError,
    fetchMore: fetchMoreVatRates,
  } = useQuery(gql(GET_VAT_RATES()), {
    fetchPolicy,
    onCompleted: ({ vatRates }) =>
      recurseApolloQuery({
        fetchMore: fetchMoreVatRates,
        modelName: "vatRates",
        offset: vatRates?.length ?? 0,
        sort: [{ field: "code", direction }],
      }),
    variables: { sort: [{ field: "code", direction }] },
  });

  const {
    data: productTagsResponse = { productTags: [] },
    error: getProductTagsError,
    fetchMore: fetchMoreSubGroups,
  } = useQuery(gql(GET_PRODUCT_TAGS()), {
    fetchPolicy,
    onCompleted: ({ productTags }) =>
      recurseApolloQuery({
        fetchMore: fetchMoreSubGroups,
        modelName: "productTags",
        offset: productTags?.length ?? 0,
        sort: [{ field: "name", direction }],
      }),
    variables: {
      filter: {
        type_in: ["SUBGROUP", "PRODUCTGROUP"],
      },
      sort: [{ field: "name", direction }],
    },
  });

  // #endregion

  // #region ERROR HANDLING

  const generateErrorMessageNotification = useCallback(
    (prefix, errorMessage) =>
      enqueueSnackbar(`${prefix} - ${errorMessage ?? defaultErrorMessage}`, {
        SnackbarProps: { "data-testid": `${prefix}-snackbar` },
        variant: "error",
      }),
    [enqueueSnackbar],
  );

  useEffect(() => {
    [
      { name: "productOptions", error: getProductOptionsError },
      { name: "modifierGroups", error: getModifierGroupsError },
      { name: "sites", error: getSitesError },
      { name: "vatRates", error: getVatRatesError },
      { name: "productTags", error: getProductTagsError },
    ].forEach(({ name, error }) => {
      if (error?.message) {
        generateErrorMessageNotification(name, error.message);
      }
    });
  }, [
    generateErrorMessageNotification,
    getProductOptionsError,
    getModifierGroupsError,
    getSitesError,
    getVatRatesError,
    getProductTagsError,
  ]);

  // #endregion

  useEffect(() => {
    appStore.setLoading(false);
  });

  const mapToDropdownOptions = (arrayToConvert, textKey = "name") =>
    arrayToConvert.map(item => ({ text: item[textKey], value: item.id }));

  return (
    <ErrorBoundary>
      <Page title="Bulk Product Management">
        <BulkEditor
          productOptions={mapToDropdownOptions(
            productOptionsResponse.productOptions,
          )}
          modifierGroups={mapToDropdownOptions(
            modifierGroupResponse.modifierGroups,
          )}
          sites={mapToDropdownOptions(sitesResponse.sites)}
          vatRates={mapToDropdownOptions(vatRatesResponse.vatRates, "code")}
          subGroups={mapToDropdownOptions(
            productTagsResponse.productTags.filter(
              ({ type }) => type === "SUBGROUP",
            ),
          )}
          productGroups={mapToDropdownOptions(
            productTagsResponse.productTags.filter(
              ({ type }) => type === "PRODUCTGROUP",
            ),
          )}
        />
      </Page>
    </ErrorBoundary>
  );
};

DataWrapper.propTypes = {
  appStore: MobXPropTypes.objectOrObservableObject.isRequired,
};

export default inject("appStore")(observer(DataWrapper));
