import * as React from "react";
import Button from "@mui/material/Button";
import PropTypes from "prop-types";

export default function NegativeAction({
  buttonText = "No",
  disabled = false,
  onClick,
  testId = "negative-action-button",
}) {
  return (
    <Button
      aria-label="negative-action-button"
      color="secondary"
      disabled={disabled}
      onClick={onClick}
      type="button"
      variant="contained"
      data-testid={testId}
    >
      {buttonText}
    </Button>
  );
}

NegativeAction.propTypes = {
  buttonText: PropTypes.string,
  disabled: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  testId: PropTypes.string,
};
