import { types, getSnapshot, getParent, flow, getEnv } from "mobx-state-tree";
import gql from "graphql-tag";

export const fragments = {
  fullDetails: gql`
    fragment CustomerFullDetails on Customer {
      id
      firstName
      lastName
      email
      phone
    }
  `,
};

export default types
  .model("Customer", {
    id: types.identifier,
    firstName: types.maybeNull(types.string),
    lastName: types.maybeNull(types.string),
    email: types.maybeNull(types.string),
    phone: types.maybeNull(types.string),
  })
  .views(self => ({
    get parent() {
      return getParent(self);
    },
  }))
  .actions(self => {
    const { apolloClient } = getEnv(self);

    return {
      setFirstName: value => {
        self.firstName = value;
      },
      setLastName: value => {
        self.lastName = value;
      },

      setEmail: value => {
        self.email = value;
      },

      setPhone: value => {
        self.phone = value;
      },

      remove() {
        apolloClient.mutate({
          variables: { id: self.id },
          mutation: gql`
            mutation deleteUser($id: ID!) {
              deleteUser(id: $id) {
                result
              }
            }
          `,
        });
      },

      save: flow(function* save() {
        const snapshot = getSnapshot(self);
        const params = Object.keys(snapshot)
          .map(key => `${key}: ${JSON.stringify(snapshot[key])}`)
          .join(", ");

        yield apolloClient.mutate({
          mutation: gql`
            mutation updateUser {
                updateCustomer(${params}) {
                    id
                }
            }
        `,
        });
      }),
    };
  });
