import React, { useCallback, useEffect, useState } from "react";
import { observer, inject, PropTypes as MobXPropTypes } from "mobx-react";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Card,
  CardContent,
  Container,
  Grid,
  TableCell,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import MUIDataTable, {
  debounceSearchRender,
  ExpandButton,
} from "mui-datatables";

import PositiveAction from "../../components/Button/PositiveAction";
import ErrorBoundary from "../../components/ErrorBoundary";
import Page from "../../components/Page";

const LOG_LEVELS_TO_FLAG = ["ERROR", "WARN"];

const DashboardComponent = ({ appStore }) => {
  const navigate = useNavigate();

  const [tableData, setTableData] = useState([]);
  const [hasAcked, setHasAcked] = useState({});

  const [tableOptions, setTableOptions] = useState({
    page: 0,
    rowsPerPage: 10,
    filter: {},
    search: null,
    sort: { sortCol: "createdAt", direction: "desc" },
  });

  const [newLogs, setNewLogs] = useState(0);
  const [rowCount, setRowCount] = useState(0);

  const [columnFilterLists, setColumnFilterLists] = useState({});

  // Handle pagination
  const findAllAuditLogs = useCallback(() => {
    if (appStore.isLoading === false) {
      appStore.setLoading(true);
    }
    const {
      filter,
      page,
      rowsPerPage,
      search,
      sort: { direction, sortCol: field },
    } = tableOptions;

    appStore.auditLogStore
      .findAll({
        limit: rowsPerPage,
        offset: rowsPerPage * page,
        // set direction to uppercase to match enum value
        sort: { direction: direction.toUpperCase(), field },
        filter,
        search,
      })
      .then(auditLogResponse => {
        setTableData(auditLogResponse);
        appStore.setLoading(false);
      })
      .catch(error => {
        appStore.setLoading(false);
        appStore.log.error(error);
        navigate("/app/404");
      });
  }, [appStore, tableOptions]);

  useEffect(findAllAuditLogs, [appStore, tableOptions, findAllAuditLogs]);

  // Fetch total auditLogs
  const countAuditLogs = useCallback(() => {
    const fileredAuditLogQuery = { ...tableOptions.filter };

    if (tableOptions.search) {
      fileredAuditLogQuery.message_contains = tableOptions.search;
    }

    appStore.auditLogStore
      .count(fileredAuditLogQuery)
      .then(auditLogCount => setRowCount(auditLogCount));
  }, [appStore, tableOptions]);

  useEffect(countAuditLogs, [appStore, tableOptions, countAuditLogs]);

  // Fetch recent auditLogs
  const countNewAuditLogs = useCallback(() => {
    const yesterday = moment().subtract(1, "day").startOf("day");
    const endOfToday = moment().endOf("day");

    const newAuditLogQuery = {
      acknowledgedBy_is: null,
      createdAt_between: {
        from: yesterday,
        to: endOfToday,
      },
      level_in: LOG_LEVELS_TO_FLAG,
    };

    appStore.auditLogStore
      .count(newAuditLogQuery)
      .then(auditLogCount => setNewLogs(auditLogCount));
  }, [appStore]);

  useEffect(countNewAuditLogs, [appStore, countNewAuditLogs, hasAcked]);

  const handleAcknowledgedAuditLog = id => {
    if (appStore.isLoading === false) {
      appStore.setLoading(true);
    }

    appStore.auditLogStore.acknowledgeAuditLogEntry(id).then(() => {
      setHasAcked({ ...hasAcked, [id]: new Date().toISOString() });
      appStore.setLoading(false);
    });
  };

  const handleChangePage = newPage => {
    setTableOptions({ ...tableOptions, page: newPage });
  };

  const handleChangeRowsPerPage = numberOfRows => {
    setTableOptions({
      ...tableOptions,
      page: 0,
      rowsPerPage: parseInt(numberOfRows, 10),
    });
  };

  const handleSearchChange = searchText => {
    setTableOptions({
      ...tableOptions,
      page: 0,
      search: searchText,
    });
  };

  const handleFilterChange = (applyNewFilters, columns) => {
    const newFilters = applyNewFilters();
    const filtersToApply = {};
    const columnFiltersToUpdate = columnFilterLists;

    newFilters.forEach((filter, index) => {
      const { name } = columns[index];
      const filterValue = filter[0];

      if (name && filterValue) {
        switch (name) {
          case "level":
            filtersToApply.level_in = filterValue;
            columnFiltersToUpdate[name] = [filterValue];
            break;
          default:
            filtersToApply[name] = filterValue;
            break;
        }
      } else if (name && !filterValue) {
        columnFiltersToUpdate[name] = [];
      }
    });

    setTableOptions({
      ...tableOptions,
      filter: filtersToApply,
    });
    setColumnFilterLists(columnFiltersToUpdate);
  };

  const handleSortChange = (changedColumn, direction) => {
    setTableOptions({
      ...tableOptions,
      sort: { sortCol: changedColumn, direction },
    });
  };

  const columns = [
    {
      name: "level",
      label: "Level",
      options: {
        filter: true,
        sort: false,
        filterList: columnFilterLists.active,
        filterOptions: {
          names: ["ERROR", "WARN", "INFO", "HTTP", "VERBOSE", "DEBUG", "TRACE"],
        },
        customFilterListOptions: { render: value => `Log Level: ${value}` },
      },
    },
    {
      name: "message",
      label: "Message",
      options: {
        filter: false,
        sort: false,
        setCellProps: () => ({
          style: {
            width: "500px",
          },
        }),
      },
    },
    {
      name: "source",
      label: "Source",
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: "createdAt",
      label: "Created At",
      options: {
        filter: false,
        sort: true,
        customBodyRender: value => moment(value).format("lll"),
      },
    },
    {
      name: "",
      label: "Acknowledgement Status",
      options: {
        customBodyRender: (value, meta) => {
          const { id, acknowledgedAt } = tableData[meta.rowIndex];

          return acknowledgedAt || hasAcked[id] ? (
            moment(acknowledgedAt ?? hasAcked[id]).format("lll")
          ) : (
            <PositiveAction
              buttonText="Acknowledge"
              testId={`acknowledge-button-${meta.rowIndex}`}
              onClick={() => handleAcknowledgedAuditLog(id)}
            />
          );
        },
        setCellProps: () => ({
          style: {
            width: "250px",
          },
        }),

        filter: false,
        sort: false,
      },
    },
  ];

  const components = {
    ExpandButton: props => <ExpandButton {...props} />,
  };

  // Configuration for MUIDataTable
  const options = {
    confirmFilters: true,
    count: rowCount,
    customFilterDialogFooter: (currentFilterList, applyNewFilters) => {
      return (
        <div style={{ marginTop: "40px", width: "200px" }}>
          <PositiveAction
            buttonText="Apply Filters"
            onClick={() => handleFilterChange(applyNewFilters, columns)}
          />
        </div>
      );
    },
    customSearchRender: debounceSearchRender(500),
    expandableRows: true,
    expandableRowsHeader: false,
    filter: true,
    onChangePage: handleChangePage,
    onChangeRowsPerPage: handleChangeRowsPerPage,
    onColumnSortChange: handleSortChange,
    onFilterChange: (column, filterList, type) => {
      if (type === "chip") {
        const applyNewFilters = () => filterList;
        handleFilterChange(applyNewFilters, columns);
      }
    },
    onSearchChange: handleSearchChange,
    page: tableOptions.page,
    renderExpandableRow: (rowData, rowMeta) => {
      const rowDataObject = tableData[rowMeta.dataIndex];
      return (
        <TableRow>
          <TableCell colSpan={rowData.length + 1}>
            Full Message Details:&nbsp;
            <Box sx={{ px: 2, py: 1 }}>{rowDataObject.message}</Box>
          </TableCell>
        </TableRow>
      );
    },
    rowsPerPage: tableOptions.rowsPerPage,
    rowsPerPageOptions: [10, 25, 50, 100],
    selectableRows: "none",
    serverSide: true,
    setRowProps: row => {
      const levelRowItem = row[0];
      const createdAtRowItem = row[3];
      const acknowledgedAtRowItem = row[4];

      if (
        typeof acknowledgedAtRowItem === "object" &&
        moment(createdAtRowItem, "lll").isAfter(moment().subtract(1, "day")) &&
        LOG_LEVELS_TO_FLAG.find(logLevel => logLevel === levelRowItem)
      ) {
        return {
          sx: {
            "&:hover": {
              background: "lightpink !important",
            },
            background: "pink",
          },
        };
      }

      return {};
    },
    setTableProps: () => ({ "data-testid": "MUI-Datatable" }),
    sortOrder: {
      name: tableOptions.sort.sortCol,
      direction: tableOptions.sort.direction,
    },
  };

  return (
    <ErrorBoundary>
      <Page title="Audit Logs">
        <Container maxWidth={false}>
          <Grid
            container
            spacing={0}
            justifyContent="space-between"
            sx={{ marginBottom: 3 }}
          >
            <Grid item>
              <Typography variant="h2" gutterBottom sx={{ marginTop: 3 }}>
                Audit Logs
              </Typography>
            </Grid>
          </Grid>
          <Box sx={{ marginBottom: 3 }}>
            <Card sx={{ marginBottom: 3 }}>
              <CardContent>
                <Typography
                  variant="h2"
                  textAlign="center"
                  sx={{ marginBottom: 1 }}
                >
                  Issues In The Last 24 Hours{" "}
                  <Tooltip
                    arrow
                    title={`This is not a total of all new logs, it counts logs with levels: ${LOG_LEVELS_TO_FLAG.join(
                      ", ",
                    )} that have not been acknowledged. These will be highlighted with a red background.`}
                  >
                    <HelpOutlineIcon fontSize="small" />
                  </Tooltip>
                </Typography>
                <Typography variant="h1" textAlign="center">
                  {newLogs}
                </Typography>
              </CardContent>
            </Card>
            <MUIDataTable
              data={tableData}
              columns={columns}
              options={options}
              components={components}
            />
          </Box>
        </Container>
      </Page>
    </ErrorBoundary>
  );
};
DashboardComponent.propTypes = {
  appStore: MobXPropTypes.objectOrObservableObject.isRequired,
};

const AuditLogDashboard = inject("appStore")(observer(DashboardComponent));

export default AuditLogDashboard;
