import { types, getEnv, flow } from "mobx-state-tree";
import gql from "graphql-tag";
import moment from "moment";

import { removeTypename } from "../helpers";
import Session, { fragments as sessionFragments } from "./models/Session";
import User from "./models/User";
import DateTime from "./models/DateTime";

import SiteStore from "./SiteStore";
import UserStore from "./UserStore";
import SessionStore from "./SessionStore";
import BookingStore from "./BookingStore";
import BookingTypeStore from "./BookingTypeStore";
import CustomerStore from "./CustomerStore";
import OrganisationStore from "./OrganisationStore";
import PaymentTypeStore from "./PaymentTypeStore";
import DepositStore from "./DepositStore";
import SettingsStore from "./SettingsStore";
import ModifierGroupStore from "./ModifierGroupStore";
import ProductTagStore from "./ProductTagStore";
import PromotionStore from "./PromotionStore";
import RecipientStore from "./RecipientStore";
import ReportStore from "./ReportStore";
import ChangesetStore from "./ChangesetStore";
import ProductStore from "./ProductStore";
import AuditLogStore from "./AuditLogStore";

import Changeset from "./models/Changeset";

export default types
  .model("AppStore", {
    userStore: types.optional(UserStore, {}),
    siteStore: types.optional(SiteStore, {}),
    modifierGroupStore: types.optional(ModifierGroupStore, {}),
    sessionStore: types.optional(SessionStore, {}),
    bookingStore: types.optional(BookingStore, {}),
    bookingTypeStore: types.optional(BookingTypeStore, {}),
    customerStore: types.optional(CustomerStore, {}),
    organisationStore: types.optional(OrganisationStore, {}),
    paymentTypeStore: types.optional(PaymentTypeStore, {}),
    depositStore: types.optional(DepositStore, {}),
    settingsStore: types.optional(SettingsStore, {}),
    productTagStore: types.optional(ProductTagStore, {}),
    promotionStore: types.optional(PromotionStore, {}),
    recipientStore: types.optional(RecipientStore, {}),
    reportStore: types.optional(ReportStore, {}),
    productStore: types.optional(ProductStore, {}),
    changesetStore: types.optional(ChangesetStore, {}),
    auditLogStore: types.optional(AuditLogStore, {}),
    session: types.maybeNull(types.reference(Session)),
    viewer: types.maybeNull(types.reference(User)),
    inChangeset: types.maybeNull(Changeset),
    displayName: "",
    expiredLogin: types.optional(types.boolean, false),
    isLoading: true,
    isLoadingLevel: 0,
    isSidebarOpen: false, // counts only on mobile/tablet (when overlayer)
    isInitializing: true,
    currentDateTime: types.optional(DateTime, moment()),
    sessionExpiryCountdown: false, // triggers popup modal that begins countdown till session expiry
  })
  .views(self => ({
    get log() {
      return getEnv(self).logger;
    },
  }))
  .actions(self => {
    const { SESSION_TOKEN_NAME, apolloClient } = getEnv(self);
    return {
      afterCreate: async () => {
        const token = localStorage.getItem(SESSION_TOKEN_NAME);

        if (token) {
          try {
            const session = await self.sessionStore.find(token);

            self.setSession(session);
            self.setViewer(session.user);

            await self.settingsStore.load();
          } catch (error) {
            self.logout(false);
          }
        }
        await self.getPublicSettings();
        self.setInitializing(false);
      },

      setSessionExpiryCountdown: async (flag = true) => {
        self.sessionExpiryCountdown = flag;
      },

      setExpiredLogin: async (flag = true) => {
        self.expiredLogin = flag;
      },

      // TODO - remove this comment line (95). Used to trigger some coverage and test CI

      /**
       * Flag to block entire interface
       * Might be called concurrently by multiple components, each should be honored
       */
      setLoading: (flag = true) => {
        if (flag) {
          self.isLoadingLevel += 1;
          self.isLoading = Boolean(flag);
        } else {
          self.isLoadingLevel -= 1;
          if (self.isLoadingLevel <= 0) {
            self.isLoading = Boolean(flag);
          }
        }
      },

      setSidebarOpen: (flag = true) => {
        self.isSidebarOpen = flag;
      },

      setChangeset: item => {
        self.inChangeset = item;
      },

      setInitializing: (flag = true) => {
        self.isInitializing = Boolean(flag);
      },

      setDisplayName: name => {
        self.displayName = name;
      },

      setSession(session) {
        localStorage.setItem(
          SESSION_TOKEN_NAME,
          session !== null ? session.id : null,
        );
        self.session = session;
      },

      setViewer(user) {
        self.viewer = user;
      },

      getPublicSettings: async () => {
        const settings = await apolloClient.query({
          query: gql`
            {
              publicSettings {
                accountName
              }
            }
          `,
        });

        self.setDisplayName(settings.data.publicSettings.accountName);
      },

      authenticate: flow(function* authenticate(username, password) {
        try {
          let response = yield apolloClient.mutate({
            mutation: gql`
            mutation authenticate{
              authenticate(username: "${username}", password: "${password}") {
                ...SessionFullDetails
              }
            }
            ${sessionFragments.fullDetails}
          `,
          });

          response = removeTypename(response);

          const session = self.sessionStore.track(response.data.authenticate);

          self.setSession(session);
          self.setViewer(session.user);

          yield self.settingsStore.load();

          return Promise.resolve(session);
        } catch (error) {
          return Promise.reject(error);
        }
      }),

      logout: (reload = true) => {
        self.setSession(null);
        if (reload === true) {
          window.location.reload();
        }
      },
    };
  });
