import React, { Fragment, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { observer, inject, PropTypes as MobXPropTypes } from "mobx-react";
import { toJS } from "mobx";
import { Link } from "react-router-dom";

import { withStyles } from "@mui/styles";
import red from "@mui/material/colors/red";

import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import FormHelperText from "@mui/material/FormHelperText";

// Icons
import IconDownload from "@mui/icons-material/Download";
import IconGroup from "@mui/icons-material/Group";

import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";

import MUIDataTable from "mui-datatables";

import ErrorBoundary from "../../components/ErrorBoundary";
import Page from "../../components/Page";
import MuiDatatablesFilters from "../../components/Filter/MuiDatatablesFilters";
import AddAction from "../../components/Button/AddAction";

const styles = theme => ({
  margin: {
    margin: theme.spacing(1),
  },
  button: {
    margin: 0,
    marginLeft: theme.spacing(1),
  },
  danger: {
    color: red[500],
    "&:hover": {
      backgroundColor: red[50],
    },
  },
});

const ListComponent = ({ appStore, classes }) => {
  const [items, setItems] = useState([]);
  const [formError, setFormError] = useState("");

  useEffect(() => {
    appStore.reportStore
      .findAll()
      .then(results => {
        setItems(
          [...results.values()].map(report => [
            report.name,
            [...report.recipients.values()].length,
            toJS(report),
          ]),
        );

        appStore.setLoading(false);
      })
      .catch(error => {
        appStore.log.error(error);
        setFormError(error.message);
        appStore.setLoading(false);
      });
  }, [appStore]);

  const columns = [
    {
      name: "Report Name",
      options: {
        filter: true,
        sort: true,
        sortOrder: "asc",
      },
    },
    {
      name: "Subscribers",
      options: {
        filter: true,
        sort: true,
        sortOrder: "asc",
      },
    },
    {
      name: "Actions",
      options: {
        filter: false,
        sort: false,
        customBodyRender: record => {
          return (
            <Fragment key={record.id}>
              <Tooltip title="Add/Remove Recipients">
                <Link to={`/app/reports/${record.id}`}>
                  <IconButton
                    className={classes.button}
                    aria-label="Edit Report"
                    size="medium"
                  >
                    <IconGroup />
                  </IconButton>
                </Link>
              </Tooltip>
              <Tooltip title="Generate Report">
                <Link to={`/app/reports/${record.id}/generate`}>
                  <IconButton
                    className={classes.button}
                    aria-label="Generate Report"
                    size="medium"
                  >
                    <IconDownload />
                  </IconButton>
                </Link>
              </Tooltip>
            </Fragment>
          );
        },
      },
    },
  ];

  const options = {
    filter: false,
    rowsPerPage: 100,
    selectableRows: "none",
    search: false,
    download: false,
    print: false,
    viewColumns: false,
    sortOrder: {
      name: "Name",
      direction: "asc",
    },
  };

  return (
    <ErrorBoundary>
      <Page title="Sites">
        <Container maxWidth={false}>
          <Grid container rowSpacing={3}>
            <Grid container item xs={12} justifyContent="end" mt={3}>
              <Link to="/app/reports/manage-subscribers">
                <AddAction buttonText="Manage Subscribers" />
              </Link>
            </Grid>
            <Grid item xs={12}>
              <section>
                {formError && (
                  <FormHelperText error>{formError}</FormHelperText>
                )}
                {items.length > 0 && (
                  <MUIDataTable
                    data={toJS(items)}
                    columns={columns}
                    options={options}
                    components={{
                      TableFilterList: MuiDatatablesFilters,
                    }}
                  />
                )}
              </section>
            </Grid>
          </Grid>
        </Container>
      </Page>
    </ErrorBoundary>
  );
};
ListComponent.propTypes = {
  appStore: MobXPropTypes.objectOrObservableObject.isRequired,
  classes: PropTypes.shape({
    button: PropTypes.string,
    danger: PropTypes.string,
    addButton: PropTypes.string,
  }).isRequired,
};

const List = withStyles(styles)(inject("appStore")(observer(ListComponent)));

export default List;
