import React from "react";
import { useParams, Link } from "react-router-dom";
import gql from "graphql-tag";
import { useQuery } from "@apollo/client";
import { useSnackbar } from "notistack";

import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

import NegativeAction from "../../components/Button/NegativeAction";
import ErrorBoundary from "../../components/ErrorBoundary";
import Page from "../../components/Page";
import TillConfigBulkEditor from "./components/TillConfigBulkEditor";

import { GET_TILL_BY_ID } from "../../helpers/apollo/utils";

const TillConfig = () => {
  const { tillId } = useParams();
  const { enqueueSnackbar } = useSnackbar();

  const title = "Till Configuration";

  const { data: { till: tillData = [] } = {}, loading } = useQuery(
    gql(GET_TILL_BY_ID()),
    {
      fetchPolicy: "cache-and-network",
      onError: error => {
        enqueueSnackbar(`Error: ${error.message}`, {
          variant: "error",
          SnackbarProps: {
            "data-testid": "till-config-view-error-snackbar",
          },
        });
      },
      skip: !tillId,
      variables: {
        tillId,
      },
    },
  );

  return (
    <ErrorBoundary>
      <Page title={title}>
        <Container maxWidth={false}>
          <Grid container rowSpacing={3}>
            <Grid
              container
              item
              xs={12}
              justifyContent="space-between"
              alignItems="center"
              mt={3}
            >
              <Grid item>
                <Typography variant="h2">
                  {title +
                    (tillData.tillName && tillData.siteId.name
                      ? ` (${tillData.tillName} - ${tillData.siteId.name})`
                      : "")}
                </Typography>
              </Grid>
            </Grid>
            <Grid item xs={12} sx={{ width: "0px" }}>
              <TillConfigBulkEditor />
            </Grid>
          </Grid>

          <Box display="flex" justifyContent="flex-end" mt={2} pb={3}>
            <Link to="/app/tills">
              <NegativeAction
                onClick={() => {}}
                buttonText="Cancel"
                testId="cancel-button"
              />
            </Link>
          </Box>
        </Container>
      </Page>
    </ErrorBoundary>
  );
};

export default TillConfig;
