import React, { useState, useEffect } from "react";
import { observer, inject, PropTypes as MobXPropTypes } from "mobx-react";

import { Box, Typography } from "@mui/material";

import AlertDialog from "./Dialog/AlertDialog";

const UserTimeoutModal = ({ appStore: app }) => {
  const [counter, setCounter] = useState(60);

  const handleAlertResponse = proceed => {
    if (proceed) {
      app.settingsStore.loadNoCache(); // Refresh session timeout to keep session alive.
      app.setSessionExpiryCountdown(false);
    } else {
      app.logout();
    }
  };

  useEffect(() => {
    if (app.sessionExpiryCountdown) {
      if (counter > 0) {
        setTimeout(() => setCounter(counter - 1), 1000);
      } else {
        app.logout();
      }
    }
  }, [counter, app, app.sessionExpiryCountdown]);

  return (
    <AlertDialog
      backButtonText="No"
      okButtonText="Yes"
      notifyClosure={handleAlertResponse}
      present={app.sessionExpiryCountdown}
      setPresence={app.setSessionExpiryCountdown}
      testId="user-timeout-modal"
      title="Are you still there?"
    >
      <Box>
        <Typography>
          For security we&apos;ll log you out in {counter} seconds. Would you
          like to remain logged in?
        </Typography>
      </Box>
    </AlertDialog>
  );
};

UserTimeoutModal.propTypes = {
  appStore: MobXPropTypes.objectOrObservableObject.isRequired,
};

export default inject("appStore")(observer(UserTimeoutModal));
