import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { observer, inject, PropTypes as MobXPropTypes } from "mobx-react";
import { useParams, useNavigate } from "react-router-dom";
import gql from "graphql-tag";
import { useQuery } from "@apollo/client";
import moment from "moment";

import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";

import ErrorBoundary from "../../components/ErrorBoundary";
import Page from "../../components/Page";
import ViewChangeManager from "./ViewChangeManager";
import AddEdit from "./AddEdit";

import { GET_CHANGE_BY_ID } from "../../helpers/apollo/utils";

const defaultAddEditForm = AddEdit;

const disabledChildComponentStyling = { pointerEvents: "none" };

const ViewChangeComponent = ({
  appStore,
  addEditForm = defaultAddEditForm,
}) => {
  const AddEditForm = addEditForm;
  const navigate = useNavigate();
  const { promotionId, changeId } = useParams();

  const [change, setChange] = useState({});
  const [currentView, setCurrentView] = useState("before");
  const [changeViewLoading, setChangeViewLoading] = useState(false);

  // This is being done as when refreshing on the "add" version of this page
  // the loading will never be set to false, instead using local loading values from
  // apollo client.
  useEffect(() => {
    appStore.setLoading(false);
  });

  const { error } = useQuery(gql(GET_CHANGE_BY_ID()), {
    fetchPolicy: "cache-and-network", // TODO - this should be the default for all requests
    onCompleted: ({ change: getChangeResponse }) => {
      // This mutation to the reponse is specifically required for promotions
      // if this were to be rolled out to other page this would need to be
      // abstracted in some way
      setChange({
        ...getChangeResponse,
        beforeChange: {
          ...getChangeResponse.beforeChange,

          startAt: moment(getChangeResponse.beforeChange.startAt),
          endAt: getChangeResponse.beforeChange.endAt
            ? moment(getChangeResponse.beforeChange.endAt)
            : null,
        },
        afterChange: {
          ...getChangeResponse.afterChange,
          // due to an issue with changesets and how we manage saving the after state for promotions
          // will store the actions and rules as a string.
          actions: JSON.stringify(getChangeResponse.afterChange.actions),
          rules: JSON.stringify(getChangeResponse.afterChange.rules),
          startAt: moment(getChangeResponse.afterChange.startAt),
          endAt: getChangeResponse.afterChange.endAt
            ? moment(getChangeResponse.afterChange.endAt)
            : null,
        },
      });
    },
    skip: !changeId,
    variables: {
      changeId,
    },
  });

  // This should be replaced with an actual error handler
  useEffect(() => {
    if (error) {
      throw error;
    }
  }, [error]);

  const handleExitViewChange = () => {
    navigate(`/app/promotions/${promotionId}`);
  };

  const handleChangeView = updatedView => {
    if (changeViewLoading) {
      clearTimeout(changeViewLoading);
    }

    // Create a new timeout that hides the alert after 3 seconds
    const timeoutId = setTimeout(() => {
      setChangeViewLoading(false);
    }, 100);

    setChangeViewLoading(timeoutId);
    setCurrentView(updatedView);
  };

  return (
    <ErrorBoundary>
      <Page title="Viewing Change">
        <ViewChangeManager
          currentView={currentView}
          handleExit={handleExitViewChange}
          handleChangeView={handleChangeView}
        />
        <Box sx={disabledChildComponentStyling}>
          {Object.values(change).length && !changeViewLoading ? (
            <AddEditForm
              viewChangeOverride={
                currentView === "before"
                  ? change.beforeChange
                  : change.afterChange
              }
            />
          ) : (
            <Box textAlign="center">
              <CircularProgress />
            </Box>
          )}
        </Box>
      </Page>
    </ErrorBoundary>
  );
};

ViewChangeComponent.propTypes = {
  appStore: MobXPropTypes.objectOrObservableObject.isRequired,
  addEditForm: PropTypes.func,
};

const ViewChange = inject("appStore")(observer(ViewChangeComponent));

export default ViewChange;
