import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import gql from "graphql-tag";
import moment from "moment";
import { useQuery, useMutation } from "@apollo/client";
import { useSnackbar } from "notistack";

import Alert from "@mui/material/Alert";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

import {
  GET_PRODUCT_BY_ID_TAGS,
  UPDATE_PRODUCT,
} from "../../../helpers/apollo/utils";

import Switch from "../../../components/Switch";
import PositiveAction from "../../../components/Button/PositiveAction";

import { convertTagArrayForDatabase } from "./ComponentHelpers";
import ProductTagsTable from "../../ProductTags/Components/ProductTagsTable";

const columns = [
  { label: "Name", name: "name", options: { filter: false, sort: true } },
  { label: "POS Id", name: "POSId", options: { filter: false, sort: true } },
  {
    label: "Type",
    name: "type",
    options: {
      filter: true,
      filterBy: "type_is",
      filterOptions: {
        names: ["PRODUCTGROUP", "SUBGROUP", "GENERIC"],
      },
      customFilterListOptions: { render: v => `Type: ${v}` },
      sort: true,
    },
  },
  {
    label: "Created At",
    name: "createdAt",
    options: { filter: false, sort: true },
  },
];

const ProductTagManager = () => {
  const { productId, siteId } = useParams();
  const { enqueueSnackbar } = useSnackbar();

  const [currentPage, setCurrentPage] = useState(0);
  const [savedTags, setSavedTags] = useState([]);
  const [selectedTags, setSelectedTags] = useState([]);
  const [showSelected, setShowSelected] = useState(false);

  const { error: getQueryError } = useQuery(
    gql(GET_PRODUCT_BY_ID_TAGS()),

    {
      onCompleted: ({ product }) => {
        if (product?.tags) {
          setSavedTags(product.tags);
        }
      },
      fetchPolicy: "cache-and-network",
      skip: !productId,
      variables: {
        productId,
        siteId,
      },
    },
  );

  const [submitToServer, { error: addEditQueryError }] = useMutation(
    gql(UPDATE_PRODUCT()),
    {
      onCompleted: () => {
        enqueueSnackbar("Your changes have been saved", {
          SnackbarProps: { "data-testid": "product-tags-saved-snackbar" },
          variant: "success",
        });
      },
    },
  );

  const handleSubmit = updatedTags =>
    submitToServer({
      variables: {
        input: {
          id: productId,
          tags: convertTagArrayForDatabase(updatedTags),
          siteId,
        },
      },
    });

  const toggleSelectedFilter = (items, value) => {
    setSelectedTags(value ? items : []);
    setShowSelected(value);
    setCurrentPage(0);
  };

  const parseSelectedItems = items => {
    return items.map(([id, { name, POSId, type, createdAt }]) => {
      const createdAtUTC = moment(createdAt, "Do MMM YYYY, HH:mm");

      return {
        id,
        name,
        POSId,
        type,
        createdAt: createdAtUTC.isValid()
          ? createdAtUTC.format("Do MMM YYYY, HH:mm")
          : moment(createdAt).format("Do MMM YYYY, HH:mm"),
      };
    });
  };

  useEffect(() => {
    if (getQueryError || addEditQueryError) {
      const fallbackErrorMessage = "Please try again later.";

      const errorMsg =
        getQueryError?.message ||
        addEditQueryError?.message ||
        fallbackErrorMessage;

      enqueueSnackbar(`Error: ${errorMsg}`, {
        variant: "error",
        SnackbarProps: {
          "data-testid": "product-tags-error-snackbar",
        },
      });
    }
  }, [getQueryError, addEditQueryError, enqueueSnackbar]);

  return (
    <Card>
      <CardContent>
        <Grid container spacing={2}>
          <Grid item container alignItems="center" xs={12}>
            <Grid item>
              <Typography>Product Tags</Typography>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            {!productId ? (
              <Alert severity="info" data-testid="no-product-id-message">
                In order to assign product tags you must first create this
                product.
              </Alert>
            ) : (
              <ProductTagsTable
                columns={columns}
                overrideData={showSelected ? selectedTags : null}
                multiSelectOptions={selectionState => ({
                  customToolbar: () => {
                    let itemsToAdd = false;

                    selectionState.forEach((value, key) => {
                      if (!savedTags.some(({ id }) => id === key)) {
                        itemsToAdd = true;
                      }
                    });

                    const itemsToRemove = savedTags.find(
                      ({ id }) => !selectionState.has(id),
                    );

                    return (
                      <Box sx={{ width: "100%" }}>
                        <Box sx={{ mt: "12px" }}>
                          <PositiveAction
                            disabled={
                              !itemsToAdd &&
                              !itemsToRemove &&
                              !addEditQueryError
                            }
                            buttonText="Save Changes"
                            onClick={() => {
                              const iteratorToArray = Array.from(
                                selectionState.entries(),
                              ).map(([id, { type }]) => ({
                                id,
                                type,
                              }));

                              handleSubmit(iteratorToArray);
                              setSavedTags(
                                parseSelectedItems(
                                  Array.from(selectionState.entries()),
                                ),
                              );
                            }}
                            testId="save-tags-btn"
                          />
                        </Box>
                      </Box>
                    );
                  },
                  customToolbarSelect: () => {
                    const items = parseSelectedItems(
                      Array.from(selectionState.entries()),
                    );

                    return (
                      <Grid
                        container
                        item
                        justifyContent="flex-start"
                        xl={10}
                        lg={9}
                        xs={6}
                        sx={{ mr: 8 }}
                      >
                        <Switch
                          active={showSelected}
                          disabled={!items.length}
                          label="Show selected only"
                          onChange={() =>
                            toggleSelectedFilter(items, !showSelected)
                          }
                          testId="show-selected-switch"
                        />
                      </Grid>
                    );
                  },
                  onRowSelectionChange: (cur, allRowsSelected) => {
                    if (!allRowsSelected.length) {
                      toggleSelectedFilter(null, false);
                    }
                  },
                  selectableRows: "multiple",
                  selectToolbarPlacement: "above",
                })}
                onPageChange={curPage => setCurrentPage(curPage)}
                page={currentPage}
                defaultFilter={{ type_notIn: "MAJORGROUP" }}
                defaultSort={[{ field: "name", direction: "ASC" }]}
                defaultSelectedItems={savedTags}
              />
            )}
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default ProductTagManager;
