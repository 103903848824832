import { types } from "mobx-state-tree";
import gql from "graphql-tag";

import User, { fragments as UserFragments } from "./User";
import DateTime from "./DateTime";

export const fragments = {
  fullDetails: gql`
    fragment NoteFullDetails on Note {
      id
      message
      createdAt
      createdBy {
        ...UserFullDetails
      }
      updatedAt
      updatedBy {
        ...UserFullDetails
      }
    }
    ${UserFragments.fullDetails}
  `,
};

export default types.model("Note", {
  id: types.identifier,
  message: types.string,
  createdAt: DateTime,
  createdBy: types.reference(User),
  updatedAt: types.maybeNull(DateTime),
  updatedBy: types.maybeNull(types.reference(User)),
});
