import { types, getEnv, getParent, flow } from "mobx-state-tree";
import gql from "graphql-tag";

import Customer, { fragments as CustomerFragments } from "./models/Customer";
import { removeTypename } from "../helpers";

// @todo treat versioning changes on preProcess
export default types
  .model("CustomerStore", {
    customers: types.optional(types.map(Customer), {}),
  })
  .views(self => ({
    get appStore() {
      return getParent(self);
    },
  }))
  .actions(self => {
    const { apolloClient } = getEnv(self);

    return {
      find: flow(function* find(id) {
        const data = yield apolloClient.query({
          query: gql`
            {
              customer(id: "${id}") {
                ...CustomerFullDetails
              }
            }
            ${CustomerFragments.fullDetails}
          `,
        });

        self.track(removeTypename(data.data.user));
      }),

      track(entity) {
        if (undefined === entity) {
          throw new Error("Trying to track an undefined entity");
        }
        if (!self.customers.has(entity.id)) {
          return self.customers.put(entity);
        }

        return self.customers.get(entity.id);
      },
    };
  });
