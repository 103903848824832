import * as React from "react";
import PropTypes from "prop-types";
import Switch from "@mui/material/Switch";
import FormControlLabel from "@mui/material/FormControlLabel";

export default function SwitchComponent({
  active,
  colour = "primary",
  disabled = false,
  label = null,
  onChange,
  testId = "switch-button",
}) {
  const renderSwitch = () => (
    <Switch
      checked={active}
      disabled={disabled}
      onChange={onChange}
      data-testid={testId}
      color={colour}
      onClick={e => e.stopPropagation()}
    />
  );

  return label ? (
    <FormControlLabel
      control={renderSwitch()}
      label={label}
      data-testid={`${testId}-label`}
    />
  ) : (
    renderSwitch()
  );
}

SwitchComponent.propTypes = {
  active: PropTypes.bool.isRequired,
  colour: PropTypes.string,
  disabled: PropTypes.bool,
  label: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  testId: PropTypes.string,
};
