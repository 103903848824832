const recurseApolloQuery = ({
  fetchMore,
  modelName,
  offset = 0,
  limit = 100,
  sort = [],
}) => {
  if (!fetchMore || !modelName) {
    throw new Error("Missing required parameter.");
  }

  return fetchMore({
    variables: { limit, offset, ...(sort.length && { sort }) },
    updateQuery: (previousQueryResult, { fetchMoreResult }) => ({
      ...previousQueryResult,
      [modelName]: [
        ...previousQueryResult[modelName],
        ...fetchMoreResult[modelName],
      ],
    }),
  }).then(({ data }) => {
    if (data[modelName].length < limit) {
      return Promise.resolve();
    }

    const newOffset = offset + limit;
    return recurseApolloQuery({
      fetchMore,
      modelName,
      offset: newOffset,
      limit,
      sort,
    });
  });
};

export default recurseApolloQuery;
