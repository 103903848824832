import React, { useState, useEffect, useCallback } from "react";
import propTypes from "prop-types";
import { useSnackbar } from "notistack";
import gql from "graphql-tag";
import { useLazyQuery } from "@apollo/client";
import { flushSync } from "react-dom";

import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";

import {
  DataGridPro,
  GridRow,
  useGridApiContext,
  useGridApiRef,
} from "@mui/x-data-grid-pro";

import { GET_FLAT_PRODUCT_AT_SITES } from "../../../helpers/apollo/utils.js";

import { siteEnabledColumn, sitePickedColumn } from "../utils/columns.js";
import { mapNullAssociatedFields } from "../utils/dataMapper";

const slotProps = {
  loadingOverlay: {
    variant: "linear-progress",
    noRowsVariant: "linear-progress",
  },
};

const dataGridStyling = {
  minHeight: "120px",
  flex: 1,
  "& .siteName-cell": {
    backgroundColor: "#f8f8f8",
  },
};

const datagridInitialState = {
  pinnedColumns: {
    left: ["siteName"],
  },
};

const ProductAtSitesPanel = ({
  columns,
  productId,
  rowUpdate,
  triggerRefetch,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const apiRef = useGridApiRef();
  const apiContext = useGridApiContext();

  const [rows] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const siteColumns = [
    {
      headerName: "Site",
      field: "siteName",
      type: "text",
      sortable: true,
      editable: false,
      cellClassName: "siteName-cell",
    },
    ...columns,
    sitePickedColumn,
    siteEnabledColumn,
  ];

  const [getProductAtSites] = useLazyQuery(gql(GET_FLAT_PRODUCT_AT_SITES()), {
    fetchPolicy: "no-cache",
    onError: error =>
      enqueueSnackbar(`${error.message}`, {
        variant: "error",
        SnackbarProps: {
          "data-testid": "bulk-product-at-site-query-error-snackbar",
        },
      }),
  });

  const loadData = useCallback(
    ({ productId }) => {
      getProductAtSites({
        variables: {
          productId,
        },
      })
        .then(({ data }) => {
          if (data?.flatProductAtSites) {
            flushSync(() => {
              const mappedData = data.flatProductAtSites.map(row => {
                const updatedRow = {
                  ...row,
                  productId,
                  id: `${productId}-${row.siteId}`,
                };
                return mapNullAssociatedFields(updatedRow);
              });

              apiRef?.current?.updateRows(mappedData);
            });
          }
        })
        .then(() => {
          return new Promise(resolve => {
            setTimeout(() => {
              resolve();
            }, 0);
          });
        })
        .then(() =>
          apiRef?.current?.autosizeColumns({
            includeHeaders: true,
            includeOutliers: true,
          }),
        )
        .then(() => {
          // !! You MUST disable virtualization here after autosizing because that enables it.
          apiRef?.current?.unstable_setColumnVirtualization(false);
        })
        .then(() => {
          setIsLoading(false);
        });
    },
    [apiRef],
  );

  useEffect(() => {
    if (productId) {
      loadData({ productId });
    }
  }, [productId]);

  useEffect(() => {
    if (triggerRefetch && productId) {
      loadData({ productId });
    }
  }, [triggerRefetch]);

  const [width, setWidth] = useState(() => {
    const dimensions = apiContext?.current?.getRootDimensions();
    return dimensions?.viewportOuterSize?.width;
  });

  const handleViewportInnerSizeChange = useCallback(() => {
    const dimensions = apiContext?.current?.getRootDimensions();
    setWidth(dimensions?.viewportOuterSize?.width);
  }, [apiContext]);

  useEffect(() => {
    return apiContext?.current?.subscribeEvent(
      "viewportInnerSizeChange",
      handleViewportInnerSizeChange,
    );
  }, [apiContext, handleViewportInnerSizeChange]);

  const onProcessRowUpdateError = ({ message }) => {
    enqueueSnackbar(`${message}`, {
      variant: "error",
      SnackbarProps: {
        "data-testid": "bulk-product-at-site-update-error-snackbar",
      },
    });
  };

  const CustomGridRow = props => {
    const { index } = props;
    return <GridRow {...props} data-testid={`product-at-site-row-${index}`} />;
  };

  return (
    <Stack
      sx={{
        py: 2,
        height: "100%",
        boxSizing: "border-box",
        position: "sticky",
        left: 0,
        pr: 2,
        width,
        backgroundColor: "#f8f8f8",
      }}
      direction="column"
    >
      <Paper sx={{ flex: 1, mx: "auto", width: "97%", p: 1 }}>
        <Stack direction="column" spacing={1} sx={{ height: 1 }}>
          <Typography variant="h4" sx={{ pb: 1 }}>
            Site Configuration
          </Typography>
          <DataGridPro
            disableVirtualization
            disableAutosize
            apiRef={apiRef}
            density="compact"
            columns={siteColumns}
            editMode="row"
            loading={isLoading}
            rows={rows}
            initialState={datagridInitialState}
            onProcessRowUpdateError={onProcessRowUpdateError}
            processRowUpdate={rowUpdate}
            slots={{
              row: CustomGridRow,
            }}
            slotProps={slotProps}
            sx={dataGridStyling}
            hideFooter
          />
        </Stack>
      </Paper>
    </Stack>
  );
};

ProductAtSitesPanel.propTypes = {
  columns: propTypes.array.isRequired,
  index: propTypes.number,
  productId: propTypes.string.isRequired,
  rowUpdate: propTypes.func.isRequired,
  triggerRefetch: propTypes.bool.isRequired,
};

export default ProductAtSitesPanel;
