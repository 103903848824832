import { types, getEnv, flow } from "mobx-state-tree";
import gql from "graphql-tag";
import { reduce } from "lodash";

import { transformer, removeTypename } from "../../helpers";
import DateTime from "./DateTime";
import User, { fragments as UserFragments } from "./User";
import PaymentType, { fragments as PaymentTypeFragments } from "./PaymentType";

export const fragments = {
  fullDetails: gql`
    fragment DepositFullDetails on Deposit {
      id
      externalReference
      status
      for {
        id
      }
      amount
      currency
      paymentType {
        ...PaymentTypeFullDetails
      }
      refunds {
        id
        amount
        notes
        createdAt
        createdBy {
          ...UserFullDetails
        }
      }
      createdAt
      createdBy {
        ...UserFullDetails
      }

      updatedAt
      updatedBy {
        ...UserFullDetails
      }
    }
    ${UserFragments.fullDetails}
    ${PaymentTypeFragments.fullDetails}
  `,
};

const Refund = types
  .model("Refund", {
    id: types.string,
    amount: types.integer,
    notes: types.maybeNull(types.string),
    createdAt: DateTime,
  })
  .views(self => ({
    get formattedAmount() {
      // @todo use currency formatter
      return `£${(self.amount / 100).toFixed(2)}`;
    },
  }));

export default types
  .model("Deposit", {
    id: types.identifier,
    externalReference: types.maybeNull(types.string),
    status: types.string,
    for: types.frozen({ id: types.string }),
    amount: types.integer,
    currency: types.string,
    paymentType: types.reference(PaymentType),
    refunds: types.array(Refund),
    takenAt: DateTime,
    createdAt: DateTime,
    createdBy: types.reference(User),
    updatedAt: types.maybeNull(DateTime),
    updatedBy: types.maybeNull(types.reference(User)),
  })
  .views(self => ({
    get formattedAmount() {
      // @todo use currency formatter
      return `£${(self.amount / 100).toFixed(2)}`;
    },
    get refundedAmount() {
      return reduce(self.refunds, (total, refund) => total + refund.amount, 0);
    },
  }))
  .actions(self => ({
    afterCreate: () => {},

    addRefund: flow(function* addRefund({ amount, notes = "" }) {
      // @todo this gets called on a clone so outside scope apolloClient is not available
      const { apolloClient } = getEnv(self);
      const params = transformer({
        id: self.id,
        amount,
        notes,
      });
      try {
        const response = yield apolloClient.mutate({
          mutation: gql`
          mutation refundDeposit {
            refundDeposit(input: {
                ${params}
              }) {
                id
                amount
                notes
                createdAt
                createdBy {
                  ...UserFullDetails
                }
              }
          }
          ${UserFragments.fullDetails}
        `,
        });
        self.refunds.push(removeTypename(response.data.refundDeposit));
        return Promise.resolve(self);
      } catch (error) {
        return Promise.reject(error);
      }
    }),
  }));
