import { types, getEnv, getParent, flow } from "mobx-state-tree";
import gql from "graphql-tag";
import { values } from "mobx";

import ProductTag, {
  fragments as productTagFragments,
} from "./models/ProductTag";
import { removeTypename, transformer } from "../helpers";

// @todo treat versioning changes on preProcess
export default types
  .model("ProductTagStore", {
    items: types.optional(types.map(ProductTag), {}),
  })
  .views(self => ({
    get appStore() {
      return getParent(self);
    },
    get count() {
      return values(self.items).filter(value => value.isFiltered !== false)
        .length;
    },
    filter(filters) {
      /**
       * Filtering is complex. This can be given an object where the key and value are the column and value to sort on,
       * or it can be given an object with AND or OR as a key with array as values.  The values to be filtered can be strings, but may be
       * numbers or null.
       */
      const { filter, type } = filters;

      const filterFunction = item => {
        const filterResults = [];
        Object.keys(filter).forEach(filterKey => {
          const itemFilterKey = filterKey.match(/^[^_]*/)[0];
          const isNotFilter = filterKey.match(/_not$/);

          const filterableValue = item[itemFilterKey] ?? "";
          const itemPropAsString = filterableValue.toUpperCase
            ? filterableValue.toUpperCase()
            : filterableValue.toString();

          if (typeof filter[filterKey] === "string") {
            filterResults.push(
              itemPropAsString.includes(
                filter[filterKey].toUpperCase
                  ? filter[filterKey].toUpperCase()
                  : filter[filterKey].toString(),
              ) === !isNotFilter,
            );
          } else {
            filter[filterKey].forEach(comparator => {
              filterResults.push(
                itemPropAsString.includes(
                  comparator.toUpperCase
                    ? comparator.toUpperCase()
                    : comparator.toString().toUpperCase(),
                ) === !isNotFilter,
              );
            });
          }
        });

        const shouldShow =
          (type === "OR" && filterResults.includes(true)) ||
          (type === "AND" && !filterResults.includes(false));
        item.setFilter(shouldShow);
      };

      self.items.forEach(item => {
        filterFunction(item);
      });
    },
    getOrFetch(id) {
      if (self.items.has(id)) {
        return self.items.get(id);
      }
      setImmediate(() => {
        self.find(id);
      });
      return [];
    },
    paginate(limit, offset, sort, filter = null) {
      if (!filter) {
        self.items.forEach(item => {
          item.setFilter(true);
        });
        return self.sort(sort).splice(offset, limit);
      }
      self.filter(filter);
      return self
        .sort(sort)
        .filter(item => item.isFiltered !== false)
        .splice(offset, limit);
    },
    get parentTagList() {
      return values(self.items).filter(({ type }) => type === "MAJORGROUP");
    },
    sort(sort) {
      let sortFunction;
      const itemSortKey = sort.sortCol;
      switch (itemSortKey) {
        case "type":
        case "name":
          sortFunction = (a, b) => {
            return a.toUpperCase().localeCompare(b.toUpperCase());
          };
          break;
        case "POSId":
        default:
          sortFunction = (a, b) => a - b;
          break;
      }
      return values(self.items).sort((a, b) => {
        if (sort.direction === "asc") {
          return sortFunction(a[itemSortKey], b[itemSortKey]);
        }
        return sortFunction(b[itemSortKey], a[itemSortKey]);
      });
    },
  }))
  .actions(self => {
    const { apolloClient } = getEnv(self);
    const findAllLoaded = false;

    return {
      find: flow(function* find(id) {
        if (self.items.has(id)) {
          return self.items.get(id);
        }

        try {
          const response = yield apolloClient.query({
            query: gql`
            {
              productTag(id: "${id}") {
                ...ProductTagFullDetails
              }
            }
            ${productTagFragments.fullDetails}
          `,
          });

          const item = self.track(response.data.productTag);

          return Promise.resolve(item);
        } catch (error) {
          return Promise.reject(error);
        }
      }),

      findAll: flow(function* findAll(limit = 100, offset = 0, filter = {}) {
        if (findAllLoaded) {
          return Promise.resolve(self.items);
        }

        try {
          const response = yield apolloClient.query({
            query: gql`
              {
                productTags(limit: ${limit}, offset: ${offset}) {
                  ...ProductTagFullDetails
                }
              }
              ${productTagFragments.fullDetails}
            `,
          });

          response.data.productTags.forEach(item => self.track(item));
          if (response.data.productTags.length === 100) {
            self.findAll(limit, offset + limit);
          }
          return Promise.resolve(self.items);
        } catch (error) {
          return Promise.reject(error);
        }
      }),

      add: flow(function* add(entity) {
        try {
          const input = transformer({
            name: entity.name,
            POSId: entity.POSId,
            type: () => entity.type,
            parentTagId: entity.parentTagId,
          });

          const response = yield apolloClient.mutate({
            mutation: gql`
            mutation addProductTag {
              addProductTag(input: {
                ${input}
              }) {
                ...ProductTagFullDetails
              }
            }
            ${productTagFragments.fullDetails}
          `,
          });

          return Promise.resolve(self.track(response.data.addProductTag));
        } catch (error) {
          return Promise.reject(error);
        }
      }),

      track(entity) {
        if (!self.items.has(entity.id)) {
          const input = removeTypename(entity);
          return self.items.put(input);
        }

        return self.items.get(entity.id);
      },
    };
  });
