import React, { useState, useEffect } from "react";
import { observer, inject, PropTypes as MobXPropTypes } from "mobx-react";
import validator from "validator";
import { useParams, useNavigate } from "react-router-dom";

import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Container from "@mui/material/Container";
import FormHelperText from "@mui/material/FormHelperText";
import Grid from "@mui/material/Grid";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";

import ErrorBoundary from "../../components/ErrorBoundary";
import Page from "../../components/Page";
import NegativeAction from "../../components/Button/NegativeAction";
import PositiveAction from "../../components/Button/PositiveAction";

export const ERROR_MESSAGES = {
  required: {
    name: "Please specify Product Tag name",
    POSId: "Please specify POS ID",
    type: "Please specify POS Type",
  },
  isNumeric: { POSId: "Please specify a numeric POS ID" },
  other: { parentTag: "Please specify parent tag" },
};

const AddEditComponent = ({ appStore }) => {
  const navigate = useNavigate();
  const { productTagId } = useParams();

  const [formData, setFormData] = useState({
    name: "",
    POSId: "",
    type: "",
    parentTag: null,
  });
  const [tag, setTag] = useState(null);

  const [hasParentTag, setHasParentTag] = useState(false);
  const [hasPOSId, setHasPOSId] = useState(true);
  const [parentTagsList, setParentTagsList] = useState([]);

  const [formDataErrors, setFormDataErrors] = useState({});
  const [queryError, setQueryError] = useState("");

  const handleCancel = () => {
    appStore.setLoading();
    navigate("/app/product-tags");
  };

  const handleInputChange = ({ target: { value, name } }) => {
    setFormData({ ...formData, [name]: value });
  };

  const handlePOSIdChange = ({ target: { value } }) => {
    const fieldHasValue = value !== "";
    const fieldNotValid = !validator.isInt(value, {
      allow_leading_zeroes: false,
      min: 1,
    });

    if (fieldHasValue && fieldNotValid) {
      setFormDataErrors({
        ...formDataErrors,
        POSId: ERROR_MESSAGES.isNumeric.POSId,
      });
    } else {
      const newValue = parseInt(value, 10);

      setFormData({ ...formData, POSId: fieldHasValue ? newValue : "" });
    }
  };

  const handleParentTagChange = ({ target: { value } }) => {
    setFormData({
      ...formData,
      parentTag: parentTagsList.find(item => item.id === value) ?? null,
    });
  };

  const handleTypeChange = ({ target: { value } }) => {
    const updatedFormData = { ...formData };

    updatedFormData.type = value;
    if (value === "MAJORGROUP") {
      setHasParentTag(false);
      updatedFormData.parentTag = null;
    } else {
      setHasParentTag(true);
    }

    if (value === "GENERIC") {
      setHasPOSId(false);
      updatedFormData.POSId = null;
    } else {
      setHasPOSId(true);
    }

    setFormData(updatedFormData);
  };

  useEffect(() => {
    appStore.productTagStore
      .findAll()
      .then(() => {
        setParentTagsList(appStore.productTagStore.parentTagList);
        if (productTagId !== undefined) {
          appStore.productTagStore
            .find(productTagId)
            .then(item => {
              item.getParentTag().then(tagWithParent => {
                const { name, POSId, type, parentTag } = tagWithParent;
                setTag(tagWithParent);

                if (
                  tagWithParent.parentTag !== null ||
                  tagWithParent.type === "GENERIC"
                ) {
                  setFormData({ name, POSId, type, parentTag });
                  setHasParentTag(true);
                } else {
                  setFormData({ name, POSId, type });
                }

                if (tagWithParent.type === "GENERIC") {
                  setHasPOSId(false);
                }
                appStore.setLoading(false);
              });
            })
            .catch(error => {
              appStore.log.error(error);
              navigate("/app/404");
            });
        } else {
          appStore.setLoading(false);
        }
      })
      .catch(error => {
        appStore.log.error(error);
        navigate("/app/404");
      });
  }, [appStore.isLoading]);

  const validateFormData = data => {
    const validationErrors = {};

    if (validator.isEmpty(data.name.toString())) {
      validationErrors.name = ERROR_MESSAGES.required.name;
    }

    if (data.type !== "GENERIC" && validator.isEmpty(data.POSId.toString())) {
      validationErrors.POSId = ERROR_MESSAGES.required.POSId;
    }

    if (validator.isEmpty(data.type.toString())) {
      validationErrors.type = ERROR_MESSAGES.required.type;
    }

    if (
      !["MAJORGROUP", "GENERIC"].includes(data.type) &&
      data.parentTag === null
    ) {
      validationErrors.parentTag = ERROR_MESSAGES.other.parentTag;
    }

    return validationErrors;
  };

  const handleSubmit = e => {
    e.preventDefault();

    const validationErrors = validateFormData(formData);

    const { name, POSId, type, parentTag } = formData;

    if (!Object.keys(validationErrors).length) {
      appStore.setLoading();

      if (tag === null) {
        appStore.productTagStore
          .add({
            name,
            POSId,
            type,
            parentTagId: parentTag?.id || null,
          })
          .then(result => {
            navigate(`/app/product-tags/`);
            setTag(result);
          })
          .catch(error => {
            appStore.log.error(error);
            setQueryError(error.message);
            appStore.setLoading(false);
          });
      } else {
        tag
          .setName(name)
          .setPOSId(POSId)
          .setType(type)
          .setParentTag(parentTag)
          .save()
          .then(() => navigate("/app/product-tags"))
          .catch(error => {
            appStore.log.error(error);
            setQueryError(error.message);
            appStore.setLoading(false);
          });
      }
    } else {
      setFormDataErrors(validationErrors);
    }
  };

  if (appStore.isLoading) {
    return null;
  }

  const { name, POSId, type, parentTag } = formData;

  return (
    <ErrorBoundary>
      <Page title={`${tag !== null ? "Edit" : "Add"} product tag`}>
        <Container maxWidth={false}>
          <form autoComplete="off" noValidate>
            <Card>
              <CardContent>
                <Grid container spacing={3}>
                  {queryError && (
                    <Grid item xs={12}>
                      <FormHelperText error>{queryError}</FormHelperText>
                    </Grid>
                  )}
                  <Grid item lg={3} md={6} xs={12}>
                    <TextField
                      name="name"
                      required
                      variant="standard"
                      fullWidth
                      label="Name"
                      value={name}
                      error={!!formDataErrors.name}
                      helperText={formDataErrors.name}
                      onChange={handleInputChange}
                      inputProps={{ "data-testid": "name-input" }}
                    />
                  </Grid>
                  {hasPOSId && (
                    <Grid item lg={3} md={6} xs={12}>
                      <TextField
                        required
                        data-testid="POSID"
                        fullWidth
                        label="POS Id"
                        value={POSId}
                        error={!!formDataErrors.POSId}
                        helperText={formDataErrors.POSId}
                        onChange={handlePOSIdChange}
                        inputProps={{ "data-testid": "POSId-input" }}
                      />
                    </Grid>
                  )}
                  <Grid item lg={12} />
                  <Grid item lg={3} md={6} xs={12}>
                    <FormControl
                      variant="standard"
                      fullWidth
                      error={!!formDataErrors.type}
                    >
                      <InputLabel required>POS Type</InputLabel>
                      <Select
                        variant="standard"
                        value={type}
                        onChange={handleTypeChange}
                        inputProps={{ "aria-label": "type-input" }}
                        disabled={tag !== null && type === "MAJORGROUP"}
                      >
                        <MenuItem value="">Select type</MenuItem>
                        <MenuItem value="MAJORGROUP">Major Group</MenuItem>
                        <MenuItem value="PRODUCTGROUP">Product Group</MenuItem>
                        <MenuItem value="SUBGROUP">Sub Group</MenuItem>
                        <MenuItem value="GENERIC">Generic Group</MenuItem>
                      </Select>
                      {formDataErrors.type && (
                        <FormHelperText>{formDataErrors.type}</FormHelperText>
                      )}
                    </FormControl>
                  </Grid>
                  {hasParentTag && (
                    <Grid item lg={3} md={6} xs={12}>
                      <FormControl
                        variant="standard"
                        fullWidth
                        error={!!formDataErrors.parentTag}
                      >
                        <InputLabel>Parent category</InputLabel>
                        <Select
                          variant="standard"
                          value={parentTag?.id ?? ""}
                          onChange={handleParentTagChange}
                          inputProps={{ "aria-label": "parent-input" }}
                        >
                          <MenuItem value="">Select parent tag</MenuItem>
                          {parentTagsList.map(item => (
                            <MenuItem key={item.id} value={item.id}>
                              {item.name}
                            </MenuItem>
                          ))}
                        </Select>
                        {formDataErrors.parentTag && (
                          <FormHelperText>
                            {formDataErrors.parentTag}
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                  )}
                  <Grid item xs={12}>
                    <Box pr={1} display="inline">
                      <NegativeAction
                        buttonText="Cancel"
                        onClick={handleCancel}
                      />
                    </Box>
                    <PositiveAction buttonText="Save" onClick={handleSubmit} />
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </form>
        </Container>
      </Page>
    </ErrorBoundary>
  );
};

AddEditComponent.propTypes = {
  appStore: MobXPropTypes.objectOrObservableObject.isRequired,
};

export default inject("appStore")(observer(AddEditComponent));
