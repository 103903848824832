import React from "react";
import PropTypes from "prop-types";
import { observer, inject, PropTypes as MobXPropTypes } from "mobx-react";
import { observable, action, toJS, makeObservable } from "mobx";

import { withStyles } from "@mui/styles";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";

import MUIDataTable from "mui-datatables";

import { red } from "@mui/material/colors";
import ErrorBoundary from "../../components/ErrorBoundary";
import Page from "../../components/Page";
import MuiDatatablesFilters from "../../components/Filter/MuiDatatablesFilters";

import dateColumn from "../../components/dateColumn";
import { FRONTEND_DATE_FORMAT } from "../../constants";

import createCSVDownload from "../../helpers/datatables/utils";

const styles = theme => ({
  margin: {
    margin: theme.spacing(1),
  },

  button: {
    margin: 0,
    marginLeft: theme.spacing(1),
  },
  danger: {
    color: red[500],
    "&:hover": {
      backgroundColor: red[50],
    },
  },
  warning: {
    backgroundColor: "#fff3cd",
  },
  warningMessage: {
    height: "auto",
    borderBottom: 0,
  },
});

class ListComponent extends React.Component {
  items = [];
  columns = [
    {
      name: "Site",
      options: {
        filter: true,
        sort: true,
      },
    },
    { name: "Customer", options: { filter: false, sort: false } },
    { name: "Organisation", options: { filter: true, sort: true } },
    { name: "Deposit Total", options: { filter: false, sort: false } },
    { name: "Deposit Currency", options: { filter: false, sort: false } },
    { name: "Deposits Created At", options: { filter: false, sort: false } },
    dateColumn("Expected"),
    dateColumn("Created"),
  ];

  options = {
    pagination: true,
    count: 0, // this updates
    downloadOptions: {
      filterOptions: {
        useDisplayedRowsOnly: false,
      },
      separator: ",",
      filename: "export.csv",
    },
    filterType: "dropdown",
    onDownload: (buildHead, buildBody, currentColumns) => {
      const { appStore: app } = this.props;
      app.setLoading(true);
      app.bookingStore
        .findAll(this.options.count, 0, {})
        .then(data => {
          const formattedItems = this.formatItems([...data.values()]).map(
            item => ({ data: item }),
          );

          createCSVDownload(currentColumns, formattedItems, {
            ...this.options,
            onDownload: null,
          });
        })
        .finally(() => {
          app.setLoading(false);
        });

      return false;
    },
    rowsPerPage: 100,
    filter: true,
    search: false,
    serverSide: true,
    selectableRows: "none",
    onTableChange: (actionName, { rowsPerPage, page, ...more }) => {
      const { appStore: app } = this.props;
      const limit = rowsPerPage;
      const offset = rowsPerPage * page;
      if (actionName === "propsUpdate") {
        return;
      }
      app.setLoading(true);
      this.load({ limit, offset, app, actionName, more });
    },
  };

  constructor(props) {
    super(props);

    makeObservable(this, {
      items: observable,
      setItems: action,
    });
  }

  load({ limit, offset, app }) {
    app.bookingStore
      .findAll(limit, offset, {}, true)
      .then(bookings => {
        this.setItems([...bookings.values()]);
        this.options.count = app.bookingStore.count;
        app.setLoading(false);
      })
      .catch(error => {
        app.log.error(error);
        this.setFormError(error.message);
        app.setLoading(false);
      });
  }

  componentDidMount() {
    const { appStore: app } = this.props;
    const limit = this.options.rowsPerPage;
    const offset = 0;
    this.load({ limit, offset, app });
  }

  setFormError = value => {
    this.error = value;
  };

  formatItems = items =>
    items
      .filter(booking => booking.status !== "CANCELLED")
      .map(booking => [
        booking.status === "ERROR"
          ? `${booking.site.name} ${booking.errorReason}`
          : booking.site.name,
        `${booking.customer.firstName || ""} ${
          booking.customer.lastName || ""
        }`.trim(),
        booking.createdBy.organisation.name,
        booking.deposits && booking.deposits.length
          ? (
              booking.deposits
                .map(x => x.amount)
                .reduce((acc, cur) => acc + cur) / 100
            ).toFixed(2)
          : 0,
        booking.deposits && booking.deposits.length
          ? booking.deposits[0].currency
          : ``,
        (booking.deposits &&
          booking.deposits.length &&
          booking.deposits
            .map(x => x.createdAt.format(FRONTEND_DATE_FORMAT))
            .join(", ")) ||
          "",
        booking.expectedAt.format(FRONTEND_DATE_FORMAT),
        booking.createdAt.format(FRONTEND_DATE_FORMAT),
      ]);

  setItems = items => {
    this.items = this.formatItems(items);
  };

  render() {
    return (
      <ErrorBoundary>
        <Page title="Bookings">
          <Container maxWidth={false}>
            <Box mt={3}>
              {this.items.length > 0 && (
                <MUIDataTable
                  data={toJS(this.items)}
                  columns={this.columns}
                  options={this.options}
                  components={{
                    TableFilterList: MuiDatatablesFilters,
                  }}
                />
              )}
            </Box>
          </Container>
        </Page>
      </ErrorBoundary>
    );
  }
}
ListComponent.propTypes = {
  appStore: MobXPropTypes.objectOrObservableObject.isRequired,
  classes: PropTypes.shape({
    button: PropTypes.string,
    danger: PropTypes.string,
  }).isRequired,
};

const List = withStyles(styles)(inject("appStore")(observer(ListComponent)));

export default List;
