import React from "react";

import FormGroup from "@mui/material/FormGroup";
import FormLabel from "@mui/material/FormLabel";

import TextField from "@mui/material/TextField";

import moment from "moment";
import { FRONTEND_DATE_FORMAT } from "../constants";

export default name => ({
  name,
  options: {
    filter: true,
    customFilterListRender: filters =>
      (filters[0] &&
        filters[1] &&
        `${name}: ${moment(filters[0]).format(FRONTEND_DATE_FORMAT)} - ${moment(
          filters[1],
        ).format(FRONTEND_DATE_FORMAT)}`) ||
      false,
    filterOptions: {
      names: [name],
      /**
       * @param {object} value
       * @param {object[]} filters
       * @returns bool - whether to EXCLUDE!
       */
      logic: (value, filters) => {
        const mVal = moment(value, FRONTEND_DATE_FORMAT);
        return (
          filters[0] &&
          filters[1] &&
          !mVal.isBetween(moment(filters[0]), moment(filters[1]))
        );
      },
      display: (filterList, onChange, index, column) => {
        const refresh = () =>
          filterList[index][0] &&
          filterList[index][1] &&
          onChange(filterList[index], index, column);

        return (
          <div>
            <FormLabel>{column.label}</FormLabel>
            <FormGroup row>
              <TextField
                variant="standard"
                label="From"
                type="date"
                InputLabelProps={{ shrink: true }}
                margin="dense"
                value={filterList[index][0] || ""}
                className="form-control"
                onChange={ev => {
                  filterList[index][0] = ev.target.value;
                  refresh();
                }}
              />
              <TextField
                variant="standard"
                label="To"
                type="date"
                margin="dense"
                InputLabelProps={{ shrink: true }}
                value={filterList[index][1] || ""}
                className="form-control"
                onChange={ev => {
                  filterList[index][1] = ev.target.value;
                  refresh();
                }}
              />
            </FormGroup>
          </div>
        );
      },
    },
    filterType: "custom",
    sort: true,
  },
});
