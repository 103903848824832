import { types, getParent, flow, getEnv } from "mobx-state-tree";
import gql from "graphql-tag";

import User, { fragments as UserFragments } from "./User";
import DateTime from "./DateTime";
import { transformer } from "../../helpers";

export const fragments = {
  fullDetails: gql`
    fragment ModifierGroupFullDetails on ModifierGroup {
      id
      name
      POSClassId
      selectionLimit
      createdAt
      createdBy {
        ...UserFullDetails
      }
      updatedAt
      updatedBy {
        ...UserFullDetails
      }
    }
    ${UserFragments.fullDetails}
  `,
  modifierProducts: gql`
    fragment ModifierGroupProducts on ModifierGroup {
      id
      modifierProducts {
        id
        name
        POSId
      }
    }
  `,
};

const modifierProduct = types.custom({
  isTargetType() {
    return true;
  },
});

export default types
  .model("ModifierGroup", {
    id: types.identifier,
    name: types.string,
    modifierProducts: types.maybeNull(
      types.frozen(types.array(types.maybeNull(modifierProduct))),
    ),
    POSClassId: types.number,
    selectionLimit: types.maybeNull(types.number),
    createdAt: DateTime,
    createdBy: types.reference(User),
    updatedAt: types.maybeNull(DateTime),
    updatedBy: types.maybeNull(types.reference(User)),
  })
  .views(self => ({
    get siteStore() {
      return getParent(getParent(self));
    },
    get appStore() {
      return getParent(self.modifierGroupStore);
    },
  }))
  .actions(self => {
    // const { apolloClient } = getEnv(self);

    return {
      setName(value) {
        self.name = value;
        return self;
      },

      setPOSClassId(value) {
        self.POSClassId = value;
        return self;
      },

      setSelectionLimit(value) {
        self.selectionLimit = value;
        return self;
      },

      getModifierProducts: flow(function* getModifierProducts() {
        if (self.modifierProducts) {
          return Promise.resolve(self);
        }
        const { apolloClient } = getEnv(self);
        try {
          const response = yield apolloClient.query({
            query: gql`
            {
              modifierGroup(id: "${self.id}") {
                ...ModifierGroupProducts
              }
            }
            ${fragments.modifierProducts}
          `,
          });

          self.modifierProducts =
            response.data.modifierGroup.modifierProducts.map(
              ({ id, name, POSId }) => ({ id, name, POSId }),
            );

          return Promise.resolve(self);
        } catch (error) {
          return Promise.reject(error);
        }
      }),

      setModifierProducts(value) {
        self.modifierProducts = value;
        return self;
      },

      save: flow(function* save() {
        // @todo this gets called on a clone so outside scope apolloClient is not available
        const { apolloClient } = getEnv(self);
        const params = transformer({
          id: self.id,
          name: self.name,
          POSClassId: self.POSClassId,
          selectionLimit: self.selectionLimit,
          productIds: self.modifierProducts.map(item => item.id),
        });

        yield apolloClient.mutate({
          mutation: gql`
          mutation update {
            updateModifierGroup(input: {
                ${params}
              }) {
                 id
              }
          }
      `,
        });
      }),
    };
  });
