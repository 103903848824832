import React, { useState } from "react";
import PropTypes from "prop-types";

import ContentCopyIcon from "@mui/icons-material/ContentCopy";

import IconButton from "../Button/IconButton";

const CopyToClipboardButton = ({
  testId = "copy-to-clipboard-button",
  value,
}) => {
  const [tooltipText, setTooltipText] = useState("Copy To Clipboard");

  const handleClick = () => {
    navigator.clipboard.writeText(value);
    setTooltipText("Copied!");
    setTimeout(() => {
      setTooltipText("Copy To Clipboard");
    }, 2000);
  };

  return (
    <IconButton
      onClick={handleClick}
      icon={<ContentCopyIcon />}
      testId={testId}
      tooltipText={tooltipText}
    />
  );
};

CopyToClipboardButton.propTypes = {
  testId: PropTypes.string,
  value: PropTypes.string.isRequired,
};

export default CopyToClipboardButton;
