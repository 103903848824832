import { types } from "mobx-state-tree";
import gql from "graphql-tag";

import DateTime from "./DateTime";

export const fragments = {
  fullDetails: gql`
    fragment UserOrganisationFullDetails on Organisation {
      id
      name
      createdAt
      updatedAt
    }
  `,
};

export default types.model("UserOrganisation", {
  id: types.identifier,
  name: types.string,
  createdAt: DateTime,
  updatedAt: types.maybeNull(DateTime),
});
