import { priceFields, modifierFields, statusColumns } from "./columns";

const generalFieldMappings = {
  productCode: "POSId",
  description: "name",
  tillDescription: "tillProductName",
  // tags - map to PRODUCTGROUP and SUBGROUP respectively
  modifierOf: "modifierGroup",
  maxMod: "maxMod",
  // priority - should map to the priority modifier group, not setup in API yet
  // RRP - not in the API currently
  // Cost Price - not in the API currently
  vatCode: "vatRateCode", // This will be a dropdown in the future taking a UUID, currently API accepts string
  picked: "picked",
  enabled: "enabled",
};

// Takes the fields to update as retrieved from the flatProduct query
// and converts them to the appropriate input to be used in the product mutation
export const mapFieldsToInput = fields => {
  const mappedFields = {};

  const prices = [];
  const modifiers = [];
  const statuses = [];

  let hasModifierGroupChanges = false;

  Object.keys(fields).forEach(key => {
    const generalFieldMapped = generalFieldMappings[key];

    const priceMatch = priceFields.indexOf(key);

    // Map all non associate-related fields
    if (generalFieldMapped !== undefined) {
      mappedFields[generalFieldMapped] = fields[key];

      // Map price level 1-6 related fields
    } else if (priceMatch > -1) {
      // Ignore null values, as these are price levels that have been removed by the user
      if (fields[key] !== null) {
        prices.push({
          level: priceMatch + 1,
          price: fields[key],
        });
      }

      // Map modifier groups 1-6 related fields
    } else if (modifierFields.includes(key)) {
      hasModifierGroupChanges = true;

      if (fields[key]) {
        modifiers.push({ id: fields[key], required: true });
      }

      // Map product status related fields
    } else if (statusColumns.some(({ field }) => key === field)) {
      statuses.push({
        name: key,
        enabled: fields[key],
      });

      // Map product tags related fields
      // we can ignore subGroup as both are included if one is changed
    } else if (key === "productGroup") {
      mappedFields.tags = {
        PRODUCTGROUP: !fields.productGroup ? [] : [fields.productGroup],
        SUBGROUP: !fields.subGroup ? [] : [fields.subGroup],
      };
    }
  });

  // Return the formatted fields to be used in the mutation input
  return {
    ...mappedFields,
    ...(prices.length && { prices }),
    ...(hasModifierGroupChanges && { modifierGroups: modifiers }),
    ...(statuses.length && { statuses }),
  };
};

// Since we are adding a blank dropdown in case we want to remove these associations,
// we need to convert the null values to empty strings for the dropdown to work
export const mapNullAssociatedFields = row => {
  const mappedRow = { ...row };

  ["subGroup", "productGroup", "modifierOf", ...modifierFields].forEach(
    field => {
      if (mappedRow[field] === null) {
        mappedRow[field] = "";
      }
    },
  );

  return mappedRow;
};
