import React, { useState } from "react";
import PropTypes from "prop-types";
import Alert from "@mui/material/Alert";
import { Avatar, Chip, InputAdornment } from "@mui/material";

import { makeStyles } from "@mui/styles";

import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Grid from "@mui/material/Grid";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import TextField from "@mui/material/TextField";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";

import Autocomplete from "../../../../components/Autocomplete";

const useStyles = makeStyles(() => ({
  bucket: {
    minHeight: 250,
  },
  defaultBucket: {
    minHeight: 50,
  },
  chip: {
    margin: "5px 5px",
  },
  errorAlert: {
    marginBottom: 10,
  },
}));

const itemFilter = filter => item => {
  return !filter || item.name.toLowerCase().includes(filter.toLowerCase());
};

const defaultBuckets = {};

const DiscountBucketBuilder = ({
  buckets = defaultBuckets,
  handleAdd,
  handleRemove,
  prefix,
  percentDiscount,
  submitError,
  handlePercentDiscountChange,
}) => {
  const classes = useStyles();
  const [productFilter, setProductFilter] = useState("");

  const handleFilterChange = event => {
    setProductFilter(event.target.value);
  };

  const getItemStyle = () => {
    return {
      backgroundColor: "inherit",
      border: "",
    };
  };

  const shouldShowFilter = () => {
    const productCount = Object.keys(buckets).reduce((acc, bucket) => {
      return acc + Object.keys(buckets[bucket].items).length;
    }, 0);
    return productCount > 10;
  };

  return (
    <Card style={{ borderColor: "red" }}>
      <CardContent>
        <Grid container>
          {submitError.discountableError && (
            <Grid item xs={12}>
              <Alert severity="error" className={classes.errorAlert}>
                <Typography>{submitError.discountableError}</Typography>
              </Alert>
            </Grid>
          )}
          <Grid container item xs={6} alignItems="center">
            <Typography>Discountable List Selector&nbsp;</Typography>
            <Tooltip
              arrow
              title="The products which are eligible for discount must be selected here."
            >
              <HelpOutlineIcon fontSize="small" />
            </Tooltip>
          </Grid>
          <Grid item xs={3}>
            {shouldShowFilter() && (
              <TextField
                variant="standard"
                label="Filter products"
                placeholder="Filter"
                className={classes.textField}
                margin="normal"
                fullWidth
                onChange={handleFilterChange}
                value={productFilter}
              />
            )}
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item lg={3} md={6} xs={12}>
            <Autocomplete
              fullWidth
              includeGroups
              onSelect={handleAdd}
              label="discountable"
              useFrozenProducts
            />
          </Grid>
          <Grid item xs={12}>
            <Box className={classes.defaultBucket} style={getItemStyle()}>
              {Object.values(buckets.discountable?.items || [])
                .filter(itemFilter(productFilter))
                .map(item => (
                  <Chip
                    avatar={
                      <Avatar>
                        {item.type === "PRODUCTGROUP" ? "PG" : item.type?.[0]}
                      </Avatar>
                    }
                    key={`${prefix}-${item.id}`}
                    label={item.name}
                    onDelete={handleRemove("discountable")(item)}
                    classes={{
                      root: classes.chip,
                    }}
                    title={item.POSId}
                  />
                ))}
            </Box>
          </Grid>
        </Grid>
        <Grid>
          <TextField
            required
            variant="standard"
            label="Promotional Value (%)"
            value={percentDiscount}
            error={!!submitError.percentDiscountError}
            helperText={submitError.percentDiscountError}
            onChange={handlePercentDiscountChange}
            InputProps={{
              endAdornment: <InputAdornment position="end">%</InputAdornment>,
            }}
            // Incredibly this exists. InputProps passes props to the parent, inputProps passes props to the input field
             
            inputProps={{
              "data-testid": "promotional-value-input",
            }}
          />
        </Grid>
      </CardContent>
    </Card>
  );
};

DiscountBucketBuilder.propTypes = {
   
  buckets: (props, propName, componentName) => {
    if (!props[propName].default) {
      return new Error(
        `Invalid prop \`${propName}\` supplied to \`${componentName}\`. A default bucket is required.`,
      );
    }
    // Each bucket requires an items property
    const errors = Object.entries(props[propName]).filter(
      ([, value]) => !value.items,
    );
    if (errors.length) {
      return new Error(
        `Invalid prop \`${propName}\` supplied to \`${componentName}\`. All buckets require an items property.`,
      );
    }
    return null;
  },
  handleAdd: PropTypes.func.isRequired,
  handleRemove: PropTypes.func.isRequired,
  prefix: PropTypes.string.isRequired,
  percentDiscount: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
  submitError: PropTypes.shape({
    percentDiscountError: PropTypes.string,
    discountableError: PropTypes.string,
  }).isRequired,
  handlePercentDiscountChange: PropTypes.func.isRequired,
};

export default DiscountBucketBuilder;
