import { types, getEnv, getParent, flow } from "mobx-state-tree";
import gql from "graphql-tag";

import { removeTypename, transformer } from "../helpers";
import Recipient, { fragments as RecipientFragments } from "./models/Recipient";
import SubscriberStore from "./SubscriberStore";

export default types
  .model("RecipientStore", {
    items: types.optional(types.map(Recipient), {}),
    subscriberStore: types.optional(SubscriberStore, {}),
  })
  .views(self => ({
    get appStore() {
      return getParent(self);
    },
    get sorted() {
      const items = [...self.items.values()];
      return items.sort(({ email: emailA }, { email: emailB }) =>
        emailA.localeCompare(emailB),
      );
    },
  }))
  .actions(self => {
    const { apolloClient } = getEnv(self);
    const findAllLoaded = false;

    return {
      findAll: flow(function* findAll(
        limit = 0,
        offset = 0,
        filter = null,
        sort = null,
      ) {
        if (findAllLoaded) {
          return Promise.resolve(self.items);
        }

        try {
          const response = yield apolloClient.query({
            query: gql`
              {
                recipients (
                  limit: ${limit},
                  offset: ${offset}
                  ${filter ? `,filter: {${transformer(filter)}}` : ""}
                  ${sort ? `,sort: {${transformer(sort)}}` : ""}

                ) {
                  ...UserRecipientDetails
                  ...SubscriberRecipientDetails
                }
              }
              ${RecipientFragments.fullDetails}
            `,
          });

          response.data.recipients.forEach(item => self.track(item));

          return Promise.resolve(self.items);
        } catch (error) {
          return Promise.reject(error);
        }
      }),
      removeSubscriber(id) {
        self.items.delete(id);
      },
      track(entity) {
        if (!self.items.has(entity.id)) {
          const input = removeTypename(entity);
          return self.items.put(input);
        }

        return self.items.get(entity.id);
      },
    };
  });
