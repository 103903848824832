import React, { useState, useEffect } from "react";
import { Grid } from "@mui/material";
import PropTypes from "prop-types";
import { v4 as uuidv4 } from "uuid";

import PriceInputBox from "./PriceInputBox";

const MAX_PRICE_LEVELS = 6;

// create data for all input boxes
const populateInputBoxes = prices => {
  return Array.from(Array(MAX_PRICE_LEVELS)).map((_, i) => {
    return (
      prices?.find(item => item.level === i + 1) || {
        id: null,
        level: i + 1,
        price: null,
        productOption: null,
      }
    );
  });
};

// Duplicate and reshape state data so it can be indexed by productId
// and updated independantly from the form data preventing sluggish key press
const createPricesByProdIdObject = pricesData =>
  pricesData.reduce((acc, item) => {
    const objectId = item?.productOption ? item.productOption.id : "default";
    return {
      ...acc,
      [objectId]: acc[objectId] ? [...acc[objectId], item] : [item],
    };
  }, {});

const PricesTable = ({
  pricesData,
  productOptionId = null,
  updateFormData,
}) => {
  const [priceInputBoxes, setPriceInputBoxes] = useState([]);
  const [pricesByProdId, setPricesByProdId] = useState({});

  useEffect(() => {
    setPricesByProdId(createPricesByProdIdObject(pricesData));
  }, [pricesData]);

  useEffect(() => {
    setPriceInputBoxes(populateInputBoxes(pricesByProdId[productOptionId]));
  }, [pricesByProdId, productOptionId]);

  const handlePriceUpdate = ({ id, level, price }) => {
    const priceId = id || uuidv4();
    const newPrice = {
      __typename: "Price",
      id: priceId,
      level,
      price,
      productOption:
        productOptionId === "default" ? null : { id: productOptionId },
    };
    const newPrices = [...priceInputBoxes];
    newPrices[level - 1] = newPrice;
    setPricesByProdId({
      ...pricesByProdId,
      [productOptionId]: [...newPrices],
    });
    setTimeout(() => {
      updateFormData(newPrice);
    }, 1);
  };

  return (
    <Grid container spacing={3}>
      {priceInputBoxes.map(price => (
        <PriceInputBox
          key={`level${price.level}`}
          priceData={price}
          updatePrices={handlePriceUpdate}
        />
      ))}
    </Grid>
  );
};

PricesTable.propTypes = {
  pricesData: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      level: PropTypes.number.isRequired,
      price: PropTypes.string,
    }),
  ).isRequired,
  productOptionId: PropTypes.string,
  updateFormData: PropTypes.func.isRequired,
};

export default PricesTable;
