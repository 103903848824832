import React, { useState, useEffect } from "react";

import { useMutation } from "@apollo/client";
import PropTypes from "prop-types";
import { observer, inject, PropTypes as MobXPropTypes } from "mobx-react";

import AutorenewIcon from "@mui/icons-material/Autorenew";
import Typography from "@mui/material/Typography";

import AlertDialog from "../../../../../components/Dialog/AlertDialog";
import IconButton from "../../../../../components/Button/IconButton";

const RegenerateButton = ({
  apiField,
  appStore: { settingsStore },
  generationAlgorithm = "HMAC",
  mutationDocument,
  secretName,
  updateHandler,
}) => {
  const [present, setPresence] = useState(false);

  const handleClick = () => {
    setPresence(true);
  };

  const [regenerate, { error, data = {} }] = useMutation(mutationDocument);

  useEffect(() => {
    if (data.generateSecret) {
      updateHandler(data);
    }
  }, [data, settingsStore, updateHandler]);

  useEffect(() => {
    if (error) {
      throw error;
    }
  }, [error]);

  const content = (
    <Typography>
      Regenerating this key will invalidate the {secretName}. Are you sure?
    </Typography>
  );
  return (
    <>
      <IconButton
        color="error"
        icon={<AutorenewIcon />}
        tooltipText="Regenerate This Secret"
        onClick={handleClick}
        testId="regenerate-icon"
      />
      <AlertDialog
        testId="regenerate-alert"
        title="Warning"
        present={present}
        setPresence={setPresence}
        notifyClosure={proceed => {
          if (proceed) {
            regenerate({
              variables: {
                input: {
                  name: apiField,
                  generationAlgorithm,
                },
              },
            });
          }
        }}
      >
        {content}
      </AlertDialog>
    </>
  );
};

RegenerateButton.propTypes = {
  apiField: PropTypes.string.isRequired,
  appStore: MobXPropTypes.objectOrObservableObject.isRequired,
  generationAlgorithm: PropTypes.string,
  mutationDocument: PropTypes.shape({}).isRequired,
  secretName: PropTypes.string.isRequired,
  updateHandler: PropTypes.func.isRequired,
};

export default inject("appStore")(observer(RegenerateButton));
