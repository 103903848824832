import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { Link } from "react-router-dom";

import { observer, inject, PropTypes as MobXPropTypes } from "mobx-react";
import gql from "graphql-tag";
import { useQuery } from "@apollo/client";

import EditIcon from "@mui/icons-material/Edit";

import DataTable from "../../../components/Datatable/Datatable";
import IconButton from "../../../components/Button/IconButton";
import { GET_PRODUCTS_WITH_COUNT } from "../../../helpers/apollo/utils";

const defaultColumns = [
  { label: "Name", name: "name", options: { filter: false, sort: true } },
  { label: "POS Id", name: "POSId", options: { filter: false, sort: true } },
  { label: "Price", name: "price", options: { filter: false, sort: true } },
  { label: "Sites", name: "sites", options: { filter: false, sort: false } },
  {
    label: "Created at",
    name: "createdAt",
    options: { filter: false, sort: true },
  },
  {
    label: "Major Group",
    name: "Major Group",
    options: {
      viewColumns: false,
      display: false,
      filter: true,
    },
  },
  {
    label: "Product Group",
    name: "Product Group",
    options: {
      viewColumns: false,
      display: false,
      filter: true,
    },
  },
  {
    label: "Sub Group",
    name: "Sub Group",
    options: {
      viewColumns: false,
      display: false,
      filter: true,
    },
  },
  {
    name: "id",
    label: "Actions",
    options: {
      viewColumns: false,
      filter: false,
      sort: false,
      customBodyRender: value => (
        <Link to={`/app/products/${value}`}>
          <IconButton icon={<EditIcon />} />
        </Link>
      ),
    },
  },
];

const defaultColumnSort = [
  {
    field: "createdAt",
    direction: "DESC",
  },
];

const defaultFilterObject = {};

const defaultMapper = item => ({
  ...item,
  sites: item.sites.length,
  createdAt: moment(item.createdAt).format("Do MMM YYYY, HH:mm"),
});

const defaultRowsPerPageOptions = [10, 25, 50, 100];

const defaultSortFieldMapper = item => {
  return item;
};

function ProductsTable({
  appStore,
  columns = defaultColumns,
  defaultSort = defaultColumnSort,
  defaultFilter = defaultFilterObject,
  handleRowClick = () => {},
  mapper = defaultMapper,
  rowsPerPageOptions = defaultRowsPerPageOptions,
  searchBy = "name_contains", // Can be set to null to disable search
  sortFieldMapper = defaultSortFieldMapper,
  query = GET_PRODUCTS_WITH_COUNT(),
  viewColumns = true,
}) {
  const [limit, setLimit] = useState(rowsPerPageOptions[0]);
  const [offset, setOffset] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [sort, setSort] = useState(defaultSort);
  const [filter, setFilter] = useState(defaultFilter);

  // Fetch the first page of data
  const {
    error,
    data = { productsWithCount: { totalRows: 0, products: [] } },
    fetchMore,
  } = useQuery(gql(query), {
    // Use the cache, but also fetch from the API and if the API is newer
    // this will auto-update what's rendered
    fetchPolicy: "cache-and-network",
    onCompleted: () => {
      appStore.setLoading(false);
      // When we've completed the initial request we'll load the next page into the cache
      // so we can render it quickly. It won't go stale because Apollo will still use the API
      // and update this cache and view if the API is newer.
      fetchMore({
        variables: {
          limit,
          offset: offset + limit,
          ...(sort.length > 0 && { sort }),
          ...(filter && { filter }),
        },
      });
    },
    variables: {
      limit,
      offset,
      ...(sort.length > 0 && { sort }),
      ...(filter && { filter }),
    },
  });

  useEffect(() => {
    if (error) {
      // todo - render an error here
      throw error;
    }
  }, [error]);

  return (
    <DataTable
      columns={columns}
      // This is a hack to disable the filter change handler when there are no filters.
      handleFilterChange={
        columns.some(col => col.options?.filter) ? () => {} : null
      }
      elevation={1}
      page={currentPage}
      tableData={data.productsWithCount.products.map(mapper)}
      // There are lots of 'required' functions on this DataTable that should be optional.
      // Todo - refactor the DataTable when time allows.
      handleChangePage={curPage => setCurrentPage(curPage)}
      handleChangeRowsPerPage={() => {}}
      handleRowClick={handleRowClick}
      handleSearchChange={searchBy ? () => {} : null}
      handleTableChange={(action, tableState) => {
        // For this table this is all we're interested in.
        if (
          ![
            "changeRowsPerPage",
            "changePage",
            "sort",
            "filterChange",
            "search",
          ].includes(action)
        ) {
          return;
        }

        if (tableState.sortOrder.name) {
          setSort([
            {
              field: sortFieldMapper(tableState.sortOrder.name),
              direction: tableState.sortOrder.direction.toUpperCase(),
            },
          ]);
        }

        if (tableState.searchText) {
          const updateFilter = {
            ...filter,
            [searchBy]: tableState.searchText,
          };
          setFilter(updateFilter);
        } else {
          const { [searchBy]: discardSearch, ...updateFilter } = filter;
          setFilter(updateFilter);
        }

        setLimit(tableState.rowsPerPage);
        setOffset(tableState.page * tableState.rowsPerPage);
      }}
      rowsPerPageOptions={rowsPerPageOptions}
      totalRowCount={data.productsWithCount.totalRows}
      tableOptions={{
        page: 0,
        rowsPerPage: limit,
      }}
      viewColumns={viewColumns}
    />
  );
}

ProductsTable.propTypes = {
  appStore: MobXPropTypes.objectOrObservableObject.isRequired,
  columns: PropTypes.arrayOf(
    PropTypes.shape({ label: PropTypes.string, name: PropTypes.string }),
  ),
  defaultSort: PropTypes.arrayOf(
    PropTypes.shape({
      field: PropTypes.string,
      direction: PropTypes.oneOf(["ASC", "DESC"]),
    }),
  ),
  defaultFilter: PropTypes.shape({ filter: PropTypes.string }),
  handleRowClick: PropTypes.func,
  mapper: PropTypes.func,
  rowsPerPageOptions: PropTypes.arrayOf(PropTypes.number),
  searchBy: PropTypes.string,
  sortFieldMapper: PropTypes.func,
  query: PropTypes.string,
  viewColumns: PropTypes.bool,
};

export default inject("appStore")(observer(ProductsTable));
