import React from "react";

import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";

import ErrorBoundary from "../../components/ErrorBoundary";
import Page from "../../components/Page";
import PDQPaymentTypesTable from "./Components/SalesAreasBulkEditor";

const TillsList = () => {
  const title = "Sales Areas";

  return (
    <ErrorBoundary>
      <Page title={title}>
        <Container maxWidth={false}>
          <Grid container rowSpacing={3}>
            <Grid
              container
              item
              xs={12}
              justifyContent="space-between"
              alignItems="center"
              mt={3}
            >
              <Grid item>
                <Typography variant="h2" data-testid="main-header">
                  {title}
                </Typography>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Paper>
                <Box>
                  <PDQPaymentTypesTable />
                </Box>
              </Paper>
            </Grid>
          </Grid>
        </Container>
      </Page>
    </ErrorBoundary>
  );
};

export default TillsList;
