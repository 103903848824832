import { types, getEnv, getParent, flow } from "mobx-state-tree";
import { toJS, values } from "mobx";
import gql from "graphql-tag";
import moment from "moment";

import Promotion, { fragments as promotionFragments } from "./models/Promotion";

import { removeTypename, transformer } from "../helpers";

export default types
  .model("PromotionStore", {
    items: types.optional(types.map(Promotion), {}),
  })
  .views(self => ({
    get appStore() {
      return getParent(self);
    },
    get sortByPriority() {
      return Array.from(self.items.values()).sort(
        (a, b) => a.priority - b.priority,
      );
    },
    get count() {
      return values(self.items).filter(value => value.isFiltered !== false)
        .length;
    },
    filter(filters) {
      const filterFields = Object.keys(filters);

      const filterFunction = item => {
        let passedFilter = true;

        filterFields.forEach(filterField => {
          if (
            item[filterField] === undefined ||
            item[filterField] !== filters[filterField]
          ) {
            passedFilter = false;
          }
        });
        item.setFilter(passedFilter);
      };

      self.items.forEach(item => {
        filterFunction(item);
      });
    },
    search(search) {
      const searchFunction = item => {
        let isFiltered = true;

        if (
          !item.name.toLowerCase().includes(search.toLowerCase()) ||
          item.isFiltered === false
        ) {
          isFiltered = false;
        }

        item.setFilter(isFiltered);
      };

      self.items.forEach(item => searchFunction(item));
    },
    paginate({ limit, offset, sort, filter, search }) {
      if (!filter && !search) {
        self.items.forEach(item => {
          item.setFilter(true);
        });
        return self.sort(sort).splice(offset, limit);
      }
      if (filter) {
        self.filter(filter);
      }
      if (search) {
        self.search(search);
      }
      return self
        .sort(sort)
        .filter(item => item.isFiltered !== false)
        .splice(offset, limit);
    },
    sort({ sortCol: itemSortKey, direction }) {
      let sortFunction;

      switch (itemSortKey) {
        case "name":
          sortFunction = (a, b) => {
            return a.toUpperCase().localeCompare(b.toUpperCase());
          };
          break;
        case "startAt":
          sortFunction = (a, b) => {
            return moment(a).diff(moment(b));
          };
          break;
        case "endAt":
          // The use of "moment().add(9999, "y")" here is because without a valid date it will not sort correctly
          sortFunction = (a, b) => {
            return moment(a ?? moment().add(9999, "y")).diff(
              b ?? moment().add(9999, "y"),
            );
          };
          break;
        default:
          sortFunction = (a, b) => a - b;
          break;
      }
      return values(self.items).sort((a, b) => {
        if (direction === "asc") {
          return sortFunction(a[itemSortKey], b[itemSortKey]);
        }
        return sortFunction(b[itemSortKey], a[itemSortKey]);
      });
    },
  }))
  .actions(self => {
    const { apolloClient } = getEnv(self);
    let findAllLoaded = false;

    return {
      find: flow(function* find(id) {
        if (self.items.has(id)) {
          return self.items.get(id);
        }

        try {
          const response = yield apolloClient.query({
            query: gql`
            {
              promotion(id: "${id}") {
                ...PromotionFullDetails
              }
            }
            ${promotionFragments.fullDetails}
          `,
          });

          const item = self.track(response.data.promotion);

          return Promise.resolve(item);
        } catch (error) {
          return Promise.reject(error);
        }
      }),
      add: flow(function* add(entity) {
        try {
          const input = transformer({
            name: entity.name,
            description: entity.description,
            startAt: entity.startAt.format(),
            endAt: entity.endAt?.format() || null,
            rules: entity.rules,
            actions: entity.actions,
            active: entity.active,
          });

          const response = yield apolloClient.mutate({
            mutation: gql`
            mutation add {
              addPromotion(input: {
                ${input}
              }) {
                ...PromotionFullDetails
              }
            }
            ${promotionFragments.fullDetails}
          `,
          });

          return Promise.resolve(self.track(response.data.addPromotion));
        } catch (error) {
          return Promise.reject(error);
        }
      }),
      remove: flow(function* remove(id) {
        try {
          const response = yield apolloClient.mutate({
            mutation: gql`
            mutation deletePromotion {
              deletePromotion(id: "${id}")
            }
          `,
          });

          if (response.data.deletePromotion === true) {
            self.items.delete(id);
          }
          return Promise.resolve(true);
        } catch (error) {
          return Promise.reject(error);
        }
      }),
      updatePriorities: flow(function* update(promotions) {
        try {
          const input = promotions.map(({ id, priority }) => ({
            id,
            priority,
          }));

          const response = yield apolloClient.mutate({
            mutation: gql`
              mutation updatePromotionPriorities(
                $input: [UpdatePrioritiesInput]
              ) {
                updatePromotionPriorities(input: $input)
              }
            `,
            variables: { input },
          });

          return response;
        } catch (error) {
          return Promise.reject(error);
        }
      }),
      findAll: flow(function* findAll(limit = 100, offset = 0) {
        if (findAllLoaded) {
          return Promise.resolve(self.items);
        }

        try {
          const response = yield apolloClient.query({
            query: gql`
                    {
                      promotions (
                        limit: ${limit},
                        offset: ${offset},
                      ) {
                        ...PromotionFullDetails
                      }
                    }
                    ${promotionFragments.fullDetails}
                  `,
          });

          response.data.promotions.forEach(item => self.track(item));
          if (response.data.promotions.length === 100) {
            self.findAll(limit, offset + limit);
          } else {
            findAllLoaded = true;
          }

          return Promise.resolve(self.items);
        } catch (error) {
          return Promise.reject(error);
        }
      }),
      duplicate: flow(function* duplicate(entity) {
        try {
          const clone = toJS(entity);
          clone.active = false;
          clone.name = `Copy of ${clone.name}`;
          yield self.add(clone);
          return Promise.resolve(true);
        } catch (error) {
          return Promise.reject(error);
        }
      }),
      track(entity) {
        if (!self.items.has(entity.id)) {
          const input = removeTypename(entity);

          input.createdBy = self.appStore.userStore.track(input.createdBy);
          if (input.updatedBy) {
            input.updatedBy = self.appStore.userStore.track(input.updatedBy);
          }
          return self.items.put(input);
        }

        return self.items.get(entity.id);
      },
    };
  });
