import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import gql from "graphql-tag";
import { useQuery } from "@apollo/client";
import { useSnackbar } from "notistack";

import Grid from "@mui/material/Grid";

import { GET_SITES } from "../helpers/apollo/utils";

import Select from "./Select";

const defaultPrependOptions = [];

const SiteSelector = ({
  siteSelectCallback,
  prependOptions = defaultPrependOptions,
}) => {
  const { enqueueSnackbar } = useSnackbar();

  const [sites, setSites] = useState([]);
  const [additionalOptions, setAdditionalOptions] = useState([]);
  const [selectedSite, setSelectedSite] = useState("");

  const { error: getSitesQueryError } = useQuery(gql(GET_SITES()), {
    fetchPolicy: "cache-and-network",
    onCompleted: ({ sites: siteData }) => {
      if (siteData.length) {
        setSites(siteData);

        if (!selectedSite) {
          setSelectedSite(siteData[0].id);
          siteSelectCallback(siteData[0].id);
        }
      }
    },
    variables: {
      sort: [
        {
          field: "name",
          direction: "ASC",
        },
      ],
    },
  });

  useEffect(() => {
    if (getSitesQueryError) {
      const fallbackErrorMessage = "Please try again later.";

      const errorMsg = getSitesQueryError?.message || fallbackErrorMessage;

      enqueueSnackbar(`An unexpected error has occurred: ${errorMsg}`, {
        variant: "error",
        SnackbarProps: {
          "data-testid": "site-selector-error-snackbar",
        },
      });
    }
  }, [getSitesQueryError, enqueueSnackbar]);

  useEffect(() => {
    setAdditionalOptions(prependOptions);

    const combinedSiteArray = [...prependOptions, ...sites];

    if (
      combinedSiteArray.length &&
      !combinedSiteArray.find(({ id }) => id === selectedSite)
    ) {
      setSelectedSite(combinedSiteArray[0].id);
      siteSelectCallback(combinedSiteArray[0].id);
    }
  }, [prependOptions]);

  return (
    <Grid container>
      <Grid
        item
        container
        justifyContent="space-between"
        alignItems="center"
        xs={12}
        md={10}
      >
        <Grid item>
          <Select
            label="Site"
            name="sites"
            onChange={({ target }) => {
              const { value: val } = target;
              setSelectedSite(val);
              siteSelectCallback(val);
            }}
            options={[...additionalOptions, ...sites].map(({ id, name }) => ({
              text: name,
              value: id,
            }))}
            value={selectedSite}
            testId="sites-select"
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

SiteSelector.propTypes = {
  siteSelectCallback: PropTypes.func.isRequired,
  prependOptions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    }),
  ),
};

export default SiteSelector;
