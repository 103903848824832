import React, { useState, useEffect } from "react";
import { observer, inject, PropTypes as MobXPropTypes } from "mobx-react";
import { useParams, useNavigate } from "react-router-dom";
import gql from "graphql-tag";
import { useQuery, useMutation } from "@apollo/client";

import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { useSnackbar } from "notistack";

import ErrorBoundary from "../../components/ErrorBoundary";
import NegativeAction from "../../components/Button/NegativeAction";
import Page from "../../components/Page";
import PositiveAction from "../../components/Button/PositiveAction";

import {
  ADD_PRODUCT,
  GET_PRODUCT_BY_ID,
  UPDATE_PRODUCT,
} from "../../helpers/apollo/utils";
import {
  checkForInvalidFloatInput,
  checkForInvalidIntegerInput,
  checkForInvalidPOSId,
  checkRequiredFields,
} from "./Components/AddEditValidation";

import {
  convertStatusArrayToObject,
  convertStatusObjectToArray,
} from "./Components/ComponentHelpers";
import DefaultOnlyField from "./Components/DefaultOnlyField";
import Prices from "./Components/Prices/Prices";
import POSStatusSelector from "./Components/POSStatusSelector";
import SitesSelector from "./Components/SitesSelector";

import ProductModifierManager from "./Components/ProductModifierManager";
import ProductOptionGroupManager from "./Components/ProductOptionGroupManager";
import ProductTagManager from "./Components/ProductTagManager";
import ModifierGroupManager from "./Components/ModifierGroupManager";

const AddEditComponent = ({ appStore }) => {
  const navigate = useNavigate();
  const { productId, siteId } = useParams();
  const { enqueueSnackbar } = useSnackbar();

  const [changes, setChanges] = useState({});
  const [disableSave, setDisableSave] = useState(true);
  const [formErrors, setFormErrors] = useState({});
  const [formData, setFormData] = useState({
    maxMod: "",
    name: "",
    POSId: "",
    price: "",
    prices: [],
    tillProductName: "",
    vatRateCode: "",
    statuses: {},
  });
  const [initialData, setInitialData] = useState({
    maxMod: "",
    name: "",
    POSId: "",
    price: "",
    prices: [],
    tillProductName: "",
    vatRateCode: "",
    statuses: {},
  });
  const [hasModifierGroups, setHasModifierGroups] = useState(false);
  const [isModifierProduct, setIsModifierProduct] = useState(false);

  const { error: getQueryError, loading: getQueryLoading } = useQuery(
    gql(GET_PRODUCT_BY_ID()),
    {
      fetchPolicy: "cache-and-network",
      onCompleted: ({ product }) => {
        const statuses = product.statuses
          ? convertStatusArrayToObject(product.statuses)
          : [];

        const data = {
          ...formData,
          ...{
            ...product,
            price: product.price ? product.price / 100 : 0,
            prices: product.prices.map(item => ({
              ...item,
              price: item.price ? (item.price / 100).toFixed(2) : null,
            })),
            maxMod: product.maxMod ? product.maxMod : "",
            statuses,
          },
        };

        // We use this to track what changes have been made from current to initial data
        // Allowing us to disable the ability to save if nothing has changed
        setInitialData(data);
        setFormData(data);
        setChanges({});

        appStore.setLoading(false);
      },
      skip: !productId,
      variables: {
        productId,
        siteId,
      },
    },
  );

  if (!productId) {
    appStore.setLoading(false);
  }

  const [
    submitToServer,
    { error: addEditQueryError, loading: addEditQueryLoading },
  ] = useMutation(gql(productId ? UPDATE_PRODUCT() : ADD_PRODUCT()), {
    onCompleted: response => {
      enqueueSnackbar(
        productId ? "Your changes have been saved" : "New product created",
        {
          SnackbarProps: {
            "data-testid": productId
              ? "product-information-saved-snackbar"
              : "product-created-snackbar",
          },
          variant: "success",
        },
      );

      setChanges({});
      setInitialData(formData);

      if (!productId) {
        const {
          addProduct: { id },
        } = response;

        navigate(`/app/products/${id}`);
      }
    },
  });

  useEffect(() => {
    if (getQueryError || addEditQueryError) {
      const fallbackErrorMessage = "And no error message was provided.";

      const errorMsg =
        getQueryError?.message ||
        addEditQueryError?.message ||
        fallbackErrorMessage;

      enqueueSnackbar(`An unexpected error has occured: ${errorMsg}`, {
        variant: "error",
        SnackbarProps: { "data-testid": "apollo-error-snackbar" },
      });

      appStore.setLoading(false);
    }
  }, [appStore, getQueryError, addEditQueryError, enqueueSnackbar]);

  // Disable the ability to save if there are no changes
  useEffect(() => {
    if (Object.keys(changes).length) {
      setDisableSave(false);
    } else {
      setDisableSave(true);
    }
  }, [changes]);

  const handleFieldUpdate = (fieldName, value) => {
    setFormData({
      ...formData,
      [fieldName]: value,
    });

    if (productId) {
      // Add the change to be used in the mutation input
      if (JSON.stringify(value) !== JSON.stringify(initialData[fieldName])) {
        setChanges({
          ...changes,
          [fieldName]: value,
        });
      }
      // Remove the change from the mutation input
      else {
        const { [fieldName]: discardField, ...updateChanges } = changes;
        setChanges(updateChanges);
      }
    }
  };

  const handleChange = ({ target: { name: fieldName, value } }) => {
    switch (fieldName) {
      case "price":
        if (!checkForInvalidFloatInput(value)) {
          handleFieldUpdate(fieldName, value);
        }
        break;
      case "maxMod":
      case "POSId":
        if (!checkForInvalidIntegerInput(value, true)) {
          handleFieldUpdate(fieldName, value);
        }
        break;
      default:
        handleFieldUpdate(fieldName, value);
        break;
    }
  };

  const handlePricesChange = newPricesData => {
    let newPricesArray = [];
    if (newPricesData.price) {
      if (formData.prices.find(item => item.id === newPricesData.id))
        newPricesArray = formData.prices.map(item =>
          item.id === newPricesData.id ? newPricesData : item,
        );
      else newPricesArray = [...formData.prices, newPricesData];
    } else
      newPricesArray = [
        ...formData.prices.filter(item => item.id !== newPricesData.id),
      ];

    handleFieldUpdate("prices", newPricesArray);
  };

  const handlePOSStatusChange = (fieldName, value) => {
    setFormData({
      ...formData,
      statuses: { ...formData.statuses, [fieldName]: value },
    });

    if (productId) {
      const initialStatus = initialData.statuses[fieldName];
      let updatedStatuses = { ...changes.statuses, [fieldName]: value };

      // Remove the changed POS status if it's the same as the initial data
      // Or doesn't currently exist in the initial data and is false (meaning it doesn't need to be mutated)
      if (value === initialStatus || (!value && initialStatus === undefined)) {
        const { [fieldName]: discardField, ...statusChanges } = updatedStatuses;
        updatedStatuses = statusChanges;
      }

      // If there are no changes to the statuses, remove the statuses from the changes object
      if (!Object.keys(updatedStatuses).length) {
        const { statuses: discardField, ...updateChanges } = changes;
        setChanges(updateChanges);
      } else {
        setChanges({
          ...changes,
          statuses: updatedStatuses,
        });
      }
    }
  };

  // will be used later on when modification is implemented

  const handleModifierGroupChange = updatedModiferGroups => {};

  const handleFormCancel = () => navigate(`/app/products`);

  const handleValidatePayload = dataToCheck => {
    const errors = {
      ...checkRequiredFields(dataToCheck),
      invalidPOSId: checkForInvalidPOSId(dataToCheck.POSId),
    };

    return errors;
  };

  const handleFormSubmit = () => {
    const errors = handleValidatePayload(formData);

    setFormErrors(errors);

    if (!Object.values(errors).filter(error => error).length) {
      let dataToSubmit = {};

      const dataStructure = {
        maxMod: formData.maxMod ? parseInt(formData.maxMod, 10) : null,
        name: formData.name,
        POSId: formData.POSId,
        price: formData.price ? parseInt(formData.price * 100, 10) : 0,
        prices: formData.prices.map(({ level, price, productOption } = {}) => ({
          price: price && Math.round(price * 100),
          level,
          productOptionId: productOption?.id,
        })),
        tillProductName: formData.tillProductName,
        vatRateCode: formData.vatRateCode,
        statuses: convertStatusObjectToArray(formData.statuses),
      };

      if (productId) {
        dataToSubmit.id = productId;

        // Only include changed fields in the mutation
        Object.keys(changes).forEach(key => {
          dataToSubmit[key] = dataStructure[key];
        });

        if (siteId !== undefined) {
          dataToSubmit.siteId = siteId;
        }
      } else {
        // We're creating a new product so want to submit all fields
        dataToSubmit = dataStructure;
      }

      submitToServer({
        variables: {
          input: dataToSubmit,
        },
      });
    }
  };

  const setSiteHandler = value => {
    if (value === "default") {
      navigate(`/app/products/${productId}`);
    } else {
      navigate(`/app/products/${productId}/sites/${value}`);
    }

    appStore.setLoading(true);
  };

  return (
    <ErrorBoundary>
      <Page title={`${productId !== null ? "Edit" : "Add"} Product`}>
        <Container maxWidth={false} sx={{ mt: 3 }}>
          <form autoComplete="off" noValidate>
            {productId && <SitesSelector siteSelectCallback={setSiteHandler} />}

            <Card sx={{ mb: 2 }}>
              <CardContent>
                <Card>
                  <CardContent>
                    <Grid container spacing={3}>
                      <Grid
                        item
                        container
                        justifyContent="space-between"
                        alignItems="center"
                        xs={12}
                      >
                        <Grid item>
                          <Typography>Basic Information</Typography>
                        </Grid>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <TextField
                          name="tillProductName"
                          label="Name"
                          fullWidth
                          onChange={handleChange}
                          value={formData.tillProductName}
                          variant="outlined"
                          data-testid="tillProductName-input"
                          InputLabelProps={{ shrink: true }}
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <TextField
                          required
                          name="name"
                          label="Long Description"
                          fullWidth
                          onChange={handleChange}
                          value={formData.name}
                          error={!!formErrors.nameEmpty}
                          helperText={formErrors.nameEmpty}
                          variant="outlined"
                          data-testid="name-input"
                          InputLabelProps={{ shrink: true }}
                        />
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <DefaultOnlyField disabled={siteId !== undefined}>
                          <TextField
                            disabled={siteId !== undefined}
                            required
                            name="POSId"
                            label="POS ID"
                            fullWidth
                            onChange={handleChange}
                            value={formData.POSId}
                            error={
                              !!formErrors.POSIdEmpty ||
                              !!formErrors.invalidPOSId
                            }
                            helperText={
                              formErrors.POSIdEmpty || formErrors.invalidPOSId
                            }
                            variant="outlined"
                            data-testid="POSId-input"
                            InputLabelProps={{ shrink: true }}
                          />
                        </DefaultOnlyField>
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <TextField
                          name="maxMod"
                          label="Max Mod"
                          fullWidth
                          onChange={handleChange}
                          value={formData.maxMod ?? ""}
                          variant="outlined"
                          data-testid="maxMod-input"
                          InputLabelProps={{ shrink: true }}
                        />
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <DefaultOnlyField disabled={siteId !== undefined}>
                          <TextField
                            disabled={siteId !== undefined}
                            name="vatRateCode"
                            label="VAT Rate Code"
                            fullWidth
                            value={formData.vatRateCode}
                            onChange={handleChange}
                            data-testid="vatRateCode-input"
                            InputLabelProps={{ shrink: true }}
                          />
                        </DefaultOnlyField>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>

                <Box mt={3}>
                  <Prices
                    productId={productId}
                    pricesData={initialData.prices}
                    updateFormData={handlePricesChange}
                  />
                </Box>

                <Box mt={3}>
                  <POSStatusSelector
                    selectionData={formData.statuses}
                    handleChange={handlePOSStatusChange}
                  />
                </Box>
                {productId && (
                  <Box display="flex" justifyContent="flex-end" mt={2}>
                    <PositiveAction
                      buttonText="Save"
                      disabled={
                        addEditQueryLoading || disableSave || getQueryLoading
                      }
                      onClick={handleFormSubmit}
                      testId="edit-save-button"
                    />
                  </Box>
                )}
              </CardContent>
            </Card>
            <Box mt={3}>
              <ProductTagManager />
            </Box>
            <Box mt={3}>
              <ModifierGroupManager
                disabled={isModifierProduct}
                setHasGroups={setHasModifierGroups}
              />
            </Box>
            <Box mt={3}>
              <ProductModifierManager
                disabled={hasModifierGroups}
                setHasGroups={setIsModifierProduct}
              />
            </Box>
            <Box mt={3}>
              <ProductOptionGroupManager />
            </Box>

            <Box display="flex" justifyContent="flex-end" mt={2} pb={3}>
              <NegativeAction
                buttonText="Cancel"
                onClick={handleFormCancel}
                testId="cancel-button"
              />
              {!productId && (
                <Box ml={1}>
                  <PositiveAction
                    buttonText="Submit"
                    disabled={getQueryLoading || addEditQueryLoading}
                    onClick={handleFormSubmit}
                    testId="add-submit-button"
                  />
                </Box>
              )}
            </Box>
          </form>
        </Container>
      </Page>
    </ErrorBoundary>
  );
};

AddEditComponent.propTypes = {
  appStore: MobXPropTypes.objectOrObservableObject.isRequired,
};

const AddEditProduct = inject("appStore")(observer(AddEditComponent));

export default AddEditProduct;
