import React, { Fragment, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { observer, inject, PropTypes as MobXPropTypes } from "mobx-react";
import { toJS } from "mobx";
import { Link } from "react-router-dom";

import { withStyles } from "@mui/styles";
import red from "@mui/material/colors/red";

import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import FormHelperText from "@mui/material/FormHelperText";
import IconButton from "@mui/material/IconButton";
import clsx from "clsx";
import CancelIcon from "@mui/icons-material/Cancel";

import MUIDataTable from "mui-datatables";

import ErrorBoundary from "../../components/ErrorBoundary";
import Page from "../../components/Page";
import MuiDatatablesFilters from "../../components/Filter/MuiDatatablesFilters";
import PositiveAction from "../../components/Button/PositiveAction";
import NegativeAction from "../../components/Button/NegativeAction";
import AddAction from "../../components/Button/AddAction";

const styles = theme => ({
  margin: {
    margin: theme.spacing(1),
  },
  button: {
    margin: 0,
    marginLeft: theme.spacing(1),
  },
  danger: {
    color: red[500],
    "&:hover": {
      backgroundColor: red[50],
    },
  },
});

const ManageSubscribersComponent = ({ appStore, classes }) => {
  const [items, setItems] = useState([]);
  const [selected, setSelected] = useState(null);
  const [formError, setFormError] = useState("");

  useEffect(() => {
    appStore.recipientStore.subscriberStore
      .findAll()
      .then(() => {
        const sortedResults = appStore.recipientStore.subscriberStore.sorted;
        setItems(
          sortedResults.map(subscriber => [
            subscriber.email,
            subscriber.id,
            toJS(sortedResults),
          ]),
        );

        appStore.setLoading(false);
      })
      .catch(error => {
        appStore.log.error(error);
        setFormError(error.message);
        appStore.setLoading(false);
      });
  }, [appStore]);

  const handleDeleteClick = record => {
    setSelected(toJS(record));
  };

  const handleDeleteYes = () => {
    appStore.setLoading();
    appStore.recipientStore.subscriberStore
      .remove(selected)
      .then(results => {
        handleDeleteClick(null);
        appStore.setLoading(false);
        setItems(
          [...results.values()].map(subscriber => [
            subscriber.email,
            subscriber.id,
            toJS(results),
          ]),
        );
      })
      .catch(error => {
        appStore.log.error(error);
        setFormError(error.message);
        appStore.setLoading(false);
      });
  };

  const handleDeleteNo = () => {
    setSelected(null);
  };

  const columns = [
    {
      name: "Subscriber Email",
      options: {
        filter: true,
        sort: true,
        sortOrder: "asc",
      },
    },
    {
      name: "Actions",
      options: {
        filter: false,
        sort: false,
        customBodyRender: record => {
          return (
            <Fragment key={record.id}>
              <IconButton
                className={clsx(classes.button, classes.danger)}
                onClick={() => handleDeleteClick(record)}
                aria-label="Delete Subscriber"
                size="medium"
              >
                <CancelIcon />
              </IconButton>
            </Fragment>
          );
        },
      },
    },
  ];

  const options = {
    filterType: "dropdown",
    rowsPerPage: 100,
    selectableRows: "none",
    search: false,
    sortOrder: {
      name: "Name",
      direction: "asc",
    },
    print: false,
    viewColumns: false,
    download: false,
    filter: false,
  };

  return (
    <ErrorBoundary>
      <Page title="Sites">
        <Container maxWidth={false}>
          <Grid container rowSpacing={3}>
            <Grid container item xs={12} justifyContent="end" mt={3}>
              <Link to="/app/reports/manage-subscribers/add">
                <AddAction buttonText="Add Subscriber" />
              </Link>
            </Grid>
            <Grid item xs={12}>
              <section>
                {selected && (
                  <Dialog open>
                    <DialogTitle>Delete Subscriber</DialogTitle>
                    <DialogContent>
                      <DialogContentText>
                        Deleting this subscriber will remove it from any reports
                        it&apos;s currently receiving. Are you sure?
                        <br />
                        <b>{selected.name}</b>
                      </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                      <NegativeAction
                        buttonText="No"
                        onClick={handleDeleteNo}
                      />
                      <PositiveAction
                        buttonText="Yes"
                        onClick={handleDeleteYes}
                      />
                    </DialogActions>
                  </Dialog>
                )}
                {formError && (
                  <FormHelperText error>{formError}</FormHelperText>
                )}
                {items.length > 0 && (
                  <MUIDataTable
                    data={toJS(items)}
                    columns={columns}
                    options={options}
                    components={{
                      TableFilterList: MuiDatatablesFilters,
                    }}
                  />
                )}
              </section>
            </Grid>
          </Grid>
        </Container>
      </Page>
    </ErrorBoundary>
  );
};
ManageSubscribersComponent.propTypes = {
  appStore: MobXPropTypes.objectOrObservableObject.isRequired,
  classes: PropTypes.shape({
    button: PropTypes.string,
    danger: PropTypes.string,
    addButton: PropTypes.string,
  }).isRequired,
};

const List = withStyles(styles)(
  inject("appStore")(observer(ManageSubscribersComponent)),
);

export default List;
