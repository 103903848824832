import { types, getParent, flow, getEnv, hasParent } from "mobx-state-tree";
import gql from "graphql-tag";

import { removeTypename, transformer } from "../helpers";
import Subscriber, {
  fragments as subscriberFragments,
} from "./models/Subscriber";

export default types
  .model("SubscriberStore", {
    items: types.optional(types.map(Subscriber), {}),
  })
  .views(self => ({
    get recipientStore() {
      if (hasParent(self)) {
        return getParent(self);
      }
      return null;
    },
    get sorted() {
      const items = [...self.items.values()];
      return items.sort(({ email: emailA }, { email: emailB }) =>
        emailA.localeCompare(emailB),
      );
    },
    get reportStore() {
      const { recipientStore } = self;
      if (hasParent(recipientStore)) {
        return getParent(recipientStore).reportStore;
      }
      return null;
    },
  }))
  .actions(self => {
    const { apolloClient } = getEnv(self);

    return {
      findAll: flow(function* findAll(limit = 0, offset = 0, filter = {}) {
        try {
          const response = yield apolloClient.query({
            query: gql`
              {
                subscribers (
                  limit: ${limit},
                  offset: ${offset},
                  filter: {${transformer(filter)}}
                ) {
                  ...SubscriberDetails
                }
              }
              ${subscriberFragments.fullDetails}
            `,
          });

          response.data.subscribers.forEach(subscriber =>
            self.track(subscriber),
          );
          return Promise.resolve(self.items);
        } catch (error) {
          return Promise.reject(error);
        }
      }),
      remove: flow(function* remove(id) {
        try {
          const response = yield apolloClient.mutate({
            mutation: gql`
            mutation deleteSubscriber {
              deleteSubscriber(id: "${id}")
            }
          `,
          });

          if (response.data.deleteSubscriber === true) {
            self.items.delete(id);
            self.recipientStore?.removeSubscriber(id);
            self.reportStore?.removeSubscriberFromReports(id);
          }
          return Promise.resolve(self.items);
        } catch (error) {
          return Promise.reject(error);
        }
      }),

      add: flow(function* add(entity) {
        try {
          const input = transformer({
            email: entity.email,
          });

          const response = yield apolloClient.mutate({
            mutation: gql`
            mutation addSubscriber {
              addSubscriber(input: {
                ${input}
              }) {
                id
                email
              }
            }
          `,
          });
          self.recipientStore?.track(response.data.addSubscriber);
          return Promise.resolve(self.track(response.data.addSubscriber));
        } catch (error) {
          return Promise.reject(error);
        }
      }),
      track(entity) {
        if (!self.items.has(entity.id)) {
          const input = removeTypename(entity);
          return self.items.put(input);
        }

        return self.items.get(entity.id);
      },
    };
  });
