import React from "react";
import PropTypes from "prop-types";

import { styled } from "@mui/material/styles";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";

const CustomTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 500,
  },
});

const SettingTooltip = ({ tooltip, testId }) => {
  return (
    <CustomTooltip
      arrow
      title={tooltip}
      placement="bottom-start"
      data-testid={`${testId}-tooltip`}
    >
      <HelpOutlineIcon
        sx={{ color: "rgb(150,150,150)", marginLeft: "5px" }}
        fontSize="small"
      />
    </CustomTooltip>
  );
};

SettingTooltip.propTypes = {
  tooltip: PropTypes.string.isRequired,
  testId: PropTypes.string.isRequired,
};

export default SettingTooltip;
