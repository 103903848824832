import React, { useEffect } from "react";

import { observer, inject, PropTypes as MobXPropTypes } from "mobx-react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Container from "@mui/material/Container";

import ErrorBoundary from "../components/ErrorBoundary";
import Page from "../components/Page";

const DashboardComponent = ({ appStore }) => {
  useEffect(() => {
    appStore.setLoading(false);
  }, [appStore.isLoading]);

  return (
    <ErrorBoundary>
      <Page title="Dashboard">
        <Container maxWidth={false}>
          <Box mt={3}>
            <Card>
              <CardContent>Dashboard</CardContent>
            </Card>
          </Box>
        </Container>
      </Page>
    </ErrorBoundary>
  );
};

DashboardComponent.propTypes = {
  appStore: MobXPropTypes.objectOrObservableObject.isRequired,
};

const Dashboard = inject("appStore")(observer(DashboardComponent));

export default Dashboard;
