import { types, getParent, getEnv, flow } from "mobx-state-tree";
import gql from "graphql-tag";

import Recipients from "./Recipient";
import ReportArguments from "./ReportArguments";

import { transformer } from "../../helpers";

export const fragments = {
  fullDetails: gql`
    fragment ReportDetails on Report {
      id
      name
      recipients {
        id
        email
      }
      requiredArguments {
        id
        name
      }
    }
  `,
};

export default types
  .model("Report", {
    id: types.identifier,
    name: types.string,
    recipients: types.array(Recipients),
    requiredArguments: types.array(ReportArguments),
  })
  .views(self => ({
    get parent() {
      return getParent(self);
    },
  }))
  .actions(self => {
    return {
      clearVolatileState: () => {
        self.requiredArguments.forEach(argument => {
          argument.setValue("");
        });
        return self;
      },
      getOutput: flow(function* getOutput() {
        const { apolloClient } = getEnv(self);
        const params = {
          reportId: self.id,
          requiredArguments: self.requiredArguments.map(
            argument => argument.requiredArgumentInput,
          ),
        };
        const reportOutput = yield apolloClient.query({
          query: gql`
          query generateReport {
            generateReport(input: {
                ${transformer(params)}
              }) {
                 reportOutput
              }
          }
      `,
          fetchPolicy: "no-cache",
        });
        return reportOutput;
      }),
      setName: value => {
        self.name = value;
        return self;
      },

      setRecipients: value => {
        self.recipients = value;
        return self;
      },

      save: flow(function* save() {
        const { apolloClient } = getEnv(self);
        const params = {
          id: self.id,
          name: self.name,
          recipients: self.recipients.map(({ id }) => ({
            id,
          })),
        };

        yield apolloClient.mutate({
          mutation: gql`
          mutation updateReport {
            updateReport(input: {
                ${transformer(params)}
              }) {
                 id
              }
          }
      `,
        });
      }),
    };
  });
