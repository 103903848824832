import React from "react";

import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";

import ErrorBoundary from "../../components/ErrorBoundary";
import Page from "../../components/Page";
import ProductOptionsTable from "./Components/ProductOptionsTable";

function List() {
  return (
    <ErrorBoundary>
      <Page title="Product Options">
        <Container maxWidth={false}>
          <Grid container rowSpacing={3}>
            <Grid
              container
              item
              xs={12}
              justifyContent="space-between"
              alignItems="center"
              mt={3}
            >
              <Grid item>
                <Typography variant="h2">Product Options</Typography>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Paper>
                <Box>
                  <ProductOptionsTable handleRowClick={() => {}} />
                </Box>
              </Paper>
            </Grid>
          </Grid>
        </Container>
      </Page>
    </ErrorBoundary>
  );
}

export default List;
