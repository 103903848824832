import React from "react";
import PropTypes from "prop-types";

import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

import { posStatuses } from "./ComponentHelpers";

const defaultSelectionData = {};

const POSStatusSelector = ({
  handleChange,
  options = posStatuses,
  selectionData = defaultSelectionData,
}) => {
  return (
    <Card>
      <CardContent>
        <Grid container spacing={2}>
          <Grid item container alignItems="center" xs={12}>
            <Grid item>
              <Typography>POS Status Management</Typography>
            </Grid>
          </Grid>
          {options.map(({ text, value }) => (
            <Grid item xs={6} md={3} key={value}>
              <FormControlLabel
                control={
                  <Checkbox
                    color="primary"
                    checked={!!selectionData[value]}
                    onChange={() => handleChange(value, !selectionData[value])}
                    data-testid={`pos-status-checkbox-${value}`}
                  />
                }
                label={text}
              />
            </Grid>
          ))}
        </Grid>
      </CardContent>
    </Card>
  );
};

POSStatusSelector.propTypes = {
  handleChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({ text: PropTypes.string, value: PropTypes.string }),
  ),
  selectionData: PropTypes.shape(
    posStatuses.reduce(
      (acc, curr) => ({
        ...acc,
        [curr.value]: PropTypes.bool,
      }),
      {},
    ),
  ),
};

export default POSStatusSelector;
