import React, { useState, useEffect, useCallback, useRef } from "react";
import PropTypes from "prop-types";

import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";

const SplitView = ({
  isResizable = true,
  // Left content is shown in the drawer, this allows us to have split scrollbars.
  leftContent,
  minWidthPercentage = 35,
  maxWidthPercentage = 60,
  open = true,
  startingWidthPercentage = 50,
  // Right content is shown on the remainder of the page.
  rightContent,
}) => {
  // This is required to accurately calculate the drawer width on resize.
  const sidebarWidth = 260;

  const [drawerWidth, setDrawerWidth] = useState(`${startingWidthPercentage}%`);
  const container = useRef();

  const handleMouseDown = e => {
    e.preventDefault();
    document.addEventListener("mouseup", handleMouseUp, true);
    document.addEventListener("mousemove", handleMouseMove, true);
  };

  const handleMouseUp = e => {
    e.preventDefault();
    document.removeEventListener("mouseup", handleMouseUp, true);
    document.removeEventListener("mousemove", handleMouseMove, true);
  };

  // Resize the drawer width within the bounds of min and max width %.
  const handleMouseMove = useCallback(e => {
    const newWidth = e.clientX - document.body.offsetLeft - sidebarWidth;

    const containerWidth = container.current.offsetWidth;

    // We get the min and max drawer width as a percentage of the containers width.
    const minWidth = (containerWidth * minWidthPercentage) / 100;
    const maxWidth = (containerWidth * maxWidthPercentage) / 100;

    const drawerWidth = Math.min(Math.max(newWidth, minWidth), maxWidth);
    setDrawerWidth(drawerWidth);
  }, []);

  // We need to resize the drawer on window resize, to stop overlapping x-axis issues.
  useEffect(() => {
    window.addEventListener("resize", () => {
      setDrawerWidth(`${startingWidthPercentage}%`);
    });
    return () => {
      window.removeEventListener("resize", () => {
        setDrawerWidth(`${startingWidthPercentage}%`);
      });
    };
  }, [container]);

  return (
    <Box
      sx={{
        display: { xs: "block", md: "flex" },
        transform: "translateZ(0)",
        height: "100%",
      }}
      ref={container}
    >
      {open && (
        <Drawer
          sx={{
            width: { xs: "100%", md: drawerWidth },
            minWidth: { md: `${minWidthPercentage}%` },
            maxWidth: { md: `${maxWidthPercentage}%` },
            flexShrink: 0,
            overflow: "auto",
            "& .MuiDrawer-paper": {
              position: { xs: "static", md: "fixed" },
              width: { xs: "100%", md: drawerWidth },
              minWidth: { md: `${minWidthPercentage}%` },
              maxWidth: { md: `${maxWidthPercentage}%` },
              overflow: "auto",
              boxSizing: "border-box",
            },
          }}
          variant="permanent"
          anchor="left"
        >
          {leftContent}
          {isResizable && (
            <Box
              data-testid="split-view-resizer"
              onMouseDown={e => handleMouseDown(e)}
              sx={{
                width: "5px",
                height: "100%",
                cursor: "ew-resize",
                padding: "4px 0 0",
                borderTop: "1px solid #ddd",
                position: "absolute",
                display: { xs: "none", md: "block" },
                right: 0,
                zIndex: 100,
                backgroundColor: "#f4f7f9",
              }}
            />
          )}
        </Drawer>
      )}
      <Box
        sx={{
          overflow: "auto",
          width: "100%",
        }}
      >
        {rightContent}
      </Box>
    </Box>
  );
};

SplitView.propTypes = {
  isResizable: PropTypes.bool,
  leftContent: PropTypes.node.isRequired,
  minWidthPercentage: PropTypes.number,
  maxWidthPercentage: PropTypes.number,
  open: PropTypes.bool,
  startingWidthPercentage: PropTypes.number,
  rightContent: PropTypes.node.isRequired,
};

export default SplitView;
