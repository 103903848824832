import React, { Fragment } from "react";
import { observer, inject, PropTypes as MobXPropTypes } from "mobx-react";
import { observable, action, toJS, reaction, makeObservable } from "mobx";
import { Link } from "react-router-dom";

import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import IconButton from "@mui/material/IconButton";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import EditIcon from "@mui/icons-material/Edit";

import MUIDataTable from "mui-datatables";

import ErrorBoundary from "../../components/ErrorBoundary";
import Page from "../../components/Page";
import MuiDatatablesFilters from "../../components/Filter/MuiDatatablesFilters";
import PositiveAction from "../../components/Button/PositiveAction";
import NegativeAction from "../../components/Button/NegativeAction";
import AddAction from "../../components/Button/AddAction";

class ListComponent extends React.Component {
  items = [];
  selected = null;

  listReaction = () => {};

  columns = [
    {
      name: "Name",
      options: {
        filter: true,
        sort: true,
        sortOrder: "asc",
      },
    },
    { name: "POS CLASS ID", options: { filter: false, sort: true } },

    {
      name: "Actions",
      options: {
        filter: false,
        sort: false,
        customBodyRender: record => {
          return (
            <Fragment key={record.id}>
              <Link to={`/app/modifier-groups/${record.id}`}>
                <IconButton size="medium">
                  <EditIcon />
                </IconButton>
              </Link>
            </Fragment>
          );
        },
      },
    },
  ];

  options = {
    filterType: "dropdown",
    rowsPerPage: 100,
    selectableRows: "none",
    search: false,
    sortOrder: {
      name: "Name",
      direction: "asc",
    },
  };

  constructor(props) {
    super(props);

    makeObservable(this, {
      items: observable,
      selected: observable.ref,
      handleDeleteClick: action,
      handleDeleteNo: action,
      handleDeleteYes: action,
      setItems: action,
    });
  }

  componentDidMount() {
    const { appStore: app } = this.props;
    const that = this;
    app.modifierGroupStore
      .findAll()
      .then(results => {
        this.listReaction = reaction(
          () => [...results.values()].length,
          () => this.setItems([...results.values()]),
          { fireImmediately: true },
        );

        app.setLoading(false);
      })
      .catch(error => {
        app.log.error(error);
        that.setFormError(error.message);
        app.setLoading(false);
      });
  }

  componentWillUnmount() {
    this.listReaction();
  }

  setFormError = value => {
    this.error = value;
  };

  setItems = items => {
    this.items = items.map(site => [site.name, site.POSClassId, toJS(site)]);
  };

  handleDeleteClick = item => {
    this.selected = toJS(item);
  };

  handleDeleteYes = () => {
    const { appStore: app } = this.props;
    const that = this;

    app.setLoading();

    app.siteStore
      .remove(this.selected.id)
      .then(() => {
        that.handleDeleteClick(null);
        app.setLoading(false);
      })
      .catch(error => {
        app.log.error(error);
        that.setFormError(error.message);
        app.setLoading(false);
      });
  };

  handleDeleteNo = () => {
    this.selected = null;
  };

  render() {
    return (
      <ErrorBoundary>
        <Page title="Modifier Groups">
          <Container maxWidth={false}>
            <Grid container rowSpacing={3}>
              <Grid container item xs={12} justifyContent="end" mt={3}>
                <Link to="/app/modifier-groups/add">
                  <AddAction buttonText="Add modifier group" />
                </Link>
              </Grid>
              <Grid item xs={12}>
                <section>
                  {/* I dont think delete functionality works */}
                  {this.selected && (
                    <Dialog open onClose={this.handleClose}>
                      <DialogTitle>Delete site</DialogTitle>
                      <DialogContent>
                        <DialogContentText>
                          Are you sure you want to delete this site ?
                          <br />
                          <b>{this.selected.name}</b>
                        </DialogContentText>
                      </DialogContent>
                      <DialogActions>
                        <NegativeAction
                          buttonText="No"
                          onClick={this.handleDeleteNo}
                        />
                        <PositiveAction
                          buttonText="Yes"
                          onClick={this.handleDeleteYes}
                        />
                      </DialogActions>
                    </Dialog>
                  )}
                  {this.items.length > 0 && (
                    <MUIDataTable
                      data={toJS(this.items)}
                      columns={this.columns}
                      options={this.options}
                      components={{
                        TableFilterList: MuiDatatablesFilters,
                      }}
                    />
                  )}
                </section>
              </Grid>
            </Grid>
          </Container>
        </Page>
      </ErrorBoundary>
    );
  }
}

ListComponent.propTypes = {
  appStore: MobXPropTypes.objectOrObservableObject.isRequired,
};

const List = inject("appStore")(observer(ListComponent));

export default List;
