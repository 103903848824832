import React, { useEffect, useReducer, useState } from "react";
import { observer, inject, PropTypes as MobXPropTypes } from "mobx-react";
import owasp from "owasp-password-strength-test";
import validator from "validator";
import { useParams, useNavigate } from "react-router-dom";

import { makeStyles } from "@mui/styles";
import {
  Checkbox,
  FormHelperText,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Box,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import Input from "@mui/material/Input";
import InputAdornment from "@mui/material/InputAdornment";
import IconVisibility from "@mui/icons-material/Visibility";
import IconVisibilityOff from "@mui/icons-material/VisibilityOff";

import PositiveAction from "../../components/Button/PositiveAction";
import NegativeAction from "../../components/Button/NegativeAction";

export const ERROR_MESSAGES = {
  required: {
    organisation: "Please select an Organisation",
    firstName: "Please specify a First Name",
    lastName: "Please specify a Last Name",
    email: "Please specify an Email",
    // this is a generated messaged by "owasp" meaning it may have to be manually updated if the packages changes it
    password:
      "The password must be at least 10 characters long. The password must contain at least one lowercase letter. The password must contain at least one uppercase letter. The password must contain at least one number. The password must contain at least one special character.",
    scopes: "Please specify a permission level",
  },
  other: {
    password:
      "The password must be at least 10 characters long and contain at least one uppercase letter, one number and one special character",
  },
};

const scopesList = [
  { name: "Promotions Only", value: "PROMOTIONS_ADMIN" },
  { name: "Super Admin", value: "SUPER" },
];

const useStyles = makeStyles(theme => ({
  input: {
    margin: theme.spacing(1),
  },
  form: {
    padding: 12,
  },
  button: {
    marginRight: theme.spacing(1),
  },
}));

const showPasswordReducer = (state, action) => {
  switch (action.type) {
    case "toggle":
      return !state;
    default:
      return state;
  }
};

/**
 * @todo improvement: all button actions to be exposed as overridable props
 */
const AddEditComponent = ({ appStore }) => {
  const navigate = useNavigate();
  const { userId } = useParams();
  const classes = useStyles();

  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    phone: "",
    organisation: "",
    isPasswordChangeRequired: false,
    scopes: [],
  });
  const [user, setUser] = useState(null);
  const [showPassword, showPasswordAction] = useReducer(
    showPasswordReducer,
    false,
  );

  const [formDataErrors, setFormDataErrors] = useState({});
  const [queryError, setQueryError] = useState("");

  useEffect(() => {
    if (userId !== undefined) {
      appStore.userStore
        .find(userId)
        .then(apiUser => {
          const {
            firstName,
            lastName,
            email,
            password,
            phone,
            organisation,
            isPasswordChangeRequired,
          } = apiUser;

          setUser(apiUser);
          setFormData({
            firstName,
            lastName,
            email,
            password: password ?? "",
            phone,
            organisation,
            isPasswordChangeRequired,
          });
          appStore.setLoading(false);
        })
        .catch(error => {
          appStore.log.error(error);
          navigate("/app/404");
        });
    } else {
      appStore.organisationStore
        .findAll()
        .then(() => appStore.setLoading(false))
        .catch(error => {
          appStore.log.error(error);
          navigate("/app/404");
        });
    }
  }, [userId]);

  const handleCancel = () => {
    appStore.setLoading();
    navigate("/app/users");
  };

  const handleMouseDownPassword = e => {
    e.preventDefault();
  };

  const handleShowPassword = () => {
    showPasswordAction({ type: "toggle" });
  };

  const handleBooleanChange = (name, value) => {
    setFormData({ ...formData, [name]: value });
  };

  const handleInputChange = ({ target: { value, name } }) => {
    setFormData({ ...formData, [name]: value });
  };

  const validateFormData = data => {
    const validationErrors = {};

    if (user === null && validator.isEmpty(data.organisation)) {
      validationErrors.organisation = ERROR_MESSAGES.required.organisation;
    }

    if (
      user === null &&
      (!Array.isArray(data.scopes) ||
        (Array.isArray(data.scopes) && data.scopes.length < 1))
    ) {
      validationErrors.scopes = ERROR_MESSAGES.required.scopes;
    }

    if (validator.isEmpty(data.firstName.toString())) {
      validationErrors.firstName = ERROR_MESSAGES.required.firstName;
    }

    if (validator.isEmpty(data.lastName.toString())) {
      validationErrors.lastName = ERROR_MESSAGES.required.lastName;
    }

    if (!validator.isEmail(data.email)) {
      validationErrors.email = ERROR_MESSAGES.required.email;
    }

    if (user === null || data.password.toString() !== "") {
      const result = owasp.test(data.password.toString());
      if (result.strong === false) {
        validationErrors.password = result.errors.join(" ");
      }
    }

    return validationErrors;
  };

  const handleSubmit = e => {
    e.preventDefault();

    const validationErrors = validateFormData(formData);

    if (!Object.keys(validationErrors).length) {
      appStore.setLoading();

      const {
        firstName,
        lastName,
        email,
        password,
        phone,
        organisation,
        isPasswordChangeRequired,
        scopes,
      } = formData;

      if (user === null) {
        appStore.userStore
          .add({
            organisationId: organisation,
            firstName,
            lastName,
            email,
            password,
            phone,
            isPasswordChangeRequired,
            scopes,
          })
          .then(() => navigate("/app/users"))
          .catch(error => {
            appStore.log.error(error);
            setQueryError(error.message.replace("GraphQL error: ", ""));
            appStore.setLoading(false);
          });
      } else {
        user
          .setFirstName(firstName)
          .setLastName(lastName)
          .setEmail(email)
          .setPhone(phone)
          .setIsPasswordChangeRequired(isPasswordChangeRequired);

        if (password.length > 0) {
          user.setPassword(password);
        }

        user
          .save()
          .then(() => navigate("/app/users"))
          .catch(error => {
            appStore.log.error(error);
            setQueryError(error.message.replace("GraphQL error: ", ""));
            appStore.setLoading(false);
          });
      }
    } else {
      setFormDataErrors(validationErrors);
    }
  };

  if (appStore.isLoading) {
    return null;
  }

  const {
    firstName,
    lastName,
    email,
    password,
    phone,
    organisation,
    isPasswordChangeRequired,
    scopes,
  } = formData;

  return (
    <>
      <form onSubmit={handleSubmit} className={classes.form}>
        <Grid container spacing={3} justifyContent="flex-start">
          {queryError && (
            <Grid item xs={12}>
              <FormHelperText error>{queryError}</FormHelperText>
            </Grid>
          )}
          {user === null && appStore.organisationStore.items.size > 1 && (
            <Grid item xs={3}>
              <FormControl
                variant="standard"
                fullWidth
                error={!!formDataErrors.organisation}
              >
                <InputLabel required>Organisation</InputLabel>
                <Select
                  inputProps={{ "aria-label": "organisation" }}
                  name="organisation"
                  variant="standard"
                  value={organisation}
                  onChange={handleInputChange}
                >
                  <MenuItem value="">
                    <em>Select organisation...</em>
                  </MenuItem>
                  {[...appStore.organisationStore.items.values()].map(item => (
                    <MenuItem key={`org-${item.id}`} value={item.id}>
                      {item.name}
                    </MenuItem>
                  ))}
                </Select>
                <FormHelperText>{formDataErrors.organisation}</FormHelperText>
              </FormControl>
            </Grid>
          )}
          {user === null && (
            <Grid item xs={3}>
              <FormControl
                variant="standard"
                fullWidth
                error={!!formDataErrors.scopes}
              >
                <InputLabel required>Permissions (Scope)</InputLabel>
                <Select
                  inputProps={{ "aria-label": "scopes" }}
                  name="scopes"
                  variant="standard"
                  value={scopes}
                  onChange={({ target: { value, name } }) => {
                    setFormData({
                      ...formData,
                      // if the user attempts to update to no scope, remain as is
                      [name]: value ? [value] : formData.scopes,
                    });
                  }}
                >
                  <MenuItem value="">
                    <em>Select scope...</em>
                  </MenuItem>
                  {scopesList.map(item => (
                    <MenuItem key={`scope-${item.name}`} value={item.value}>
                      {item.name}
                    </MenuItem>
                  ))}
                </Select>
                <FormHelperText>{formDataErrors.scopes}</FormHelperText>
              </FormControl>
            </Grid>
          )}
          <Grid item xs={12} />
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              inputProps={{ "data-testid": "firstName" }}
              name="firstName"
              required
              variant="standard"
              fullWidth
              autoComplete="off"
              label="First Name"
              value={firstName}
              error={!!formDataErrors.firstName}
              helperText={formDataErrors.firstName}
              onChange={handleInputChange}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              inputProps={{ "data-testid": "lastName" }}
              name="lastName"
              required
              variant="standard"
              fullWidth
              autoComplete="off"
              label="Last Name"
              value={lastName}
              error={!!formDataErrors.lastName}
              helperText={formDataErrors.lastName}
              onChange={handleInputChange}
            />
          </Grid>
          <Grid item xs={12} />
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              inputProps={{ "data-testid": "email" }}
              name="email"
              required
              variant="standard"
              fullWidth
              autoComplete="off"
              label="Email"
              value={email}
              error={!!formDataErrors.email}
              helperText={formDataErrors.email}
              onChange={handleInputChange}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              name="phone"
              variant="standard"
              fullWidth
              autoComplete="off"
              label="Phone"
              value={phone}
              error={!!formDataErrors.phone}
              helperText={formDataErrors.phone}
              onChange={handleInputChange}
            />
          </Grid>
          <Grid item xs={12} />
          <Grid item xs={12} sm={6} md={6}>
            <FormControl
              variant="standard"
              fullWidth
              error={!!formDataErrors.password}
            >
              <InputLabel required={user === null}>Password</InputLabel>
              <Input
                inputProps={{ "data-testid": "password" }}
                name="password"
                required={user === null}
                type={showPassword ? "text" : "password"}
                value={password}
                onChange={handleInputChange}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      size="medium"
                    >
                      {showPassword ? (
                        <IconVisibility />
                      ) : (
                        <IconVisibilityOff />
                      )}
                    </IconButton>
                  </InputAdornment>
                }
              />
              <FormHelperText>
                {formDataErrors.password ?? ERROR_MESSAGES.other.password}
              </FormHelperText>
            </FormControl>
          </Grid>
          <Grid item xs={12} />
          <Grid item xs={12} sm={6} md={6}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={isPasswordChangeRequired}
                  onChange={() =>
                    handleBooleanChange(
                      "isPasswordChangeRequired",
                      !isPasswordChangeRequired,
                    )
                  }
                  color="primary"
                />
              }
              label="Require password change on next login"
            />
          </Grid>
          <Grid item xs={12}>
            <Box mr={1} display="inline">
              <NegativeAction buttonText="Cancel" onClick={handleCancel} />
            </Box>
            <PositiveAction buttonText="Save" onClick={handleSubmit} />
          </Grid>
        </Grid>
      </form>
    </>
  );
};

AddEditComponent.propTypes = {
  appStore: MobXPropTypes.objectOrObservableObject.isRequired,
};

export default inject("appStore")(observer(AddEditComponent));
