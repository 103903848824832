import React, { useEffect } from "react";
import { gql, useMutation } from "@apollo/client";
import PropTypes from "prop-types";
import moment from "moment";

import { Link } from "react-router-dom";

import DeleteIcon from "@mui/icons-material/Delete";
import VisibilityIcon from "@mui/icons-material/Visibility";

import ChangesetsTable from "../../Changesets/Components/ChangesetsTable";
import IconButton from "../../../components/Button/IconButton";

import {
  GET_CHANGESETS_BY_TARGET_ID,
  DELETE_CHANGE,
} from "../../../helpers/apollo/utils";

function ChangeSection({ targetId }) {
  const GET_CHANGESETS_BY_TARGET_ID_QUERY = GET_CHANGESETS_BY_TARGET_ID({
    targetId,
  });

  const [onDeleteHandler, { error }] = useMutation(gql(DELETE_CHANGE()), {
    refetchQueries: [
      gql(GET_CHANGESETS_BY_TARGET_ID_QUERY), // DocumentNode object
      "Changesets", // Query name
    ],
  });

  useEffect(() => {
    if (error) {
      // todo - render an error here
      throw error;
    }
  }, [error]);

  const mapper = (item, index) => {
    // Find the change in the changeset that matches the model ID.
    // Since each changeset can only have one change for a specific target ID, this should only return the correct item.
    const changeId = item.changes.find(
      change => change.targetId === targetId,
    ).id;

    return {
      ...item,
      createdBy: item.changes[0].createdBy.email,
      scheduledAt: moment(item.scheduledAt).format("Do MMM YYYY, HH:mm"),
      actions: (
        <>
          {item.status === "PENDING" && (
            <IconButton
              icon={<DeleteIcon />}
              onClick={() =>
                // Trigger the mutation with the target change ID.
                onDeleteHandler({ variables: { deleteChangeId: changeId } })
              }
              testId={`changesets-table-delete-button-${index}`}
            />
          )}
          {item.status === "APPLIED" && (
            <Link to={`/app/promotions/${targetId}/change/${changeId}`}>
              <IconButton
                icon={<VisibilityIcon />}
                testId={`changesets-table-view-button-${index}`}
              />
            </Link>
          )}
        </>
      ),
    };
  };

  const sortMapper = item => {
    if (item === "createdBy") {
      return "changes.createdBy.email";
    }
    return item;
  };

  const columns = [
    { label: "Name", name: "name" },
    { label: "Change date", name: "scheduledAt" },
    { label: "Created by", name: "createdBy" },
    { label: "Status", name: "status" },
    {
      label: "Actions",
      name: "actions",
      options: { sort: false, viewColumns: false },
    },
  ];

  return (
    <ChangesetsTable
      columns={columns}
      mapper={mapper}
      query={GET_CHANGESETS_BY_TARGET_ID_QUERY}
      rowsPerPageOptions={[5, 10, 25, 50, 100]}
      sortFieldMapper={sortMapper}
      viewColumns={false}
    />
  );
}

ChangeSection.propTypes = {
  targetId: PropTypes.string.isRequired,
};

export default ChangeSection;
