import { types, getEnv, getParent, flow } from "mobx-state-tree";
import gql from "graphql-tag";

import BookingType, {
  fragments as bookingTypeFragments,
} from "./models/BookingType";

import { transformer, removeTypename } from "../helpers";

// @todo treat versioning changes on preProcess
export default types
  .model("BookingTypeStore", {
    items: types.optional(types.map(BookingType), {}),
  })
  .views(self => ({
    get appStore() {
      return getParent(self);
    },
  }))
  .actions(self => {
    const { apolloClient } = getEnv(self);
    const findAllLoaded = false;

    return {
      find: flow(function* find(id) {
        if (self.items.has(id)) {
          return self.items.get(id);
        }

        try {
          const response = yield apolloClient.query({
            query: gql`
            {
              bookingType(id: "${id}") {
                ...BookingTypeFullDetails
              }
            }
            ${bookingTypeFragments.fullDetails}
          `,
          });

          const item = self.track(response.data.bookingType);

          return Promise.resolve(item);
        } catch (error) {
          return Promise.reject(error);
        }
      }),

      findAll: flow(function* findAll(filter = {}) {
        if (findAllLoaded) {
          return Promise.resolve(self.items);
        }

        try {
          const response = yield apolloClient.query({
            query: gql`
              {
                bookingTypes {
                  ...BookingTypeFullDetails
                }
              }
              ${bookingTypeFragments.fullDetails}
            `,
          });

          response.data.bookingTypes.forEach(bookingType => {
            self.track(removeTypename(bookingType));
          });

          return Promise.resolve(self.items);
        } catch (error) {
          return Promise.reject(error);
        }
      }),

      add: flow(function* add(entity) {
        try {
          const response = yield apolloClient.mutate({
            mutation: gql`
            mutation addBookingType {
              addBookingType(input: {
                ${transformer(entity)}
              }) { 
                ...BookingTypeFullDetails
              }
            }
            ${bookingTypeFragments.fullDetails}
          `,
          });
          const bookingType = response.data.addBookingType;

          return Promise.resolve(self.track(bookingType));
        } catch (error) {
          return Promise.reject(error);
        }
      }),

      remove: flow(function* remove(id) {
        try {
          const response = yield apolloClient.mutate({
            mutation: gql`
            mutation deleteBookingType {
              deleteBookingType(id: "${id}")
            }
          `,
          });

          if (response.data.deleteBookingType === true) {
            self.items.delete(id);
          }
          return Promise.resolve(true);
        } catch (error) {
          return Promise.reject(error);
        }
      }),

      track(entity) {
        if (!self.items.has(entity.id)) {
          const input = removeTypename(entity);

          input.createdBy = self.appStore.userStore.track(input.createdBy);
          if (input.updatedBy) {
            input.updatedBy = self.appStore.userStore.track(input.updatedBy);
          }
          return self.items.put(input);
        }

        return self.items.get(entity.id);
      },
    };
  });
