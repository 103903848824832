import React, { useEffect, useReducer, useState } from "react";
import { observer, inject, PropTypes as MobXPropTypes } from "mobx-react";
import { useNavigate, useLocation } from "react-router-dom";

import {
  Box,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  CardHeader,
  Container,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import moment from "moment";

import ErrorBoundary from "../../components/ErrorBoundary";
import Page from "../../components/Page";
import NegativeAction from "../../components/Button/NegativeAction";
import PositiveAction from "../../components/Button/PositiveAction";

/**
 * TODO
 * Going to query the reportOutput query on API
 * Going to get that string and spit it out as a download
 */

/** HELPERS */
const getInputComponent = ({ onChange, ruleset, value }) => {
  if (ruleset.type === "date") {
    return (
      <DateTimePicker
        ampm={false}
        label={ruleset.humanName}
        value={value}
        onChange={onChange}
        slotProps={{
          textField: {
            error: !!ruleset.validate(value).length,
            helperText: ruleset.helperText,
            sx: { width: "75%" },
          },
        }}
      />
    );
  }
  if (ruleset.type === "text") {
    return (
      <TextField
        error={!!ruleset.validate(value).length}
        label={ruleset.humanName}
        helperText={ruleset.helperText}
        onChange={onChange}
        value={value}
        sx={{ width: "75%" }}
      />
    );
  }
  return <></>;
};

const reportOutputReducer = (state, { type, payload }) => {
  if (type === "mergeValue") {
    return {
      ...state,
      values: {
        ...state.values,
        ...payload,
      },
    };
  }
  return {
    ...state,
    errors: {
      ...state.errors,
      ...payload,
    },
  };
};

/** CONSTS */
export const reportArgumentsTitle = "Report Arguments";

// This is not ideal, but currently all arguments on the API are strings that can be "".
// We should extend it to cater for different types, and then update this to allow for better
// validation and dynamic recognition of fields.
const rules = {
  dateFrom: {
    defaultValue: () => moment.utc().startOf("day"),
    humanName: "Date From",
    helperText:
      "Select the start date & time for your report content (Europe/London)",
    type: "date",
    validate: value => {
      const errors = [];
      if (!value) {
        errors.push("Date From is required");
      }
      // This is a moment date from the picker
      if (value?.isValid && !value.isValid()) {
        errors.push("Date From is invalid");
      }
      // If neither of the above found a problem it's fine, this must be a native Date
      // that we set as default
      return errors;
    },
  },
  dateTo: {
    defaultValue: () => moment.utc().endOf("day"),
    humanName: "Date To",
    helperText:
      "Select the end date & time for your report content (Europe/London)",
    type: "date",
    validate: value => {
      const errors = [];
      if (!value) {
        errors.push("Date To is required");
      }
      // This is a moment date from the picker
      if (value?.isValid && !value.isValid()) {
        errors.push("Date To is invalid");
      }
      // If neither of the above found a problem it's fine, this must be a native Date
      // that we set as default
      return errors;
    },
  },
  POSHouseNumber: {
    defaultValue: () => {
      return "";
    },
    humanName: "POS House Number",
    helperText:
      "Enter a POS House Number to report on a single site, or leave blank for all sites",
    type: "text",
    validate: value => {
      return value.match(/^[0-9]*$/)
        ? []
        : ["POS House Number must be a number"];
    },
  },
  POSId: {
    defaultValue: () => {
      return "";
    },
    humanName: "POS Id",
    helperText:
      "Enter a POS Id to report on a single product, or leave blank for all products",
    type: "text",
    validate: value => {
      return value.match(/^[0-9]*$/) ? [] : ["POS ID must be a number"];
    },
  },
};

/** COMPONENT */
const GenerateReport = ({ appStore: { reportStore, setLoading } }) => {
  const location = useLocation();
  const navigate = useNavigate();
  // State setup
  const [queryError, setQueryError] = useState(null);

  const [reportOutput, reportOutputStateChange] = useReducer(
    reportOutputReducer,
    { values: {}, errors: {} },
  );
  const [reportToGenerate, setReportToGenerate] = useState({
    requiredArguments: [],
  });

  // Handlers
  const handleDownload = () => {
    let hasErrors = false;
    reportToGenerate.requiredArguments.forEach(requiredArgument => {
      const { id: requiredArgumentId } = requiredArgument;
      const inputValue = reportOutput.values[requiredArgumentId] ?? "";
      const errors = Object.values(rules)
        .find(
          ({ id: inputArgumentId }) => inputArgumentId === requiredArgumentId,
        )
        .validate(inputValue);
      if (errors.length) {
        reportOutputStateChange({
          type: "mergeErrors",
          payload: { [requiredArgumentId]: errors },
        });
        hasErrors = true;
      } else {
        requiredArgument.setValue(inputValue);
      }
    });
    if (hasErrors) {
      return;
    }
    reportToGenerate
      .getOutput()
      .then(
        ({
          data: {
            generateReport: { reportOutput: reportString },
          },
        }) => {
          const encodedUri = encodeURI(
            `data:text/csv;charset=utf-8,${reportString}`,
          );
          window.open(encodedUri);
        },
      )
      .catch(e => {
        setQueryError(e.message);
      });
  };

  const handleReportArgumentChange = (inputValue, reportArgumentId) => {
    reportOutputStateChange({
      type: "mergeErrors",
      payload: { [reportArgumentId]: null },
    });
    reportOutputStateChange({
      type: "mergeValue",
      payload: { [reportArgumentId]: inputValue },
    });
  };

  useEffect(() => {
    const paramMatch = "/app/reports/(.*)/generate";
    const reportId = location.pathname.match(paramMatch)[1];

    reportStore
      .find(reportId)
      .then(report => {
        setReportToGenerate(report);
        // Setup the default values
        report.requiredArguments.forEach(reportArgument => {
          reportArgument.setValue(rules[reportArgument.name].defaultValue());
          handleReportArgumentChange(
            rules[reportArgument.name].defaultValue(),
            reportArgument.id,
          );
        });
      })
      .catch(() => {
        navigate("/app/404");
      });
    setLoading(false);
  }, [reportStore, setLoading]);

  return (
    <ErrorBoundary>
      <Page title="Generate Report">
        <Container maxWidth={false}>
          <Box mt={3}>
            <Typography variant="h2">{reportToGenerate.name}</Typography>
            <Card sx={{ marginTop: 3 }}>
              <CardHeader
                title={reportArgumentsTitle}
                titleTypographyProps={{ variant: "body1" }}
              />
              <CardContent>
                {[...reportToGenerate.requiredArguments].map(({ name, id }) => {
                  const ruleset = rules[name];
                  ruleset.id = id;
                  return (
                    <Grid
                      container
                      justifyContent="space-between"
                      key={id}
                      sx={{ marginBottom: 1 }}
                    >
                      <Grid
                        alignItems="center"
                        item
                        container
                        xs={6}
                        sx={{ marginBottom: 2 }}
                      >
                        {getInputComponent({
                          onChange: value => {
                            handleReportArgumentChange(
                              value?.target?.value ?? value,
                              id,
                            );
                          },
                          ruleset,
                          value: reportOutput.values[id],
                        })}
                      </Grid>
                    </Grid>
                  );
                })}
              </CardContent>
              <CardActionArea sx={{ paddingLeft: 1 }}>
                <CardActions>
                  <Typography color="error" variant="body2">
                    {queryError}
                  </Typography>
                </CardActions>
              </CardActionArea>
            </Card>
            <Grid container justifyContent="flex-end" item sx={{ marginY: 3 }}>
              <Box mr={1} display="inline">
                <NegativeAction
                  buttonText="CANCEL"
                  onClick={() => {
                    navigate("/app/reports");
                  }}
                />
              </Box>
              <PositiveAction buttonText="DOWNLOAD" onClick={handleDownload} />
            </Grid>
          </Box>
        </Container>
      </Page>
    </ErrorBoundary>
  );
};
GenerateReport.propTypes = {
  appStore: MobXPropTypes.observableObject.isRequired,
};

export default inject("appStore")(observer(GenerateReport));
