import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useParams } from "react-router-dom";
import gql from "graphql-tag";
import { useMutation, useQuery } from "@apollo/client";
import { useSnackbar } from "notistack";

import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

import {
  GET_PICKED_AND_ENABLED_FOR_PRODUCT,
  GET_SITES,
  UPDATE_PRODUCT,
} from "../../../helpers/apollo/utils";

import Select from "../../../components/Select";
import Switch from "../../../components/Switch";
import { DEFAULT_TO_TRUE } from "../../../constants";

const SitesSelector = ({ siteSelectCallback }) => {
  const { productId, siteId } = useParams();
  const { enqueueSnackbar } = useSnackbar();

  const [apolloError, setApolloError] = useState("");
  const [sites, setSites] = useState([]);
  const [siteActive, setSiteActive] = useState(true);
  const [sitePicked, setSitePicked] = useState(DEFAULT_TO_TRUE);
  const [selectedSite, setSelectedSite] = useState("default");

  const { error: getSitesQueryError } = useQuery(gql(GET_SITES()), {
    fetchPolicy: "cache-and-network",
    onCompleted: ({ sites: sitesData }) => {
      setApolloError("");
      setSites(sitesData);

      if (sitesData.some(({ id }) => siteId === id)) {
        setSelectedSite(siteId);
      }
    },
    variables: {
      sort: [
        {
          field: "name",
          direction: "ASC",
        },
      ],
    },
  });

  const {
    error: getPickedAndEnabledQueryError,
    loading: getPickedAndEnabledQueryLoading,
  } = useQuery(gql(GET_PICKED_AND_ENABLED_FOR_PRODUCT()), {
    onCompleted: ({ product }) => {
      setApolloError("");
      setSiteActive(product.enabled);
      setSitePicked(product.picked);
    },
    fetchPolicy: "cache-and-network",
    skip: !productId && !siteId,
    variables: {
      productId,
      siteId,
    },
  });

  const [
    updateSiteProduct,
    { error: siteProductMutationError, loading: siteProductMutationLoading },
  ] = useMutation(gql(UPDATE_PRODUCT()), {
    onCompleted: () => {
      enqueueSnackbar("Your changes have been saved", {
        SnackbarProps: { "data-testid": "site-selector-saved-snackbar" },
        variant: "success",
      });
    },
    refetchQueries: [
      gql(GET_PICKED_AND_ENABLED_FOR_PRODUCT()), // DocumentNode object
      "PickedAndEnabledForProduct", // Query name
    ],
  });

  useEffect(() => {
    if (
      getSitesQueryError ||
      getPickedAndEnabledQueryError ||
      siteProductMutationError
    ) {
      const fallbackErrorMessage = "Please try again later.";

      const errorMsg =
        getSitesQueryError?.message ||
        getPickedAndEnabledQueryError?.message ||
        siteProductMutationError?.message ||
        fallbackErrorMessage;

      enqueueSnackbar(`An unexpected error has occured: ${errorMsg}`, {
        variant: "error",
        SnackbarProps: {
          "data-testid": "site-selector-error-snackbar",
        },
      });
    }
  }, [
    getSitesQueryError,
    getPickedAndEnabledQueryError,
    siteProductMutationError,
    enqueueSnackbar,
  ]);

  return (
    <Card sx={{ mb: 3 }}>
      <CardContent>
        <Grid container>
          <Grid
            item
            container
            justifyContent="space-between"
            alignItems="center"
            xs={12}
            md={10}
          >
            <Grid item xs={12}>
              <Typography>Editing Product at:</Typography>
            </Grid>

            <Grid item xs={12} md={6} lg={3} sx={{ mt: 3 }}>
              <Select
                label="Sites"
                name="sites"
                onChange={({ target }) => {
                  setSelectedSite(target.value);
                  siteSelectCallback(target.value);
                }}
                options={[
                  { text: "Default", value: "default" },
                  ...sites.map(({ id, name }) => ({
                    text: name,
                    value: id,
                  })),
                ]}
                value={selectedSite}
                testId="sites-select"
              />
            </Grid>
          </Grid>

          {siteId && (
            <Grid
              container
              item
              justifyContent="flex-end"
              alignItems="baseline"
              xs={12}
              md={2}
            >
              <Switch
                disabled={
                  getPickedAndEnabledQueryLoading || siteProductMutationLoading
                }
                active={sitePicked}
                onChange={event => {
                  updateSiteProduct({
                    variables: {
                      input: {
                        id: productId,
                        siteId,
                        picked: event.target.checked,
                      },
                    },
                  });
                }}
                label={sitePicked ? "Picked" : "Hidden"}
                colour="success"
                testId="picked-toggle"
              />
              <Switch
                disabled={
                  getPickedAndEnabledQueryLoading || siteProductMutationLoading
                }
                active={siteActive}
                onChange={event => {
                  updateSiteProduct({
                    variables: {
                      input: {
                        id: productId,
                        siteId,
                        enabled: event.target.checked,
                      },
                    },
                  });
                }}
                label={siteActive ? "Enabled" : "Disabled"}
                colour="success"
                testId="site-enabled-toggle"
              />
            </Grid>
          )}
        </Grid>
        {apolloError && (
          <Alert
            severity="error"
            data-testid="sites-apollo-error-alert"
            sx={{ mt: 2 }}
          >
            <AlertTitle>
              <strong>An unexpected error has occured</strong>
            </AlertTitle>
            {apolloError}
          </Alert>
        )}
      </CardContent>
    </Card>
  );
};

SitesSelector.propTypes = {
  siteSelectCallback: PropTypes.func.isRequired,
};

export default SitesSelector;
