import React, { useState, useEffect } from "react";
import gql from "graphql-tag";
import { useQuery, useMutation } from "@apollo/client";
import { styled } from "@mui/material/styles";
import { observer, inject, PropTypes as MobXPropTypes } from "mobx-react";
import { useSnackbar } from "notistack";
import { v4 } from "uuid";

import Tooltip from "@mui/material/Tooltip";
import { DataGrid, useGridApiRef } from "@mui/x-data-grid";

import CustomGridRow from "../../../components/Datagrid/CustomGridRow";
import CustomToolbar from "../../../components/Datagrid/CustomToolbar";

import {
  ADD_PRODUCT_TAG,
  GET_PRODUCT_TAGS_WITH_PARENT,
  UPDATE_PRODUCT_TAG,
} from "../../../helpers/apollo/utils";

import {
  hasNumberValidationError,
  hasCharacterLengthValidationError,
} from "../../../components/Datagrid/utils/validation";

const StyledBox = styled("div")(({ theme }) => ({
  height: "70vh",
  width: "100%",
  "& .Mui-error": {
    backgroundColor: `rgb(126,10,15, ${theme.palette.mode === "dark" ? 0 : 0.1})`,
    color: theme.palette.error.main,
  },
  "& .posId-cell": {
    backgroundColor: `rgb(200,200,200, ${theme.palette.mode === "dark" ? 0 : 0.1})`,
  },
}));

const getColumns = majorGroups => [
  {
    field: "POSId",
    headerName: "POS ID",
    type: "text",
    editable: true,
    cellClassName: "posId-cell",
    renderCell: params => {
      const { value } = params;
      return (
        <Tooltip title="POS ID not editable once created">
          <span>{value}</span>
        </Tooltip>
      );
    },
    preProcessEditCellProps: params => {
      const {
        props,
        props: { value },
      } = params;

      return {
        ...props,
        // Validation: A required integer between 1-3 digits
        error:
          hasNumberValidationError(value, true) ||
          hasCharacterLengthValidationError({
            value,
            minLength: 1,
            maxLength: 3,
          }),
      };
    },
  },
  {
    field: "name",
    headerName: "Description",
    type: "text",
    editable: true,
    minWidth: 150,
    preProcessEditCellProps: params => {
      const {
        props,
        props: { value },
      } = params;

      return {
        ...props,
        error: hasCharacterLengthValidationError({ value, minLength: 2 }), // Min length of 2 as per the API
      };
    },
  },
  {
    field: "parentTagId",
    headerName: "Major Group",
    type: "singleSelect",
    valueOptions: majorGroups,
    editable: true,
    minWidth: 150,
    getOptionLabel: ({ text }) => text,
    getOptionValue: ({ value }) => value,
    preProcessEditCellProps: params => {
      const { props } = params;
      const { value } = props;

      return {
        ...props,
        error: !value, // Required field
      };
    },
  },
];

export const getNewRow = () => ({
  id: v4(),
  POSId: "",
  name: "",
  parentTagId: "",
  // We use this when updating a row to tell us this department needs to be created
  createdRow: true,
});

const ProductGroupsBulkEditor = ({ appStore }) => {
  const apiRef = useGridApiRef();
  const { enqueueSnackbar } = useSnackbar();

  const [columns, setColumns] = useState([]);
  const [createdRow, setCreatedRow] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [majorGroups, setMajorGroups] = useState([]);

  const errorSnackbar = errorMsg => {
    enqueueSnackbar(`Error: ${errorMsg}`, {
      variant: "error",
      SnackbarProps: {
        "data-testid": "bulk-product-groups-error-snackbar",
      },
    });
  };

  const resizeColumns = () => {
    apiRef?.current?.autosizeColumns({
      includeHeaders: true,
      includeOutliers: true,
    });
  };

  useEffect(() => {
    // Look at this in Tills/Components/TillsBulkEditor.js to reference of using a timeout
    setTimeout(() => {
      resizeColumns();
    }, 10);
  }, [tableData]);

  const { loading } = useQuery(gql(GET_PRODUCT_TAGS_WITH_PARENT()), {
    fetchPolicy: "cache-and-network",
    onCompleted: data => {
      if (data?.productTagsWithCount?.productTags) {
        const productTagData = data.productTagsWithCount.productTags;

        const formattedData = productTagData.map(item => ({
          id: item.id,
          POSId: item.POSId,
          name: item.name,
          parentTagId: item.parentTag.id,
        }));

        // Get unique major group names to be used in the filter dropdown
        const majorGroups = [
          ...new Map(
            productTagData.map(item => [
              item.parentTag.id,
              { text: item.parentTag.name, value: item.parentTag.id },
            ]),
          ).values(),
        ];

        setColumns(getColumns(majorGroups));
        setTableData(formattedData);
      }

      appStore.setLoading(false);
    },
    onError: error => {
      appStore.setLoading(false);
      errorSnackbar(error.message);
    },
    variables: {
      filter: { type_is: ["PRODUCTGROUP"] },
    },
  });

  const [updateProductTag] = useMutation(gql(UPDATE_PRODUCT_TAG()), {
    onCompleted: () => {
      enqueueSnackbar("Your changes have been saved", {
        SnackbarProps: { "data-testid": "bulk-product-groups-saved-snackbar" },
        variant: "success",
      });
    },
    refetchQueries: [
      gql(GET_PRODUCT_TAGS_WITH_PARENT()),
      "ProductTagsWithParent",
    ],
  });

  const [addProductTag, { error: addTillError }] = useMutation(
    gql(ADD_PRODUCT_TAG()),
    {
      onCompleted: () => {
        enqueueSnackbar("Your new product group has been created", {
          SnackbarProps: {
            "data-testid": "bulk-product-groups-added-snackbar",
          },
          variant: "success",
        });
      },
      refetchQueries: [
        gql(GET_PRODUCT_TAGS_WITH_PARENT()),
        "ProductTagsWithParent",
      ],
    },
  );

  const addRow = () => {
    const newRow = getNewRow();

    const newTableData = [...tableData];
    newTableData.unshift(newRow);

    setTableData(newTableData);
    setCreatedRow(true);
  };

  const deleteRow = () => {
    const newTableData = tableData.filter(row => row.createdRow === undefined);

    setTableData(newTableData);
    setCreatedRow(false);
  };

  const updateRow = (newRow, oldRow) => {
    return new Promise((resolve, reject) => {
      const rowUpdates = Object.fromEntries(
        Object.entries(newRow).filter(
          ([key, val]) => key in oldRow && oldRow[key] !== val,
        ),
      );

      // Only mutate if there are changes made
      if (Object.keys(rowUpdates).length) {
        if (newRow.createdRow !== undefined) {
          const rowReturn = addProductTag({
            variables: {
              input: {
                type: "PRODUCTGROUP",
                name: newRow.name,
                POSId: parseInt(newRow.POSId),
                parentTagId: newRow.parentTagId,
              },
            },
          })
            .then(() => {
              resolve(newRow);
              setCreatedRow(false);
            })
            .catch(error => {
              reject(error.message);
            });
        } else {
          updateProductTag({
            variables: {
              input: {
                id: newRow.id,
                ...rowUpdates,
              },
            },
          })
            .then(() => {
              resolve(newRow);
            })
            .catch(error => {
              reject(error.message);
            });
        }
      } else {
        resolve(newRow);
      }
    });
  };

  // We only want POSId to be editable when creating a new ProductGroup
  const isCellEditable = params => {
    if (params.row.createdRow) {
      return true;
    } else if (params.field === "POSId") {
      return false;
    }

    return true;
  };

  return (
    <StyledBox>
      <DataGrid
        apiRef={apiRef}
        columns={columns}
        editMode="row"
        isCellEditable={isCellEditable}
        loading={loading}
        rows={tableData}
        slots={{
          row: CustomGridRow,
          toolbar: () => (
            <CustomToolbar
              addAction={addRow}
              deleteAction={deleteRow}
              isRowCreated={createdRow}
            />
          ),
        }}
        processRowUpdate={updateRow}
        onProcessRowUpdateError={errorSnackbar}
      />
    </StyledBox>
  );
};

ProductGroupsBulkEditor.propTypes = {
  appStore: MobXPropTypes.objectOrObservableObject.isRequired,
};

export default inject("appStore")(observer(ProductGroupsBulkEditor));
