import React from "react";
import Tooltip from "@mui/material/Tooltip";

import MajorGroupEditSelect from "../Components/MajorGroupEditSelect";

import { hasCharacterLengthValidationError } from "../../../components/Datagrid/utils/validation";

export const getSubGroupColumns = (
  api,
  majorGroups = [],
  priorityPrintGroups = [],
) => {
  const getPriorityPrintGroupsForMajorGroup = id => {
    return priorityPrintGroups.filter(group => group.majorGroup?.id === id);
  };

  return [
    {
      field: "POSId",
      headerName: "POS ID",
      type: "text",
      editable: true,
      cellClassName: "posId-cell",
      renderCell: params => {
        const { value } = params;
        return (
          <Tooltip title="POS ID is not editable once created">
            <span>{value}</span>
          </Tooltip>
        );
      },
      preProcessEditCellProps: params => {
        const {
          props,
          props: { value },
        } = params;

        return {
          ...props,
          // Validation: A required integer between 1-999
          error: !/^([1-9][0-9]{0,2})$/.test(value),
        };
      },
    },
    {
      field: "name",
      headerName: "Description",
      type: "text",
      editable: true,
      minWidth: 150,
      preProcessEditCellProps: params => {
        const {
          props,
          props: { value },
        } = params;

        return {
          ...props,
          error: hasCharacterLengthValidationError({ value, minLength: 2 }), // Min length of 2 as per the API
        };
      },
    },
    {
      field: "parentTagId",
      headerName: "Major Group",
      type: "singleSelect",
      valueOptions: majorGroups,
      editable: true,
      minWidth: 150,
      getOptionLabel: ({ name }) => name,
      getOptionValue: ({ id }) => id,
      preProcessEditCellProps: params => {
        const { props } = params;
        const { value } = props;

        return {
          ...props,
          error: !value, // Required field
        };
      },
      renderEditCell: params => (
        <MajorGroupEditSelect props={params} api={api} />
      ),
    },
    {
      field: "accommodationIncome",
      headerName: "Accommodation Income",
      type: "boolean",
      editable: true,
      minWidth: 200,
    },
    {
      field: "priorityPrintGroup",
      headerName: "Priority Print Group",
      type: "singleSelect",
      valueOptions: params => {
        if (params.row) {
          return [
            { description: "-", id: "" },
            ...getPriorityPrintGroupsForMajorGroup(params.row.parentTagId),
          ];
        }

        // This allows the filter to still return all priorityPrintGroups
        return [{ description: "-", id: "" }, ...priorityPrintGroups];
      },
      editable: true,
      minWidth: 150,
      getOptionLabel: ({ description }) => description,
      getOptionValue: ({ id }) => id,
    },
    {
      field: "siteManaged",
      headerName: "Site Managed",
      type: "boolean",
      editable: true,
      minWidth: 150,
    },
  ];
};
