import React from "react";
import PropTypes from "prop-types";

import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

import SiteSelector from "./Components/SiteSelector";
import BulkEditorTable from "./Components/BulkEditorTable";

const defaultOptionsArray = [];

const BulkEditor = ({
  productOptions = defaultOptionsArray,
  modifierGroups = defaultOptionsArray,
  sites = defaultOptionsArray,
  vatRates = defaultOptionsArray,
  subGroups = defaultOptionsArray,
  productGroups = defaultOptionsArray,
}) => {
  return (
    <Container maxWidth={false}>
      <Grid container rowSpacing={3}>
        <Grid
          container
          item
          xs={12}
          justifyContent="space-between"
          alignItems="center"
          mt={3}
        >
          <Grid item>
            <Typography variant="h2">Bulk Product Management</Typography>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <SiteSelector sites={sites} />
        </Grid>
        <Grid item xs={12} sx={{ width: "0px" }}>
          <BulkEditorTable
            productOptions={productOptions}
            modifierGroups={modifierGroups}
            vatRates={vatRates}
            subGroups={subGroups}
            productGroups={productGroups}
          />
        </Grid>
      </Grid>
    </Container>
  );
};
BulkEditor.propTypes = {
  ...[
    "productOptions",
    "modifierGroups",
    "sites",
    "vatRates",
    "subGroups",
    "productGroups",
  ].reduce(
    (acc, curr) => ({
      ...acc,
      [curr]: PropTypes.arrayOf(
        PropTypes.shape({
          text: PropTypes.string.isRequired,
          value: PropTypes.string.isRequired,
        }),
      ),
    }),
    {},
  ),
};

export default BulkEditor;
