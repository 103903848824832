import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { observer, inject, PropTypes as MobXPropTypes } from "mobx-react";
import { useParams } from "react-router-dom";
import validator from "validator";
import moment from "moment";

import clsx from "clsx";

import { withStyles } from "@mui/styles";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import FormHelperText from "@mui/material/FormHelperText";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

import Page from "../../components/Page";
import Alert from "../../components/Alert";
import AddDepositForm from "../Deposit/AddForm";
import RefundDepositForm from "../Deposit/RefundForm";

const styles = theme => ({
  input: {
    margin: theme.spacing(1),
  },
  form: {
    padding: 12,
  },
  formControl: {
    maxWidth: 200,
  },
  button: {
    marginRight: theme.spacing(1),
  },
  refunds: {
    marginTop: theme.spacing(1),
    marginLeft: theme.spacing(2),
  },
  tableCell: {
    paddingTop: theme.spacing(1),
    paddingLeft: 2,
    paddingRight: 2,
    verticalAlign: "top",
  },
  noBorder: {
    border: 0,
  },
  paper: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    [theme.breakpoints.up("sm")]: {
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
    },
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    display: "inline-block",
  },
});

const DepositComponent = ({ deposit, classes }) => {
  const [isRefundVisible, setRefundVisible] = useState(false);

  const hasRefunds = deposit.refunds.length > 0;
  const className = clsx(classes.tableCell, {
    [classes.noBorder]: hasRefunds,
  });

  const isForfeited =
    deposit.status === "FORFEITED" || deposit.status === "PARTIALLY_FORFEITED";

  return (
    <>
      <TableRow>
        <TableCell align="left" className={className}>
          <b>{deposit.formattedAmount}</b>
        </TableCell>
        <TableCell className={className}>
          paid on <b>{deposit.takenAt.format("ddd, Do MMM YYYY")}</b> with{" "}
          <b>{deposit.paymentType.name}</b>{" "}
        </TableCell>
        <TableCell className={hasRefunds ? classes.noBorder : ""}>
          {deposit.refundedAmount < deposit.amount &&
            !isRefundVisible &&
            !isForfeited && (
              <Button
                variant="outlined"
                size="small"
                color="primary"
                onClick={() => setRefundVisible(true)}
              >
                refund
              </Button>
            )}
          {isForfeited && "Forfeited"}
        </TableCell>
      </TableRow>
      {deposit.refunds.length > 0 && (
        <TableRow>
          <TableCell />
          <TableCell colSpan={2}>
            {deposit.refunds.map(refund => (
              <div key={refund.id}>
                <b>{refund.formattedAmount}</b> refunded on{" "}
                <b>{refund.created.performedAt.format("ddd, Do MMM YYYY")}</b>
              </div>
            ))}
          </TableCell>
        </TableRow>
      )}
      {isRefundVisible && (
        <TableRow>
          <TableCell />
          <TableCell colSpan={2}>
            <RefundDepositForm
              deposit={deposit}
              onSuccess={() => setRefundVisible(false)}
              onCancel={() => setRefundVisible(false)}
            />
          </TableCell>
        </TableRow>
      )}
    </>
  );
};

DepositComponent.propTypes = {
  deposit: PropTypes.shape({
    formattedAmount: PropTypes.string.isRequired,
    refunds: PropTypes.arrayOf({}),
    status: PropTypes.string,
    takenAt: PropTypes.shape({
      format: PropTypes.func,
    }),
    paymentType: PropTypes.shape({
      name: PropTypes.string,
    }),
    refundedAmount: PropTypes.number,
    amount: PropTypes.number,
  }).isRequired,
  classes: PropTypes.shape({
    noBorder: PropTypes.string,
    refunds: PropTypes.string,
    tableCell: PropTypes.string,
  }).isRequired,
};

const Deposit = withStyles(styles)(observer(DepositComponent));

/**
 * @todo all buttons should be exposed as overridable props
 * @todo all routerStore.push has been commented out as it is being dropped, if this page
 * is to be used, it should be reworked.
 */
const EditComponent = ({ appStore, classes }) => {
  const { id } = useParams();
  const [booking, setBooking] = useState(null);

  const [formError, setFormError] = useState("");

  const [isAddDepositVisible, setAddDepositVisible] = useState(false);

  const [arrivalDate, setArrivalDate] = useState(null);
  const [errorArrivalDate, setErrorArrivalDate] = useState("");

  const [expectedGuests, setExpectedGuests] = useState("");
  const [errorExpectedGuests, setErrorExpectedGuests] = useState("");

  useEffect(() => {
    appStore.bookingStore
      .find(id)
      .then(result => {
        setBooking(result);
        if (result) {
          setArrivalDate(result.expectedAt);
          setExpectedGuests(result.expectedGuests);
        }
        appStore.setLoading(false);
      })
      .catch(error => {
        appStore.log.error(error);
        // appStore.routerStore.push("/app/404");
      });

    setArrivalDate(appStore.currentDateTime);
  }, [appStore, id]);

  const handleArrivalDateChange = ({ target: { value } }) => {
    const date = moment(value, "YYYY-MM-DD");

    if (date !== null) {
      const old = arrivalDate.clone();
      setArrivalDate(
        old.year(date.year()).month(date.month()).date(date.date()),
      );
    } else {
      setArrivalDate(date);
    }
    setErrorArrivalDate("");
    setFormError("");
  };

  const handleArrivalTimeChange = ({ target: { value } }) => {
    const date = moment(value, "HH:mm");
    if (arrivalDate !== null) {
      const old = arrivalDate.clone();
      setArrivalDate(old.hour(date.hour()).minute(date.minute()));
    } else {
      setArrivalDate(date);
    }
    setErrorArrivalDate("");
    setFormError("");
  };

  const handleExpectedGuestsChange = event => {
    setExpectedGuests(event.target.value);

    setErrorExpectedGuests("");
    setFormError("");
  };

  const handleCancel = () => {
    appStore.setLoading();
    if (appStore.returnUrl) {
      appStore.goBack();
    } else {
      // appStore.routerStore.push("/app/bookings");
    }
  };

  const handleSubmit = event => {
    event.preventDefault();
    let hasError = false;

    if (validator.isEmpty(expectedGuests)) {
      hasError = true;
      setErrorExpectedGuests("Please specify number of guests");
    }

    if (arrivalDate === null) {
      hasError = true;
      setErrorArrivalDate("Please specify arrival date");
    }

    if (!hasError) {
      appStore.setLoading();

      booking.accept();
      booking.setExpectedGuests(expectedGuests);
      booking.setExpectedAt(arrivalDate);
      booking
        .save()
        // .then(() => appStore.routerStore.push("/app/bookings"))
        .catch(error => {
          appStore.log.error(error);
          setFormError(error.message);
          appStore.setLoading(false);
        });
    }
  };

  if (!booking) {
    return null;
  }

  return (
    <Page title="Edit booking">
      <Container maxWidth={false}>
        <form onSubmit={handleSubmit} className={classes.form}>
          <Grid container justifyContent="flex-start">
            {booking.status === "ERROR" && (
              <Grid item xs={12}>
                <Alert variant="warning">{booking.errorReason}</Alert>
              </Grid>
            )}
            {formError && (
              <Grid item xs={12}>
                <FormHelperText error>{formError}</FormHelperText>
              </Grid>
            )}
            <Grid item xs={12}>
              <Typography variant="h5">
                {booking.customer.firstName} {booking.customer.last} @{" "}
                {booking.site.name}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              {" "}
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <TextField
                variant="standard"
                label="Arrival date"
                type="date"
                min={appStore.currentDateTime}
                className={classes.textField}
                value={arrivalDate && arrivalDate.format("YYYY-MM-DD")}
                onChange={handleArrivalDateChange}
                error={!!errorArrivalDate}
                helperText={errorArrivalDate}
                style={{ width: 130, marginRight: 10 }}
              />
              <TextField
                variant="standard"
                label="Time"
                type="time"
                value={arrivalDate && arrivalDate.format("HH:mm")}
                onChange={handleArrivalTimeChange}
                error={!!errorArrivalDate}
                helperText={errorArrivalDate}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <TextField
                variant="standard"
                fullWidth
                autoComplete="off"
                label="Expected guests"
                value={expectedGuests}
                error={!!errorExpectedGuests}
                helperText={errorExpectedGuests}
                onChange={handleExpectedGuestsChange}
              />
            </Grid>
            <Grid item xs={12}>
              <Button
                className={classes.button}
                type="submit"
                color="primary"
                variant="contained"
                onClick={handleSubmit}
              >
                Save
              </Button>

              <Button
                className={classes.button}
                type="submit"
                variant="contained"
                onClick={handleCancel}
              >
                Cancel
              </Button>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h4">Deposits</Typography>
            </Grid>
            <Grid item xs={12}>
              <Table style={{ display: "inline-block" }}>
                <TableBody>
                  {booking.deposits.map(deposit => (
                    <Deposit key={deposit.id} deposit={deposit} />
                  ))}
                </TableBody>
              </Table>
            </Grid>
            <Grid item xs={12}>
              {isAddDepositVisible ? (
                <Paper className={classes.paper} elevation={1}>
                  <AddDepositForm
                    forId={booking.id}
                    onSuccess={() => setAddDepositVisible(false)}
                    onCancel={() => setAddDepositVisible(false)}
                  />
                </Paper>
              ) : (
                <Button
                  className={classes.button}
                  type="submit"
                  color="primary"
                  variant="contained"
                  onClick={() => setAddDepositVisible(true)}
                >
                  Add deposit
                </Button>
              )}
            </Grid>
          </Grid>
        </form>
      </Container>
    </Page>
  );
};

EditComponent.propTypes = {
  appStore: MobXPropTypes.objectOrObservableObject.isRequired,
  classes: PropTypes.shape({
    root: PropTypes.string,
    appFrame: PropTypes.string,
    button: PropTypes.string,
    content: PropTypes.string,
    form: PropTypes.string,
    paper: PropTypes.string,
    tableCell: PropTypes.string,
    textField: PropTypes.string,
  }).isRequired,
};

const Edit = observer(EditComponent);

export default withStyles(styles)(inject("appStore")(Edit));
