import React from "react";
import propTypes from "prop-types";
import { v4 as uuid } from "uuid";

import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";

import { DataGridPro, GridRow } from "@mui/x-data-grid-pro";

import { orderDetailColumns } from "../utils/columns";

const OrdersDetailPanel = ({ row: rowProp }) => {
  // Combine all item group's items and modifiers into one array
  const combinedItems = rowProp.itemGroups.flatMap(itemGroup =>
    itemGroup.items.flatMap(item => [
      item,
      ...item.modifiers.map(modifier => ({
        ...modifier,
        id: uuid(), // For some reason, order item modifiers don't have an graphQL ID field, so we generate one as it's a requirement for the DataGrid
        modifier: true,
      })),
    ]),
  );

  // Calculate the combined serviceCharge from all itemGroups
  const combinedServiceCharge = rowProp.itemGroups.reduce(
    (total, itemGroup) => total + itemGroup.serviceCharge,
    0,
  );

  // Calculate the Order Total value
  const orderTotal = combinedItems.reduce((total, item) => {
    const itemTotal =
      (item.discounted !== null ? item.discounted : item.price) * item.quantity;
    return total + itemTotal;
  }, 0);

  const paymentTotal = ((combinedServiceCharge + orderTotal) / 100).toFixed(2);

  return (
    <Stack
      sx={{ p: 2, height: "100%", boxSizing: "border-box" }}
      direction="column"
    >
      <Paper
        sx={{
          flex: 1,
          width: "100%",
          p: 2,
          mb: 2,
          backgroundColor: "#f8f8f8",
        }}
      >
        <Stack direction="column" spacing={1}>
          <Typography variant="h4" sx={{ pb: 1 }}>
            Order Items
          </Typography>
          <DataGridPro
            autosizeOnMount
            autoHeight
            disableColumnSorting
            disableColumnFilter
            density="compact"
            columns={orderDetailColumns}
            getRowClassName={params =>
              params.row.modifier !== undefined
                ? "item-modifier-row"
                : "item-row"
            }
            getRowHeight={params => {
              if (params.model.modifier !== undefined) {
                return 25;
              }
              return null;
            }}
            rows={combinedItems}
            slots={{
              row: (props = {}) => {
                return (
                  <GridRow {...props} data-testid={`row-${props.index}`} />
                );
              },
            }}
            sx={{
              flex: 1,
              "& .item-row": {
                fontSize: "15px",
                backgroundColor: "transparent",
              },
              "& .item-row:hover": {
                backgroundColor: "transparent",
              },
              "& .MuiDataGrid-columnHeaders .MuiDataGrid-row--borderBottom": {
                backgroundColor: "#e0e0e0",
              },
              "& .item-modifier-row": {
                fontSize: "15px",
                backgroundColor: "#efefef",
              },
              "& .modifier-plu-cell": {
                paddingLeft: "30px",
              },
            }}
            hideFooter
          />
          <Grid container rowSpacing={1} sx={{ width: 300, pt: 2 }}>
            <Grid item xs={6}>
              <Typography sx={{ fontWeight: 700 }}>Service Charge:</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography data-testid="order-service-charge">
                {`£${(combinedServiceCharge / 100).toFixed(2)}`}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography sx={{ fontWeight: 700 }}>Order Total:</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography data-testid="order-total">{`£${(orderTotal / 100).toFixed(2)}`}</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography sx={{ fontWeight: 700 }}>Payment Total:</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography data-testid="order-payment-total">{`£${paymentTotal}`}</Typography>
            </Grid>
          </Grid>
        </Stack>
      </Paper>
    </Stack>
  );
};

OrdersDetailPanel.propTypes = {
  index: propTypes.number,
  row: propTypes.shape({
    id: propTypes.string,
    itemGroups: propTypes.arrayOf(
      propTypes.shape({
        items: propTypes.arrayOf(
          propTypes.shape({
            id: propTypes.string,
            code: propTypes.string,
            quantity: propTypes.number,
            price: propTypes.number,
            discounted: propTypes.number,
            modifiers: propTypes.arrayOf(
              propTypes.shape({
                id: propTypes.string,
                code: propTypes.string,
                quantity: propTypes.number,
                price: propTypes.number,
                discounted: propTypes.number,
              }),
            ),
          }),
        ),
        serviceCharge: propTypes.number,
      }),
    ),
  }).isRequired,
};

export default OrdersDetailPanel;
