import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { styled } from "@mui/material/styles";
import gql from "graphql-tag";
import { useQuery, useMutation } from "@apollo/client";
import { observer, inject, PropTypes as MobXPropTypes } from "mobx-react";
import { useSnackbar } from "notistack";

import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarFilterButton,
  GridToolbarDensitySelector,
  GridToolbarQuickFilter,
} from "@mui/x-data-grid";

import CustomGridRow from "../../../components/Datagrid/CustomGridRow";

import {
  GET_TILL_CONFIG_VALUES_BY_ID,
  UPDATE_TILL_CONFIG_VALUE,
} from "../../../helpers/apollo/utils";

const StyledBox = styled("div")(({ theme }) => ({
  height: "75vh",
  width: "100%",
  "& .Mui-error": {
    backgroundColor: `rgb(126,10,15, ${theme.palette.mode === "dark" ? 0 : 0.1})`,
    color: theme.palette.error.main,
  },
  "& .config-name-cell": {
    backgroundColor: `rgb(200,200,200, ${theme.palette.mode === "dark" ? 0 : 0.1})`,
  },
}));

const getColumns = groupOptions => [
  {
    field: "groupName",
    headerName: "Group",
    type: "singleSelect",
    valueOptions: groupOptions,
    editable: false,
    minWidth: 150,
    cellClassName: "config-name-cell",
  },
  {
    field: "name",
    headerName: "Name",
    type: "text",
    editable: false,
    minWidth: 300,
    cellClassName: "config-name-cell",
  },
  {
    field: "value",
    headerName: "Value",
    type: "text",
    editable: true,
    flex: 1,
  },
];

const CustomToolbar = () => (
  <GridToolbarContainer sx={{ m: 1 }}>
    <GridToolbarFilterButton />
    <GridToolbarDensitySelector
      slotProps={{ tooltip: { title: "Change density" } }}
    />
    <Box sx={{ flexGrow: 1 }} />
    <Grid item sx={{ mt: 0.5, mr: 1 }}>
      <Box sx={{ marginLeft: "auto" }}>
        <GridToolbarQuickFilter />
      </Box>
    </Grid>
  </GridToolbarContainer>
);

const TillConfigBulkEditor = ({ appStore }) => {
  const { tillId } = useParams();
  const { enqueueSnackbar } = useSnackbar();

  const [columns, setColumns] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [loading, setLoading] = useState(true);

  const errorSnackbar = errorMsg => {
    enqueueSnackbar(`Error: ${errorMsg}`, {
      variant: "error",
      SnackbarProps: {
        "data-testid": "till-config-error-snackbar",
      },
    });
  };

  useQuery(gql(GET_TILL_CONFIG_VALUES_BY_ID()), {
    fetchPolicy: "cache-and-network",
    onCompleted: data => {
      if (data?.tillConfigValues?.length) {
        const newTableData = data.tillConfigValues.map(
          ({ configurationId, groupName, name, value }) => ({
            id: configurationId,
            groupName,
            name,
            value,
          }),
        );

        // Get unique group names to be used in the filter dropdown
        const groupNames = [
          ...new Set(newTableData.map(item => item.groupName)),
        ];

        setColumns(getColumns(groupNames));
        setTableData(newTableData);
      }

      appStore.setLoading(false);
      setLoading(false);
    },
    onError: error => {
      appStore.setLoading(false);
      setLoading(false);
      errorSnackbar(error.message);
    },
    skip: !tillId,
    variables: {
      tillId,
    },
  });

  const [updateTillConfig] = useMutation(gql(UPDATE_TILL_CONFIG_VALUE()), {
    onCompleted: () => {
      enqueueSnackbar("Your changes have been saved", {
        SnackbarProps: { "data-testid": "till-config-saved-snackbar" },
        variant: "success",
      });
    },
    onError: error => errorSnackbar(error.message),
    refetchQueries: [gql(GET_TILL_CONFIG_VALUES_BY_ID()), "TillConfigValues"],
  });

  const updateRow = (newRow, oldRow) => {
    if (newRow.value !== oldRow.value) {
      updateTillConfig({
        variables: {
          input: {
            configurationId: newRow.id,
            tillId,
            value: newRow.value,
          },
        },
      });
    }

    return newRow;
  };

  return (
    <StyledBox>
      <DataGrid
        columns={columns}
        editMode="row"
        initialState={{
          density: "compact",
        }}
        loading={loading}
        rows={tableData}
        slots={{
          toolbar: CustomToolbar,
          row: CustomGridRow,
        }}
        slotProps={{
          toolbar: {
            showQuickFilter: true,
          },
        }}
        processRowUpdate={updateRow}
        onProcessRowUpdateError={error => errorSnackbar(error)}
      />
    </StyledBox>
  );
};

TillConfigBulkEditor.propTypes = {
  appStore: MobXPropTypes.objectOrObservableObject.isRequired,
};

export default inject("appStore")(observer(TillConfigBulkEditor));
