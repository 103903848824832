import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import gql from "graphql-tag";
import { useQuery, useMutation } from "@apollo/client";
import { styled } from "@mui/material/styles";
import { observer, inject, PropTypes as MobXPropTypes } from "mobx-react";
import { useSnackbar } from "notistack";
import { v4 } from "uuid";

import { DataGrid, useGridApiRef } from "@mui/x-data-grid";

import CustomGridRow from "../../../components/Datagrid/CustomGridRow";
import CustomToolbar from "../../../components/Datagrid/CustomToolbar";

import { getSubGroupColumns } from "../utils/columns";
import {
  GET_PRODUCT_TAGS_WITH_PARENT,
  ADD_PRODUCT_TAG,
  UPDATE_PRODUCT_TAG,
} from "../../../helpers/apollo/utils";

const StyledBox = styled("div")(({ theme }) => ({
  height: "70vh",
  width: "100%",
  "& .Mui-error": {
    backgroundColor: `rgb(126,10,15, ${theme.palette.mode === "dark" ? 0 : 0.1})`,
    color: theme.palette.error.main,
  },
  "& .posId-cell": {
    backgroundColor: `rgb(200,200,200, ${theme.palette.mode === "dark" ? 0 : 0.1})`,
  },
}));

export const getNewRow = () => ({
  id: v4(),
  POSId: "",
  name: "",
  parentTagId: "",
  accommodationIncome: false,
  priorityPrintGroup: "",
  siteManaged: false,
  // We use this when updating a row to tell us this department needs to be created
  createdRow: true,
});

const SubGroupsBulkEditor = ({
  appStore,
  majorGroups,
  priorityPrintGroups,
}) => {
  const apiRef = useGridApiRef();
  const { enqueueSnackbar } = useSnackbar();

  const [columns, setColumns] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [createdRow, setCreatedRow] = useState(false);

  // We need to update the columns once majorGroups/priorityPrintGroups are loaded
  useEffect(() => {
    setColumns(getSubGroupColumns(apiRef, majorGroups, priorityPrintGroups));
  }, [majorGroups, priorityPrintGroups]);

  const addRow = () => {
    const newRow = getNewRow();

    const newTableData = [...tableData];
    newTableData.unshift(newRow);

    setTableData(newTableData);
    setCreatedRow(true);
  };

  const deleteRow = () => {
    const newTableData = tableData.filter(row => row.createdRow === undefined);

    setTableData(newTableData);
    setCreatedRow(false);
  };

  const errorSnackbar = errorMsg => {
    enqueueSnackbar(`Error: ${errorMsg}`, {
      variant: "error",
      SnackbarProps: {
        "data-testid": "bulk-sub-groups-error-snackbar",
      },
    });
  };

  // We only want POSId to be editable when creating a new SubGroup
  const isCellEditable = params => {
    if (params.row.createdRow) {
      return true;
    } else if (params.field === "POSId") {
      return false;
    }

    return true;
  };

  // Conditionally format the input to include typeSettings in the update input
  const formatUpdateInput = row => {
    const { accommodationIncome, siteManaged, priorityPrintGroup, ...rest } =
      row;

    return {
      ...rest,
      ...((accommodationIncome !== undefined ||
        siteManaged !== undefined ||
        priorityPrintGroup !== undefined) && {
        typeSettings: {
          subgroupSettings: {
            ...(accommodationIncome !== undefined && { accommodationIncome }),
            ...(siteManaged !== undefined && { siteManaged }),
            ...(priorityPrintGroup !== undefined && {
              priorityPrintGroupId: priorityPrintGroup || null,
            }),
          },
        },
      }),
    };
  };

  const updateRow = (newRow, oldRow) => {
    return new Promise((resolve, reject) => {
      const rowUpdates = Object.fromEntries(
        Object.entries(newRow).filter(
          ([key, val]) => key in oldRow && oldRow[key] !== val,
        ),
      );

      // Only mutate if there are changes made
      if (Object.keys(rowUpdates).length) {
        if (newRow.createdRow !== undefined) {
          addProductTag({
            variables: {
              input: {
                type: "SUBGROUP",
                name: newRow.name,
                POSId: parseInt(newRow.POSId),
                parentTagId: newRow.parentTagId,
                typeSettings: {
                  subgroupSettings: {
                    accommodationIncome: newRow.accommodationIncome,
                    siteManaged: newRow.siteManaged,
                    // Add priorityPrintGroupId here when it exists in the mutation
                  },
                },
              },
            },
          })
            .then(() => {
              resolve(newRow);
              setCreatedRow(false);
            })
            .catch(error => {
              reject(error.message);
            });
        } else {
          updateProductTag({
            variables: {
              input: {
                id: newRow.id,
                ...formatUpdateInput(rowUpdates),
              },
            },
          })
            .then(() => {
              resolve(newRow);
            })
            .catch(error => {
              reject(error.message);
            });
        }
      } else {
        resolve(newRow);
      }
    });
  };

  const { loading } = useQuery(gql(GET_PRODUCT_TAGS_WITH_PARENT()), {
    fetchPolicy: "cache-and-network",
    onCompleted: data => {
      if (data?.productTagsWithCount?.productTags) {
        const productTagData = data.productTagsWithCount.productTags;

        const formattedData = productTagData.map(item => ({
          id: item.id,
          POSId: item.POSId,
          name: item.name,
          parentTagId: item.parentTag.id,
          accommodationIncome:
            item.typeSettings.subgroupSettings?.accommodationIncome ?? false,
          priorityPrintGroup:
            item.typeSettings.subgroupSettings?.priorityPrintGroupId ?? "",
          siteManaged: item.typeSettings.subgroupSettings?.siteManaged ?? false,
        }));

        setTableData(formattedData);
      }

      appStore.setLoading(false);
    },
    onError: error => {
      appStore.setLoading(false);
      errorSnackbar(error.message);
    },
    variables: {
      filter: { type_is: ["SUBGROUP"] },
    },
  });

  const [updateProductTag] = useMutation(gql(UPDATE_PRODUCT_TAG()), {
    onCompleted: () => {
      enqueueSnackbar("Your changes have been saved", {
        SnackbarProps: { "data-testid": "bulk-sub-groups-saved-snackbar" },
        variant: "success",
      });
    },
    onError: ({ message }) => errorSnackbar(message),
    refetchQueries: [
      gql(GET_PRODUCT_TAGS_WITH_PARENT()),
      "ProductTagsWithParent",
    ],
  });

  const [addProductTag] = useMutation(gql(ADD_PRODUCT_TAG()), {
    onCompleted: () => {
      enqueueSnackbar("Your new sub group has been created", {
        SnackbarProps: {
          "data-testid": "bulk-sub-groups-added-snackbar",
        },
        variant: "success",
      });
    },
    onError: ({ message }) => errorSnackbar(message),
    refetchQueries: [
      gql(GET_PRODUCT_TAGS_WITH_PARENT()),
      "ProductTagsWithParent",
    ],
  });

  return (
    <StyledBox>
      <DataGrid
        apiRef={apiRef}
        columns={columns}
        editMode="row"
        isCellEditable={isCellEditable}
        rows={tableData}
        loading={loading}
        slots={{
          row: CustomGridRow,
          toolbar: () => (
            <CustomToolbar
              addAction={addRow}
              deleteAction={deleteRow}
              isRowCreated={createdRow}
            />
          ),
        }}
        processRowUpdate={updateRow}
        onProcessRowUpdateError={errorSnackbar}
      />
    </StyledBox>
  );
};

SubGroupsBulkEditor.propTypes = {
  appStore: MobXPropTypes.objectOrObservableObject.isRequired,
  majorGroups: PropTypes.array.isRequired,
  priorityPrintGroups: PropTypes.array.isRequired,
};

export default inject("appStore")(observer(SubGroupsBulkEditor));
