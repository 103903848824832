import React from "react";
import PropTypes from "prop-types";
import { styled } from "@mui/system";
import { Draggable } from "react-beautiful-dnd";

import Avatar from "@mui/material/Avatar";
import Chip from "@mui/material/Chip";

import PromotionChipStyles from "../../styles/DraggableChip";

const StyledChip = styled(Chip)(PromotionChipStyles);

const DraggableProductChip = ({
  item,
  index,
  handleRemove,
  prefix,
  testId = "draggable-chip",
}) => {
  return (
    <Draggable draggableId={item.meta.id} index={index}>
      {draggableProvided => (
        <StyledChip
          avatar={
            <Avatar>
              {item.type === "PRODUCTGROUP" ? "PG" : item.type?.[0]}
            </Avatar>
          }
          key={`${prefix}-${item.id}`}
          label={item.type === "PRODUCT" ? item.tillProductName : item.name}
          onDelete={() => handleRemove(item)}
          title={item.POSId}
          {...draggableProvided.draggableProps}
          {...draggableProvided.dragHandleProps}
          ref={draggableProvided.innerRef}
          data-testid={testId}
        />
      )}
    </Draggable>
  );
};

DraggableProductChip.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    type: PropTypes.string,
    POSId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    tillProductName: PropTypes.string,
    meta: PropTypes.shape({
      id: PropTypes.string,
    }),
  }).isRequired,
  index: PropTypes.number.isRequired,
  handleRemove: PropTypes.func.isRequired,
  prefix: PropTypes.string.isRequired,
  highlight: PropTypes.bool.isRequired,
  testId: PropTypes.string,
};

export default DraggableProductChip;
