import React, { useState } from "react";
import gql from "graphql-tag";
import { useQuery } from "@apollo/client";
import { useSnackbar } from "notistack";

import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

import ErrorBoundary from "../../components/ErrorBoundary";
import Page from "../../components/Page";
import SubGroupsBulkEditor from "./Components/SubGroupsBulkEditor";

import { GET_PRODUCT_TAGS_WITH_COUNT } from "../../helpers/apollo/utils";

const SubGroupsList = () => {
  const { enqueueSnackbar } = useSnackbar();

  const title = "Sub Groups";
  const [majorGroups, setMajorGroups] = useState([]);

  useQuery(gql(GET_PRODUCT_TAGS_WITH_COUNT()), {
    fetchPolicy: "cache-and-network",
    onCompleted: data => {
      if (data?.productTagsWithCount?.productTags) {
        setMajorGroups(data.productTagsWithCount.productTags);
      }
    },
    onError: error => {
      enqueueSnackbar(`Error: ${error.message}`, {
        variant: "error",
        SnackbarProps: {
          "data-testid": "sub-groups-list-error-snackbar",
        },
      });
    },
    variables: {
      filter: { type_is: ["MAJORGROUP"] },
      sort: { field: "name", direction: "ASC" },
    },
  });

  return (
    <ErrorBoundary>
      <Page title={title}>
        <Container maxWidth={false}>
          <Grid container rowSpacing={3}>
            <Grid
              container
              item
              xs={12}
              justifyContent="space-between"
              alignItems="center"
              mt={3}
            >
              <Grid item>
                <Typography variant="h2">{title}</Typography>
              </Grid>
            </Grid>
            <Grid item xs={12} sx={{ width: "0px" }}>
              <SubGroupsBulkEditor
                majorGroups={majorGroups}
                priorityPrintGroups={[]}
              />
            </Grid>
          </Grid>
        </Container>
      </Page>
    </ErrorBoundary>
  );
};

export default SubGroupsList;
