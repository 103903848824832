import { types } from "mobx-state-tree";

export default types
  .model("ReportArgument", {
    id: types.identifier,
    name: types.string,
  })
  .volatile(() => ({
    value: "",
  }))
  .actions(self => ({
    setValue(newValue) {
      self.value = newValue;
    },
  }))
  .views(self => ({
    get requiredArgumentInput() {
      return {
        reportArgumentId: self.id,
        value: self.value,
      };
    },
  }));
