import { types, getEnv, getParent, flow } from "mobx-state-tree";
import gql from "graphql-tag";

import Booking, { fragments as bookingFragments } from "./models/Booking";

import { transformer, removeTypename } from "../helpers";

// @todo treat versioning changes on preProcess
export default types
  .model("BookingStore", {
    count: types.maybe(types.number),
    bookings: types.optional(types.map(Booking), {}),
  })
  .views(self => ({
    get appStore() {
      return getParent(self);
    },
  }))
  .actions(self => {
    const { apolloClient } = getEnv(self);

    return {
      find: flow(function* find(id) {
        if (self.bookings.has(id)) {
          return self.bookings.get(id);
        }

        try {
          const response = yield apolloClient.query({
            query: gql`
            {
              booking(id: "${id}") {
                ...BookingFullDetails
              }
            }
            ${bookingFragments.fullDetails}
          `,
          });

          const { booking } = response.data;

          return Promise.resolve(self.track(booking));
        } catch (error) {
          return Promise.reject(error);
        }
      }),

      findAll: flow(function* findAll(limit = 0, offset = 0, filter = {}) {
        try {
          const response = yield apolloClient.query({
            query: gql`
              {
                bookings(
                  limit: ${limit},
                  offset: ${offset},
                  filter: [${transformer(filter, true)}]
                ) {
                  ...BookingFullDetails
                }
                count: bookingsCount (filter: [${transformer(filter, true)}])
              }
              ${bookingFragments.fullDetails}
            `,
          });

          response.data.bookings.map(booking => self.track(booking));

          self.count = response.data.count;

          return Promise.resolve(self.bookings);
        } catch (error) {
          return Promise.reject(error);
        }
      }),

      add: flow(function* add(entity) {
        try {
          const response = yield apolloClient.mutate({
            mutation: gql`
              mutation addBooking {
                addBooking(input: {
                  ${transformer(entity)}
                }) {
                  ...BookingFullDetails
                }
              }
              ${bookingFragments.fullDetails}
          `,
          });
          const booking = response.data.addBooking;

          return Promise.resolve(booking);
        } catch (error) {
          return Promise.reject(error);
        }
      }),

      update: flow(function* update(entity) {
        try {
          const response = yield apolloClient.mutate({
            mutation: gql`
              mutation updateBooking {
                updateBooking(input: {
                  ${transformer(entity)}
                }) {
                  ...BookingFullDetails
                }
              }
              ${bookingFragments.fullDetails}
          `,
          });
          const booking = response.data.updateBooking;

          return Promise.resolve(booking);
        } catch (error) {
          return Promise.reject(error);
        }
      }),

      track(entity) {
        if (!self.bookings.has(entity.id)) {
          const input = removeTypename(entity);

          input.customer = self.appStore.customerStore.track(input.customer);
          input.site = self.appStore.siteStore.track(input.site);

          input.deposits = input.deposits.map(deposit =>
            self.appStore.depositStore.track(deposit),
          );

          input.createdBy = self.appStore.userStore.track(input.createdBy);

          if (input.updatedBy !== null) {
            input.updatedBy = self.appStore.userStore.track(input.updatedBy);
          }

          return self.bookings.put(input);
        }

        return self.bookings.get(entity.id);
      },
    };
  });
